import { v4 as uuidv4 } from 'uuid'

const doNothing = () => {}
const handleTextClick = () => {}

const apiDataToShow = ['modified', 'inserted']
const dataTemplate = {
  content: '',
  onTextClick: handleTextClick,
  onIconClick: doNothing,
  leftIcons: [
    {
      icon: 'arrow_right',
      iconSize: 'large',
      infos: {},
      uniqueKey: uuidv4(),
    },
  ],
  rightIcons: [],
}

const api = `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSliceSubnetHistory_short`
const apiSpecificItem = `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSliceSubnetHistory`

const cardBoxStyleList = {
  cardStyle: {
    width: '20vw',
    height: '75vh',
  },
  textItemStyle: { marginRight: '20px', marginLeft: '20px' },
  titleStyle: {
    marginTop: '30px',
    backgroundColor: 'background.light',
    paddingLeft: '7%',
    paddingRight: '7%',
    color: 'primary.light',
    width: '105%',
  },
}

const titleDataList = {
  title: '',
  variant: 'h4',
}
export const list = {
  api,
  apiSpecificItem,
  apiDataToShow,
  dataTemplate,
  cardBoxStyleList,
  titleDataList,
}
