import React, { createContext, useContext, useState } from 'react'

const MessageContext = createContext()
const UpdateMessageContext = createContext()

const useMessageContext = () => {
  return useContext(MessageContext)
}

const useUpdateMessageContext = () => {
  return useContext(UpdateMessageContext)
}

const MessageContextProvider = ({ children }) => {
  const [taskId, setTaskId] = useState(null)


  return (
    <MessageContext.Provider value={{ taskId, setTaskId }}>
      <UpdateMessageContext.Provider value={setTaskId}>
        {children}
      </UpdateMessageContext.Provider>
    </MessageContext.Provider>
  )
}

export { MessageContextProvider, useMessageContext, useUpdateMessageContext }
