import React, { useState, useEffect } from 'react'
import CardBox from '../../../components/CardBox/CardBox'
import { useLocation } from 'react-router-dom'

import { list } from '../../../schema/template/templateRAN-List'
import { cardMain } from '../../../schema/template/templateRAN-Main'
import { outsideActions } from '../../../schema/template/templateRAN-outsideData'

import { useCardBoxContext } from '../../../contexts/cardBoxContext'
import useSaveDeleteCardBoxContent from '../../../customHooks/useSaveDeleteCardBoxContent'
import useHttpService from '../../../customHooks/useHttpService'
import { useSnackbar } from '../../../contexts/snackbarContext'

import { checkWhichMissingFields } from '../../../utils'

function RANTemplate() {
  const [usageData, setUsageData] = useState(null)

  const cardBoxContext = useCardBoxContext()
  const httpService = useHttpService()
  const snackbar = useSnackbar()

  const {
    putCardBoxMainJsonData,
    deleteCardBoxMainJsonData,
    deleteCardBoxListData,
    postCardBoxMainJsonData,
    importExcel,
    exportExcel,
  } = useSaveDeleteCardBoxContent()

  //init the cardBoxContext with the list coming from the schema
  useEffect(() => {
    cardBoxContext.init(list, cardMain)
  }, [])

  //Preparing data for the Usage dialog
  useEffect(() => {
    !usageData &&
      cardBoxContext?.cardMainSchema?.actions?.forEach((action) => {
        if (action.icon === 'list')
          httpService.getAPI(action.url, (data) => {
            console.log(data)
            setUsageData(data)
          })
      })
  }, [cardBoxContext])

  useEffect(() => {
    cardBoxContext?.cardMainSchema?.actions?.forEach((action) => {
      if (action.icon === 'list') {
        action.dataApi = usageData
      }
    })
    cardBoxContext.init(list, cardMain)
  }, [usageData])

  //Icons in the card, on the top
  //Assign onClick handlers to the icons inside the card, on the top of it
  useEffect(() => {
    console.log(cardBoxContext.choosenItem)
    if (usageData && cardBoxContext.choosenItem) {
      cardBoxContext?.cardMainSchema?.actions?.forEach((action) => {
        if (action.icon === 'list') {
          action.dataApi.forEach((data) => {
            if (data.id === cardBoxContext.choosenItem.id) {
              action.label = `Usage: ${data.perc}%`
            }
          })
        }
      })
    }
  }, [cardBoxContext.listData, cardBoxContext.choosenItem])

  //Icons on the bottom of the card
  //assign onClick handlers to the actions array of icons you have on the cardMain schema
  useEffect(() => {
    if (cardBoxContext.cardMainSchema) {
      cardBoxContext.cardMainSchema.actions.forEach((action) => {
        if (action.icon === 'save')
          action.onClick = (e, data, context) => {
            const missingFields = checkWhichMissingFields(
              data,
              context.cardMainSchema.schema.fields
            )
            if (missingFields.length > 0) {
              snackbar.setSnackbarInfo({
                status: 'error',
                message: `Before saving, please set the following ${
                  missingFields.length === 1 ? 'field' : 'fields'
                }: ${missingFields.join(', ')}`,
              })
            } else putCardBoxMainJsonData(data, action, context)
          }
        if (action.icon === 'delete')
          action.onClick = (e, data, context) => {
            deleteCardBoxMainJsonData(action, data, context)
          }
        if (action.type === 'dialog') {
          console.log(action)
          action.dialog.buttons.forEach((button) => {
            if (button.type === 'close') {
              button.onClick = () => {
                console.log('close')
              }
            }
          })
        }
      })
    }
  }, [cardBoxContext.cardMainSchema])

  //Icons on the very bottom of the page
  //Assign onClick handlers for the icons outside the list and the card
  useEffect(() => {
    if (outsideActions) {
      outsideActions.map((action) => {
        if (action.type === 'dialog') {
          action.dialog.buttons.forEach((button) => {
            if (button.type === 'close') {
              button.onClick = () => {}
            }
            if (button.type === 'cancel') {
              button.onClick = () => {}
            }
            if (button.type === 'save') {
              button.onClick = (e, data, context) => {
                postCardBoxMainJsonData(data, action, context)
              }
            }
          })
        }
        if (action.type === 'upload') {
          action.onClick = (e, action, context) => {
            importExcel(e, action, context)
          }
        }
        if (action.icon === 'download') {
          action.onClick = (action) => {
            exportExcel(action, true)
          }
        }
      })
    }
  }, [outsideActions])

  //render the CardBox component if there are data to show, passing the outside buttons if needed
  return (
    <>
      {cardBoxContext.listData && <CardBox outsideActions={outsideActions} />}
    </>
  )
}

export default RANTemplate
