import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import TreeView from '@mui/lab/TreeView'
import TreeItem from '@mui/lab/TreeItem'
import ReactJson from 'react-json-view'
import { useTheme } from '@mui/material/styles'
import EnhancedTableBox from '../../components/EnhancedTableBox'
import { Typography } from '@mui/material'
import { fromDBDateToGUIDate } from '../../utils'

function GitlabDashboard() {
  const [retrievedProducts, setretrievedProducts] = useState(null)
  const [retrievedIssues, setretrievedIssues] = useState(null)
  const [retrievedEpics, setretrievedEpics] = useState(null)
  const [jsonTreeData, setjsonTreeData] = useState(null)
  const [tableData, settableData] = useState(null)

  const [expanded, setExpanded] = useState([])
  const [selected, setSelected] = useState([])

  const theme = useTheme()

  //APIs and bodies of the relays
  const relayApi = 'http://127.0.0.1:8010/relay'
  const productsApi =
    'http://127.0.0.1:8010/api/gitlab_products_manager/gitlab_products_manager/product'
  const epicsBody = {
    method: 'GET',
    header: { 'PRIVATE-TOKEN': '##GITLAB_TOKEN##' },
    url: '##GITLAB_URL##/groups/%epics_group_id%/epics',
  }
  const issuesBody = {
    method: 'GET',
    header: { 'PRIVATE-TOKEN': '##GITLAB_TOKEN##' },
    url: '##GITLAB_URL##/projects/%issues_project_id%/issues?state=opened',
  }

  const visibleColumns = [
    {
      id: 'type',
      alignRight: false,
      disablePadding: false,
      label: 'Type',
    },
    {
      id: 'title',
      alignRight: false,
      disablePadding: false,
      label: 'Title',
    },
    {
      id: 'created_at',
      alignRight: false,
      disablePadding: false,
      label: 'Created at',
    },
    {
      id: 'labels',
      alignRight: false,
      disablePadding: false,
      label: 'Labels',
    },
    {
      id: 'state',
      alignRight: false,
      disablePadding: false,
      label: 'Status',
    },
  ]

  const handleToggle = (e, nodeIds) => {
    setExpanded(nodeIds)
  }

  const handleSelect = (e, nodeIds) => {
    setSelected(nodeIds)
  }

  const handleExpandClick = () => {
    setExpanded((oldExpanded) =>
      oldExpanded.length === 0 ? ['1', '5', '6', '7'] : []
    )
  }

  const handleSelectClick = () => {
    setSelected((oldSelected) =>
      oldSelected.length === 0
        ? ['1', '2', '3', '4', '5', '6', '7', '8', '9']
        : []
    )
  }

  //Fetching all the products
  const fetchProducts = async () => {
    return fetch(productsApi).then((data) => data)
  }

  useEffect(() => {
    const getProducts = async () => {
      const fetchedProducts = await fetchProducts()
      const fetchedJsonProducts = await fetchedProducts.json()
      fetchedJsonProducts.result &&
        setretrievedProducts(fetchedJsonProducts.data)
    }
    getProducts()
  }, [])

  //Fetching all the epics
  const fetchEpic = async (body) => {
    return fetch(relayApi, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then((data) => data)
  }

  useEffect(() => {
    if (retrievedProducts && retrievedProducts.length > 0) {
      const getEpic = async () => {
        const fetchedEpics = await Promise.all(
          retrievedProducts.map(async (prod) => {
            let tmpEpicBody = { ...epicsBody }
            tmpEpicBody.url = tmpEpicBody.url.replace(
              '%epics_group_id%',
              prod.epics_group_id
            )
            const fetchedEpic = await fetchEpic(tmpEpicBody)
            const fetchedJsonEpic = await fetchedEpic.json()
            return { group: prod.epics_group_id, epic: fetchedJsonEpic }
          })
        )
        fetchedEpics && setretrievedEpics(fetchedEpics)
      }

      getEpic()
    }
  }, [retrievedProducts])

  //Fetching all the issues
  const fetchIssue = async (body) => {
    return fetch(relayApi, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then((data) => data)
  }

  useEffect(() => {
    if (retrievedProducts && retrievedProducts.length > 0) {
      const getIssue = async () => {
        const fetchedIssues = await Promise.all(
          retrievedProducts.map(async (prod) => {
            let tmpIssueBody = { ...issuesBody }
            tmpIssueBody.url = tmpIssueBody.url.replace(
              '%issues_project_id%',
              prod.issues_project_id
            )
            const fetchedIssue = await fetchIssue(tmpIssueBody)
            const fetchedJsonIssue = await fetchedIssue.json()
            return {
              project_id: prod.issues_project_id,
              issue: fetchedJsonIssue,
            }
          })
        )
        fetchedIssues && setretrievedIssues(fetchedIssues)
      }

      getIssue()
    }
  }, [retrievedProducts])

  const createTableEntry = (type, data) => {
    let tableRow = {}
    tableRow.type = type
    tableRow.title = data.title
    tableRow.created_at = fromDBDateToGUIDate(data.created_at)
    tableRow.labels = data.labels.length > 0 ? data.labels.join(';  ') : null
    tableRow.state = data.state
    return tableRow
  }

  const iterateEpics = (product) => {
    let tableData = []
    for (let index_epic = 0; index_epic < retrievedEpics.length; index_epic++) {
      if (product.epics_group_id === retrievedEpics[index_epic].group) {
        if (retrievedEpics[index_epic].epic.length > 0) {
          product.epics = [...retrievedEpics[index_epic].epic]
          product.epics.forEach((epic) => {
            tableData = [...tableData, createTableEntry('Epic', epic)]
          })
        }
      }
    }
    return tableData
  }

  const iterateIssues = (product) => {
    let tableData = []
    for (
      let index_issue = 0;
      index_issue < retrievedIssues.length;
      index_issue++
    ) {
      if (
        product.issues_project_id === retrievedIssues[index_issue].project_id
      ) {
        if (retrievedIssues[index_issue].issue.length > 0) {
          product.issues = [...retrievedIssues[index_issue].issue]
          product.issues.forEach((issue) => {
            tableData = [...tableData, createTableEntry('Issue', issue)]
          })
        }
      }
    }
    return tableData
  }

  useEffect(() => {
    if (retrievedEpics && retrievedIssues) {
      let jsonData = [...retrievedProducts]
      let tables = []

      for (let index_prod = 0; index_prod < jsonData.length; index_prod++) {
        let tableData = iterateEpics(jsonData[index_prod])

        tableData = [...tableData, ...iterateIssues(jsonData[index_prod])]
        tables = [
          ...tables,
          { title: jsonData[index_prod].name, table: tableData },
        ]
      }
      setjsonTreeData(jsonData)
      settableData(tables)
    }
  }, [retrievedEpics, retrievedIssues])

  return (
    <Box sx={{ margin: '30pt' }}>
      <ReactJson
        style={{ backgroundColor: theme.palette.background.light }}
        theme='harmonic'
        src={jsonTreeData || {}}
        name='Products'
        shouldCollapse={(field) => {
          return field.name !== 'root'
        }}
        //   onEdit={(edit) => {
        //     return true;
        //   }}
        //   onAdd={(add) => {
        //     return true;
        //   }}
      ></ReactJson>
      {tableData &&
        tableData.length > 0 &&
        tableData.map((table) => {
          return (
            <>
              <Typography variant='h4' sx={{ marginTop: '30px' }}>
                {table.title}
              </Typography>
              <EnhancedTableBox
                heightPercentage='0.5'
                title={table.title}
                data={table.table}
                valueMember='id'
                visibleColumns={visibleColumns}
                search
              />
            </>
          )
        })}
    </Box>
  )
}

export default GitlabDashboard
