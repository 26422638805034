import React, { createContext, useContext, useState, useEffect } from 'react'
import UserService from '../services/UserService'

const UserContext = createContext()
const UpdateUserContext = createContext()

const useUserContext = () => {
  return useContext(UserContext)
}

const useUpdateUserContext = () => {
  return useContext(UpdateUserContext)
}

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  const getUser = () => {
    return user
  }

  const initUser = () => {
    if (!UserService.isLoggedIn()) {
      UserService.doLogin()
    } else if (!user) {
      setUser({
        name: UserService.getUsername() || 'Mr Unknown',
        roles: UserService.getRoles(process.env.REACT_APP_KEYCLOAK_RESOURCE),
        token: UserService.getToken(),
        firstName: UserService.getFirstName() || 'Unknown',
        lastName: UserService.getLastName() || 'User',
      })
    }
  }

  !user && initUser()

  return (
    <UserContext.Provider value={{ setUser, getUser }}>
      <UpdateUserContext.Provider value={setUser}>
        {children}
      </UpdateUserContext.Provider>
    </UserContext.Provider>
  )
}

export { UserContextProvider, useUserContext, useUpdateUserContext }
