import { v4 as uuidv4 } from 'uuid'

const doNothing = () => {}
const handleTextClick = () => {}

const apiDataToShow = ['name', 'id']
const dataTemplate = {
  content: '',
  onTextClick: handleTextClick,
  onIconClick: doNothing,
  leftIcons: [
    {
      uniqueKey: uuidv4(),
      icon: 'arrow_right',
      iconSize: 'large',
      infos: {},
    },
  ],
  rightIcons: [],
}

const api = `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSlice_short`
const apiSpecificItem = `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSlice?id=`

const cardBoxStyleList = {
  cardStyle: {
    width: '20vw',
    height: '75vh',
  },
  textItemStyle: { marginRight: '30px', marginLeft: '30px' },
  titleStyle: {
    marginTop: '30px',
    backgroundColor: 'background.light',
    paddingLeft: '7%',
    paddingRight: '7%',
    color: 'primary.light',
    width: '105%',
  },
}

const titleDataList = {
  title: 'List of NSI',
  variant: 'h4',
}

export const list = {
  api,
  apiSpecificItem,
  apiDataToShow,
  dataTemplate,
  cardBoxStyleList,
  titleDataList,
}
