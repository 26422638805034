export function MyToken() {
    return localStorage.getItem('token');
}


function SetToken(token) {
    localStorage.setItem('token', token);
    //window.location.reload();
}

const baseURL = "https://networkteam.it.nttdata-emea.com";

export function GetToken(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: username, password: password })
    };
    fetch(`${baseURL}/users/authenticateext`, requestOptions)
        .then(response => response.json())
        .then(rj => SetToken(rj.data.token))
        .catch((error) => {
            console.error(error);
        });
};



export function GetZone() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + MyToken()
        }
    };
    fetch(`${baseURL}/api/scalar/istat/zone`, requestOptions)
        .then(response => response.json())
        .then(rj => { return rj })
        .catch((error) => {
            console.error(error);
        });
};


export function GetRegioni(zona) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + MyToken()
        }
    };
    fetch(`${baseURL}/api/scalar/istat/zona_regione?zone_id=${zona}`, requestOptions)
        .then(response => response.json())
        .then(rj => { return rj })
        .catch((error) => {
            console.error(error);
        });
};