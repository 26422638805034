import * as React from 'react'
import produce from 'immer'
import { set, has } from 'lodash'
import { useEffect } from 'react'
import { Grid } from '@mui/material'
import Button from '@mui/material/Button'
import { TextField } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import EnhancedTableBox from '../../components/EnhancedTableBox'

function enhancedReducer(state, updateArg) {
  // check if the type of update argument is a callback function
  if (updateArg.constructor === Function) {
    return { ...state, ...updateArg(state) }
  }

  // if the type of update argument is an object
  if (updateArg.constructor === Object) {
    // does the update object have _path and _value as it's keys
    // if yes then use them to update deep object values
    if (has(updateArg, '_path') && has(updateArg, '_value')) {
      const { _path, _value } = updateArg

      return produce(state, (draft) => {
        set(draft, _path, _value)
      })
    } else {
      return { ...state, ...updateArg }
    }
  }
}

const visibleColumnsPoles = [
  {
    id: 'baseHeight',
    alignRight: false,
    disablePadding: false,
    label: 'Altezza base tronco (m)',
  },
  {
    id: 'summitHeight',
    alignRight: true,
    disablePadding: false,
    label: 'Altezza sommità tronco (m)',
  },
  {
    id: 'overlappingLogs',
    alignRight: true,
    disablePadding: false,
    label: 'Sovrapposizione tronchi (m)',
  },
  {
    id: 'extBaseDiameter',
    alignRight: true,
    disablePadding: false,
    label: 'Diametro ext. base (mm)',
  },
  {
    id: 'extSummitDiameter',
    alignRight: true,
    disablePadding: false,
    label: 'Diametro ext. sommità (mm)',
  },
  {
    id: 'thickness',
    alignRight: true,
    disablePadding: false,
    label: 'Spessore (mm)',
  },
  {
    id: 'sidesSection',
    alignRight: true,
    disablePadding: false,
    label: 'Lati sezione',
  },
  {
    id: 'steelType',
    alignRight: true,
    disablePadding: false,
    label: 'Tipo Acciaio',
  },
]

const actions = [
  {
    action: 'edit',
    icon: 'edit',
    tooltip: 'edit current row',
  },
  {
    action: 'delete',
    icon: 'delete',
    tooltip: 'delete current row',
  },
]

export default function TestForm() {
  function queryAPI(myURL) {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    fetch(myURL, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status)
        } else return response.json()
      })
      .then((jsondata) => {
        setFormState(jsondata)
      })
      .catch((error) => {
        console.error(error)
        //localStorage.removeItem('token');
        //window.location.reload();
      })
  }

  function onActionPoles(action, row, e) {
    setLastSelectedRowId(row.id)
    setPolePopupState(row)
    setopenPutPoles(true)
  }

  useEffect(() => {
    queryAPI(
      'https://networkteam.it.nttdata-emea.com/api/scalar/statica/structures?site_code=I068AT'
    )
  }, [])

  const [formState, setFormState] = React.useReducer(enhancedReducer, {})
  const [lastSelectedRowId, setLastSelectedRowId] = React.useState()
  const [polePopupState, setPolePopupState] = React.useReducer(
    enhancedReducer,
    {}
  )
  const [openPutPoles, setopenPutPoles] = React.useState(false)

  const handleCloseCancel = () => {
    setopenPutPoles(false)
  }

  const handleClosePutPolesSave = () => {
    setopenPutPoles(false)
    var mynewState = formState
    mynewState.data.data.structures[0].ssdStaticaPoles[lastSelectedRowId] =
      polePopupState
    setFormState(mynewState)
  }

  const updateForm = React.useCallback(
    ({ target: { value, name, type } }, stateSetter) => {
      const updatePath = name.split('.')

      // if the input is a checkbox then use callback function to update
      // the toggle state based on previous state
      if (type === 'checkbox') {
        stateSetter((prevState) => ({
          [name]: !prevState[name],
        }))

        return
      }

      // if we have to update the root level nodes in the form
      if (updatePath.length === 1) {
        const [key] = updatePath

        stateSetter({
          [key]: value,
        })
      }

      // if we have to update nested nodes in the form object
      // use _path and _value to update them.
      if (updatePath.length > 1) {
        stateSetter({
          _path: updatePath,
          _value: value,
        })
      }
    },
    []
  )

  if (formState.data)
    return (
      <div className='App'>
        <h1>Hello, i'm your supposedly complex form.</h1>
        <TextField
          helperText='Altitudine'
          FormHelperTextProps={{ style: { fontSize: 10 } }}
          style={{ width: '70%' }}
          variant='standard'
          type='number'
          name='data.data.ssdStaticaTerritorialParameter.altitude'
          onChange={(e) => {
            updateForm(e, setFormState)
          }}
          value={formState.data.data.ssdStaticaTerritorialParameter.altitude}
        />

        <EnhancedTableBox
          headerStyle={{ backgroundColor: 'white', color: 'blue' }}
          heightPercentage='1'
          title='Sezioni'
          data={formState.data.data.structures[0].ssdStaticaPoles}
          valueMember='id'
          visibleColumns={visibleColumnsPoles}
          actions={actions}
          onAction={onActionPoles}
        />

        <Button variant='text' onClick={() => alert(JSON.stringify(formState))}>
          SAVE
        </Button>

        {/*******************************************EDIT POP UP FOR POLES***********************************************/}

        <Dialog open={openPutPoles} onClose={handleCloseCancel}>
          <DialogTitle>Edit poles parameters</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <TextField
                  label='Altezza base'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='baseHeight'
                  value={polePopupState.baseHeight}
                  onChange={(e) => {
                    updateForm(e, setPolePopupState)
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancel}>Cancel</Button>
            <Button onClick={handleClosePutPolesSave}>Save</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  else return <div className='App'></div>
}
