import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material'
import { Link } from 'react-router-dom'
import EnhancedIconButton from '../EnhancedIconButton'
import JsonSchemaBox from '../JsonSchemaBox'
import {
  overrideStyle,
  isNonEmptyObject,
  countRequiredInSchema,
  getArrayOfMandatoryIfInSchema,
  getTotalRequired,
  checkRequiredFieldNumber,
} from '../../utils'

import { Prompt } from 'react-router-dom'

import { useCardBoxContext } from '../../contexts/cardBoxContext'
import EnhancedTableBox from '../EnhancedTableBox'
import { useRouteMatch } from 'react-router-dom'

function CardBoxMainJson({
  cardBoxStyle,
  titleVariant,
  actionButtons,
  schemaFields,
}) {
  const [currentDialog, setCurrentDialog] = useState(null)
  const [dialogData, setDialogData] = useState(null)
  const [cardData, setCardData] = useState(null)
  const [cannotSave, setCannotSave] = useState(false)

  const cardBoxContext = useCardBoxContext()

  const dialogs = actionButtons?.filter((a) => a.type === 'dialog')
  // const routeMatch = useRouteMatch()
  // console.log(routeMatch)

  // const alertUser = (e) => {
  //   e.preventDefault()
  //   e.returnValue = ''
  // }

  // const beforeUnload = () => cardBoxContext.setModifiedData(false)

  // useEffect(() => {
  //   window.addEventListener('beforeunload', alertUser)
  //   window.addEventListener('unload', beforeUnload)
  //   return () => {
  //     window.removeEventListener('beforeunload', alertUser)
  //     window.removeEventListener('unload', beforeUnload)
  //   }
  // }, [])

  //ENABLE THIS PIECE OF CODE IF YOU WANT TO ACTIVATE DISABLE/ENABLE BUTTON SYSTEM. REMEMBER TO PUT cannotSave TO true IN THE INITIALIZATION OF THE STATE
  // useEffect(() => {
  //   if (isNonEmptyObject(cardData)) {
  //     console.log(schemaFields, currentDialog)
  //     const schema = currentDialog?.dialog?.schemaFields || schemaFields
  //     let howManyRequiredInSchema = countRequiredInSchema(schema)

  //     if (howManyRequiredInSchema === 0) {
  //       setCannotSave(false)
  //       return
  //     }

  //     const mandatoryIfInSchema = getArrayOfMandatoryIfInSchema(schema)
  //     const totalRequired = getTotalRequired(
  //       howManyRequiredInSchema,
  //       mandatoryIfInSchema,
  //       cardData
  //     )

  //     if (totalRequired > 0) {
  //       const checked = checkRequiredFieldNumber(
  //         totalRequired,
  //         { ...currentDialog },
  //         schema,
  //         cardData,
  //         actionButtons
  //       )
  //       if (checked) {
  //         setCannotSave(false)
  //       } else {
  //         setCannotSave(true)
  //       }
  //     }
  //   }
  // }, [cardData])

  return (
    <>
      {/* {
        <Prompt
          when={cardBoxContext.modifiedData}
          message='There are not saved data! Are sure you want to leave?'
        />
      } */}
      {/* if a dialog has been opened, we render it */}
      {currentDialog?.dialog && (
        <Dialog
          sx={currentDialog.dialog.style?.card}
          fullWidth
          maxWidth='lg'
          open={true}
          onClose={() => {
            setCurrentDialog(null)
          }}
        >
          <DialogTitle sx={currentDialog.dialog.style?.title}>
            {currentDialog.dialog.title}
          </DialogTitle>
          <DialogContent sx={currentDialog.dialog.style?.content}>
            {/* if the content of the dialog is a table, we render the table */}
            {currentDialog.dialog.content?.type === 'table' ? (
              <EnhancedTableBox
                title={currentDialog.dialog.content.title}
                visibleColumns={currentDialog.dialog.content.visibleColumns}
                valueMember={currentDialog.dialog.content.valueMember}
                apiURL={`${currentDialog.dialog.content.url}${cardBoxContext.choosenItem.id}`}
                heightPercentage='0.7'
              />
            ) : currentDialog.dialog.content?.type === 'json' ? (
              <pre>
                {JSON.stringify(currentDialog.dialog.dialogData, null, 2)}
              </pre>
            ) : (
              // otherwise, we render a jsonSchemaBox in the dialog
              <JsonSchemaBox
                schema={currentDialog.dialog.schemaFields}
                formData={currentDialog?.dialog?.dialogData || null}
                tabs={currentDialog?.dialog?.tabs || null}
                onOnlyModifiedDataChange={(data) => {
                  console.log(data)
                  if (data) {
                    cardBoxContext.modifiedData = true
                    setDialogData({ ...dialogData, ...data })
                  }
                }}
              />
            )}
          </DialogContent>
          {/* Now we render the buttons of the dialog */}
          <DialogActions sx={currentDialog.dialog.style?.actions}>
            {currentDialog.dialog.buttons.map((button) => {
              return (
                <Button
                  onClick={(e) => {
                    let dataToSend = { ...cardData, ...dialogData }
                    button.onClick(e, dataToSend, cardBoxContext)
                    setCurrentDialog(null)
                  }}
                >
                  {button.type}
                </Button>
              )
            })}
          </DialogActions>
        </Dialog>
      )}
      {/* Now we render the main card, but only if we have enought informations */}
      {cardBoxContext?.choosenItem && (
        <Paper
          elevation={3}
          sx={overrideStyle(
            {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
            },
            cardBoxStyle.cardStyle
          )}
        >
          <Box sx={{ ...cardBoxStyle.titleStyle }}>
            <Typography variant={titleVariant}>
              {cardBoxContext.mainCardTitle || ''}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '2%',
              justifyContent: 'space-evenly',
              width: '100%',
              alignItems: 'center',
              // border: '1px solid purple',
            }}
          >
            {/* here we will be showing the button icons on the top of the card,
              if we have any */}
            {actionButtons &&
              actionButtons.length > 0 &&
              actionButtons
                .filter((action) => action.place === 'top')
                ?.map((action) => (
                  <Box
                    key={action.uniqueKey}
                    sx={overrideStyle(
                      {
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      },
                      cardBoxStyle?.iconStyle?.topBox
                    )}
                  >
                    {action.label}
                    {/* a button may open a dialog, so we check its type
                      property ('dialog') */}
                    {action.type === 'dialog' && (
                      <EnhancedIconButton
                        key={`${action.uniqueKey}-EnhancedIconButton`}
                        icon={action.icon}
                        sx={{ cursor: 'pointer' }}
                        tooltip={action.tooltip || null}
                        onClick={() => {
                          dialogs.forEach((d) => {
                            if (d.icon === action.icon) {
                              setCurrentDialog(d) //we set the clicked dialog as the current one
                            }
                          })
                        }}
                      />
                    )}
                    {/* a button may bring to another page, so we check its type
                      property ('link') */}
                    {action.type === 'link' && (
                      <EnhancedIconButton
                        key={`${action.uniqueKey}-EnhancedIconButton`}
                        icon={action.icon}
                        sx={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          //we can assign an onClick event to the link button as well
                          if (typeof action.onClick === 'function') {
                            action.onClick(e, cardBoxContext)
                          }
                        }}
                        tooltip={action.tooltip || null}
                        link={
                          action.params
                            ? `../${action.url}/${action.params}`
                            : `../${action.url}`
                        }
                      ></EnhancedIconButton>
                    )}
                    {/* here we have icon buttons with no other type of actions */}
                    {action.type === 'icon' && (
                      <EnhancedIconButton
                        key={`${action.uniqueKey}-EnhancedIconButton-2`}
                        icon={action.icon}
                        sx={{
                          cursor:
                            action.onClick != null &&
                            String(action.onClick).replaceAll(' ', '') !==
                              '()=>{}' &&
                            String(action.onClick).replaceAll(' ', '') !==
                              'function(){}' &&
                            String(action.onClick).replaceAll(' ', '') !==
                              'f(){}'
                              ? 'pointer'
                              : 'unset',
                        }}
                        tooltip={action.tooltip || null}
                        onClick={(e) => {
                          if (typeof action.onClick === 'function') {
                            action.onClick(e, null, cardBoxContext)
                          }
                        }}
                      />
                    )}
                  </Box>
                ))}
          </Box>
          {/* inside the body of the card we render the JsonSchemaBox */}
          {cardBoxContext.choosenItem && (
            <JsonSchemaBox
              schema={schemaFields}
              formData={cardBoxContext.choosenItem}
              onChange={(data) => {
                console.log(data)
                setCardData({ ...cardData, ...data })
              }}
              tabs={cardBoxContext?.cardMainSchema?.schema?.tabs || null}
              // onOnlyModiedDataChange={(data) => {
              //   console.log(data)
              //   if (data) cardBoxContext.modifiedData.current = true
              // }}
            />
          )}
          {/* then we show the buttons on the bottom of the card, if there are
            any */}
          {actionButtons.length > 0 && (
            <Box
              sx={overrideStyle(
                {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  width: '100%',
                  flex: 1,
                  // border: '1px solid red',
                  padding: '2%',
                },
                cardBoxStyle?.iconStyle?.bottomBox
              )}
            >
              {/* even here we can have icon buttons with type 'dialog' */}
              {actionButtons.map((actionButton) => {
                if (actionButton.place === 'bottom') {
                  if (actionButton.type === 'dialog') {
                    console.log(actionButton, cannotSave)
                    return (
                      <EnhancedIconButton
                        key={`${actionButton.uniqueKey}-EnhancedIconButton`}
                        icon={actionButton.icon}
                        size={actionButton.iconSize}
                        sx={{
                          marginLeft: '15px',
                          cursor: 'pointer',
                          ...(actionButton.style || ''),
                        }}
                        tooltip={actionButton.tooltip || null}
                        disabled={
                          actionButton.disabled != null
                            ? actionButton.disabled
                            : actionButton.icon === 'save'
                            ? cannotSave
                            : false
                        }
                        onClick={() => {
                          dialogs.forEach((d) => {
                            if (d.icon === actionButton.icon) {
                              setCurrentDialog(d) //we set the clicked dialog as the current one
                            }
                          })
                        }}
                      />
                    )
                  } else {
                    // if the icon button does not open any dialog, we just render the button with its own onClick method
                    return (
                      <EnhancedIconButton
                        key={actionButton.uniqueKey}
                        icon={actionButton.icon}
                        size={actionButton.iconSize}
                        tooltip={actionButton.tooltip || null}
                        disabled={
                          actionButton.disabled != null
                            ? actionButton.disabled
                            : actionButton.icon === 'save'
                            ? cannotSave
                            : false
                        }
                        onClick={(e) => {
                          const dataToSend = { ...cardData, ...dialogData }
                          actionButton.onClick(e, dataToSend, cardBoxContext)
                        }}
                      />
                    )
                  }
                }
              })}
            </Box>
          )}
        </Paper>
      )}
    </>
  )
}

export default CardBoxMainJson
