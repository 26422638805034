import React from 'react'
import EnhancedTableBox from '../../components/EnhancedTableBox'
import { Box } from '@mui/material'

const Platform__credential = () => {
  //array of the columns we want to show on the table
  const visibleColumns = [
    {
      id: 'name',
      alignRight: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'hostserver',
      alignRight: true,
      disablePadding: false,
      label: 'Host',
    },
    {
      id: 'username',
      alignRight: true,
      disablePadding: false,
      label: 'Username',
    },
    // {
    //     id: "certificate_authority_data",
    //     alignRight: false,
    //     disablePadding: false,
    //     label: "Certificate Authority"
    // },
    // {
    //     id: "client_certificate_data",
    //     alignRight: false,
    //     disablePadding: false,
    //     label: "Client Certificate"
    // },
    // {
    //     id: "client_key_data",
    //     alignRight: false,
    //     disablePadding: false,
    //     label: "Client Key"
    // },
  ]

  //array of actions placed at the end of each row
  const actions = [
    {
      action: 'edit',
      icon: 'edit',
      tooltip: 'edit current row',
    },

    {
      action: 'delete',
      icon: 'delete',
      tooltip: 'delete current row',
    },
  ]

  const editSchema = {
    name: {
      title: 'Name',
      type: 'text',
      md: 6,
    },
    hostserver: {
      title: 'Host',
      type: 'text',
      md: 6,
    },
    username: {
      title: 'Username',
      type: 'text',
      md: 6,
    },
    password: {
      title: 'Password',
      type: 'password',
      md: 6,
    },
    certificate_authority_data: {
      multiline: true,
      title: 'Certificate Authority',
      type: 'text',
      md: 12,
    },
    client_certificate_data: {
      multiline: true,
      title: 'Client Certificate',
      type: 'text',
      md: 12,
    },
    client_key_data: {
      multiline: true,
      title: 'Client Key',
      type: 'text',
      md: 12,
    },
  }

  return (
    <Box sx={{ padding: '2%' }}>
      <EnhancedTableBox
        heightPercentage='0.7'
        title='Platform Credential'
        apiURL={`${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/platform_credential`}
        valueMember='id'
        visibleColumns={visibleColumns}
        actions={actions}
        //onAction={onAction}
        addButton
        //addJsonSchema={{}}
        editJsonSchema={editSchema}
        search
      />
    </Box>
  )
}
export default Platform__credential
