import { v4 as uuidv4 } from 'uuid'
import cardMain from './templateSP-Main'
const doNothing = () => {}

const {
  id,
  name,
  plmnInfoList_Id,
  sst,
  latency,
  // delayTolerance,
  dLThptPerUE,
  uLThptPerUE,
  maxDLDataVolume,
  maxULDataVolume,
  maxNumberofUEs,
  dLThptPerSlice_guaThpt,
  dLThptPerSlice_maxThpt,
  uLThptPerSlice_guaThpt,
  uLThptPerSlice_maxThpt,
  sliceSimultaneousUse,
  positioning_availability,
  positioning_Predictionfrequency,
  positioning_Accuracy,
  v2XModels,
  nROperatingBands,

  ...schemaFields
} = cardMain.schema.fields

export const outsideActions = [
  {
    icon: 'add_circle',
    label: 'Add New Item',
    place: 'bottom',
    onClick: doNothing,
    type: 'dialog',
    uniqueKey: uuidv4(),
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/ServiceProfile`,
    dialog: {
      title: 'Add New Item',
      style: {
        card: {},
        title: {
          marginTop: '30px',
          backgroundColor: 'background.light',
          paddingLeft: '6%',
          paddingRight: '6%',
          color: 'primary.light',
          width: '100%',
        },
        content: {},
        actions: {},
      },
      schemaFields: {
        plmnInfoList_Id: {
          ...plmnInfoList_Id,
          mandatory: true,
          showAsterisk: true,
        },
        name: {
          ...name,
          mandatory: true,
          showAsterisk: true,
        },
        sst: {
          ...sst,
          mandatory: true,
          showAsterisk: true,
        },
        maxNumberofUEs: {
          ...maxNumberofUEs,
          mandatory: true,
          showAsterisk: true,
        },
        latency: {
          ...latency,
          mandatory: true,
          showAsterisk: true,
        },
        // delayTolerance: {
        //   ...delayTolerance,
        //   mandatory: true,
        // },
        dLThptPerUE: {
          ...dLThptPerUE,
          mandatory: true,
          showAsterisk: true,
        },
        uLThptPerUE: {
          ...uLThptPerUE,
          mandatory: true,
          showAsterisk: true,
        },
        positioning_availability: {
          ...positioning_availability,
          mandatory: true,
          showAsterisk: true,
        },
        positioning_Predictionfrequency: {
          ...positioning_Predictionfrequency,
          mandatory: true,
          showAsterisk: true,
        },
        positioning_Accuracy: {
          ...positioning_Accuracy,
          mandatory: true,
          showAsterisk: true,
        },
        maxDLDataVolume: {
          ...maxDLDataVolume,
          mandatory: true,
          showAsterisk: true,
        },
        maxULDataVolume: {
          ...maxULDataVolume,
          mandatory: true,
          showAsterisk: true,
        },
        sliceSimultaneousUse: {
          ...sliceSimultaneousUse,
          mandatory: true,
          showAsterisk: true,
        },
        v2XModels: {
          ...v2XModels,
          mandatory: true,
          showAsterisk: true,
        },
        nROperatingBands: {
          ...nROperatingBands,
          mandatory: true,
          showAsterisk: true,
        },
        ...schemaFields,
      },
      // tabs: ['General', 'Deterministic Comm', 'Positioning'],
      tabs: ['General', 'Positioning', 'User Related Data'],
      buttons: [
        { type: 'cancel' },
        { type: 'save', tooltip: 'Please fill all the mandatory attributes.' },
      ],
    },
  },
  {
    icon: 'upload',
    label: 'Import Item',
    place: 'bottom',
    onClick: doNothing,
    type: 'upload',
    uniqueKey: uuidv4(),
    url: `${process.env.REACT_APP_API_ADDRESS.replace(
      '/api',
      ''
    )}/fileio/nssmf_managed_object/ServiceProfile/import`,
  },
  {
    icon: 'download',
    label: 'Export Format',
    place: 'bottom',
    onClick: doNothing,
    type: 'template',
    uniqueKey: uuidv4(),
    // url: `${process.env.REACT_APP_API_ADDRESS.replace(
    //   '/api',
    //   ''
    // )}/fileio/nssmf_managed_object/ServiceProfile/template`,
    templateURL: `/Template_ServiceProfile.xlsx`,
  },
  {
    icon: 'download',
    label: 'Download Data',
    place: 'bottom',
    onClick: doNothing,
    type: 'export',
    uniqueKey: uuidv4(),
    url: `${process.env.REACT_APP_API_ADDRESS.replace(
      '/api',
      ''
    )}/fileio/nssmf_managed_object/ServiceProfile/export`,
  },
]
