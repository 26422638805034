import React from 'react'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { Link } from 'react-router-dom'

export default function EnhancedIconButton(props) {
  const iconComponent = (
    <Icon fontSize={props.size} style={props.sx}>
      {props.icon}
    </Icon>
  )

  const iconButtonComponent = (
    <IconButton
      onClick={props.onClick}
      size={props.size}
      color={props.color}
      sx={props.sx}
      disabled={props.disabled}
    >
      {iconComponent}
    </IconButton>
  )

  const tooltipComponent = (
    <Tooltip title={props.tooltip}>
      {props.onClick ? iconButtonComponent : iconComponent}
    </Tooltip>
  )

  const fileDownloadComponent = props.templateURL != null && (
    <a href={props.templateURL} download>
      {props.tooltip
        ? tooltipComponent
        : props.onClick
        ? iconButtonComponent
        : iconComponent}
    </a>
  )

  const linkComponent = (
    <Link
      to={{
        pathname: props.link,
      }}
      style={{ all: 'unset' }}
      onLinkClick={props.onLinkClick}
    >
      {props.tooltip
        ? tooltipComponent
        : props.onClick
        ? iconButtonComponent
        : iconComponent}
    </Link>
  )

  return (
    <>
      {props.link
        ? linkComponent
        : props.templateURL
        ? fileDownloadComponent
        : props.tooltip
        ? tooltipComponent
        : props.onClick
        ? iconButtonComponent
        : iconComponent}
    </>
  )
}
