import { v4 as uuidv4 } from 'uuid';

import mainCard from './mainData-NF';
const doNothing = () => {};

export const outsideActions = [
	// {
	//   uniqueKey: uuidv4(),
	//   icon: 'add_circle',
	//   label: 'Add New NF (FFS)',
	//   place: 'bottom',
	//   onClick: doNothing,
	//   url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkFunction`,
	//   type: 'dialog',
	//   dialog: {
	//     style: {
	//       card: {},
	//       title: {
	//         marginTop: '30px',
	//         backgroundColor: 'background.light',
	//         paddingLeft: '6%',
	//         paddingRight: '6%',
	//         color: 'primary.light',
	//         width: '100%',
	//       },
	//       content: {},
	//       actions: {},
	//     },
	//     schemaFields: mainCard.schema.fields,
	//     tabs: [],
	//     onDataChange: doNothing,
	//   },
	// },
	// {
	//   uniqueKey: uuidv4(),
	//   icon: 'upload',
	//   type: 'upload',
	//   label: 'Import Item',
	//   place: 'bottom',
	//   onClick: doNothing,
	//   url: `${process.env.REACT_APP_API_ADDRESS.replace(
	//     '/api',
	//     ''
	//   )}/fileio/nssmf_managed_object/NetworkFunction/import`,
	// },
	// {
	//   uniqueKey: uuidv4(),
	//   icon: 'download',
	//   type: 'template',
	//   label: 'Export Format',
	//   place: 'bottom',
	//   onClick: doNothing,
	//   url: `${process.env.REACT_APP_API_ADDRESS.replace(
	//     '/api',
	//     ''
	//   )}/fileio/nssmf_managed_object/NetworkFunction/template`,
	// },
	{
		uniqueKey: uuidv4(),
		icon: 'download',
		type: 'export',
		label: 'Download Data',
		place: 'bottom',
		onClick: doNothing,
		url: `${process.env.REACT_APP_API_ADDRESS.replace(
			'/api',
			''
		)}/fileio/nssmf_managed_object/NetworkFunction/export`,
	},
];
