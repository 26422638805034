import { createTheme } from '@mui/material'

const gold = '#e6b600' //primary color
const darkGold = '#b09350 ' //disabled color
const lightGold = '#fee6b6' //secondary color
const white = '#FFFFFF' // primary string color
const background = '#070e27' //theme background
const navy = '#0f1c50' // list data components background
const backgroundLight = '#242f57' // header components background
const backgroundDark = '#1f123e' // main data components background
const darkRed = '#d32f2f' //text helper color for errors

const customtheme = createTheme({
  palette: {
    root: {
      color: white,
    },
    primary: {
      main: gold,
      dark: darkGold,
      light: lightGold,
    },
    secondary: {
      main: white,
    },
    background: {
      default: background, //theme background
      paper: navy, //list data components background
      light: backgroundLight, //ligher navy, components header
      dark: backgroundDark, //dark violet, components main data
    },
    text: {
      primary: gold, //general string parameters
      error: darkRed,
    },
    divider: white, //components parameters divider
  },

  //components specific theme
  components: {
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: white,
        },
      },
    },
    //table cell, used to color homepage Table Cell Header
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: '1rem',
          color: white,
          backgroundColor: gold,
        },
      },
    },
    //sort hover, active states in homepage Table
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: white,
          },
          ':hover': {
            color: navy,
          },
        },
      },
    },
    // inputs label, used in all inputs
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: white,
        },
      },
    },
    // icons color
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: gold,
          '&.Mui-disabled': { color: darkGold },
        },
      },
    },
    // inputs label in disabled state
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: white,
          },
        },
      },
    },
    // input value in disabled state
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&.Mui-disabled': {
            '-webkit-text-fill-color': darkGold,
          },
        },
        root: {
          'border-bottom:before': 5,
        },
      },
    },

    // input select box arrow color
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: lightGold,
        },
      },
    },

    // input border outline color amd width controls
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          'border-color': lightGold,
          'border-width': 2,
        },
      },
    },

    // check boxes across the site, control color
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': { color: white },
          '&.Mui-disabled': { color: darkGold },
          color: gold,
        },
      },
    },

    // control color of the underline of all inputs
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottom: '2px solid #fee6b6',
          },
        },
      },
    },

    // control icon colors of Schedule calendar
    MuiTab: {
      styleOverrides: {
        root: {
          color: gold,
        },
      },
    },

    // control input colors of Schedule calendar
    MuiTypography: {
      styleOverrides: {
        caption: {
          color: white,
          'background-color': gold,
          'font-size': '20px',
        },
      },
    },

    // scrollbar theme controls
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: 'green',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: background,
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: gold,
            minHeight: 2,
            border: '4px solid #2b2b2b',
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
            {
              backgroundColor: '#959595',
            },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#2b2b2b',
          },
        },
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          [`&.Mui-disabled`]: {
            color: `${darkGold} !important`,
            opacity: 0.5,
          },
        },
      },
    },

    //For Tab, here is an example
    // MuiTab: {
    //   styleOverrides: {
    //     root: {
    //       '&.Mui-selected': {
    //         backgroundColor: 'purple',
    //         color: 'green',
    //         borderRadius: '25px',
    //       },
    //     },
    //   },
    // },
  },
})

export default customtheme
