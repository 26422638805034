import React, { useState } from 'react'
import { Grid } from '@mui/material'
import ComboBox from '../../components/ComboBox'

import EnhancedTableBox from '../../components/EnhancedTableBox'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import EnhancedIconButton from '../../components/EnhancedIconButton'

import { useConfirm } from 'material-ui-confirm'

export default function B() {
  //begin - combo boxes selection states
  const [selectedZone, setSelectedZone] = useState()
  function onChangeZone(e) {
    setSelectedZone(e.id)
    setSelectedRegion()
    setSelectedProvince()
  }

  const [selectedRegion, setSelectedRegion] = useState()
  function onChangeRegion(e) {
    setSelectedRegion(e.cod_reg)
    setSelectedProvince()
  }

  const [selectedProvince, setSelectedProvince] = useState()
  function onChangeProvince(e) {
    setSelectedProvince(e.cod_prov)
  }

  const [selectedMunicipality, setSelectedMunicipality] = useState()
  function onChangeMunicipality(e) {
    setSelectedMunicipality(e)
  }
  //end - combo boxes selection states

  //array of the columns we want to show on the table
  const visibleColumns = [
    {
      id: 'gid',
      alignRight: false,
      disablePadding: false,
      label: 'G ID',
    },
    {
      id: 'comune',
      alignRight: false,
      disablePadding: false,
      label: 'Municipality',
    },
    {
      id: 'pro_com_t',
      alignRight: true,
      disablePadding: false,
      label: 'procomT',
    },
  ]

  //array of actions placed at the end of each row
  const actions = [
    {
      action: 'edit',
      icon: 'edit',
      tooltip: 'edit current row',
    },
    {
      action: 'clone',
      icon: 'copy_all',
      tooltip: 'clone current row',
    },
    {
      action: 'delete',
      icon: 'delete',
      tooltip: 'delete current row',
    },
    {
      action: 'options',
      icon: 'more_vert',
    },
  ]

  //prepare the confirm dialog
  const confirm = useConfirm()

  //event that is fired when an action is clicked
  function onAction(action, row, e) {
    //set the clicked row in a state
    setActionRow(row)

    //different actions may have different beaviour
    switch (action) {
      case 'edit':
        setOpen(true)
        break
      case 'options':
        setAnchorEl(e.target)
        break
      case 'delete':
        confirm({
          description: `This will permanently delete ${row.gid}.`,
        }).then(() => {})
        break
      default:
        alert(`called action ${action} on item ${JSON.stringify(row)}`)
        break
    }
  }
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [actionRow, setActionRow] = React.useState(null)

  function PositionedMenu() {
    const open = Boolean(anchorEl)

    const handleClosePositionedMenu = () => {
      setAnchorEl(null)
    }

    return (
      <div>
        <Menu
          id='demo-positioned-menu'
          aria-labelledby='demo-positioned-button'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClosePositionedMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {actionRow && actionRow.gid === 2131 && (
            <MenuItem onClick={handleClosePositionedMenu}>
              Is Albisola Superiore
            </MenuItem>
          )}
          <MenuItem onClick={handleClosePositionedMenu}>other menu</MenuItem>
          <MenuItem onClick={handleClosePositionedMenu}>another one</MenuItem>
        </Menu>
      </div>
    )
  }

  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Grid container spacing={5} padding={5}>
        <Grid item md={3}>
          <ComboBox
            label='Zone'
            apiURL='https://networkteam.it.nttdata-emea.com/api/scalar/istat/zone'
            valueMember='id'
            displayMember='zone_name'
            onSelectChange={onChangeZone}
            // selectedValue=""
          />
        </Grid>

        <Grid item md={3}>
          <ComboBox
            label='Region'
            apiURL={`https://networkteam.it.nttdata-emea.com/api/scalar/istat/zona_regione?zone_id=${selectedZone}`}
            valueMember='cod_reg'
            displayMember='den_reg'
            onSelectChange={onChangeRegion}
          />
        </Grid>

        <Grid item md={3}>
          <ComboBox
            label='Province'
            apiURL={`https://networkteam.it.nttdata-emea.com/api/scalar/istat/province?id=${selectedRegion}`}
            valueMember='gid'
            displayMember='den_pcm'
            onSelectChange={onChangeProvince}
          />
        </Grid>
        <Grid item md={1}>
          <EnhancedIconButton
            size='large'
            tooltip='push to add a new item'
            icon='add_circle'
          />
        </Grid>
        {/* <Grid item md={3}>
                    <ComboBox
                        label="Municipality"
                        apiURL={`https://networkteam.it.nttdata-emea.com/api/scalar/istat/comuni?id=${selectedProvince}`}
                        valueMember="gid"
                        displayMember="comune"
                        onSelectChange={onChangeMunicipality}
                    />
                </Grid> 

                <Grid item md={12}>
                    <TableBox
                        apiURL={`https://networkteam.it.nttdata-emea.com/api/scalar/istat/comuni?id=${selectedProvince}`}
                        valueMember="gid"
                        visibleColumns={['gid', 'comune', 'pro_com_t']}
                        columnCaptions={['id', 'municipality', 'pro_code']}
                    />
                </Grid>*/}

        <Grid item md={12}>
          <EnhancedTableBox
            heightPercentage='0.5'
            title='Municipalities'
            multiselect
            onSelectChange={onChangeMunicipality}
            apiURL={`https://networkteam.it.nttdata-emea.com/api/scalar/istat/comuni?id=${selectedProvince}`}
            valueMember='gid'
            visibleColumns={visibleColumns}
            actions={actions}
            onAction={onAction}
          />
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit</DialogTitle>
        <DialogContent>
          <DialogContentText>bla bla bla.</DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Email Address'
            type='email'
            fullWidth
            variant='standard'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Save</Button>
        </DialogActions>
      </Dialog>
      <PositionedMenu />
    </div>
  )
}
