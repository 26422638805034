import { MobileFriendlyTwoTone } from '@mui/icons-material'
import { v4 as uuidv4 } from 'uuid'

export const onDataChange = () => {}
const doNothing = () => {}
const saveData = () => {}

export const cardMainActions = [
  {
    icon: 'list',
    place: 'top',
    label: 'Usage: 0%',
    type: 'dialog',
    iconSize: 'large',
    onClick: doNothing,
    tooltip: 'List of Slice Subnet using this template',
    uniqueKey: uuidv4(),
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/RANSliceSubnetProfile_usage`,
    dialog: {
      style: {
        card: {},
        title: {},
        content: {},
        actions: {},
      },
      content: {
        type: 'table',
        title: 'List of Network Slice Subnet using this template',
        valueMember: 'id',
        url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSliceSubnet?RANSliceSubnetProfile_id=`,
        visibleColumns: [
          {
            id: 'name',
            alignRight: false,
            disablePadding: false,
            label: 'Name',
          },
          {
            id: 'RANSliceSubnetProfile',
            alignRight: false,
            disablePadding: false,
            label: 'Template',
          },
        ],
      },
      buttons: [{ type: 'close' }],
    },
  },

  {
    icon: 'delete',
    iconSize: 'large',
    place: 'bottom',
    onClick: doNothing,
    tooltip: 'Delete',
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/RANSliceSubnetProfile`,
    uniqueKey: uuidv4(),
  },
  {
    icon: 'save',
    iconSize: 'large',
    place: 'bottom',
    onClick: saveData,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/RANSliceSubnetProfile`,
    tooltip: 'Save Changes',
    uniqueKey: uuidv4(),
  },
]

const schemaFields = {
  id: {
    title: 'ID',
    type: 'text',
    md: 4,
    disabled: true,
    tab: 'General',
  },
  name: {
    title: 'Name',
    type: 'text',
    md: 4,
    tab: 'General',
    mandatory: true,
    showAsterisk: false,
  },
  maxNumberofUEs: {
    title: 'Max Number of UEs',
    type: 'number',
    md: 4,
    tooltip: `An attribute specifies the maximum number of UEs may simultaneously access the network slice or network slice subnet instance.`,
    nonNegative: true,
    tab: 'General',
    mandatory: true,
    showAsterisk: false,
  },
  coverageAreaTAList: {
    title: 'Coverage Area TA List',
    type: 'text',
    md: 4,
    placeholder: '1,2,3',
    admittedChars: /^[0-9\,]+$/,
    tooltip: `An attribute specifies a list of Tracking Areas for the network slice .`,
    tab: 'General',
    mandatory: true,
    showAsterisk: false,
  },
  uEMobilityLevel: {
    title: 'UE Mobility Level',
    type: 'combo',
    md: 4,
    tooltip: `An attribute specifies the mobility level of UE accessing the network slice. See 6.2.1 of TS 22.261 [28].
    allowedValues: stationary, nomadic, restricted mobility, fully mobility.`,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/uEMobilityLevel`,
    valueMember: 'val',
    displayMember: 'val',
    allowEmptySelection: true,
    tab: 'User Related Data',
  },
  resourceSharingLevel: {
    title: 'Resource Sharing Level',
    type: 'combo',
    md: 4,
    tooltip: `An attribute specifies whether the resources to be allocated to the network slice subnet may be shared with another network slice subnet(s).
    allowedValues: shared, non-shared.`,
    tab: 'General',
    mockedData: ['Empty', 'SHARED', 'NON-SHARED'],
  },
  // delayTolerance: {
  //   title: 'Delay Tolerance',
  //   type: 'text',
  //   md: 4,
  //   tooltip: `An attribute specifies the properties of service delivery flexibility, especially for the vertical services that are not chasing a high system performance. See clause 4.3 of TS 22.104 [51].`,
  //   enum: ['SUPPORTED', 'NOT SUPPORTED'],
  // },
  // deterministicCommDlAvailability: {
  //   title: 'DL Availability',
  //   type: 'text',
  //   md: 4,
  //   enum: ['SUPPORTED', 'NOT SUPPORTED'],
  //   tab: 'Deterministic Comm',
  //   tooltip: `An attribute specifies the properties of service delivery flexibility, especially for the vertical services that are not chasing a high system performance. See clause 4.3 of TS 22.104 [51].`,
  // },
  // deterministicCommDlPeriodicityList: {
  //   title: 'DL Periodicity List (s)',
  //   type: 'number',
  //   md: 4,
  //   nonNegative: true,
  //   tab: 'Deterministic Comm',
  //   tooltip: `An attribute specifies the properties of service delivery flexibility, especially for the vertical services that are not chasing a high system performance. See clause 4.3 of TS 22.104 [51].`,
  // },
  // deterministicCommUlAvailability: {
  //   title: 'UL Availability',
  //   type: 'text',
  //   md: 4,
  //   enum: ['SUPPORTED', 'NOT SUPPORTED'],
  //   tab: 'Deterministic Comm',
  //   tooltip: `An attribute specifies the properties of service delivery flexibility, especially for the vertical services that are not chasing a high system performance. See clause 4.3 of TS 22.104 [51].`,
  // },
  // deterministicCommUlperiodicityList: {
  //   title: 'UL Periodicity List (s)',
  //   type: 'number',
  //   md: 4,
  //   nonNegative: true,
  //   tab: 'Deterministic Comm',
  //   tooltip: `An attribute specifies the properties of service delivery flexibility, especially for the vertical services that are not chasing a high system performance. See clause 4.3 of TS 22.104 [51].`,
  // },
  // dLThptPerSlice: {
  //   title: `DL THPT per Slice`,
  //   type: 'number',
  //   disabled: true,
  //   md: 4,
  //   tab: 'General',
  //   tooltip: `This attribute defines achievable data rate of the network slice in downlink that is available ubiquitously across the coverage area of the slice, refer NG.116 [50]. he value is automatically calculated leveraging on maxNumberofUEs,  activityFactor and dLThptUE.`,
  // },
  dLThptPerUE: {
    title: 'DL THPT per UE (Mbps)',
    type: 'combo',
    md: 4,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/mod_ThptPerUE`,
    valueMember: 'name',
    displayMember: 'name',
    tooltip: `This attribute defines data rate supported by the network slice per UE, refer NG.116 [50]. `,
    tab: 'User Related Data',
    mandatory: true,
    showAsterisk: false,
  },
  // uLThptPerSlice: {
  //   title: `UL THPT per Slice (Mbps)`,
  //   type: 'number',
  //   md: 4,
  //   tooltip: `This attribute defines achievable data rate of the network slice in downlink that is available ubiquitously across the coverage area of the slice, refer NG.116 [50]. The value is automatically calculated leveraging on maxNumberofUEs,  activityFactor and uLThptUE.`,
  //   tab: 'General',
  //   disabled: true,
  // },
  uLThptPerUE: {
    title: 'UL THPT per UE (Mbps)',
    type: 'combo',
    md: 4,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/mod_ThptPerUE`,
    valueMember: 'name',
    displayMember: 'name',
    tooltip: `This attribute defines data rate supported by the network slice per UE, refer NG.116 [50]. `,
    tab: 'User Related Data',
    mandatory: true,
    showAsterisk: false,
  },
  dLMaxPktSize: {
    title: 'DL Max Packet Size',
    type: 'number',
    md: 4,
    nonNegative: true,
    tooltip: `This parameter specifies the maximum packet size supported by the network slice, refer NG.116 [50]. `,
    tab: 'General',
  },
  uLMaxPktSize: {
    title: 'UL Max Packet Size',
    type: 'number',
    md: 4,
    nonNegative: true,
    tooltip: `This parameter specifies the maximum packet size supported by the network slice, refer NG.116 [50]. `,
    tab: 'General',
  },
  termDensity: {
    title: 'Term Density',
    type: 'number',
    nonNegative: true,
    md: 4,
    tooltip: `An attribute specifies the overall user density over the coverage area of the network slice. See Table 7.1-1 of TS 22.261 [28]).`,
    tab: 'General',
  },
  activityFactor: {
    title: 'Activity Factor (%)',
    type: 'number',
    nonNegative: true,
    maxValue: 100,
    md: 4,
    tooltip: `An attribute specifies the percentage value of the amount of simultaneous active UEs to the total number of UEs where active means the UEs are exchanging data with the network. See Table 7.1-1 of TS 22.261 [28]).`,
    tab: 'General',
  },
  uESpeed: {
    title: 'UE Speed',
    type: 'combo',
    md: 4,
    tooltip: `An attribute specifies the maximum speed (in km/hour) supported by the network slice or network slice subnet at which a defined QoS can be achieved. See Table 7.1-1 of TS 22.261 [28]).`,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/mod_uESpeed`,
    valueMember: 'id',
    displayMember: 'name',
    allowEmptySelection: true,
    tab: 'User Related Data',
  },
  survivalTime: {
    title: 'Survival Time (ms)',
    type: 'number',
    md: 4,
    tooltip: `An attribute specifies the time that an application consuming a communication service may continue without an anticipated message. See clause 5 of TS 22.104 [51]).`,
    nonNegative: true,
    tab: 'General',
  },
  reliability: {
    title: 'Reliability (%)',
    type: 'combo',
    md: 4,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/mod_sst`,
    valueMember: 'reliability',
    displayMember: 'fullname',
    tab: 'General',
    allowEmptySelection: true,
    tooltip: `An attribute specifies in the context of network layer packet transmissions, percentage value of the amount of sent network layer packets successfully delivered to a given system entity within the time constraint required by the targeted service, divided by the total number of sent network layer packets, see TS 22.261 [28] and TS 22.104 [51].`,
  },
  serviceType: {
    title: 'Service Type',
    type: 'combo',
    md: 4,
    tooltip: `An attribute specifies the standardized network slice type.
    allowedValues: eMBB, URLLC, MIoT, V2X.`,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/mod_sst`,
    valueMember: 'name',
    displayMember: 'name',
    allowEmptySelection: true,
    tab: 'General',
  },
  // synchronicity: {
  //   title: 'Synchronicity',
  //   type: 'number',
  //   decimals: 2,
  //   md: 4,
  // },
  positioning_availability: {
    title: 'Availability',
    type: 'list',
    dense: true,
    multiselect: true,
    md: 4,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/mod_positioning_availability`,
    valueMember: 'name',
    displayMember: 'name',
    tooltip: `An attribute specifies whether the network slice provides geo-localization methods or supporting methods, see clause 3.4.20 of NG.116 [50].`,
    tab: 'Positioning',
    mandatory: true,
    showAsterisk: false,
  },
  positioning_Predictionfrequency: {
    title: 'Prediction Frequency',
    type: 'text',
    md: 4,
    enum: ['PER SECOND', 'PER MINUTE', 'PER HOUR'],
    tooltip: `An attribute specifies whether the network slice provides geo-localization methods or supporting methods, see clause 3.4.20 of NG.116 [50].`,
    tab: 'Positioning',
    mandatory: true,
    showAsterisk: false,
  },
  positioning_Accuracy: {
    title: 'Accuracy (m)',
    type: 'number',
    nonNegative: true,
    md: 4,
    nonNegative: true,
    tab: 'Positioning',
    mandatory: true,
    showAsterisk: false,
  },
  sliceSimultaneousUse: {
    title: 'Slice Simultaneous Use ',
    type: 'combo',
    md: 4,
    tooltip: `This attribute describes whether a network slice can be simultaneously used by a device together with other network slices and if so, with which other classes of network slices.
    Admitted values:
    “0”: Can be used with any network slice
    “1”: Can be used with network slices with same SST value
    “2”: Can be used with any network slice with same SD value
    “3”: Cannot be used with another network slice
    “4”: Cannot be used by a UE in a specific location`,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/mod_sliceSimultaneousUse`,
    valueMember: 'id',
    displayMember: 'name',
    tab: 'General',
    mandatory: true,
    showAsterisk: false,
  },
  // energyEfficiency: {
  //   title: 'Energy Efficiency',
  //   type: 'number',
  //   md: 4,
  //   tooltip: `An attribute which describes the energy efficiency, i.e. the ratio between the performance and the energy consumption (EC) when assessed during the same time frame, see clause 3.4.7 of NG.116 [50].`,
  //   enum: ['SUPPORTED', 'NOT SUPPORTED'],
  // },
  nROperatingBands: {
    title: 'NR Operating Bands',
    type: 'list',
    multiselect: true,
    dense: true,
    md: 4,
    valueMember: 'name',
    displayMember: 'name',
    tab: 'General',
    tooltip: `It represents which 5G NR frequency bands can be used to access the network slice. 5G NR operating bands are defined in 3GPP TS 38.101-1 [42].`,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/mod_nROperatingBands`,
    mandatory: true,
    showAsterisk: false,
  },
  // inserted: {
  //   title: 'Inserted',
  //   type: 'text',
  //   md: 4,
  //   tab: 'General',
  //   disabled: true,
  // },
  // modified: {
  //   title: 'Modified',
  //   type: 'text',
  //   md: 4,
  //   tab: 'General',
  //   disabled: true,
  // },
  dLThptPerSlice_guaThpt: {
    title: `DL gua Thpt per Slice`,
    type: 'number',
    nonNegative: true,
    md: 4,
    disabled: true,
    tab: 'User Related Data',
    tooltip: `This attribute defines achievable data rate of the network slice in downlink that is available ubiquitously across the coverage area of the slice, refer NG.116 [50]. he value is automatically calculated leveraging on maxNumberofUEs,  activityFactor and dLThptUE.`,
  },
  dLThptPerSlice_maxThpt: {
    title: `DL max Thpt per Slice`,
    type: 'number',
    nonNegative: true,
    md: 4,
    disabled: true,
    tab: 'User Related Data',
    tooltip: `This attribute defines achievable data rate of the network slice in downlink that is available ubiquitously across the coverage area of the slice, refer NG.116 [50]. he value is automatically calculated leveraging on maxNumberofUEs,  activityFactor and dLThptUE.`,
  },
  uLThptPerSlice_guaThpt: {
    title: `UL gua Thpt per Slice`,
    type: 'number',
    nonNegative: true,
    md: 4,
    disabled: true,
    tab: 'User Related Data',
    tooltip: `This attribute defines achievable data rate of the network slice in downlink that is available ubiquitously across the coverage area of the slice, refer NG.116 [50]. he value is automatically calculated leveraging on maxNumberofUEs,  activityFactor and uLThptUE.`,
  },
  uLThptPerSlice_maxThpt: {
    title: `UL max Thpt per Slice`,
    type: 'number',
    nonNegative: true,
    md: 4,
    disabled: true,
    tab: 'User Related Data',
    tooltip: `This attribute defines achievable data rate of the network slice in downlink that is available ubiquitously across the coverage area of the slice, refer NG.116 [50]. he value is automatically calculated leveraging on maxNumberofUEs,  activityFactor and uLThptUE.`,
  },
}

const tabs = ['General', 'Positioning', 'User Related Data']
// const tabs = ['General', 'Deterministic Comm', 'Positioning']

const schemaValues = {
  id: '',
  name: '',
  maxNumberofUEs: '',
  coverageAreaTAList: '',
  uEMobilityLevel: '',
  resourceSharingLevel: '',
  delayTolerance: '',
  dLDeterministicComm: '',
  uLDeterministicComm: '',
  dLThptPerUE: '',
  uLThptPerUE: '',
  dLMaxPktSize: '',
  uLMaxPktSize: '',
  nROperatingBands: '',
  termDensity: '',
  activityFactor: '',
  uESpeed: '',
  survivalTime: '',
  reliability: '',
  serviceType: '',
  synchronicity: '',
  positioning: '',
  sliceSimultaneousUse: '',
  energyEfficiency: '',
  inserted: '',
  modified: '',
}

const cardBoxStyleMain = {
  cardStyle: {
    width: '65vw',
    height: '75vh',
    backgroundColor: 'background.dark',
  },
  titleStyle: {
    marginTop: '30px',
    backgroundColor: 'background.light',
    paddingLeft: '6%',
    paddingRight: '6%',
    color: 'primary.light',
    width: '102%',
  },
  iconStyle: {
    topBox: {
      // border: '1px solid red',
    },
    bottomBox: {
      // border: '1px solid green',
    },
  },
}

const titleDataMain = {
  variant: 'h4',
}

export const cardMain = {
  actions: cardMainActions,
  schema: {
    fields: schemaFields,
    values: schemaValues,
    tabs,
  },
  onDataChange,
  style: cardBoxStyleMain,
  title: titleDataMain,
}
