import React, { useEffect } from 'react'
import CardBox from '../../components/CardBox/CardBox'

import { list } from '../../schema/previousNSSI-list/listData-previousNSSI'
import cardMain from '../../schema/previousNSSI-list/mainData-previousNSSI'

import { useCardBoxContext } from '../../contexts/cardBoxContext'
import useSaveDeleteCardBoxContent from '../../customHooks/useSaveDeleteCardBoxContent'
import { useParams } from 'react-router-dom'
import useHttpService from '../../customHooks/useHttpService'

function PreviousNSSI() {
  const cardBoxContext = useCardBoxContext()
  const pathParams = useParams()

  const httpService = useHttpService()

  const url = `${list.api.split('?id')[0]}?id=${pathParams.id}`
  list.titleDataList.title = `${pathParams.name} old configurations`
  list.api = url

  const {
    putCardBoxMainJsonData,
    deleteCardBoxMainJsonData,
    deleteCardBoxListData,
  } = useSaveDeleteCardBoxContent()

  //init the cardBoxContext with the list coming from the schema
  useEffect(() => {
    cardBoxContext.init(list, cardMain)
  }, [])

  //assign onClick handlers to the rightIcons array of icons you have on the dataTemplate of your listSchema
  useEffect(() => {
    if (cardBoxContext.cardListSchema) {
      cardBoxContext.cardListSchema.dataTemplate.rightIcons?.forEach((icon) => {
        if (icon.icon === 'delete') {
          icon.onClick = (e, index, context) => {
            deleteCardBoxListData(icon, index, context)
          }
        }
      })
    }
  }, [cardBoxContext.cardListSchema])

  const normalizeEmptyFields = (data) => {
    data.uEMobilityLevel =
      data.uEMobilityLevel == null ? 'Empty' : data.uEMobilityLevel
    data.resourceSharingLevel =
      data.resourceSharingLevel == null ? 'Empty' : data.resourceSharingLevel
    data.serviceType = data.serviceType == null ? 'Empty' : data.serviceType
    data.activityFactor =
      data.activityFactor == null ? '_' : data.activityFactor
    data.uESpeed = data.uESpeed == null ? '_' : data.uESpeed
    data.reliability = data.reliability == null ? '_' : data.reliability
    data.dLMaxPktSize = data.dLMaxPktSize == null ? '_' : data.dLMaxPktSize
    data.uLMaxPktSize = data.uLMaxPktSize == null ? '_' : data.uLMaxPktSize
    data.termDensity = data.termDensity == null ? '_' : data.termDensity
    data.survivalTime = data.survivalTime == null ? '_' : data.survivalTime
    return data
  }

  //assign onClick handlers to the actions array of icons you have on the cardMain schema
  useEffect(() => {
    if (cardBoxContext.cardMainSchema) {
      cardBoxContext.cardMainSchema.actions.forEach((action) => {
        if (action.icon === 'restore') {
          action.onClick = (e, data, context) => {
            return putCardBoxMainJsonData(
              normalizeEmptyFields(data),
              action,
              context
            )
          }
        }
        if (action.icon === 'delete')
          action.onClick = (e, data, context) => {
            deleteCardBoxMainJsonData(action, data, context)
          }
        if (action.type === 'dialog') {
          console.log(action)
          action.dialog.buttons.forEach((button) => {
            if (button.type === 'close') {
              console.log('close')
              button.onClick = () => {
                console.log('close')
              }
            }
          })
        }
      })
    }
  }, [cardBoxContext.cardMainSchema])

  useEffect(() => {
    cardBoxContext?.choosenItem?.id &&
      cardBoxContext?.cardMainSchema?.actions?.forEach((contextAction) => {
        if (contextAction.label === 'Policy (parameters)') {
          const url = `${contextAction.dialog.url}?id=${cardBoxContext.choosenItem.id}&progid=${cardBoxContext.choosenItem.progid}`
          httpService.getAPI(url, (data) => {
            cardMain.actions.forEach((cardMainAction) => {
              if (cardMainAction.label === 'Policy (parameters)') {
                data[0].coverageAreaTAList =
                  data[0].coverageAreaTAList.toString()
                cardMainAction.dialog.dialogData = data[0]
                cardBoxContext.init(list, cardMain)
              }
            })
          })
        }
      })
  }, [cardBoxContext.choosenItem])

  //render the CardBox component if there are data to show, passing the outside buttons if needed
  return <>{cardBoxContext.listData && <CardBox />}</>
}

export default PreviousNSSI
