import React, { useState, useEffect, useRef } from 'react'

// import "react-table/react-table.css";
import { Grid } from '@mui/material'

import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import useHttpService from '../../customHooks/useHttpService.js'

import SignalrMessageList from '../../components/SignalrMessageList'
import { HubConnectionBuilder } from '@microsoft/signalr'

import EnhancedIconButton from '../../components/EnhancedIconButton'

const EvalMessages = () => {
  const { uuid } = useParams()
  const history = useHistory()
  const httpService = useHttpService()
  const [chat, setChat] = useState([])
  const latestChat = useRef(null)
  const [chatCounter, setChatCounter] = useState()

  latestChat.current = chat
  const [taskId, setTaskId] = useState()

  const [roomCode, setRoomCode] = React.useState('')

  useEffect(() => {
    httpService.getAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/eval/eval?uuid=${uuid}`,
      onDataLoaded
    )
  }, [uuid])

  function onDataLoaded(data) {
    setRoomCode(data[0].room_code)
  }

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(`https://networkteam.it.nttdata-emea.com/chatHub`)
      .withAutomaticReconnect()
      .build()

    connection
      .start()
      .then((result) => {
        console.log('SignalR connected!')

        connection.on('ReceiveMessage', (user, message) => {
          console.log(message)
          if (message.startsWith(`(${uuid}) `)) {
            message = message.replace(`(${uuid}) `, '')
            //{"group_id": "27861ff6-6541-42b3-82b1-07724264be6c", "user_id": "EM", "status": "INFO", "message": ">> Drawing AIE for sector 220 (pole = 1, total azimuth = 220)"}

            const updatedChat = [...latestChat.current]
            updatedChat.unshift({ user: user, message: JSON.parse(message) })
            setChat(updatedChat)
            setChatCounter(updatedChat.length)
          }
        })

        connection.invoke('JoinGroup', 'reactuser', uuid)
      })
      .catch((e) => console.log('Connection failed: ', e))
  }, [])

  function goToEvalList() {
    window.location.href = `https://networkteam.it.nttdata-emea.com/Radiomod/cem/site/${roomCode}/simulations-history`
  }

  return (
    <Grid container spacing={5} padding={5}>
      <Grid item md={11}></Grid>
      <Grid item md={1}>
        <EnhancedIconButton
          icon='list'
          size='large'
          onClick={goToEvalList}
          tooltip='Back to eval list'
        />
      </Grid>

      <Grid item md={12}>
        <SignalrMessageList messages={chat} taskId={taskId} />
      </Grid>
    </Grid>
  )
}

export default EvalMessages
