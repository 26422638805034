import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { alpha } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableFooter from '@mui/material/TableFooter'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import { TextField } from '@mui/material'

import { visuallyHidden } from '@mui/utils'

import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import { useLocation, useParams } from 'react-router-dom'

import LinearProgress from '@mui/material/LinearProgress'
import { v4 as uuidv4 } from 'uuid'

import EnhancedIconButton from './EnhancedIconButton'

import { useConfirm } from 'material-ui-confirm'
import JsonSchemaBox from './JsonSchemaBox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import useHttpService from '../customHooks/useHttpService.js'

import TablePagination from '@mui/material/TablePagination'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { fromDBDateToGUIDate } from '../utils'

const isItAdministrativeOperationalOrAlarm = (field) => {
  const wordKeys = [
    'unlocked',
    'locked',
    'disabled',
    'enabled',
    'critical',
    'major',
    'minor',
    'warning',
    'cleared',
    'indeterminate',
  ]
  // console.log(field)
  field = field[0] || field
  if (field.hasOwnProperty('props') && field.props.hasOwnProperty('tooltip')) {
    return (
      wordKeys.find((w) => field.props.tooltip.toLowerCase().includes(w)) ||
      null
    )
  }
  return null
}

function descendingComparator(a, b, orderBy) {
  console.log(a[orderBy], b[orderBy])

  if (a == null || b == null || a[orderBy] == null || b[orderBy] == null) {
    return a == null ? 1 : b == null ? -1 : a[orderBy] ? 1 : b[orderBy] ? -1 : 0
  }

  if (typeof a[orderBy] === 'object') {
    const tmpA = isItAdministrativeOperationalOrAlarm(a[orderBy])
    const tmpB = isItAdministrativeOperationalOrAlarm(b[orderBy])
    return (tmpA === null) - (tmpB === null) || -(tmpA > tmpB) || +(tmpA < tmpB)
  }

  if (typeof a[orderBy] === 'number') {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
    return -1
  }
  if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator, page, rowsPerPage) {
  let stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })

  stabilizedThis = stabilizedThis.map((el) => el[0])

  return rowsPerPage > 0
    ? stabilizedThis.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : stabilizedThis
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  var mystyle = { backgroundColor: 'primary.main', color: 'white' }
  if (props.headerStyle) mystyle = props.headerStyle

  return (
    <TableHead>
      <TableRow sx={{ height: props.rowHeight ? props.rowHeight : '50px' }}>
        {props.multiselect && (
          <TableCell padding='checkbox'>
            <Checkbox
              color='primary'
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all',
              }}
            />
          </TableCell>
        )}
        {props.visibleColumns.map((headCell) => (
          <TableCell
            key={uuidv4()}
            align={headCell.alignRight ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <b style={headCell.vertical && { 'writing-mode': 'vertical-rl' }}>
                {' '}
                &nbsp;{headCell.label} &nbsp;
              </b>
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}

        {props.actions && <TableCell />}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  rowCount: PropTypes.number.isRequired,
}

const EnhancedTableToolbar = (props) => {
  const [searchValue, setSearchValue] = useState(props?.searchWord || '')

  useEffect(() => {
    props?.searchWord !== null && setSearchValue(props.searchWord)
  }, [props.searchWord])

  const { numSelected } = props

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        pt: { xs: 1, sm: 2 },
        pb: { xs: 1, sm: 2 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color='inherit'
          variant='subtitle1'
          component='div'
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant='h6'
          id='tableTitle'
          component='div'
        >
          {props.title}
        </Typography>
      )}
      {props.search && (
        <TextField
          id='search'
          sx={{ marginRight: '1em', minWidth: '30%' }}
          variant='outlined'
          label='filter'
          type='search'
          onChange={(e) => {
            setSearchValue(e.target.value)
            props.setSearchWord(e.target.value)
          }}
          value={searchValue}
          //   InputProps={{
          //     endAdornment: (
          //       <InputAdornment position='start'>
          //         <EnhancedIconButton
          //           icon='search'
          //           onClick={() => {
          //             console.log(searchValue)
          //             props.setSearchWord(searchValue)
          //           }}
          //         />
          //       </InputAdornment>
          //     ),
          //   }}
        ></TextField>
      )}
      {props.onRefresh && (
        <EnhancedIconButton
          icon='refresh'
          onClick={props.onRefresh}
          tooltip='Refresh'
        />
      )}
      {props.onAdd && (
        <EnhancedIconButton
          icon='add'
          onClick={props.onAdd}
          tooltip='Add new row'
        />
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

function TablePaginationActions(props) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (e) => {
    onPageChange(e, 0)
  }

  const handleBackButtonClick = (e) => {
    onPageChange(e, page - 1)
  }

  const handleNextButtonClick = (e) => {
    onPageChange(e, page + 1)
  }

  const handleLastPageButtonClick = (e) => {
    onPageChange(e, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box
      sx={{
        flexShrink: 0,
        ml: 2.5,
      }}
    >
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
        sx={{ color: 'white' }}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
        sx={{ color: 'white' }}
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
        sx={{ color: 'white' }}
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
        sx={{ color: 'white' }}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

export default function EnhancedTableBox(props) {
  const [myURL, setURL] = useState()
  const [myData, setData] = useState()
  const [originalData, setOriginalData] = useState()
  const [searchWord, setSearchWord] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  // const [wordFromOtherPage, setWordFromOtherPage] = useState('')

  const wordFromOtherPage = useRef(null)

  //states for json form
  const [tabs, setTabs] = React.useState([])
  const [formData, setFormData] = React.useState({})
  const [schema, setSchema] = React.useState({})
  const [editedFormData, setEditedFormData] = React.useState({})

  const [openPost, setOpenPost] = React.useState(false)
  const [openPut, setOpenPut] = React.useState(false)

  const httpService = useHttpService()

  const handleClickOpenPost = (e) => {
    setSchema(props.addJsonSchema || props.editJsonSchema)
    setFormData({})

    setOpenPost(true)
  }

  const handleCloseCancel = () => {
    loadData()
    setOpenPost(false)
    setOpenPut(false)
  }

  const handleCloseSave = () => {
    if (openPost)
      if (props.addURL)
        httpService.postAPI(props.addURL, editedFormData, handleCloseCancel)
      else if (props.apiURL)
        httpService.postAPI(myURL, editedFormData, handleCloseCancel)
      else if (props.onDataAdd) props.onDataAdd(editedFormData)
    if (openPut)
      if (props.editURL)
        httpService.putAPI(props.editURL, editedFormData, handleCloseCancel)
      else if (props.apiURL)
        httpService.putAPI(myURL, editedFormData, handleCloseCancel)
      else if (props.onDataChange) props.onDataChange(editedFormData)

    setOpenPost(false)
    setOpenPut(false)
    loadData()
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - myData.length) : 0

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  function onDataChange(data) {
    //formData is OLD, data is NEW
    setEditedFormData(data)
  }

  function onDataReceived(data) {
    setData(data)
    setOriginalData(data)
  }

  function SelectChange(e) {
    setSelected(e.target.value)
    if (props.onSelectChange)
      props.onSelectChange(
        myData.find((row) => row[props.valueMember] === e.target.value)
      )
  }

  function loadData() {
    httpService.getAPI(myURL, onDataReceived)
  }

  useEffect(() => {
    loadData()
    if (props.newValue) props.setnewValue(false)
  }, [myURL, props.newValue])

  useEffect(() => {
    if (props.apiURL) setURL(props.apiURL)
    if (props.data) {
      if (
        props.data.length > 0 &&
        props.valueMember === 'id' &&
        !props.data[0].hasOwnProperty('id')
      ) {
        props.data.forEach((row, index) => {
          row.id = uuidv4()
        })
      }
      if (props.data.length > 0) {
        setData(props.data)
        setOriginalData(props.data)
      } else {
        setData()
        setOriginalData()
      }
    }
    if (props.selectedValue) setSelected(props.selectedValue)
  }, [props])

  const [order, setOrder] = React.useState(props.orderDir || 'asc')
  const [orderBy, setOrderBy] = React.useState(props.orderBy)
  const [selected, setSelected] = React.useState([])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClick = (event, name) => {
    if (props.multiselect) {
      const selectedIndex = selected.indexOf(name)
      let newSelected = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        )
      }

      setSelected(newSelected)
      props.onSelectChange(newSelected)
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = myData.map((n) => n[props.valueMember])
      setSelected(newSelecteds)
      props.onSelectChange(newSelecteds)
      return
    }
    setSelected([])
    props.onSelectChange([])
  }

  var lastClicked
  function onMouseDown(e) {
    lastClicked = e
  }

  //prepare the confirm dialog
  const confirm = useConfirm()

  function onAction(action, e) {
    let row = myData.find((row) => row[props.valueMember] === e)
    if (props.onAction) props.onAction(action, row, lastClicked)

    if (!props.disableDefaulActions)
      switch (action) {
        case 'delete':
          confirm({
            description: `This will permanently delete the selected row.`,
          }).then(() => {
            if (props.deleteURL)
              httpService.deleteAPI(
                props.deleteURL,
                { ids: [row.id] },
                handleCloseCancel
              )
            else if (props.apiURL)
              httpService.deleteAPI(myURL, { ids: [row.id] }, handleCloseCancel)
            else if (props.onDataDelete) props.onDataDelete(row)
          })
          break

        case 'clone':
          if (props.cloneURL)
            httpService.postAPI(
              props.cloneURL,
              { id: row.id },
              handleCloseCancel
            )
          break

        case 'edit':
          setSchema(props.editJsonSchema)
          setFormData(row)

          setOpenPut(true)
          break

        default:
          break
      }
  }

  useEffect(() => {
    if (props.comingWordToSearch != null && myData) {
      setSearchWord(props.comingWordToSearch)
      props.setComingWordToSearch(null)
    }
  }, [props.comingWordToSearch, myData])

  useEffect(() => {
    const word = searchWord !== null && searchWord !== '' ? searchWord : null
    if (word && myData && myData.length > 0) {
      let tmp = new Set()
      let tmpSearchWord = word.toLocaleLowerCase()

      myData.forEach((data, index) =>
        Object.entries(data).map((key) => {
          if (
            props.visibleColumns.filter((el) => {
              //we check if
              return el.id === key[0]
            }).length === 0
          )
            return null

          const timeArray = ['started', 'canceled', 'modified', 'inserted']

          let value =
            key[1] && timeArray.includes(key[0])
              ? new Date(key[1]).toLocaleString()
              : key[1]
          if (value === null) return null

          if (
            typeof value === 'string' &&
            value.toLocaleLowerCase().includes(tmpSearchWord)
          )
            tmp.add(data)
          if (Array.isArray(value)) {
            value.forEach((el) => {
              if (el?.props?.style?.color === tmpSearchWord) tmp.add(data)
              if (
                (el?.props?.icon === 'lock' && tmpSearchWord === 'locked') ||
                (el?.props?.icon === 'lock_open' &&
                  tmpSearchWord === 'unlocked')
              )
                tmp.add(data)
            })
          }
          if (value?.props?.style?.color === tmpSearchWord) tmp.add(data)
          if (value?.props?.icon === tmpSearchWord) tmp.add(data)
        })
      )
      tmp = Array.from(tmp)
      setData(tmp)
    } else if (originalData && word) {
      setData([])
    } else if (originalData) {
      setData(originalData)
    }
  }, [searchWord])

  // const pathParams = useParams()
  // if (
  //   wordFromOtherPage.current === null &&
  //   pathParams.id &&
  //   pathParams.id !== ':id' //when the path param is not set we have ':id'
  // )
  //   wordFromOtherPage.current = pathParams.id

  //if we have id to search from another page, we set the searchWord here
  //then we put wordFromOtherPage.current = USED to avoid
  //that a modify of myData reinitialize the searchWord with the id
  //coming from the other page
  // useEffect(() => {
  //   if (
  //     wordFromOtherPage.current !== null &&
  //     wordFromOtherPage.current !== 'USED' &&
  //     myData
  //   ) {
  //     setSearchWord(wordFromOtherPage.current)
  //     wordFromOtherPage.current = 'USED'
  //   }
  // }, [myData])

  if (myData && myData.length > 0) {
    return (
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <EnhancedTableToolbar
          title={props.title}
          numSelected={selected.length}
          search={props.search}
          setSearchWord={setSearchWord}
          searchWord={searchWord}
          onRefresh={loadData}
          onAdd={props.addButton && handleClickOpenPost}
        />

        <TableContainer
          sx={{
            maxHeight:
              window.innerHeight *
              (props.heightPercentage != null ? props.heightPercentage : 1),
          }}
        >
          <Table stickyHeader>
            <EnhancedTableHead
              headerStyle={props.headerStyle}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={myData.length}
              visibleColumns={props.visibleColumns}
              multiselect={props.multiselect}
              actions={props.actions}
              // search={props.search}
            />
            <TableBody>
              {stableSort(
                myData,
                // rowsPerPage > 0
                //   ? myData.slice(
                //       page * rowsPerPage,
                //       page * rowsPerPage + rowsPerPage
                //     )
                //   : myData,
                getComparator(order, orderBy),
                page,
                rowsPerPage
              ).map((row, index) => {
                const isItemSelected = isSelected(row[props.valueMember])
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow
                    key={uuidv4()}
                    hover
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                    sx={{
                      height: props.rowHeight ? props.rowHeight : '25px',
                    }}
                  >
                    {props.multiselect && (
                      <TableCell padding='checkbox'>
                        <Checkbox
                          onClick={(event) =>
                            handleClick(event, row[props.valueMember])
                          }
                          color='primary'
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                    )}

                    {props.visibleColumns.map((headCell) => {
                      if (headCell.type === 'date') {
                        console.log(
                          row[headCell.id],
                          new Date(row[headCell.id]),
                          new Date(row[headCell.id]).toLocaleDateString()
                        )
                      }
                      return (
                        <TableCell
                          key={uuidv4()}
                          align={headCell.alignRight ? 'right' : 'left'}
                          padding={headCell.disablePadding ? 'none' : 'normal'}
                          sortDirection={
                            orderBy === headCell.id ? order : false
                          }
                        >
                          {row[headCell.id] && headCell.type === 'datetime' ? (
                            fromDBDateToGUIDate(row[headCell.id])
                          ) : headCell.type === 'date' ? (
                            new Date(row[headCell.id]).toLocaleDateString()
                          ) : headCell.type === 'color' ? (
                            <span
                              style={{
                                padding: 10,
                                backgroundColor: row[headCell.id],
                                color: '#000000',
                              }}
                            >
                              {'Color HEX code: ' + row[headCell.id]}
                            </span>
                          ) : (
                            row[headCell.id]
                          )}
                        </TableCell>
                      )
                    })}

                    <TableCell align='right' padding='none'>
                      {props.actions &&
                        props.actions.map((action) => (
                          <Tooltip key={uuidv4()} title={action?.tooltip || ''}>
                            <IconButton
                              onMouseDown={onMouseDown}
                              onClick={() => {
                                return onAction(
                                  action.action,
                                  row[props.valueMember]
                                )
                              }}
                            >
                              <Icon color='primary'>{action.icon}</Icon>
                            </IconButton>
                          </Tooltip>
                        ))}
                    </TableCell>
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ color: 'white' }}
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={props.visibleColumns.length}
                  count={myData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <Dialog
          fullWidth
          maxWidth='lg'
          open={openPost || openPut}
          onClose={handleCloseCancel}
        >
          <DialogTitle>
            {(openPost && 'Add new') || (openPut && 'Edit row')}
          </DialogTitle>
          <DialogContent>
            <JsonSchemaBox
              schema={schema}
              formData={formData}
              onChange={onDataChange}
              tabs={tabs}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancel}>Cancel</Button>
            <Button onClick={handleCloseSave}>Save</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    )
  } else if (myData && myData.length === 0) {
    return (
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <EnhancedTableToolbar
          title={props.title}
          numSelected={selected.length}
          search={props.search}
          setSearchWord={setSearchWord}
          searchWord={searchWord}
          onRefresh={loadData}
          onAdd={props.addButton && handleClickOpenPost}
        />
        {/* {searchWord !== '' ? (
          <LinearProgress />
        ) : ( */}
        <Typography variant='h6' sx={{ marginLeft: '2%', marginBottom: '2%' }}>
          No data found with this filter
        </Typography>
        {/* )} */}
        <Dialog
          fullWidth
          maxWidth='lg'
          open={openPost || openPut}
          onClose={handleCloseCancel}
        >
          <DialogTitle>
            {(openPost && 'Add new') || (openPut && 'Edit row')}
          </DialogTitle>
          <DialogContent>
            <JsonSchemaBox
              schema={schema}
              formData={formData}
              onChange={onDataChange}
              tabs={tabs}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancel}>Cancel</Button>
            <Button onClick={handleCloseSave}>Save</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    )
  } else {
    return (
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <EnhancedTableToolbar
          title={props.title}
          numSelected={selected.length}
          search={props.search}
          setSearchWord={setSearchWord}
          searchWord={searchWord}
          onRefresh={loadData}
          onAdd={props.addButton && handleClickOpenPost}
        />
        NO DATA
        <Dialog
          fullWidth
          maxWidth='lg'
          open={openPost || openPut}
          onClose={handleCloseCancel}
        >
          <DialogTitle>
            {(openPost && 'Add new') || (openPut && 'Edit row')}
          </DialogTitle>
          <DialogContent>
            <JsonSchemaBox
              schema={schema}
              formData={formData}
              onChange={onDataChange}
              tabs={tabs}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancel}>Cancel</Button>
            <Button onClick={handleCloseSave}>Save</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    )
  }
}
