import React, { useState } from 'react'
import { Tabs, Tab, Box } from '@mui/material'
import SPTemplate from './SPTemplate'
import RANTemplate from './RANTemplate'
import { v4 as uuidv4 } from 'uuid'
import { useCardBoxContext } from '../../../contexts/cardBoxContext'

const Template = () => {
  const cards = [
    { component: <SPTemplate />, name: 'SERVICE PROFILE' },
    { component: <RANTemplate />, name: 'RAN SUBNET SP' },
  ]
  const [activeCard, setActiveCard] = useState(0)
  const cardBoxContext = useCardBoxContext()

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: '1%',
        }}
      >
        <Tabs
          value={activeCard}
          onChange={(e) => {
            let index = -1
            cards.find((card, i) => {
              if (card.name === e.target.outerText) index = i
            })
            setActiveCard(index)
            //we empty the list and card schemas
            cardBoxContext.resetSchemas()
            //we remove all the data taken from the api
            cardBoxContext.emptyListData()
            //we empty the content of the main card
            cardBoxContext.emptyMainCard()
          }}
          variant='scrollable'
          scrollButtons='auto'
          aria-label='scrollable auto tabs example'
        >
          {cards.map((card) => (
            <Tab
              key={uuidv4()}
              sx={{
                backgroundColor: 'background.dark',
                color: '#564800',
              }}
              label={card.name}
            />
          ))}
        </Tabs>
      </Box>
      {cards[activeCard].component}
    </>
  )
}
export default Template
