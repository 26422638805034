import React, { useState } from 'react';
import useHttpService from '../../customHooks/useHttpService.js';
import EnhancedTableBox from '../../components/EnhancedTableBox';
import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useConfirm } from 'material-ui-confirm';
import { useMessageContext } from '../../contexts/messageContext';
import JsonSchemaBox from '../../components/JsonSchemaBox';
import { useEffect } from 'react';

const Notification = () => {
	const httpService = useHttpService();

	const [openPost, setOpenPost] = React.useState(false);
	const [mainData, setMainData] = React.useState([]);

	function loadData() {
		httpService.getAPI(
			`${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_sym/Notification`,
			onDataLoaded
		);
	}
	function onDataLoaded(data) {
		setMainData(data);
	}
	const [selectedNotification, setSelectedNotification] = React.useState();
	const [selectedAlarm, setSelectedAlarm] = React.useState();

	React.useEffect(loadData, []);

	useEffect(() => {
		if (selectedAlarm != null)
			httpService.getAPI(
				`${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_sym/NotifyAlarm?id=${selectedAlarm}`,
				onDataLoadedAlarms
			);
	}, [selectedAlarm]);

	useEffect(() => {
		if (selectedNotification != null)
			httpService.getAPI(
				`${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_sym/NotifyMOI?id=${selectedNotification}`,
				onDataLoadedMoi
			);
	}, [selectedNotification]);

	const [notifiyMoiData, setnotifiyMoiData] = useState();
	const [notifiyAlarms, setnotifiyAlarms] = useState();

	function onDataLoadedMoi(data) {
		if (data != null && data.length > 0) setnotifiyMoiData(data[0]);
	}

	function onDataLoadedAlarms(data) {
		if (data != null && data.length > 0) setnotifiyAlarms(data[0]);
	}

	//array of the columns we want to show on the table
	const visibleColumns = [
		// {
		// 	id: 'correlatedNotifications',
		// 	alignRight: false,
		// 	disablePadding: false,
		// 	label: 'Correlated Notifications',
		// },
		{
			id: 'eventTime',
			alignRight: false,
			disablePadding: false,
			label: 'Event Time',
		},
		{
			id: 'id',
			alignRight: false,
			disablePadding: false,
			label: 'ID',
		},
		{
			id: 'inserted',
			alignRight: false,
			disablePadding: false,
			label: 'Inserted',
		},
		{
			id: 'notificationId',
			alignRight: false,
			disablePadding: false,
			label: 'Notification ID',
		},
		{
			id: 'notificationType',
			alignRight: false,
			disablePadding: false,
			label: 'Notification Type',
		},
		{
			id: 'objectClass',
			alignRight: false,
			disablePadding: false,
			label: 'Object Class',
		},
		{
			id: 'objectInstance',
			alignRight: false,
			disablePadding: false,
			label: 'Object Instance',
		},
		{
			id: 'systemDN',
			alignRight: false,
			disablePadding: false,
			label: 'SystemDN',
		},
	];

	const schemaFieldsMOI = {
		additionalText: {
			title: 'Additional Text',
			type: 'text',
			md: 3,
			disabled: true,
		},
		eventTime: {
			title: 'Event Time',
			type: 'text',
			md: 3,
			disabled: true,
		},
		id: {
			title: 'ID',
			type: 'text',
			md: 3,
			disabled: true,
		},
		inserted: {
			title: 'Inserted',
			type: 'text',
			md: 3,
			disabled: true,
		},
		modified: {
			title: 'Modified',
			type: 'text',
			md: 3,
			disabled: true,
		},
		notificationId: {
			title: 'Notification ID',
			type: 'text',
			md: 3,
			disabled: true,
		},
		notificationType: {
			title: 'Notification Type',
			type: 'text',
			md: 3,
			disabled: true,
		},
		notificationType_id: {
			title: 'Notification Type ID',
			type: 'text',
			md: 3,
			disabled: true,
		},
		objectClass: {
			title: 'Object Class',
			type: 'text',
			md: 3,
			disabled: true,
		},
		objectInstance: {
			title: 'Object Instance',
			type: 'text',
			md: 3,
			disabled: true,
		},
		sourceIndicator: {
			title: 'Source Indicator',
			type: 'text',
			md: 3,
			disabled: true,
		},
		systemDN: {
			title: 'System DN',
			type: 'text',
			md: 3,
			disabled: true,
		},
		attributeList: {
			title: 'Attribute List',
			type: 'json',
			md: 8,
			disabled: true,
		},
		correlatedNotifications: {
			title: 'Correlated Notifications',
			type: 'json',
			md: 8,
			disabled: true,
		},
	};

	const schemaFieldsAlarms = {
		ackState: {
			title: 'Ack State',
			type: 'text',
			md: 3,
			disabled: true,
		},
		ackSystemId: {
			title: 'Ack System ID',
			type: 'text',
			md: 3,
			disabled: true,
		},
		ackTime: {
			title: 'Ack Time',
			type: 'text',
			md: 3,
			disabled: true,
		},
		ackUserId: {
			title: 'Ack User ID',
			type: 'text',
			md: 3,
			disabled: true,
		},
		additionalText: {
			title: 'Additional Text',
			type: 'text',
			md: 3,
			disabled: true,
		},
		alarmChangedTime: {
			title: 'Alarm Changed Time',
			type: 'text',
			md: 3,
			disabled: true,
		},
		alarmClearedTime: {
			title: 'Alarm Cleared Time',
			type: 'text',
			md: 3,
			disabled: true,
		},
		alarmId: {
			title: 'Alarm ID',
			type: 'text',
			md: 3,
			disabled: true,
		},
		alarmRaisedTime: {
			title: 'Alarm Raised Time',
			type: 'text',
			md: 3,
			disabled: true,
		},
		alarmType: {
			title: 'Alarm Type',
			type: 'text',
			md: 3,
			disabled: true,
		},
		backUpObject: {
			title: 'BackUp Object',
			type: 'text',
			md: 3,
			disabled: true,
		},
		backedUpStatus: {
			title: 'Backed Up Status',
			type: 'text',
			md: 3,
			disabled: true,
		},
		clearSystemId: {
			title: 'Clear System ID',
			type: 'text',
			md: 3,
			disabled: true,
		},
		clearUserId: {
			title: 'Clear User ID',
			type: 'text',
			md: 3,
			disabled: true,
		},
		eventTime: {
			title: 'Event Time',
			type: 'text',
			md: 3,
			disabled: true,
		},
		id: {
			title: 'ID',
			type: 'text',
			md: 3,
			disabled: true,
		},
		inserted: {
			title: 'Inserted',
			type: 'text',
			md: 3,
			disabled: true,
		},
		modified: {
			title: 'Modified',
			type: 'text',
			md: 3,
			disabled: true,
		},
		notificationId: {
			title: 'Notification ID',
			type: 'text',
			md: 3,
			disabled: true,
		},
		notificationType: {
			title: 'Notification Type',
			type: 'text',
			md: 3,
			disabled: true,
		},
		notificationType_id: {
			title: 'Notification Type ID',
			type: 'text',
			md: 3,
			disabled: true,
		},
		objectClass: {
			title: 'Object Class',
			type: 'text',
			md: 3,
			disabled: true,
		},
		objectInstance: {
			title: 'Object Instance',
			type: 'text',
			md: 3,
			disabled: true,
		},
		perceivedSeverity: {
			title: 'Perceived Severity',
			type: 'text',
			md: 3,
			disabled: true,
		},
		probableCause: {
			title: 'Probable Cause',
			type: 'text',
			md: 3,
			disabled: true,
		},
		proposedRepairActions: {
			title: 'Proposed Repair Actions',
			type: 'text',
			md: 3,
			disabled: true,
		},
		rootCauseIndicator: {
			title: 'Root Cause Indicator',
			type: 'text',
			md: 3,
			disabled: true,
		},
		securityAlarmDetector: {
			title: 'Security Alarm Detector',
			type: 'text',
			md: 3,
			disabled: true,
		},
		serviceProvider: {
			title: 'Service Provider',
			type: 'text',
			md: 3,
			disabled: true,
		},
		serviceUser: {
			title: 'Service User',
			type: 'text',
			md: 3,
			disabled: true,
		},
		specificProblem: {
			title: 'Specific Problem',
			type: 'text',
			md: 3,
			disabled: true,
		},
		systemDN: {
			title: 'System DN',
			type: 'text',
			md: 3,
			disabled: true,
		},
		thresholdinfo: {
			title: 'Threshold Info',
			type: 'text',
			md: 3,
			disabled: true,
		},
		trendIndication: {
			title: 'Trend Indication',
			type: 'text',
			md: 3,
			disabled: true,
		},
		additionalInformation: {
			title: 'Additional Information',
			type: 'json',
			md: 8,
			disabled: true,
		},
		correlatedNotifications: {
			title: 'Correlated Notifications',
			type: 'json',
			md: 8,
			disabled: true,
		},
		monitoredAttributes: {
			title: 'Monitored Attributes',
			type: 'json',
			md: 8,
			disabled: true,
		},
		stateChangeDefinition: {
			title: 'State Change Definition',
			type: 'json',
			md: 8,
			disabled: true,
		},
	};

	//array of actions placed at the end of each row
	const actions = [
		{
			action: 'list',
			icon: 'list',
			tooltip: 'Details',
		},
	];

	function onAction(action, e) {
		console.log(e);
		switch (action) {
			case 'list':
				if (e.notificationType.includes('MOI')) {
					setSelectedNotification(e.id);
					setSelectedAlarm(null);
				}
				if (e.notificationType.includes('Alarm')) {
					setSelectedAlarm(e.id);
					setSelectedNotification(null);
				}
				setOpenPost(true);
				break;
			default:
				break;
		}
	}

	return (
		<Box sx={{ padding: '2%' }}>
			<EnhancedTableBox
				heightPercentage='0.7'
				title='Notification'
				apiURL={`${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_sym/Notification`}
				data={mainData}
				valueMember='id'
				visibleColumns={visibleColumns}
				actions={actions}
				onAction={onAction}
				search
			/>

			<Dialog
				fullWidth
				maxWidth='xl-lg'
				open={openPost}
				onClose={() => {
					setOpenPost(false);
				}}>
				<DialogTitle>Notifcation</DialogTitle>
				<DialogContent>
					{selectedNotification && (
						<JsonSchemaBox
							schema={schemaFieldsMOI}
							formData={notifiyMoiData}
							onChange={(data) => {
								console.log(data);
							}}
						/>
					)}
					{selectedAlarm && (
						<JsonSchemaBox
							schema={schemaFieldsAlarms}
							formData={notifiyAlarms}
							onChange={(data) => {
								console.log(data);
							}}
						/>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setOpenPost(false);
						}}>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};
export default Notification;
