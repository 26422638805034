import React, { useState, useEffect } from 'react'
import {
  Box,
  Input,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Tooltip,
} from '@mui/material'
import EnhancedIconButton from '../EnhancedIconButton'
import JsonSchemaBox from '../JsonSchemaBox'
import EnhancedTableBox from '../EnhancedTableBox'
import { v4 as uuidv4 } from 'uuid'
import { overrideStyle } from '../../utils'
import { useCardBoxContext } from '../../contexts/cardBoxContext'
import {
  isNonEmptyObject,
  countRequiredInSchema,
  getArrayOfMandatoryIfInSchema,
  getTotalRequired,
  checkRequiredFieldNumber,
} from '../../utils'
import { ConstructionOutlined } from '@mui/icons-material'
import { useSnackbar } from '../../contexts/snackbarContext'

import _ from 'lodash'

function CardBoxBottomIcons({ actionButtons, cardBoxStyle }) {
  const [dataToSend, setDataToSend] = useState(null)
  const [currentDialog, setCurrentDialog] = useState(null)
  const [cannotSave, setCannotSave] = useState(true)

  const snackbar = useSnackbar()
  const cardBoxContext = useCardBoxContext()
  const dialogs = actionButtons?.filter((a) => a.type === 'dialog')

  useEffect(() => {
    if (isNonEmptyObject(dataToSend)) {
      const schema = currentDialog.dialog.schemaFields
      let howManyRequiredInSchema = countRequiredInSchema(schema)
      const mandatoryIfInSchema = getArrayOfMandatoryIfInSchema(schema)
      const totalRequired = getTotalRequired(
        howManyRequiredInSchema,
        mandatoryIfInSchema,
        dataToSend
      )

      if (totalRequired > 0) {
        if (
          checkRequiredFieldNumber(
            totalRequired,
            { ...currentDialog },
            schema,
            dataToSend
          )
        ) {
          setCannotSave(false)
        } else {
          setCannotSave(true)
        }
      }
    }
  }, [dataToSend])

  return (
    <>
      {currentDialog?.dialog && (
        <Dialog
          sx={currentDialog.dialog.style?.card}
          fullWidth
          maxWidth='lg'
          open={true}
          onClose={() => {
            setCurrentDialog(null)
            setDataToSend(null)
            setCannotSave(true)
          }}
        >
          <DialogTitle sx={currentDialog.dialog.style?.title}>
            {currentDialog.dialog.title}
          </DialogTitle>
          <DialogContent sx={currentDialog.dialog.style?.content}>
            {/* if the content of the dialog is a table, we render the table */}
            {currentDialog.dialog.content?.type === 'table' ? (
              <EnhancedTableBox
                title={currentDialog.dialog.content.title}
                visibleColumns={currentDialog.dialog.content.visibleColumns}
                valueMember={currentDialog.dialog.content.valueMember}
                apiURL={`${currentDialog.dialog.content.url}${cardBoxContext.choosenItem.id}`}
                heightPercentage='0.7'
              />
            ) : currentDialog.dialog.content?.type === 'json' ? (
              <pre>
                {JSON.stringify(currentDialog.dialog.dialogData, null, 2)}
              </pre>
            ) : (
              // otherwise, we render a jsonSchemaBox in the dialog
              <JsonSchemaBox
                schema={currentDialog.dialog.schemaFields}
                formData={currentDialog?.dialog?.dialogData || null}
                tabs={currentDialog?.dialog?.tabs || null}
                onChange={(data) => {
                  console.log(data)
                  data && setDataToSend(data)
                }}
              />
            )}
          </DialogContent>
          {/* Now we render the buttons of the dialog */}
          <DialogActions sx={currentDialog.dialog.style?.actions}>
            {currentDialog.dialog.buttons.map((button) => {
              return (
                <Tooltip title={button.tooltip || ''}>
                  <Button
                    key={uuidv4()}
                    disabled={button.type === 'save' ? cannotSave : false}
                    onClick={(e) => {
                      button.onClick(e, dataToSend, cardBoxContext)
                      setCurrentDialog(null)
                      setDataToSend(null)
                      setCannotSave(true)
                    }}
                  >
                    {button.type}
                  </Button>
                </Tooltip>
              )
            })}
          </DialogActions>
        </Dialog>
      )}
      {actionButtons &&
        actionButtons.length > 0 &&
        actionButtons.map((action) => {
          if (action.place === 'bottom') {
            return (
              <Box
                key={action.uniqueKey}
                sx={overrideStyle(
                  {
                    //   border: '1px solid gray',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '15%',
                  },
                  cardBoxStyle?.iconStyle?.outsideIcons
                )}
              >
                {action.label}
                {action.type === 'icon' && (
                  <EnhancedIconButton
                    key={`${action.uniqueKey}-EnhancedIconButton`}
                    icon={action.icon}
                    sx={{ marginLeft: '15px' }}
                    onClick={(e) => action.onClick(e, cardBoxContext)}
                  />
                )}
                {action.type === 'upload' && (
                  <label htmlFor='csv-uploader'>
                    <Box
                      key={`${action.uniqueKey}-Box`}
                      sx={{
                        // border: '1px solid gold',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Input
                        key={`${action.uniqueKey}-Input`}
                        inputProps={{
                          // accept:
                          //   '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                          accept: '.xlsx',
                        }}
                        id='csv-uploader'
                        type='file'
                        sx={{ display: 'none' }}
                        onChange={(e) => {
                          const event = _.cloneDeep(e)
                          if (!event.target.value.endsWith('xlsx')) {
                            snackbar.setSnackbarInfo({
                              status: 'error',
                              message: `Error 400: Unsupported file extension`,
                            })
                          } else {
                            action.onClick(event, action, cardBoxContext)
                            e.target.value = ''
                          }
                        }}
                      />
                      <EnhancedIconButton
                        key={`${action.uniqueKey}-EnhancedIconButton-2`}
                        icon={action.icon}
                        sx={{ marginLeft: '15px', cursor: 'pointer' }}
                      />
                    </Box>
                  </label>
                )}
                {action.icon === 'download' && (
                  <EnhancedIconButton
                    key={`${action.uniqueKey}-EnhancedIconButton-3`}
                    icon={action.icon}
                    sx={{ marginLeft: '15px', cursor: 'pointer' }}
                    onClick={() => {
                      action.onClick(action, true)
                    }}
                    templateURL={action.templateURL}
                  />
                )}
                {action.type === 'dialog' && (
                  <EnhancedIconButton
                    key={`${action.uniqueKey}-EnhancedIconButton-4`}
                    icon={action.icon}
                    sx={{ marginLeft: '15px', cursor: 'pointer' }}
                    onClick={() => {
                      dialogs.forEach((d) => {
                        if (d.icon === action.icon) {
                          if (d.hasOwnProperty('onClickMessage')) {
                            snackbar.setSnackbarInfo(d.onClickMessage)
                          }
                          setCurrentDialog(d) //we set the clicked dialog as the current one
                          setDataToSend(null)
                        }
                      })
                    }}
                  />
                )}
              </Box>
            )
          }
        })}
    </>
  )
}

export default CardBoxBottomIcons
