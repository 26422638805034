import React, { useEffect } from 'react'
import CardBox from '../../components/CardBox/CardBox'

import { list } from '../../schema/NF-list/listData-NF'
import cardMain from '../../schema/NF-list/mainData-NF'

import { outsideActions } from '../../schema/NF-list/outsideData-NF'

import { useCardBoxContext } from '../../contexts/cardBoxContext'
import useSaveDeleteCardBoxContent from '../../customHooks/useSaveDeleteCardBoxContent'
import useHttpService from '../../customHooks/useHttpService'
import { useSnackbar } from '../../contexts/snackbarContext'

import { checkWhichMissingFields } from '../../utils'

function NFList() {
  const cardBoxContext = useCardBoxContext()
  const snackbarContext = useSnackbar()
  const httpService = useHttpService()
  const {
    putCardBoxMainJsonData,
    deleteCardBoxMainJsonData,
    deleteCardBoxListData,
    callPostApiMessage,
    exportExcel,
  } = useSaveDeleteCardBoxContent()

  const callGetDiscovery = (context, action) => {
    httpService.getAPI(`${action.url}${context.choosenItem.id}`, (data) => {
      console.log(data)
      if (data.length === 0) {
        snackbarContext.setSnackbarInfo({
          status: 'warning',
          message:
            'Network Function Manager not found for this Network Function',
        })
      } else {
        action.messages.messageList.forEach((message) => {
          if (message.body.Name === 'getMOIattributes') {
            console.log(data[0])
            const id = data[0].ManagedElement_id
            callPostApiMessage(data[0], action, id, 'getMOIattributes')
          }
        })
        //when discovery is called, we need to put the ONLINE label
        const tmp = { ...cardBoxContext.cardMainSchema }
        tmp.actions.forEach((action) => {
          if (action.name === 'cell') {
            action.label = 'ONLINE'
            action.icon = 'celebration'
          }
        })
        cardBoxContext.setCardMainSchema(tmp)
      }
    })
  }

  //init the cardBoxContext with the list coming from the schema
  useEffect(() => {
    cardBoxContext.init(list, cardMain)
  }, [])

  //Icons in the list, on the right of the name
  //assign onClick handlers to the rightIcons array of icons you have on the dataTemplate of your listSchema
  useEffect(() => {
    if (cardBoxContext.cardListSchema) {
      cardBoxContext.cardListSchema.dataTemplate.rightIcons?.forEach((icon) => {
        if (icon.icon === 'delete') {
          icon.onClick = (e, index, context) =>
            deleteCardBoxListData(icon, index, context)
        }
      })
    }
  }, [cardBoxContext.cardListSchema])

  //Icons in the card, on the top
  //Assign onClick handlers to the icons inside the card, on the top of it
  useEffect(() => {
    const tmpCardBoxContext = cardBoxContext?.cardMainSchema
      ? { ...cardBoxContext }
      : null
    cardBoxContext?.choosenItem?.id &&
      tmpCardBoxContext &&
      cardBoxContext?.cardMainSchema?.actions?.forEach((action, index) => {
        if (action.label === 'Discovery') {
          action.onClick = (e, index, context) => {
            callGetDiscovery(context, action)
          }
        }
        if (action.type === 'dialog') {
          action.dialog.buttons.forEach((button) => {
            if (button.type === 'close') {
              button.onClick = () => {}
            }
          })
        }

        if (action.label === 'NF Parameters') {
          const url = `${action.dialog.url}${cardBoxContext.choosenItem.id}`
          console.log(url)
          httpService.getAPI(url, (data) => {
            console.log(data)
            cardMain.actions.forEach((cardMainAction) => {
              if (cardMainAction.label === 'NF Parameters') {
                console.log(data[0].json_cur || '')
                cardMainAction.dialog.dialogData =
                  data[0].json_cur ||
                  'No parameters available for this Network Function'
                cardBoxContext.init(list, cardMain)
              }
            })
          })
        }

        if (action.name === 'cell') {
          console.log(cardBoxContext.choosenItem)
          const obj = cardBoxContext.choosenItem.isDeleted
            ? { label: 'DELETED', icon: 'delete_forever' }
            : cardBoxContext.choosenItem.cells_ended ===
              cardBoxContext.choosenItem.cells_started
            ? { label: 'ONLINE', icon: 'celebration' }
            : {
                label: `${cardBoxContext.choosenItem.cells_ended}/${cardBoxContext.choosenItem.cells_started}`,
                icon: 'signal_cellular_off',
              }
          tmpCardBoxContext.cardMainSchema.actions[index].label = obj.label
          tmpCardBoxContext.cardMainSchema.actions[index].icon = obj.icon
        }
        if (action.label === 'Alarms') {
          action.params = cardBoxContext.choosenItem.ManagedElement_id
          action.onClick = () => {}
        }
        if (action.label === 'KPI') {
          action.params = cardBoxContext.choosenItem.ManagedElement_id

          console.log(action.params)
          action.onClick = () => {}
        }
      })

    tmpCardBoxContext &&
      cardBoxContext.setCardMainSchema(tmpCardBoxContext.cardMainSchema)
  }, [cardBoxContext.choosenItem])

  //Icons on the bottom of the card
  //assign onClick handlers to the actions array of icons you have on the cardMain schema
  useEffect(() => {
    cardBoxContext?.cardMainSchema?.actions?.forEach((action) => {
      if (action.icon === 'save')
        action.onClick = (e, data, context) => {
          const missingFields = checkWhichMissingFields(
            data,
            context.cardMainSchema.schema.fields
          )
          if (missingFields.length > 0) {
            snackbarContext.setSnackbarInfo({
              status: 'error',
              message: `Before saving, please set the following ${
                missingFields.length === 1 ? 'field' : 'fields'
              }: ${missingFields.join(', ')}`,
            })
          } else putCardBoxMainJsonData(data, action, context)
        }
    })
  }, [cardBoxContext.cardMainSchema])

  //Icons on the very bottom of the page
  //Assign onClick handlers for the icons outside the list and the card
  useEffect(() => {
    if (outsideActions) {
      outsideActions.map((action) => {
        if (action.icon === 'download') {
          action.onClick = (action) => {
            exportExcel(action, true)
          }
        }
      })
    }
  }, [outsideActions])

  return (
    <>
      {cardBoxContext.listData && <CardBox outsideActions={outsideActions} />}
    </>
  )
}

export default NFList
