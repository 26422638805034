import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Grid } from '@mui/material'
import ListBox from '../../components/ListBox'
import JsonSchemaBox from '../../components/JsonSchemaBox'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import EnhancedIconButton from '../../components/EnhancedIconButton'


import useHttpService from '../../customHooks/useHttpService.js'
import { useConfirm } from 'material-ui-confirm'
import Map from '../../components/Map/Map'

import { Box } from '@mui/material'
import { styled } from '@mui/system'

const CommonListTitleBox = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '30px',
  paddingLeft: '7%',
  paddingRight: '7%',
  width: '115%',
})

const Datacenter = () => {
  //state to trace data  loading
  const [dataLoaded, setDataLoaded] = useState(false)
  //main state for the whole page data
  const [datacenterTypeState, setDatacenterTypeState] = React.useState([])
  const [datacenterState, setDatacenterState] = React.useState([])

  //states for selection
  const [selectedDatacenterType, setSelectedDatacenterType] = React.useState()
  const [selectedDatacenter, setSelectedDatacenter] = React.useState()

  //states for json form
  const [tabs, setTabs] = useState([])
  const [formData, setFormData] = React.useState({})
  const [schema, setSchema] = React.useState({})
  const [editedFormData, setEditedFormData] = React.useState({})

  const [lastSelection, setLastSelection] = useState('')

  const [openPost, setOpenPost] = React.useState(false)
  //prepare the confirm dialog
  const confirm = useConfirm()
  const httpService = useHttpService()
  const handleClickOpenPost = (e, selection) => {
    setLastSelection(selection)
    switch (selection) {
      case 'DATACENTERTYPE':
        setDatacenterTypeSchema()
        setFormData({})
        break
      case 'DATACENTER':
        if (selectedDatacenterType) {
          setDatacenterSchema()
          setFormData({
            datacenter_type_id: selectedDatacenterType,
            geom: { coordinates: [13.0, 42.0] },
          })
        } else {
          alert('no Service Category selected')
          return
        }
        break

      default:
        break
    }
    setOpenPost(true)
  }

  const handleCloseCancel = () => {
    switch (lastSelection) {
      case 'DATACENTERTYPE':
        loadDatacenterTypeData()
        break

      case 'DATACENTER':
        loadDatacenterData(selectedDatacenterType)
        break

      default:
        break
    }
    setOpenPost(false)
  }
  const handleCloseSavePost = () => {
    switch (lastSelection) {
      case 'DATACENTERTYPE':
        httpService.postAPI(
          `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/datacenter_type`,
          editedFormData,
          handleCloseCancel
        )
        break

      case 'DATACENTER':
        httpService.postAPI(
          `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/datacenter`,
          editedFormData,
          handleCloseCancel
        )
        break

      default:
        break
    }
  }

  function handleSave() {
    switch (lastSelection) {
      case 'DATACENTERTYPE':
        httpService.putAPI(
          `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/datacenter_type`,
          editedFormData,
          handleCloseCancel
        )
        break

      case 'DATACENTER':
        httpService.putAPI(
          `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/datacenter`,
          editedFormData,
          handleCloseCancel
        )
        break

      default:
        break
    }
  }

  function handleDelete() {
    confirm({
      description: `This will permanently delete the selected item`,
    }).then(() => {
      switch (lastSelection) {
        case 'DATACENTERTYPE':
          httpService.deleteAPI(
            `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/datacenter_type`,
            { ids: [selectedDatacenterType] },
            handleCloseCancel
          )
          break

        case 'DATACENTER':
          httpService.deleteAPI(
            `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/datacenter`,
            { ids: [selectedDatacenter] },
            handleCloseCancel
          )
          break

        default:
          break
      }
    })
  }

  function loadDatacenterTypeData() {
    httpService.getAPI(
      `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/datacenter_type`,
      onServiceCategoryData
    )
  }

  function onServiceCategoryData(jsondata) {
    setDatacenterTypeState(jsondata)
    setDataLoaded(true)
  }

  function loadDatacenterData(datacenter_type_id) {
    httpService.getAPI(
      `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/datacenter?datacenter_type_id=${datacenter_type_id}`,
      onServiceData
    )
  }

  function onServiceData(jsondata) {
    setDatacenterState(jsondata)
  }

  useEffect(() => {
    loadDatacenterTypeData()
  }, [])

  function setDatacenterTypeSchema() {
    setTabs(['Datacenter Type Properties'])
    setSchema({
      name: {
        title: 'Name',
        type: 'text',
        md: 6,
      },
      provider_platform: {
        title: 'Provider Platform',
        type: 'text',
        md: 6,
      },
    })
  }

  function onDatacenterTypeChange(e) {
    setLastSelection('DATACENTERTYPE')
    setSelectedDatacenterType(e.id)
    loadDatacenterData(e.id)
    setFormData(e)
    setDatacenterTypeSchema()
  }

  function setDatacenterSchema() {
    setTabs(['Datacenter Properties'])
    setSchema({
      name: {
        title: 'Name',
        type: 'text',
        md: 6,
      },
      address: {
        title: 'Address',
        type: 'text',
        md: 6,
      },
      'geom.coordinates[1]': {
        title: 'Latitude',
        type: 'number',
        md: 6,
      },
      'geom.coordinates[0]': {
        title: 'Longitude',
        type: 'number',
        md: 6,
      },
      platform_credential_id: {
        title: 'Platform Credential',
        type: 'combo',
        url: `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/platform_credential`,
        valueMember: 'id',
        displayMember: 'name',
        md: 6,
      },
    })
  }

  function onDatacenterChange(e) {
    setLastSelection('DATACENTER')
    setSelectedDatacenter(e.id)
    setFormData(e)
    setDatacenterSchema()
  }

  function onDataChange(data) {
    //formData is OLD, data is NEW
    setEditedFormData(data)
  }

  function onMapClick(info, event) {
    if (lastSelection === 'DATACENTER') {
      setFormData({
        ...formData,
        geom: { coordinates: [info.coordinate[0], info.coordinate[1]] },
      })
    }
  }

  if (dataLoaded)
    return (
      <div>
        <Grid container spacing={3} padding={3}>
          <Grid item md={4}>
            <Paper elevation={3} sx={{ height: '50vh' }}>
              <Grid container spacing={1}>
                <CommonListTitleBox
                  sx={{
                    backgroundColor: 'background.light',
                    color: 'primary.light',
                  }}
                >
                  <Typography variant='h5' color='secondary'>
                    Datacenter Type
                  </Typography>
                  <EnhancedIconButton
                    icon='add_circle'
                    size='large'
                    onClick={(e) => handleClickOpenPost(e, 'DATACENTERTYPE')}
                    color='primary'
                    tooltip='Add new Datacenter Type'
                  />
                </CommonListTitleBox>
                <ListBox
                  sx={{
                    height: '33vh',
                    overflow: 'auto',
                    paddingLeft: '5%',
                    paddingRight: '5%',
                  }}
                  data={datacenterTypeState}
                  valueMember='id'
                  displayMember='name'
                  onSelectChange={onDatacenterTypeChange}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={4}>
            <Paper elevation={3} sx={{ height: '50vh' }}>
              <Grid container spacing={1}>
                <CommonListTitleBox
                  sx={{
                    backgroundColor: 'background.light',
                    color: 'primary.light',
                  }}
                >
                  <Typography variant='h5' color='secondary'>
                    Datacenter
                  </Typography>
                  <EnhancedIconButton
                    icon='add_circle'
                    size='large'
                    onClick={(e) => handleClickOpenPost(e, 'DATACENTER')}
                    color='primary'
                    tooltip='Add new Datacenter'
                  />
                </CommonListTitleBox>
                <ListBox
                  sx={{
                    height: '33vh',
                    overflow: 'auto',
                    paddingLeft: '5%',
                    paddingRight: '5%',
                  }}
                  data={datacenterState}
                  valueMember='id'
                  displayMember='name'
                  onSelectChange={onDatacenterChange}
                />
              </Grid>
            </Paper>
          </Grid>

          <Grid item md={4}>
            <Map
              style={{
                height: '50vh',
                width: '30vw',
                position: 'relative',
                marginTop: -7,
              }}
              initialViewState={{
                longitude: 13.0,
                latitude: 42.0,
                zoom: 4,
                pitch: 0,
                bearing: 0,
              }}
              geoJson={[formData]}
              onClick={onMapClick}
            />
          </Grid>

          <Grid item md={12}>
            <Paper elevation={3}>
              <Box sx={{ padding: '1%' }}>
                <JsonSchemaBox
                  schema={schema}
                  formData={formData}
                  onChange={onDataChange}
                  tabs={tabs}
                />
                <Box sx={{ textAlign: 'right' }}>
                  <EnhancedIconButton
                    icon='save'
                    size='large'
                    onClick={handleSave}
                    color='primary'
                    tooltip='Save the selected item'
                  />
                  <EnhancedIconButton
                    icon='delete'
                    size='large'
                    onClick={handleDelete}
                    color='primary'
                    tooltip='Delete the selected item'
                  />
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Dialog
          fullWidth
          maxWidth='lg'
          open={openPost}
          onClose={handleCloseCancel}
        >
          <DialogTitle>Add new</DialogTitle>
          <DialogContent>
            <JsonSchemaBox
              schema={schema}
              formData={formData}
              onChange={onDataChange}
              tabs={tabs}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancel}>Cancel</Button>
            <Button onClick={handleCloseSavePost}>Save</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  else return <div></div>
}
export default Datacenter
