import React, { useState } from 'react'
import { FormControl, InputLabel } from '@mui/material'
import { Grid } from '@mui/material'

//prova
export default function FileBox(props) {
  const [myData, setData] = useState(props.text)




  const data = new Blob([myData], { type: 'text/plain' })
  const downloadLink = window.URL.createObjectURL(data)

  const showFile = async (e) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = (e.target.result)
      setData(text)
      const myObj = { target: { name: props.name, value: text } }
      if (props.onTextChange)
        props.onTextChange(myObj)
    };
    reader.readAsText(e.target.files[0])


  }

  return (
    <FormControl fullWidth>
      <Grid container spacing={5} padding={5}>
        <Grid item md={12}>
          <InputLabel id='demo-simple-select-label'>{props.label}</InputLabel>
        </Grid>
        <Grid item md={6}>
          <a download='data.txt' href={downloadLink}>Download File</a>
        </Grid>
        <Grid item md={6}>
          <input type="file" onChange={(e) => showFile(e)} />
        </Grid>
      </Grid>
    </FormControl>
  )
}
