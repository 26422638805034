import Keycloak from 'keycloak-js'

const _kc = new Keycloak('/keycloak.json')

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${window.location.origin}${process.env.REACT_APP_CLIENT_PATH_NAME}/silent-check-sso.html`,
      pkceMethod: 'S256',
      enableLogging: true,
    })
    .then((authenticated) => {
      if (!authenticated) {
        console.log('user is not authenticated..!')
      }
      onAuthenticatedCallback()
    })
    .catch(console.error)
}

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = () => _kc.token

const getRoles = (clientName) => {
  if (_kc.tokenParsed.resource_access[clientName])
    return _kc.tokenParsed.resource_access[clientName].roles
  else return []
}

const isLoggedIn = () => !!_kc.token

const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin)

const getUsername = () => _kc.tokenParsed?.preferred_username
const getFirstName = () => _kc.tokenParsed?.given_name
const getLastName = () => _kc.tokenParsed?.family_name

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role))

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getRoles,
  updateToken,
  getUsername,
  getFirstName,
  getLastName,
  hasRole,
}

export default UserService
