import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import EnhancedTableBox from '../../../components/EnhancedTableBox'
import EnhancedIconButton from '../../../components/EnhancedIconButton'
import useHttpService from '../../../customHooks/useHttpService'
import { useParams } from 'react-router-dom'

function Threshold() {
  const httpService = useHttpService()
  const pathParams = useParams()
  const [comingWordToSearch, setComingWordToSearch] = useState(null)
  const url = `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_assurance/KeyPerformanceIndicatorThresholdOnly` //GET E PUT
  const downloadApiUrl = `${process.env.REACT_APP_FILEIO_ADDRESS}/nssmf_assurance/KpiThreshold/export`

  const [newValue, setNewValue] = useState(false)

  useEffect(() => {
    if (pathParams.id != null && pathParams.id !== ':id') {
      setComingWordToSearch(pathParams.id)
    }
  }, [])

  //array of the columns we want to show on the table
  const visibleColumns = [
    {
      id: 'NssKpiName',
      alignRight: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'NssKpiDescription',
      alignRight: false,
      disablePadding: false,
      label: 'Description',
    },
    {
      id: '1_Threshold',
      alignRight: false,
      disablePadding: false,
      label: '1° Threshold',
    },
    {
      id: '2_Threshold',
      alignRight: false,
      disablePadding: false,
      label: '2° Threshold',
    },
    {
      id: 'measureUnit',
      alignRight: false,
      disablePadding: false,
      label: 'Measure Unit',
    },
    {
      id: 'comparisonoperator',
      alignRight: false,
      disablePadding: false,
      label: 'Comparison Operator',
    },
  ]

  //array of actions placed at the end of each row
  const actions = [
    {
      action: 'edit',
      icon: 'edit',
      tooltip: 'Modify this kpi',
    },
    {
      action: 'reset',
      icon: 'restart_alt',
      tooltip: 'Reset to default values',
    },
  ]

  const schema = {
    NssKpiName: {
      type: 'text',
      title: 'Name',
      md: 6,
      disabled: true,
    },
    NssKpiDescription: {
      type: 'text',
      title: 'Description',
      md: 6,
      disabled: true,
    },
    '1_Threshold': {
      type: 'number',
      title: '1° Threshold',
      md: 3,
      decimals: 2,
      minValue: 1,
      maxValue: 100,
    },
    '2_Threshold': {
      type: 'number',
      title: '2° Threshold',
      md: 3,
      decimals: 2,
      minValue: 1,
      maxValue: 100,
    },
    measureUnit: {
      type: 'text',
      title: 'Measure Unit',
      md: 3,
      disabled: true,
    },
    comparisonoperator: {
      type: 'text',
      title: 'Comparison Operator',
      md: 3,
      disabled: true,
    },
  }

  const onAction = (action, e) => {
    if (action === 'reset') {
      httpService.postAPI(url, { NssKpiName: e.NssKpiName }, (data) => {
        setNewValue(true)
      })
    }
  }

  const downloadTable = () => {
    const body = {}
    httpService.downloadAPI(downloadApiUrl, (res) => {
      const blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const aEle = document.createElement('a') // Create a label
      const href = window.URL.createObjectURL(blob) // Create downloaded link
      aEle.href = href
      const objectType = downloadApiUrl
        .split('nssmf_assurance/')[1]
        .split('/')[0]
      aEle.download = `${objectType}_${new Date().toLocaleDateString(
        'sv'
      )}.xlsx` // File name after download
      document.body.appendChild(aEle)
      aEle.click() // Click to download
      document.body.removeChild(aEle) // Download complete remove element
      window.URL.revokeObjectURL(href) // Release blob object
    })
  }

  return (
    <div>
      <Grid container spacing={5} padding={5}>
        <Grid item md={12}>
          <EnhancedTableBox
            heightPercentage='0.5'
            title='Thresholds'
            valueMember='NssKpiName'
            visibleColumns={visibleColumns}
            actions={actions}
            onAction={onAction}
            search
            comingWordToSearch={comingWordToSearch}
            setComingWordToSearch={setComingWordToSearch}
            apiURL={url}
            editJsonSchema={schema}
            setnewValue={setNewValue}
            newValue={newValue}
          />
        </Grid>
        <Grid
          sx={{
            marginTop: '1vw',
            marginLeft: '3%',
            fontSize: 'inherit',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <span>{'Download Data'}</span>
          <EnhancedIconButton
            icon='download'
            place='bottom'
            onClick={downloadTable}
            // tooltip='Download excel/csv'
            color='inherit'
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default Threshold
