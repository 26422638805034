import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
} from '@mui/material'
import EnhancedIconButton from '../EnhancedIconButton'
import { v4 as uuidv4 } from 'uuid'
import { overrideStyle } from '../../utils'

import { styled } from '@mui/system'
import { useCardBoxContext } from '../../contexts/cardBoxContext'
import { Prompt } from 'react-router-dom'

const StyledListContainer = styled('div')({
  overflow: 'auto',
  paddingRight: '10px',
  '&::-webkit-scrollbar': {
    width: 7,
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: `rgb(179, 177, 177)`,
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgb(136, 136, 136)',
    borderRadius: '10px',
  },

  '&::-webkit-scrollbar-thumb:hover': {
    background: 'rgb(100, 100, 100)',
    borderRadius: '10px',
  },

  '&::-webkit-scrollbar-thumb:active': {
    background: 'rgb(68, 68, 68)',
    borderRadius: '10px',
  },
})

/**
 * A MUI Box element with a List in it.
 * @author Calogero Lo Bue
 * @param {Object[]} [listData] an array of object representing the entries inside the List
 * @example 
 * [
    {
      icon: 'arrow_right', //from https://fonts.googleapis.com/icon?family=Material+Icons
      content: 'item1', //the content of an item
      iconSize: 'large', //the size of the icon
      onTextClick: handleOnClick, //a callback function 
      onIconClick: doNothing, //a callback function
    },
    {
      icon: 'arrow_left',
      content: 'item2',
      onTextClick: handleOnClick,
    },
    {
      content: 'arrow_up',
    },
  ]
 * @param {string} [title] the title of the list
 * @param {Object} [cardBoxStyle] if defined it overrides the style property of the box. It consists of four objects: cardStyle, listContainerStyle, textItemStyle, titleStyle
 * @example
 * cardBoxStyle={{
    cardStyle: { width: '20vw', height: '75vh', boxShadow: 4 },
    textItemStyle: { textAlign: 'right' },
    titleStyle: {
      marginTop: '30px',
      backgroundColor: '#53B8BB',
      paddingLeft: '7%',
      paddingRight: '7%',
      color: 'white',
      width: '90%',
    },
  }}
 * @param {enum} [titleVariant] one of the following: h1, h2, h3, h4, h5, h6
 * @param {string} [api] you can pass a GET api to fill the list with returned data
 * @example
 * <CardBoxList
    cardBoxStyle={{
      cardStyle: { width: '20vw', height: '75vh', boxShadow: 4 },
      textItemStyle: { textAlign: 'right' },
      titleStyle: {
        marginTop: '30px',
        backgroundColor: '#53B8BB',
        paddingLeft: '7%',
        paddingRight: '7%',
        color: 'white',
        width: '90%',
      },
    }}
    listData={
      [
        {
          icon: 'arrow_right',
          content: 'Pagano - Elio e le Storie Tese',
          iconSize: 'large',
        },
        {
          icon: 'arrow_right',
          content: 'Wish you were here - Pink Floyd',
        },
        {
          icon: 'arrow_right',
          content: 'Learn To Fly -  Foo Fighters',
          iconSize: 'small',
        },
      ]
    }
    title='List of favourite songs'
    titleVariant='h4'
  />
 */
function CardBoxList({
  title,
  cardBoxStyle,
  titleVariant,
  listData,
  onSelectedItem,
}) {
  const cardBoxContext = useCardBoxContext()

  // useEffect(() => {
  //   if (cardBoxContext.selectedItemFromOtherPage !== null) {
  //     cardBoxContext.setChoosenIndexItem(
  //       cardBoxContext.selectedItemFromOtherPage.index
  //     )
  //     onSelectedItem(cardBoxContext.selectedItemFromOtherPage.item.content)
  //   }
  // }, [cardBoxContext.selectedItemFromOtherPage])

  return (
    <>
      <Paper
        elevation={3}
        sx={overrideStyle(
          {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
          },
          cardBoxStyle.cardStyle
        )}
      >
        <Box sx={{ ...cardBoxStyle.titleStyle }}>
          <Typography
            variant={titleVariant || cardBoxStyle.titleStyle.fontSize}
          >
            {title || ''}
          </Typography>
        </Box>
        <StyledListContainer
          style={overrideStyle(
            {
              marginTop: '20px',
              marginBottom: '20px',
              maxHeight: '70%',
            },
            cardBoxStyle?.listContainerStyle
          )}
        >
          <List>
            {listData &&
              listData.map((el, index) => {
                return (
                  <ListItem key={el.uniqueKey} disablePadding>
                    <ListItemButton
                      key={`${el.uniqueKey}-ListItemButton`}
                      selected={cardBoxContext.choosenIndexItem === index}
                      onClick={(e) => {
                        console.log(e)
                        el.onTextClick(e)
                        // if(!cardBoxContext.modifiedData.current)
                        cardBoxContext.handleSetSelectedListItem(e, el, index)
                      }}
                    >
                      {el.leftIcons &&
                        el.leftIcons.map((icon) => (
                          <EnhancedIconButton
                            key={`${icon.uniqueKey}-EnhancedIconButton`}
                            icon={icon.icon}
                            size={icon.iconSize || ''}
                            onClick={icon.onIconClick}
                            sx={{ color: icon.color }}
                            tooltip={icon.tooltip || null}
                          />
                        ))}
                      <ListItemText
                        key={`${el.uniqueKey}-ListItemText`}
                        sx={{ ...cardBoxStyle.textItemStyle }}
                        primary={el.content}
                      />
                      {el.rightIcons &&
                        el.rightIcons.map((icon) => (
                          <EnhancedIconButton
                            key={icon.uniqueKey}
                            icon={icon.icon}
                            size={icon.iconSize || ''}
                            tooltip={icon.tooltip || null}
                            onClick={(e) => {
                              cardBoxContext.setChoosenIndexItem(index)
                              icon.onClick(e, index, cardBoxContext)
                            }}
                          />
                        ))}
                    </ListItemButton>
                  </ListItem>
                )
              })}
          </List>
        </StyledListContainer>
      </Paper>
    </>
  )
}

export default CardBoxList
