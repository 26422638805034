import { v4 as uuidv4 } from 'uuid'
export const onDataChange = () => {}
const doNothing = () => {}
const saveData = () => {}

const cardMainActions = [
  {
    icon: 'list',
    place: 'top',
    label: 'Usage: 0%',
    type: 'dialog',
    iconSize: 'large',
    onClick: doNothing,
    tooltip: 'List of NF managed by this Network Element',
    uniqueKey: uuidv4(),
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_oss_object/NetworkElement_usage`,
    dialog: {
      style: {
        card: {},
        title: {},
        content: {},
        actions: {},
      },
      content: {
        type: 'table',
        title: 'List of NF managed by this Network Element',
        valueMember: 'id',
        url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkFunctionManager_short?NetworkElement_id=`,
        visibleColumns: [
          {
            id: 'ManagedElement_id',
            alignRight: false,
            disablePadding: false,
            label: 'Network Function ID',
          },
          {
            id: 'nf_name',
            alignRight: false,
            disablePadding: false,
            label: 'Network Function Name',
          },
        ],
      },
      buttons: [{ type: 'close' }],
    },
  },
  {
    uniqueKey: uuidv4(),
    icon: 'refresh',
    place: 'top',
    type: 'icon',
    label: 'Discovery',
    onClick: doNothing,
    tooltip: `Activation for NFs data update procedure`,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkFunctionManager?NetworkElement_id=`,
    messages: {
      //this is used to send messages to the rabbit queue, after the onClick of the icon
      messageList: [
        //the list of messages
        {
          uniqueKey: uuidv4(),
          name: '',
          scheduled: '',
          httpMethod: 'POST',
          url: `${process.env.REACT_APP_QUEUE_ADDRESS}/celery/push/OPERMOI/discovery`, //specify here the url of the api call
          body: {
            Name: 'discovery',
            moi_class: 'NSSI',
            moi_id: null,
            ne_id: null,
            Command: 'discovery',
          },
        },
      ],
    },
  },
  {
    icon: 'delete',
    iconSize: 'large',
    place: 'bottom',
    onClick: doNothing,
    type: 'icon',
    tooltip: 'Delete',
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_oss_object/NetworkElement`,
    uniqueKey: uuidv4(),
  },
  {
    icon: 'save',
    iconSize: 'large',
    place: 'bottom',
    type: 'icon',
    onClick: saveData,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_oss_object/NetworkElement`,
    tooltip: 'Save Changes',
    uniqueKey: uuidv4(),
  },
]

const schemaFields = {
  id: {
    title: 'ID',
    type: 'text',
    md: 6,
    disabled: true,
  },
  // name: {
  //   title: 'Name',
  //   type: 'text',
  //   md: 12,
  // },
  elementType: {
    title: 'Type',
    type: 'combo',
    md: 4,
    mandatory: true,
    showAsterisk: false,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_oss_object/NetworkElementType`,
    displayMember: 'val',
    valueMember: 'val',
    tooltip: `Network Service Type, it could be relating Configuration Management (CM), Submission for Configuration Management (SCM), Fault Management (FM), and Submission for Fault Management, Performance Management (PM) and Submission for Performance Management (SPM). The same group or resource (NF) are managed by Network Service Type belonging the same Element Group`,
  },
  url: {
    title: 'Url',
    type: 'text',
    md: 12,
    mandatory: true,
    showAsterisk: false,
    tooltip: `URL for addressing the Network Service Type. The URL can include the protocol (http or https), the credentials (username and password) and the port.`,
    // disabledIf: [{ DNS_supported: true }],
  },
  // ip: {
  //   title: 'IP',
  //   type: 'text',
  //   md: 6,
  //   // disabledIf: [{ DNS_supported: true }],
  // },
  // port: {
  //   title: 'Port',
  //   type: 'text',
  //   md: 6,
  //   // disabledIf: [{ DNS_supported: true }],
  // },
  // DNS_supported: {
  //   title: 'DNS Supported',
  //   type: 'checkbox',
  //   md: 6,
  // },
  groupName: {
    title: 'Element Group Name',
    type: 'text',
    md: 6,
    mandatory: true,
    showAsterisk: false,
    tooltip: `Element Group aggregates Network Service Type managing the same group of resources (NF).`,
    // disabledIf: [{ DNS_supported: false }],
  },
}

const schemaValues = {
  id: '',
  name: '',
  ip: '',
  port: '',
  DNS_supported: '',
  DNS_name: '',
}

const cardBoxStyleMain = {
  cardStyle: {
    width: '65vw',
    height: '75vh',
    backgroundColor: 'background.dark',
  },
  titleStyle: {
    marginTop: '30px',
    backgroundColor: 'background.light',
    paddingLeft: '6%',
    paddingRight: '6%',
    color: 'primary.light',
    width: '102%',
  },
  iconStyle: {
    topBox: {
      // border: '1px solid red',
    },
    bottomBox: {
      // border: '1px solid green',
    },
  },
}

const titleDataMain = {
  variant: 'h4',
}

const cardMain = {
  actions: cardMainActions,
  schema: {
    fields: schemaFields,
    values: schemaValues,
  },
  onDataChange,
  style: cardBoxStyleMain,
  title: titleDataMain,
}

export default cardMain
