import { v4 as uuidv4 } from 'uuid'
import cardMain from './mainData-NSI'
const doNothing = () => {}

const {
  id,
  NST,
  name,
  PlmnId_id,
  administrativeState,
  operationalState,
  ServiceProfile,
  ...schemaFields
} = cardMain.schema.fields

export const outsideActions = [
  {
    uniqueKey: uuidv4(),
    icon: 'add_circle',
    label: 'Add New Item',
    place: 'bottom',
    onClick: doNothing,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSlice`,
    type: 'dialog',
    dialog: {
      title: 'Add New NSI',
      style: {
        card: {},
        title: {
          marginTop: '30px',
          backgroundColor: 'background.light',
          paddingLeft: '6%',
          paddingRight: '6%',
          color: 'primary.light',
          width: '100%',
        },
        content: {},
        actions: {},
      },
      schemaFields: {
        ...schemaFields,
        name: {
          ...name,
          mandatory: true,
          showAsterisk: true,
        },
        PlmnId_id: {
          ...PlmnId_id,
          mandatory: true,
          showAsterisk: true,
          disabled: false,
        },
        ServiceProfile_id: {
          title: 'Nest (GST)',
          type: 'combo',
          md: 6,
          url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/ServiceProfile`,
          valueMember: 'id',
          displayMember: 'name',
          mandatory: true,
          showAsterisk: true,
        },
      },
      tabs: [],
      onDataChange: doNothing,
      buttons: [{ type: 'cancel' }, { type: 'save' }],
    },
  },
  {
    uniqueKey: uuidv4(),
    icon: 'upload',
    type: 'upload',
    label: 'Import Item',
    place: 'bottom',
    onClick: doNothing,
    url: `${process.env.REACT_APP_API_ADDRESS.replace(
      '/api',
      ''
    )}/fileio/nssmf_managed_object/NetworkSlice/import`,
  },
  {
    uniqueKey: uuidv4(),
    icon: 'download',
    type: 'template',
    label: 'Export Format',
    place: 'bottom',
    onClick: doNothing,
    url: `${process.env.REACT_APP_API_ADDRESS.replace(
      '/api',
      ''
    )}/fileio/nssmf_managed_object/NetworkSlice/template`,
  },
  {
    uniqueKey: uuidv4(),
    icon: 'download',
    type: 'export',
    label: 'Download Data',
    place: 'bottom',
    onClick: doNothing,
    url: `${process.env.REACT_APP_API_ADDRESS.replace(
      '/api',
      ''
    )}/fileio/nssmf_managed_object/NetworkSlice/export`,
  },
]
