import React from 'react'
import Map from '../../components/Map/Map'
import { TextField } from '@mui/material'
import Grid from '@mui/material/Grid'
import { Icon } from '@mui/material'
import { IconButton } from '@mui/material'

import useHttpService from '../../customHooks/useHttpService.js'


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import Drawer from '@mui/material/Drawer';
import { Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import EnhancedTableBox from '../../components/EnhancedTableBox'


import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


const RadioModMap = () => {

    //states for selection
    const httpService = useHttpService()
    const history = useHistory()
    const [site, setSite] = React.useState()
    const [mapData, setMapData] = React.useState()
    const [siteData, setSiteData] = React.useState([])
    const [siteId, setSiteId] = React.useState()

    const [alignment, setAlignment] = React.useState('map');

    const [value, setValue] = React.useState('map');

    const [open, setOpen] = React.useState(false)


    const [openDrawer, setOpenDrawer] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    // const handleChange = (event, newAlignment) => {
    //     setAlignment(newAlignment);
    //     if (newAlignment = 'list')
    //         setOpen(true)
    // };


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const visibleColumns = [
        {
            id: 'site_code',
            alignRight: false,
            disablePadding: false,
            label: 'ID INWIT',
        },
        {
            id: 'original_site_code',
            alignRight: false,
            disablePadding: false,
            label: 'Codice sito Origine',
        },
        {
            id: 'site_name',
            alignRight: false,
            disablePadding: false,
            label: 'Nome Sito',
        },
        {
            id: 'zona',
            alignRight: false,
            disablePadding: false,
            label: 'Zone',
        },
        {
            id: 'regione',
            alignRight: false,
            disablePadding: false,
            label: 'Region',
        },
        {
            id: 'provincia',
            alignRight: false,
            disablePadding: false,
            label: 'Province',
        },
        {
            id: 'comune',
            alignRight: false,
            disablePadding: false,
            label: 'Comune',
        },
        {
            id: 'address',
            alignRight: false,
            disablePadding: false,
            label: 'Indirizzo',
        }
    ]

    function mapUpdate(jsondata) {
        console.log(jsondata)
        setMapData(jsondata)
    }

    function getCoordinates() {
        httpService.getAPI(
            `https://networkteam.it.nttdata-emea.com/api/scalar/office/sites?freetext=${site}`,
            mapUpdate
        )
    }

    function onMapClick(info, event) {
        setSiteData(info.object)
        setSiteId(info.object.id)
        setOpenDrawer(true)
    }

    function openEvalListPage(e){
        history.push(`/evalList/${siteId}`)  
    }

    return (
        <Grid container spacing={3} padding={3}>
            <Grid item md={5}>
                <TextField
                    fullWidth
                    id="filled-basic"
                    label="SITE"
                    variant="filled"

                    onChange={(e) => {
                        setSite(e.target.value)
                    }}

                    InputProps={{
                        endAdornment:
                            <IconButton onClick={getCoordinates}>
                                <Icon fontSize='small'>search</Icon>
                            </IconButton>
                    }}
                />
                {/* <Map
                    style={{ height: '66vh', width: '97vw', position: 'relative' }}
                    initialViewState={{
                        longitude: 13.0,
                        latitude: 42.0,
                        zoom: 5,
                        bearing: 0,
                        pitch: 45,
                    }}
                    geoJson={mapData}
                /> */}
            </Grid>

            {/* <Grid>
                <ToggleButtonGroup
                    sx={{ ml: "2rem", mt: "2rem" }}
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                >
                    <ToggleButton value="map">Map</ToggleButton>
                    <ToggleButton value="list">List</ToggleButton>
                </ToggleButtonGroup>
            </Grid> */}

            {/* <Dialog open={open} onClose={handleCloseCancel}>
                <DialogContent>
                    <EnhancedTableBox
                        apiURL={`https://networkteam.it.nttdata-emea.com/api/scalar/office/sites?freetext=${site}`}
                        visibleColumns={visibleColumns}
                    />
                </DialogContent>
            </Dialog> */}

            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
                            <Tab label="MAP" value="map" />
                            <Tab label="LIST" value="list" />
                        </TabList>
                    </Box>
                    <TabPanel value="map">                        
                        <Map
                            style={{ height: '58vh', width: '97vw', position: 'relative' }}
                            initialViewState={{
                                longitude: 13.0,
                                latitude: 42.0,
                                zoom: 5,
                                bearing: 0,
                                pitch: 45,
                            }}
                            geoJson={mapData}
                            onClick={onMapClick}
                        />
                        <Drawer
                            variant="persistent"
                            anchor="right"
                            open={openDrawer}
                        >

                            <List sx={{ width: '100%', maxWidth: 360 }}>                                

                                <Typography variant="h5" align = "center" sx={{ flexGrow: 1 ,borderRadius: 10, display: 'inline'}} component="div">
                                    {siteData.site_name}
                                </Typography>
                                <IconButton onClick={openEvalListPage}>
                                    <Icon fontSize='large'>arrow_circle_right</Icon>
                                </IconButton>
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="ID INWIT"
                                        secondary={siteData.site_code}
                                    />
                                </ListItem>

                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="Codice sito Origine"
                                        secondary={siteData.original_site_code}
                                    />
                                </ListItem>

                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="Zone"
                                        secondary={siteData.zona}
                                    />
                                </ListItem>

                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="Region"
                                        secondary={siteData.regione}
                                    />
                                </ListItem>

                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="Comune"
                                        secondary={siteData.comune}
                                    />
                                </ListItem>

                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="Province"
                                        secondary={siteData.provincia}
                                    />
                                </ListItem>

                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="Indirizzo"
                                        secondary={siteData.address}
                                    />
                                </ListItem>
                            </List>
                        </Drawer>
                    </TabPanel>

                    <TabPanel value="list">
                        <EnhancedTableBox
                            apiURL={`https://networkteam.it.nttdata-emea.com/api/scalar/office/sites?freetext=${site}`}
                            visibleColumns={visibleColumns}
                        />
                    </TabPanel>
                </TabContext>

            </Box>
        </Grid>
    )
}
export default RadioModMap