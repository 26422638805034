import React, { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Tooltip,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material'
import EnhancedTableBox from '../../../components/EnhancedTableBox'
import EnhancedIconButton from '../../../components/EnhancedIconButton'
import useHttpService from '../../../customHooks/useHttpService'
import JsonSchemaBox from '../../../components/JsonSchemaBox'
import { Button } from '@mui/material'
import { useSnackbar } from '../../../contexts/snackbarContext'
import { useParams } from 'react-router-dom'

// AGGIUNGERE I PALLINI IN BASE AL CONTENUTO DELLA COLONNA overThreshold:
// - 0 nessun pallino
// - 1 pallino dello stesso colore dell'alarm MAJOR
// - 2 pallino dello stesso colore dell'alarm CRITICAL

const Kpi = () => {
  // let to = new Date()
  // let from = new Date()
  // from.setMinutes(from.getMinutes() - 5)
  // to = to.toISOString()
  // from = from.toISOString()

  const [dataTable, setDataTable] = useState(null)
  const [comingWordToSearch, setComingWordToSearch] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [detailsData, setDetailsData] = useState(null)
  const httpService = useHttpService()
  const [url, setUrl] = useState(null)
  let searchUrl = `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_assurance/KeyPerformanceIndicatorAll`
  let kpiCorrelatedUrl = `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_assurance/KpiCorrelated?managedElement_id=&measure=&eventTime=`
  let kpiCorrelatedSentUrl = `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_assurance/KpiCorrelatedSent?managedElement_id=&measure=&eventTime=`
  let downloadApiUrl = `${process.env.REACT_APP_FILEIO_ADDRESS}/nssmf_assurance/KeyPerformanceIndicator/export`

  const snackbar = useSnackbar()
  const pathParams = useParams()

  const initialSearchStatus = useRef('NOT STARTED')
  const searchFilters = useRef(null)

  // se inizia con nssmf -> sent

  const createOverThresholdImage = (data) => {
    return data.map((row) => {
      if (row.overThreshold === 1 || row.overThreshold === '1') {
        row.value = (
          <Tooltip title='1st Threshold exceeded'>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '50%',
              }}
            >
              {row.value}
              <EnhancedIconButton
                icon='circle'
                sx={{ color: 'orange' }}
                size='small'
              />
            </Box>
          </Tooltip>
        )
      } else if (row.overThreshold === 2 || row.overThreshold === '2') {
        row.value = (
          <Tooltip title='2nd Threshold exceeded'>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '50%',
              }}
            >
              {row.value}
              <EnhancedIconButton
                icon='circle'
                sx={{ color: 'red' }}
                size='small'
              />
            </Box>
          </Tooltip>
        )
      }
      return row
    })
  }

  const jsonSchema = {
    kpi: {
      title: 'KPIs',
      type: 'combo',
      md: 3,
      url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_assurance/KeyPerformanceIndicatorList?filtered=true`,
      displayMember: 'description',
      valueMember: 'kpi',
      multiple: true,
    },
    from: {
      title: 'From',
      type: 'date',
      md: 3,
      disablePast: false,
    },
    to: {
      title: 'To',
      type: 'date',
      md: 3,
      disablePast: false,
    },
  }

  //array of the columns we want to show on the table
  const visibleColumns = [
    {
      id: 'eventTime',
      alignRight: false,
      disablePadding: false,
      label: 'Event Time',
      type: 'datetime',
    },
    {
      id: 'measure',
      alignRight: false,
      disablePadding: false,
      label: 'Measure',
    },
    {
      id: 'name',
      alignRight: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'managedElement_id',
      alignRight: false,
      disablePadding: false,
      label: 'Managed Element ID',
    },
    {
      id: 'cellDetail',
      alignRight: false,
      disablePadding: false,
      label: 'Cell Details',
    },
    {
      id: 'value',
      alignRight: false,
      disablePadding: false,
      label: 'Value',
    },
  ]

  //array of actions placed at the end of each row
  const actions = [
    {
      action: 'showDetails',
      icon: 'more_vert',
      tooltip: 'Show details',
    },
  ]

  const detailsVisibleColumns = [
    {
      id: 'managedElement_id',
      alignRight: false,
      disablePadding: false,
      label: 'Managed Element ID',
    },
    {
      id: 'measure',
      alignRight: false,
      disablePadding: false,
      label: 'Measure',
    },
    {
      id: 'value',
      alignRight: false,
      disablePadding: false,
      label: 'Value',
    },
  ]

  // set default filter for the very first search
  useEffect(() => {
    let to = new Date()
    let from = new Date()
    from.setMinutes(from.getMinutes() - 5)
    to = to.toISOString()
    from = from.toISOString()

    searchFilters.current = {
      from,
      to,
    }
  }, [])

  //Perform the first search
  useEffect(() => {
    if (initialSearchStatus.current === 'NOT STARTED') {
      initialSearchStatus.current = 'STARTED'
      handleSearchKpi()
    }
  }, [])

  useEffect(() => {
    if (pathParams.id != null && pathParams.id !== ':id') {
      setComingWordToSearch(pathParams.id)
    }
  }, [])

  //event that is fired when an action is clicked
  function onAction(action, row, e) {
    if (action === 'showDetails') {
      const managedElement_id = row.managedElement_id
      const measure = row.measure
      const eventTime = new Date(row.eventTime).toISOString()
      let url = ''
      if (row.measure.toLowerCase().startsWith('nssmf')) {
        url = `${kpiCorrelatedSentUrl.split('=')[0]}=${managedElement_id}${
          kpiCorrelatedSentUrl.split('=')[1]
        }=${measure}${kpiCorrelatedSentUrl.split('=')[2]}=${eventTime}`
      } else {
        url = `${kpiCorrelatedUrl.split('=')[0]}=${managedElement_id}${
          kpiCorrelatedUrl.split('=')[1]
        }=${measure}${kpiCorrelatedUrl.split('=')[2]}=${eventTime}`
      }
      httpService.getAPI(url, (data) => {
        setDetailsData(data)
        setDialogOpen(true)
      })
    }
    //set the clicked row in a state
    //alert(action + ' on row:' + JSON.stringify(row))
  }

  const handleSearchKpi = () => {
    const urlApi = searchKpi()
    httpService.getAPI(urlApi, (data) => {
      data = createOverThresholdImage(data)
      setDataTable(data)
      if (initialSearchStatus.current === 'STARTED') {
        snackbar.setSnackbarInfo({
          status: 'info',
          message: `Counters calculated during the last 5 minutes.`,
        })
        initialSearchStatus.current = 'ENDED'
      } else {
        snackbar.setSnackbarInfo({
          status: 'success',
          message: 'Your request has been processed successfully',
        })
      }
    })
  }

  const searchKpi = () => {
    let from = null
    let to = null
    let measure = null

    if (!searchFilters.current?.from) {
      from = new Date()
      from.setMinutes(from.getMinutes() - 5)
    } else {
      from = searchFilters.current.from
    }
    if (!searchFilters.current?.to) {
      to = new Date()
    } else {
      to = searchFilters.current.to
    }

    from = typeof from.toISOString === 'function' ? from.toISOString() : from
    to = typeof to.toISOString === 'function' ? to.toISOString() : to

    if (!searchFilters.current?.kpi) {
      measure = `measure=&`
    } else {
      measure = searchFilters.current.kpi
        ? `measure=${searchFilters.current.kpi
            .toString()
            .replace('[', '')
            .replace(']', '')}&`
        : ''
    }

    return `${searchUrl.split('?')[0]}?${measure}from=${from}&to=${to}`
  }

  const downloadTable = () => {
    const body = {}
    httpService.downloadAPI(downloadApiUrl, (res) => {
      const blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const aEle = document.createElement('a') // Create a label
      const href = window.URL.createObjectURL(blob) // Create downloaded link
      aEle.href = href
      const objectType = downloadApiUrl
        .split('nssmf_assurance/')[1]
        .split('/')[0]
      aEle.download = `${objectType}_${new Date().toLocaleDateString(
        'sv'
      )}.xlsx` // File name after download
      document.body.appendChild(aEle)
      aEle.click() // Click to download
      document.body.removeChild(aEle) // Download complete remove element
      window.URL.revokeObjectURL(href) // Release blob object
    })
  }

  return (
    <div>
      {detailsData && (
        <Dialog
          fullWidth
          maxWidth='lg'
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false)
          }}
        >
          <DialogTitle>{`${detailsData?.name || 'KPI'} details`}</DialogTitle>
          <DialogContent>
            {/* if the content of the dialog is a table, we render the table */}
            <EnhancedTableBox
              title='Details'
              valueMember='id'
              visibleColumns={detailsVisibleColumns}
              data={detailsData}
              heightPercentage='0.7'
            />
          </DialogContent>
          {/* Now we render the buttons of the dialog */}
          <DialogActions>
            <Button
              onClick={(e) => {
                setDialogOpen(false)
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Grid container spacing={5} padding={5}>
        <JsonSchemaBox
          schema={jsonSchema}
          formData={searchFilters.current || {}}
          onChange={(data) => {
            console.log(data)
            if (data) searchFilters.current = data
          }}
        />
        <Grid item md={11}></Grid>
        <Grid item md={1}>
          <Button variant='outlined' onClick={handleSearchKpi}>
            Search
          </Button>
        </Grid>

        <Grid item md={12}>
          <EnhancedTableBox
            heightPercentage='0.5'
            title='KPIs'
            valueMember='id'
            visibleColumns={visibleColumns}
            data={dataTable}
            actions={actions}
            onAction={onAction}
            search
            // apiURL={url}
            comingWordToSearch={comingWordToSearch}
            setComingWordToSearch={setComingWordToSearch}
          />
        </Grid>
        <Grid
          sx={{
            marginTop: '1vw',
            marginLeft: '3%',
            fontSize: 'inherit',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <span>{'Download Data'}</span>
          <EnhancedIconButton
            icon='download'
            place='bottom'
            onClick={downloadTable}
            // tooltip='Download excel/csv'
            color='inherit'
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default Kpi
