import { v4 as uuidv4 } from 'uuid'

const onDataChange = () => {}
const doNothing = () => {}
const saveData = () => {}

const cardMainActions = [
  {
    icon: 'list',
    place: 'top',
    label: 'Usage: 0%',
    type: 'dialog',
    iconSize: 'large',
    onClick: doNothing,
    tooltip: 'List of Slice using this template',
    uniqueKey: uuidv4(),
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/ServiceProfile_usage`,
    dialog: {
      style: {
        card: {},
        title: {},
        content: {},
        actions: {},
      },
      content: {
        type: 'table',
        title: 'List of Network Slice using this template',
        valueMember: 'id',
        url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSlice?ServiceProfile_id=`,
        visibleColumns: [
          {
            id: 'name',
            alignRight: false,
            disablePadding: false,
            label: 'Name',
          },
          {
            id: 'ServiceProfile',
            alignRight: false,
            disablePadding: false,
            label: 'Service Profile',
          },
          {
            id: 'NetworkSliceSubnet',
            alignRight: false,
            disablePadding: false,
            label: 'Network Slice Subnet',
          },
        ],
      },
      buttons: [{ type: 'close' }],
    },
  },
  {
    icon: 'delete',
    iconSize: 'large',
    place: 'bottom',
    onClick: doNothing,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/ServiceProfile`,
    tooltip: 'Delete',
    uniqueKey: uuidv4(),
  },
  {
    icon: 'save',
    iconSize: 'large',
    place: 'bottom',
    onClick: saveData,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/ServiceProfile`,
    tooltip: 'Save Changes',
    uniqueKey: uuidv4(),
  },
]

const schemaFields = {
  id: {
    title: 'ID',
    type: 'text',
    md: 4,
    disabled: true,
    tab: 'General',
  },
  name: {
    title: 'Name',
    type: 'text',
    md: 4,
    tab: 'General',
    mandatory: true,
    showAsterisk: false,
  },
  serviceProfileId: {
    title: 'Service Profile ID',
    type: 'text',
    md: 4,
    disabled: true,
    tab: 'General',
  },
  plmnInfoList_Id: {
    title: 'PLMN Info List',
    type: 'list',
    md: 6,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/PlmnInfo`,
    dense: true,
    valueMember: 'id',
    displayMember: 'name',
    multiselect: true,
    tab: 'General',
    mandatory: true,
    showAsterisk: false,
  },
  coverageArea: {
    title: 'Coverage Area',
    type: 'text',
    md: 6,
    tab: 'General',
    tooltip: `An attribute specifies the coverage area of the network slice, i.e. the geographic region where a 3GPP communication service is accessible, see Table 7.1-1 of TS 22.261 [28]) and NG.116 [50]. `,
  },
  maxNumberofUEs: {
    title: 'Max Number of UEs',
    type: 'number',
    md: 4,
    tooltip: `An attribute specifies the maximum number of UEs may simultaneously access the network slice or network slice subnet instance.`,
    nonNegative: true,
    tab: 'General',
    mandatory: true,
    showAsterisk: false,
  },
  latency: {
    title: 'Latency (ms)',
    type: 'number',
    md: 4,
    tooltip: `An attribute specifies the packet transmission latency (millisecond) through the RAN, CN, and TN part of 5G network and is used to evaluate utilization performance of the end-to-end network slice. See clause 6.3.1 of 28.554 [27].`,
    nonNegative: true,
    tab: 'General',
    mandatory: true,
    showAsterisk: false,
  },
  uEMobilityLevel: {
    title: 'UE Mobility Level',
    type: 'combo',
    md: 4,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/uEMobilityLevel`,
    valueMember: 'val',
    displayMember: 'val',
    allowEmptySelection: true,
    tooltip: `An attribute specifies the mobility level of UE accessing the network slice. See 6.2.1 of TS 22.261 [28]. allowedValues: stationary, nomadic, restricted mobility, fully mobility.`,
    tab: 'User Related Data',
  },
  // networkSliceSharingIndicator: {
  //   title: 'Network Slice Sharing Indicator',
  //   type: 'text',
  //   md: 4,
  // },
  sst: {
    title: 'SST',
    type: 'combo',
    md: 4,
    tooltip: `This parameter specifies the slice/service type in a ServiceProfile to be supported by a network slice.`,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/mod_sst`,
    valueMember: 'id',
    displayMember: 'name',
    tab: 'General',
    mandatory: true,
    showAsterisk: false,
  },
  availability: {
    title: 'Availability (%)',
    type: 'number',
    nonNegative: true,
    maxValue: 100,
    md: 4,
    tooltip: `This parameter specifies the communication service availability requirement, expressed as a percentage. The communication service availability is defined in clause 3.1 of TS 22.261 [28].`,
    tab: 'General',
  },
  // delayTolerance: {
  //   title: 'Delay Tolerance',
  //   type: 'text',
  //   md: 4,
  //   enum: ['SUPPORTED', 'NOT SUPPORTED'],
  //   mandatory: true,
  //   tooltip: `An attribute specifies the properties of service delivery flexibility, especially for the vertical services that are not chasing a high system performance. See clause 4.3 of TS 22.104 [51].`,
  // },
  // dLDeterministicComm_Availability: {
  //   title: 'DL Availability',
  //   type: 'text',
  //   md: 4,
  //   enum: ['SUPPORTED', 'NOT SUPPORTED'],
  //   tab: 'Deterministic Comm',
  //   tooltip: `An attribute specifies the properties of service delivery flexibility, especially for the vertical services that are not chasing a high system performance. See clause 4.3 of TS 22.104 [51].`,
  // },
  // dLDeterministicComm_PeriodicityList: {
  //   title: 'DL Periodicity List (s)',
  //   type: 'number',
  //   md: 4,
  //   nonNegative: true,
  //   tab: 'Deterministic Comm',
  //   tooltip: `An attribute specifies the properties of service delivery flexibility, especially for the vertical services that are not chasing a high system performance. See clause 4.3 of TS 22.104 [51].`,
  // },
  // uLDeterministicComm_Availability: {
  //   title: 'UL Availability',
  //   type: 'text',
  //   md: 4,
  //   enum: ['SUPPORTED', 'NOT SUPPORTED'],
  //   tab: 'Deterministic Comm',
  //   tooltip: `An attribute specifies the properties of service delivery flexibility, especially for the vertical services that are not chasing a high system performance. See clause 4.3 of TS 22.104 [51].`,
  // },
  // uLDeterministicComm_PeriodicityList: {
  //   title: 'UL Periodicity List (s)',
  //   type: 'number',
  //   md: 4,
  //   nonNegative: true,
  //   tab: 'Deterministic Comm',
  //   tooltip: `An attribute specifies the properties of service delivery flexibility, especially for the vertical services that are not chasing a high system performance. See clause 4.3 of TS 22.104 [51].`,
  // },
  dLThptPerUE: {
    title: 'DL THPT per UE (Mbps)',
    type: 'combo',
    md: 4,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/mod_ThptPerUE`,
    valueMember: 'name',
    displayMember: 'name',
    tooltip: `This attribute defines data rate supported by the network slice per UE, refer NG.116 [50]. `,
    tab: 'User Related Data',
    mandatory: true,
    showAsterisk: false,
  },
  uLThptPerUE: {
    title: 'UL THPT per UE (Mbps)',
    type: 'combo',
    md: 4,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/mod_ThptPerUE`,
    valueMember: 'name',
    displayMember: 'name',
    tooltip: `This attribute defines data rate supported by the network slice per UE, refer NG.116 [50]. `,
    tab: 'User Related Data',
    mandatory: true,
    showAsterisk: false,
  },
  dLMaxPktSize: {
    title: 'DL Max Packet Size',
    type: 'number',
    md: 4,
    tab: 'General',
    nonNegative: true,
    tooltip: `This parameter specifies the maximum packet size supported by the network slice, refer NG.116 [50]. `,
  },
  uLMaxPktSize: {
    title: 'UL Max Packet Size',
    type: 'number',
    md: 4,
    tab: 'General',
    nonNegative: true,
    tooltip: `This parameter specifies the maximum packet size supported by the network slice, refer NG.116 [50]. `,
  },
  // kPIMonitoring: {
  //   title: 'KPI Monitoring',
  //   type: 'text',
  //   md: 4,
  // },
  // userMgmtOpen: {
  //   title: 'User MGMT Open',
  //   type: 'text',
  //   md: 4,
  // },
  activityFactor: {
    title: 'Activity Factor (%)',
    type: 'number',
    nonNegative: true,
    maxValue: 100,
    md: 4,
    tab: 'General',
    tooltip: `An attribute specifies the percentage value of the amount of simultaneous active UEs to the total number of UEs where active means the UEs are exchanging data with the network. See Table 7.1-1 of TS 22.261 [28]).`,
  },
  uESpeed: {
    title: 'UE Speed',
    type: 'combo',
    md: 4,
    tab: 'User Related Data',
    tooltip: `An attribute specifies the maximum speed (in km/hour) supported by the network slice or network slice subnet at which a defined QoS can be achieved. See Table 7.1-1 of TS 22.261 [28]).`,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/mod_uESpeed`,
    allowEmptySelection: true,
    valueMember: 'id',
    displayMember: 'name',
  },
  jitter: {
    title: 'Jitter',
    type: 'number',
    nonNegative: true,
    md: 4,
    tab: 'General',
    tooltip: `An attribute specifies the deviation from the desired value to the actual value when assessing time parameters, see clause C.4.1 of TS 22.104 [51].`,
  },
  survivalTime: {
    title: 'Survival Time (ms)',
    type: 'number',
    md: 4,
    tab: 'General',
    tooltip: `An attribute specifies the time that an application consuming a communication service may continue without an anticipated message. See clause 5 of TS 22.104 [51]).`,
    nonNegative: true,
  },
  // radioSpectrum: {
  //   title: 'Radio Spectrum',
  //   type: 'text',
  //   md: 4,
  // },
  reliability: {
    title: 'Reliability (%)',
    type: 'combo',
    md: 4,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/mod_sst`,
    valueMember: 'reliability',
    displayMember: 'fullname',
    tab: 'General',
    allowEmptySelection: true,
    tooltip: `An attribute specifies in the context of network layer packet transmissions, percentage value of the amount of sent network layer packets successfully delivered to a given system entity within the time constraint required by the targeted service, divided by the total number of sent network layer packets, see TS 22.261 [28] and TS 22.104 [51].`,
  },
  maxDLDataVolume: {
    title: 'Max DL Data Volume',
    type: 'number',
    nonNegative: true,
    md: 4,
    tab: 'General',
    tooltip: `An attribute specifies the maximum DL PDCP data volume supported by the network slice instance (performance measurement definition see in TS 28.552[69]). The unit is MByte/day.`,
    mandatory: true,
    showAsterisk: false,
  },
  maxULDataVolume: {
    title: 'Max UL Data Volume',
    type: 'number',
    nonNegative: true,
    md: 4,
    tab: 'General',
    tooltip: `An attribute specifies the maximum UL PDCP data volume supported by the network slice instance (performance measurement definition see in TS 28.552[69]). The unit is MByte/day.`,
    mandatory: true,
    showAsterisk: false,
  },
  // synchronicity: {
  //   title: 'Synchronicity',
  //   type: 'number',
  //   decimals: 2,
  //   md: 4,
  // },
  positioning_availability: {
    title: 'Availability',
    type: 'list',
    dense: true,
    multiselect: true,
    md: 5,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/mod_positioning_availability`,
    valueMember: 'name',
    displayMember: 'name',
    tooltip: `An attribute specifies whether the network slice provides geo-localization methods or supporting methods, see clause 3.4.20 of NG.116 [50].`,
    tab: 'Positioning',
    mandatory: true,
    showAsterisk: false,
  },
  positioning_Predictionfrequency: {
    title: 'Prediction Frequency',
    type: 'text',
    md: 4,
    enum: ['PER SECOND', 'PER MINUTE', 'PER HOUR'],
    tooltip: `An attribute specifies whether the network slice provides geo-localization methods or supporting methods, see clause 3.4.20 of NG.116 [50].`,
    tab: 'Positioning',
    mandatory: true,
    showAsterisk: false,
  },
  positioning_Accuracy: {
    title: 'Accuracy (m)',
    type: 'number',
    md: 3,
    nonNegative: true,
    tab: 'Positioning',
    mandatory: true,
    showAsterisk: false,
  },
  sliceSimultaneousUse: {
    title: 'Slice Simultaneous Use ',
    type: 'combo',
    md: 4,
    tooltip: `This attribute describes whether a network slice can be simultaneously used by a device together with other network slices and if so, with which other classes of network slices.
    Admitted values:
    “0”: Can be used with any network slice
    “1”: Can be used with network slices with same SST value
    “2”: Can be used with any network slice with same SD value
    “3”: Cannot be used with another network slice
    “4”: Cannot be used by a UE in a specific location`,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/mod_sliceSimultaneousUse`,
    valueMember: 'id',
    displayMember: 'name',
    tab: 'General',
    mandatory: true,
    showAsterisk: false,
  },
  // energyEfficiency: {
  //   title: 'Energy Efficiency',
  //   type: 'text',
  //   md: 4,
  //   tooltip: `An attribute which describes the energy efficiency, i.e. the ratio between the performance and the energy consumption (EC) when assessed during the same time frame, see clause 3.4.7 of NG.116 [50].`,
  //   enum: ['SUPPORTED', 'NOT SUPPORTED'],
  // },
  v2XModels: {
    title: 'V2X Comm Models',
    type: 'combo',
    md: 4,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/V2XMode`,
    valueMember: 'val',
    displayMember: 'val',
    tab: 'General',
    tooltip: `An attribute specifies in the context of network layer packet transmissions, percentage value of the amount of sent network layer packets successfully delivered to a given system entity within the time constraint required by the targeted service, divided by the total number of sent network layer packets, see TS 22.261 [28] and TS 22.104 [51].`,
    mandatory: true,
    showAsterisk: false,
  },
  nROperatingBands: {
    title: 'NR Operating Bands',
    type: 'list',
    multiselect: true,
    md: 4,
    valueMember: 'name',
    displayMember: 'name',
    dense: true,
    tab: 'General',
    tooltip: `It represents which 5G NR frequency bands can be used to access the network slice. 5G NR operating bands are defined in 3GPP TS 38.101-1 [42].`,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/mod_nROperatingBands`,
    mandatory: true,
    showAsterisk: false,
  },
  dLThptPerSlice_guaThpt: {
    title: `DL gua Thpt per Slice`,
    type: 'number',
    nonNegative: true,
    md: 4,
    disabled: true,
    tab: 'User Related Data',
    tooltip: `This attribute defines achievable data rate of the network slice in downlink that is available ubiquitously across the coverage area of the slice, refer NG.116 [50]. he value is automatically calculated leveraging on maxNumberofUEs,  activityFactor and dLThptUE.`,
  },
  dLThptPerSlice_maxThpt: {
    title: `DL max Thpt per Slice`,
    type: 'number',
    nonNegative: true,
    md: 4,
    disabled: true,
    tab: 'User Related Data',
    tooltip: `This attribute defines achievable data rate of the network slice in downlink that is available ubiquitously across the coverage area of the slice, refer NG.116 [50]. he value is automatically calculated leveraging on maxNumberofUEs,  activityFactor and dLThptUE.`,
  },
  uLThptPerSlice_guaThpt: {
    title: `UL gua Thpt per Slice`,
    type: 'number',
    nonNegative: true,
    md: 4,
    disabled: true,
    tab: 'User Related Data',
    tooltip: `This attribute defines achievable data rate of the network slice in downlink that is available ubiquitously across the coverage area of the slice, refer NG.116 [50]. he value is automatically calculated leveraging on maxNumberofUEs,  activityFactor and uLThptUE.`,
  },
  uLThptPerSlice_maxThpt: {
    title: `UL max Thpt per Slice`,
    type: 'number',
    nonNegative: true,
    md: 4,
    disabled: true,
    tab: 'User Related Data',
    tooltip: `This attribute defines achievable data rate of the network slice in downlink that is available ubiquitously across the coverage area of the slice, refer NG.116 [50]. he value is automatically calculated leveraging on maxNumberofUEs,  activityFactor and uLThptUE.`,
  },
  // inserted: {
  //   title: 'Inserted',
  //   type: 'text',
  //   md: 4,
  //   tab: 'General',
  //   disabled: true,
  // },
  // modified: {
  //   title: 'Modified',
  //   type: 'text',
  //   md: 4,
  //   tab: 'General',
  //   disabled: true,
  // },
}

const schemaValues = {
  id: '',
  name: '',
  serviceProfileId: '',
  plmnInfoList_Id: '',
  maxNumberofUEs: '',
  coverageArea: '',
  latency: '',
  uEMobilityLevel: '',
  networkSliceSharingIndicator: '',
  sst: '',
  availability: '',
  delayTolerance: '',
  dLDeterministicComm: '',
  uLDeterministicComm: '',
  dLThptPerSlice: '',
  dLThptPerUE: '',
  uLThptPerSlice: '',
  uLThptPerUE: '',
  uLMaxPktSize: '',
  maxNumberofPDUSessions: '',
  kPIMonitoring: '',
  userMgmtOpen: '',
  termDensity: '',
  activityFactor: '',
  uESpeed: '',
  jitter: '',
  survivalTime: '',
  radioSpectrum: '',
  reliability: '',
  maxDLDataVolume: '',
  maxULDataVolume: '',
  nBIoT: '',
  synchronicity: '',
  positioning: '',
  sliceSimultaneousUse: '',
  energyEfficiency: '',
  inserted: '',
  modified: '',
}

const cardBoxStyleMain = {
  cardStyle: {
    width: '65vw',
    height: '75vh',
    backgroundColor: 'background.dark',
  },
  titleStyle: {
    marginTop: '30px',
    backgroundColor: 'background.light',
    paddingLeft: '6%',
    paddingRight: '6%',
    color: 'primary.light',
    width: '102%',
  },
  iconStyle: {
    topBox: {
      // border: '1px solid red',
    },
    bottomBox: {
      // border: '1px solid green',
    },
  },
}

const titleDataMain = {
  variant: 'h4',
}

// const tabs = ['General', 'Deterministic Comm', 'Positioning']
const tabs = ['General', 'Positioning', 'User Related Data']

const cardMain = {
  actions: cardMainActions,
  schema: {
    fields: schemaFields,
    values: schemaValues,
    tabs,
  },
  onDataChange,
  style: cardBoxStyleMain,
  title: titleDataMain,
}

export default cardMain
