import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Grid } from '@mui/material'
import ListBox from '../../components/ListBox'
import JsonSchemaBox from '../../components/JsonSchemaBox'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import EnhancedIconButton from '../../components/EnhancedIconButton'

import useHttpService from '../../customHooks/useHttpService.js'
import { useConfirm } from 'material-ui-confirm'

import { Box } from '@mui/material'
import { styled } from '@mui/system'

const CommonListTitleBox = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '30px',
  paddingLeft: '7%',
  paddingRight: '7%',
  width: '115%',
})

const Service = () => {
  //state to trace data  loading
  const [dataLoaded, setDataLoaded] = useState(false)
  //main state for the whole page data
  const [serviceCategoryState, setServiceCategoryState] = React.useState([])
  const [serviceState, setServiceState] = React.useState([])
  const [applicationState, setApplicationState] = React.useState([])
  const [portState, setPortState] = React.useState([])
  const [variableState, setVariableState] = React.useState([])

  //states for selection
  const [selectedServiceCategory, setSelectedServiceCategory] = React.useState()
  const [selectedService, setSelectedService] = React.useState()
  const [selectedApplication, setSelectedApplication] = React.useState()
  const [selectedPort, setSelectedPort] = React.useState()
  const [selectedVariable, setSelectedVariable] = React.useState()

  //states for json form
  const [tabs, setTabs] = useState([])
  const [formData, setFormData] = React.useState({})
  const [schema, setSchema] = React.useState({})
  const [editedFormData, setEditedFormData] = React.useState({})
  const httpService = useHttpService()
  const [lastSelection, setLastSelection] = useState('')

  const [openPost, setOpenPost] = React.useState(false)
  //prepare the confirm dialog
  const confirm = useConfirm()

  const handleClickOpenPost = (e, selection) => {
    setLastSelection(selection)
    switch (selection) {
      case 'SERVICECATEGORY':
        setServiceCategorySchema()
        setFormData({})
        break
      case 'SERVICE':
        if (selectedServiceCategory) {
          setServiceSchema()
          setFormData({ servicecategory_id: selectedServiceCategory })
        } else {
          alert('no Service Category selected')
          return
        }
        break
      case 'APPLICATION':
        if (selectedService) {
          setApplicationSchema()
          setFormData({ service_id: selectedService })
        } else {
          alert('no Service selected')
          return
        }
        break
      case 'PORT':
        if (selectedApplication) {
          setPortSchema()
          setFormData({ service_application_id: selectedApplication })
        } else {
          alert('no Application selected')
          return
        }
        break

      case 'VARIABLE':
        if (selectedApplication) {
          setVariableSchema()
          setFormData({ service_application_id: selectedApplication })
        } else {
          alert('no Application selected')
          return
        }
        break


      default:
        break
    }
    setOpenPost(true)
  }

  const handleCloseCancel = () => {
    switch (lastSelection) {
      case 'SERVICECATEGORY':
        loadServiceCategoryData()
        break

      case 'SERVICE':
        loadServiceData(selectedServiceCategory)
        break

      case 'APPLICATION':
        loadApplicationData(selectedService)
        break

      case 'PORT':
        loadPortData(selectedApplication)
        break

      case 'VARIABLE':
        loadVariableData(selectedApplication)
        break

      default:
        break
    }
    setOpenPost(false)
  }
  const handleCloseSavePost = () => {
    switch (lastSelection) {
      case 'SERVICECATEGORY':
        httpService.postAPI(
          `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/servicecategory`,
          editedFormData,
          handleCloseCancel
        )
        break

      case 'SERVICE':
        httpService.postAPI(
          `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service`,
          editedFormData,
          handleCloseCancel
        )
        break

      case 'APPLICATION':
        httpService.postAPI(
          `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service_application`,
          editedFormData,
          handleCloseCancel
        )
        break

      case 'PORT':
        httpService.postAPI(
          `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service_application_port`,
          editedFormData,
          handleCloseCancel
        )
        break

      case 'VARIABLE':
        httpService.postAPI(
          `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service_application_variable`,
          editedFormData,
          handleCloseCancel
        )
        break

      default:
        break
    }
  }

  function handleSave() {
    switch (lastSelection) {
      case 'SERVICECATEGORY':
        httpService.putAPI(
          `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/servicecategory`,
          editedFormData,
          handleCloseCancel
        )
        break

      case 'SERVICE':
        httpService.putAPI(
          `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service`,
          editedFormData,
          handleCloseCancel
        )
        break

      case 'APPLICATION':
        httpService.putAPI(
          `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service_application`,
          editedFormData,
          handleCloseCancel
        )
        break

      case 'PORT':
        httpService.putAPI(
          `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service_application_port`,
          editedFormData,
          handleCloseCancel
        )
        break

      case 'VARIABLE':
        httpService.putAPI(
          `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service_application_variable`,
          editedFormData,
          handleCloseCancel
        )
        break

      default:
        break
    }
  }

  function handleDelete() {
    confirm({
      description: `This will permanently delete the selected item`,
    }).then(() => {
      switch (lastSelection) {
        case 'SERVICECATEGORY':
          httpService.deleteAPI(
            `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/servicecategory`,
            { ids: [selectedServiceCategory] },
            handleCloseCancel
          )
          break

        case 'SERVICE':
          httpService.deleteAPI(
            `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service`,
            { ids: [selectedService] },
            handleCloseCancel
          )
          break

        case 'APPLICATION':
          httpService.deleteAPI(
            `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service_application`,
            { ids: [selectedApplication] },
            handleCloseCancel
          )
          break

        case 'PORT':
          httpService.deleteAPI(
            `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service_application_port`,
            { ids: [selectedPort] },
            handleCloseCancel
          )
          break

        case 'VARIABLE':
          httpService.deleteAPI(
            `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service_application_variable`,
            { ids: [selectedVariable] },
            handleCloseCancel
          )
          break

        default:
          break
      }
    })
  }

  function loadServiceCategoryData() {
    httpService.getAPI(
      `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/servicecategory`,
      onServiceCategoryData
    )
  }

  function onServiceCategoryData(jsondata) {
    console.log(jsondata)
    setServiceCategoryState(jsondata)
    setDataLoaded(true)
  }

  function loadServiceData(servicecategory_id) {
    httpService.getAPI(
      `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service?servicecategory_id=${servicecategory_id}`,
      onServiceData
    )
  }

  function onServiceData(jsondata) {
    setServiceState(jsondata)
  }

  function loadApplicationData(service_id) {
    httpService.getAPI(
      `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service_application?service_id=${service_id}`,
      onApplicationData
    )
  }

  function onApplicationData(jsondata) {
    setApplicationState(jsondata)
  }

  function loadPortData(service_application_id) {
    httpService.getAPI(
      `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service_application_port?service_application_id=${service_application_id}`,
      onPortData
    )
  }

  function onPortData(jsondata) {
    setPortState(jsondata)
  }

  function loadVariableData(service_application_id) {
    httpService.getAPI(
      `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service_application_variable?service_application_id=${service_application_id}`,
      onVariableData
    )
  }

  function onVariableData(jsondata) {
    setVariableState(jsondata)
  }

  useEffect(() => {
    loadServiceCategoryData()
  }, [])

  function setServiceCategorySchema() {
    setTabs(['Service Category Properties'])
    setSchema({
      name: {
        title: 'Name',
        type: 'text',
        md: 6,
      },
      slicereferences: {
        title: 'Slice References',
        type: 'text',
      },
      description: {
        title: 'Description',
        type: 'text',
        multiline: true,
        md: 12,
      },
    })
  }

  function onServiceCategoryChange(e) {
    setLastSelection('SERVICECATEGORY')
    setSelectedServiceCategory(e.id)
    loadServiceData(e.id)
    setApplicationState({})
    setPortState({})
    setVariableState({})
    setFormData(e)
    setServiceCategorySchema()
  }

  function setServiceSchema() {
    setTabs(['Service Properties'])
    setSchema({
      name: {
        title: 'Name',
        type: 'text',
        md: 6,
      },
      description: {
        title: 'Description',
        type: 'text',
        multiline: true,
        md: 12,
      },
    })
  }

  function onServiceChange(e) {
    setLastSelection('SERVICE')
    setSelectedService(e.id)
    setPortState({})
    setVariableState({})
    loadApplicationData(e.id)

    setFormData(e)
    setServiceSchema()
  }

  function setApplicationSchema() {
    setTabs(['Application Properties'])
    setSchema({
      application_id: {
        tab: 'Application Properties',
        title: 'Application',
        type: 'combo',
        url: `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/application`,
        valueMember: 'id',
        displayMember: 'name',
        md: 4,
      },
      min_num: {
        tab: 'Application Properties',
        title: 'Min number of instances',
        type: 'number',
        md: 2,
      },
      max_num: {
        tab: 'Application Properties',
        title: 'Max number of instances',
        type: 'number',
        md: 2,
      },
      startup_order: {
        tab: 'Application Properties',
        title: 'Startup Order',
        type: 'number',
        md: 2,
      },
    })
  }

  function onApplicationChange(e) {
    setLastSelection('APPLICATION')
    setSelectedApplication(e.id)

    loadPortData(e.id)
    loadVariableData(e.id)

    setFormData(e)
    setApplicationSchema()
  }

  function setPortSchema() {
    setTabs(['Port Properties'])
    setSchema({
      name: {
        tab: 'Port Properties',
        title: 'Name',
        type: 'text',
        md: 4,
      },
      protocol: {
        tab: 'Port Properties',
        title: 'Protocol',
        type: 'text',
        enum: ['tcp', 'udp'],
        md: 4,
      },
      target_port: {
        tab: 'Port Properties',
        title: 'Target Port',
        type: 'number',
        md: 2,
      },
      service_port: {
        tab: 'Port Properties',
        title: 'Service Port',
        type: 'number',
        md: 2,
      },
    })
  }

  function onPortChange(e) {
    setLastSelection('PORT')
    setSelectedPort(e.id)
    setFormData(e)
    setPortSchema()
  }


  function setVariableSchema() {
    setTabs(['Variable Properties'])
    setSchema({
      var_name: {
        tab: 'Variable Properties',
        title: 'Name',
        type: 'text',
        md: 4,
      },
      var_type: {
        tab: 'Variable Properties',
        title: 'Type',
        type: 'text',
        enum: ['ask', 'kubernetes', 'copy', 'hidden'],
        md: 4,
      },
      var_value: {
        tab: 'Variable Properties',
        title: 'Value',
        type: 'text',
        md: 4,
      },
      copyfrom_id: {
        tab: 'Variable Properties',
        title: 'Copy from Variable',
        type: 'combo',
        url: `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service_application_variable?service_id=${selectedService}`,
        displayMember: 'var_name',
        valueMember: 'id',
        md: 4,
      },
    })
  }

  function onVariableChange(e) {
    setLastSelection('VARIABLE')
    setSelectedVariable(e.id)
    setFormData(e)
    setVariableSchema()
  }

  function onDataChange(data) {
    //formData is OLD, data is NEW
    setEditedFormData(data)
  }

  if (dataLoaded)
    return (
      <div>
        <Grid container spacing={3} padding={3}>
          <Grid item md={2}>
            <Paper elevation={3} sx={{ height: '50vh' }}>
              <Grid container spacing={1}>
                <CommonListTitleBox
                  sx={{
                    backgroundColor: 'background.light',
                    color: 'primary.light',
                  }}
                >
                  <Typography variant='h5' color='secondary'>
                    Service Category
                  </Typography>
                  <EnhancedIconButton
                    icon='add_circle'
                    size='large'
                    onClick={(e) => handleClickOpenPost(e, 'SERVICECATEGORY')}
                    color='primary'
                    tooltip='Add new Service Category'
                  />
                </CommonListTitleBox>

                <ListBox
                  sx={{
                    height: '33vh',
                    overflow: 'auto',
                    paddingLeft: '5%',
                    paddingRight: '5%',
                  }}
                  data={serviceCategoryState}
                  valueMember='id'
                  displayMember='name'
                  onSelectChange={onServiceCategoryChange}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={3}>
            <Paper elevation={3} sx={{ height: '50vh' }}>
              <Grid container spacing={1}>
                <CommonListTitleBox
                  sx={{
                    backgroundColor: 'background.light',
                    color: 'primary.light',
                  }}
                >
                  <Typography variant='h5' color='secondary'>
                    Service
                  </Typography>
                  <EnhancedIconButton
                    icon='add_circle'
                    size='large'
                    onClick={(e) => handleClickOpenPost(e, 'SERVICE')}
                    color='primary'
                    tooltip='Add new Service'
                  />
                </CommonListTitleBox>
                <ListBox
                  sx={{
                    height: '33vh',
                    overflow: 'auto',
                    paddingLeft: '5%',
                    paddingRight: '5%',
                  }}
                  data={serviceState}
                  valueMember='id'
                  displayMember='name'
                  onSelectChange={onServiceChange}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={2}>
            <Paper elevation={3} sx={{ height: '50vh' }}>
              <Grid container spacing={1}>
                <CommonListTitleBox
                  sx={{
                    backgroundColor: 'background.light',
                    color: 'primary.light',
                  }}
                >
                  <Typography variant='h5' color='secondary'>
                    Application
                  </Typography>
                  <EnhancedIconButton
                    icon='add_circle'
                    size='large'
                    onClick={(e) => handleClickOpenPost(e, 'APPLICATION')}
                    color='primary'
                    tooltip='Add new Application'
                  />
                </CommonListTitleBox>
                <ListBox
                  sx={{
                    height: '33vh',
                    overflow: 'auto',
                    paddingLeft: '5%',
                    paddingRight: '5%',
                  }}
                  data={applicationState}
                  valueMember='id'
                  displayMember='application'
                  onSelectChange={onApplicationChange}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={2}>
            <Paper elevation={3} sx={{ height: '50vh' }}>
              <Grid container spacing={1}>
                <CommonListTitleBox
                  sx={{
                    backgroundColor: 'background.light',
                    color: 'primary.light',
                  }}
                >
                  <Typography variant='h5' color='secondary'>
                    Port
                  </Typography>
                  <EnhancedIconButton
                    icon='add_circle'
                    size='large'
                    onClick={(e) => handleClickOpenPost(e, 'PORT')}
                    color='primary'
                    tooltip='Add new Port'
                  />
                </CommonListTitleBox>
                <ListBox
                  sx={{
                    height: '33vh',
                    overflow: 'auto',
                    paddingLeft: '5%',
                    paddingRight: '5%',
                  }}
                  data={portState}
                  valueMember='id'
                  displayMember='name'
                  onSelectChange={onPortChange}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={3}>
            <Paper elevation={3} sx={{ height: '50vh' }}>
              <Grid container spacing={1}>
                <CommonListTitleBox
                  sx={{
                    backgroundColor: 'background.light',
                    color: 'primary.light',
                  }}
                >
                  <Typography variant='h5' color='secondary'>
                    Variable
                  </Typography>
                  <EnhancedIconButton
                    icon='add_circle'
                    size='large'
                    onClick={(e) => handleClickOpenPost(e, 'VARIABLE')}
                    color='primary'
                    tooltip='Add new Variable'
                  />
                </CommonListTitleBox>
                <ListBox
                  sx={{
                    height: '33vh',
                    overflow: 'auto',
                    paddingLeft: '5%',
                    paddingRight: '5%',
                  }}
                  data={variableState}
                  valueMember='id'
                  displayMember='var_name'
                  onSelectChange={onVariableChange}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={12}>
            <Paper elevation={3}>
              <Box sx={{ padding: '1%' }}>
                <JsonSchemaBox
                  schema={schema}
                  formData={formData}
                  onChange={onDataChange}
                  tabs={tabs}
                />
                <Box sx={{ textAlign: 'right' }}>
                  <EnhancedIconButton
                    icon='save'
                    size='large'
                    onClick={handleSave}
                    color='primary'
                    tooltip='Save the selected item'
                  />
                  <EnhancedIconButton
                    icon='delete'
                    size='large'
                    onClick={handleDelete}
                    color='primary'
                    tooltip='Delete the selected item'
                  />
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Dialog
          fullWidth
          maxWidth='lg'
          open={openPost}
          onClose={handleCloseCancel}
        >
          <DialogTitle>Add new</DialogTitle>
          <DialogContent>
            <JsonSchemaBox
              schema={schema}
              formData={formData}
              onChange={onDataChange}
              tabs={tabs}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancel}>Cancel</Button>
            <Button onClick={handleCloseSavePost}>Save</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  else return <div></div>
}
export default Service
