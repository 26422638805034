import React from 'react'
import EnhancedTableBox from '../../components/EnhancedTableBox'
import { Box } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { useConfirm } from 'material-ui-confirm'

import useHttpService from '../../customHooks/useHttpService.js'
import { useMessageContext } from '../../contexts/messageContext'

const ServiceInstance = () => {
  let messageContext = useMessageContext()
  const httpService = useHttpService()
  React.useEffect(() => {
    if (messageContext.taskId === undefined)
      loadData();
  }, [messageContext, messageContext.taskId])

  const [selectedInstance, setSelectedInstance] = React.useState()
  const [openPost, setOpenPost] = React.useState(false)
  //prepare the confirm dialog
  const confirm = useConfirm()

  const [mainData, setMainData] = React.useState([])

  function loadData() {
    httpService.getAPI(
      `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/serviceinstance`,
      onDataLoaded
    )
  }

  function onDataLoaded(data) {
    setMainData(data)
  }

  React.useEffect(loadData, [])
  
  //array of the columns we want to show on the table
  const visibleColumns = [
    {
      id: 'cnt',
      alignRight: false,
      disablePadding: false,
      label: 'Counter',
    },
    {
      id: 'name',
      alignRight: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'creation_date',
      alignRight: false,
      disablePadding: false,
      label: 'Creation date',
    },
    {
      id: 'activation_date',
      alignRight: false,
      disablePadding: false,
      label: 'Activation date',
    },
    {
      id: 'shutdown_date',
      alignRight: false,
      disablePadding: false,
      label: 'Shutdown date',
    },
    {
      id: 'status',
      alignRight: false,
      disablePadding: false,
      label: 'Status',
    },
  ]

  const applicationVisibleColumns = [
    {
      id: 'cnt',
      alignRight: false,
      disablePadding: false,
      label: 'Counter',
    },
    {
      id: 'name',
      alignRight: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'creation_date',
      alignRight: false,
      disablePadding: false,
      label: 'Creation date',
    },
    {
      id: 'datacenter',
      alignRight: false,
      disablePadding: false,
      label: 'Datacenter',
    },
    {
      id: 'uuid_server',
      alignRight: false,
      disablePadding: false,
      label: 'Host',
    },
  ]

  //array of actions placed at the end of each row
  const actions = [
    {
      action: 'list',
      icon: 'list',
      tooltip: 'current instance application list',
    },

    {
      action: 'stop',
      icon: 'dangerous',
      tooltip: 'stop current instance',
    },
    {
      action: 'delete',
      icon: 'delete',
      tooltip: 'delete current instance',
    },
  ]

  function onInstanceStopped(data) {
    messageContext.setTaskId(data['task-id'])
  }

  function onAction(action, e) {
    switch (action) {
      case 'list':
        setSelectedInstance(e.id)
        setOpenPost(true)
        break
      case 'stop':
        confirm({
          description: `This will permanently delete the selected service instance`,
        }).then(() => {
          const message = {
            Name: e.name,
            Command: 'DeleteInstance',
            Platform: 'kubernetes',
            IdInstance: e.id,
          }
          httpService.postAPI(
            `${process.env.REACT_APP_QUEUE_ADDRESS}/celery/push/TFO/tfoStart`,
            message,
            onInstanceStopped
          )
        })
        break
      case 'delete':
        confirm({
          description: `This will permanently delete the selected service instance`,
        }).then(() => {
          httpService.deleteAPI(
            `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/serviceinstance`,
            { ids: [e.id] },
            () => {
              loadData()
            }
          )
        })
        break
      default:
        break
    }
  }

  return (
    <Box sx={{ padding: '2%' }}>
      <EnhancedTableBox
        heightPercentage='0.7'
        title='Service Instances'
        apiURL={`${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/serviceinstance`}
        data={mainData}
        valueMember='id'
        visibleColumns={visibleColumns}
        actions={actions}
        onAction={onAction}
        search
      />

      <Dialog
        fullWidth
        maxWidth='lg'
        open={openPost}
        onClose={() => {
          setOpenPost(false)
        }}
      >
        <DialogTitle>Application Instances</DialogTitle>
        <DialogContent>
          <EnhancedTableBox
            heightPercentage='0.5'
            title='Application'
            apiURL={`${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/applicationinstance?serviceinstance_id=${selectedInstance}`}
            valueMember='id'
            visibleColumns={applicationVisibleColumns}
            search
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenPost(false)
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
export default ServiceInstance
