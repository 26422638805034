import { v4 as uuidv4 } from 'uuid'
import { cardMain as cardMainRAN } from '../template/templateRAN-Main'

const { name, ...ranSchemaFields } = cardMainRAN.schema.fields

const onDataChange = () => {}
const doNothing = () => {}
const saveData = () => {}

const cardMainActions = [
  {
    icon: 'celebration',
    place: 'top',
    label: 'ONLINE',
    type: 'icon',
    onClick: doNothing,
    uniqueKey: uuidv4(),
    tooltip: `General State calculated on Administrative and Operational state values`,
  },
  {
    icon: 'info',
    place: 'top',
    label: 'Alarms',
    type: 'link',
    url: './alarms',
    onClick: doNothing,
    uniqueKey: uuidv4(),
    tooltip: 'Active alarms',
  },
  {
    icon: 'api',
    place: 'top',
    label: 'Kpi',
    type: 'link',
    url: './Performance',
    onClick: doNothing,
    uniqueKey: uuidv4(),
    tooltip: `KPIs related this item`,
  },
  {
    icon: 'book', //the icon you want to show
    place: 'top', // the position in the card: one of top/bottom
    label: 'Policy (parameters)', //a label on the left of the icon
    type: 'dialog', // if 'dialog', on click it will open a dialog
    onClick: doNothing, //to override, if you want to use the onClick function
    uniqueKey: uuidv4(), //leave this in this way
    allowSave: false,
    tooltip: `Parameters setting by Customer for this item`,

    dialog: {
      url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSliceSubnet?id=`,
      //the dialog itself
      title: 'Set Policy Parameters', //title of the dialog
      style: {
        //style of the dialog: you can style the card, the title, the content and the actions
        card: {},
        title: {
          marginTop: '30px',
          backgroundColor: 'background.light',
          paddingLeft: '6%',
          paddingRight: '6%',
          color: 'primary.light',
          width: '100%',
        },
        content: {},
        actions: {},
      },
      schemaFields: ranSchemaFields, //you can pass a jsonSchema if you want to render a form
      tabs: ['General', 'Positioning', 'User Related Data'], //tabs to divide the jsonSchema into many pieces
      onDataChange: doNothing, //to be removed
      buttons: [{ type: 'close' }], //list of buttons at the end of the dialog
      dataAlreadyPresent: true, //if we have already the formdata to pass to the jsonSchemaBox, we set this to true
    },
  },
  {
    icon: 'book',
    place: 'top',
    label: 'Old Configurations',
    type: 'link', //if the type is 'link', onClick it will bring you to the page specified on the 'url' parameter
    uniqueKey: uuidv4(),
    tooltip: 'Check the previouses configurations for this NSSI', //a tooltip to show on hover of the icon
    url: `./previousNSSI`,
  },
  {
    icon: 'calendar_month',
    place: 'top',
    label: 'Schedulations',
    type: 'dialog',
    uniqueKey: uuidv4(),
    tooltip: 'Show all the schedulations for this NSSI',
    dialog: {
      style: {
        card: {},
        title: {},
        content: {},
        actions: {},
      },
      content: {
        //if you don't have a jsonSchema, you can pass the content
        type: 'table', //this will show an EnhancedTableBox
        url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_scheduler/Schedule?objClass=NSSI&objId=`,
        title: 'Schedulation',
        valueMember: 'id',
        visibleColumns: [
          {
            id: 'name',
            alignRight: false,
            disablePadding: false,
            label: 'Name',
          },
          {
            id: 'scheduled',
            alignRight: false,
            disablePadding: false,
            label: 'Scheduled',
            type: 'datetime',
          },
          {
            id: 'executed',
            alignRight: false,
            disablePadding: false,
            label: 'Executed',
            type: 'datetime',
          },
        ],
      },
      tabs: [],
      onDataChange: doNothing,
      buttons: [{ type: 'close' }],
    },
  },
  {
    icon: 'delete',
    iconSize: 'large',
    place: 'bottom',
    onClick: doNothing,
    type: 'icon', //type 'icon' it's just an icon button
    tooltip: 'Delete',
    uniqueKey: uuidv4(),
    style: {},
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSliceSubnet`, //an api will be called onClick
    messages: {
      //this is used to send messages to the rabbit queue, after the onClick of the icon
      messageList: [
        //the list of messages
        // {
        //   uniqueKey: uuidv4(),
        //   name: '',
        //   scheduled: '',
        //   httpMethod: 'POST',
        //   url: `${process.env.REACT_APP_QUEUE_ADDRESS}/celery/push/OPERMOI/modifyMOIattributes`, //specify here the url of the api call
        //   body: {
        //     Name: 'modifyMOIattributes',
        //     moi_class: 'NSSI',
        //     moi_id: null,
        //     Command: 'modifyMOIattributes',
        //   },
        // },
        {
          uniqueKey: uuidv4(),
          name: '',
          scheduled: '',
          httpMethod: 'POST',
          url: `${process.env.REACT_APP_QUEUE_ADDRESS}/celery/push/NOTIFMOI/notifyMOIdeletion`,
          body: {
            Name: 'notifyMOIdeletion',
            moi_class: 'NSSI',
            moi_id: null,
            Command: 'notifyMOIdeletion',
          },
        },
      ],
    },
  },
  {
    icon: 'save',
    iconSize: 'large',
    place: 'bottom',
    type: 'dialog',
    onClick: doNothing,
    style: {},
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSliceSubnet`,
    dialog: {
      title: 'Schedule and Save',
      style: {
        card: {},
        title: {
          marginTop: '30px',
          backgroundColor: 'background.light',
          paddingLeft: '6%',
          paddingRight: '6%',
          color: 'primary.light',
          width: '100%',
        },
        content: {},
        actions: {},
      },
      content: {},
      schemaFields: {
        scheduled: {
          title: 'Schedule',
          type: 'date',
          md: 4,
        },
      },

      tabs: [],
      onDataChange: doNothing,
      buttons: [{ type: 'cancel' }, { type: 'save' }],
    },
    // messages: {
    //   url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_scheduler/Schedule`,
    //   messageList: [
    //     {
    //       uniqueKey: uuidv4(),
    //       name: '',
    //       scheduled: '',
    //       httpMethod: 'POST',
    //       url: `http://localhost/queue/celery/push/OPERMOI/modifyMOIattributes`,
    //       body: {
    //         Name: 'modifyMOIattributes',
    //         moi_class: 'NSSI',
    //         moi_id: null,
    //         Command: 'modifyMOIattributes',
    //       },
    //     },
    //     {
    //       uniqueKey: uuidv4(),
    //       name: '',
    //       scheduled: '',
    //       httpMethod: 'POST',
    //       url: `http://localhost/queue/celery/push/NOTIFMOI/notifyMOIattributeValueChanges`,
    //       body: {
    //         Name: 'notifyMOIattributeValueChanges',
    //         moi_class: 'NSSI',
    //         moi_id: null,
    //         Command: 'notifyMOIattributeValueChanges',
    //       },
    //     },
    //   ],
    // },
    tooltip: 'Save Changes',
  },
]

const schemaFields = {
  administrativeState: {
    title: 'Administrative State',
    type: 'combo',
    md: 3,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/administrativeState`,
    valueMember: 'val',
    displayMember: 'val',
    onChange: doNothing,
  },
  operationalState: {
    title: 'Operational State',
    type: 'text',
    md: 3,
    disabled: true,
  },
  maintenance: {
    title: 'Maintenance',
    type: 'checkbox',
    md: 3,
  },
  name: {
    title: 'NSSI Name',
    type: 'string',
    md: 12,
    mandatory: true,
    showAsterisk: false,
  },
  RANSliceSubnetProfile: {
    title: 'NSST',
    type: 'text',
    md: 6,
    disabled: true,
    // hyperlink: '/alarms/:id', //Add this property to let the text type become an hyperlink to the specified value (the id will be automagically replaced)
  },
  PlmnId_id: {
    title: 'PLMN',
    type: 'combo',
    md: 6,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/PlmnId`,
    valueMember: 'id',
    displayMember: 'name',
    disabled: true,
  },
  NetworkSlices: {
    title: 'NSI',
    type: 'list',
    md: 6,
    dense: true,
    baseUrl: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSliceSubnet_SliceNames`, //we use this baseUrl when we have to pass query params dinamically
    valueMember: 'id',
    displayMember: 'name',
    disabled: true,
  },
  NF: {
    title: 'NF',
    type: 'list',
    md: 6,
    dense: true,
    baseUrl: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkFunction`,
    valueMember: 'id',
    displayMember: 'name',
    disabled: true,
  },
  id: {
    title: 'NSSID',
    type: 'string',
    md: 12,
    disabled: true,
  },
}

const schemaValues = {
  administrativeState: 'Unlocked',
  operationalState: 'Enabled',
  name: 'MIoT Milano',
  id: 'NSSIDx',
  NSST: 'NSSTIDx',
  PLMN: 'PLMN2',
  NSI: 'NSIDc',
  NF: 'NFIDa',
}

const cardBoxStyleMain = {
  cardStyle: {
    width: '65vw',
    height: '75vh',
    backgroundColor: 'background.dark',
  },
  titleStyle: {
    marginTop: '30px',
    backgroundColor: 'background.light',
    paddingLeft: '6%',
    paddingRight: '6%',
    color: 'primary.light',
    width: '102%',
  },
  iconStyle: {
    topBox: {
      // border: '1px solid red',
    },
    bottomBox: {
      // border: '1px solid green',
    },
  },
}

const titleDataMain = {
  variant: 'h4',
}

const cardMain = {
  actions: cardMainActions,
  schema: {
    fields: schemaFields,
    values: schemaValues,
  },
  onDataChange,
  style: cardBoxStyleMain,
  title: titleDataMain,
}

export default cardMain
