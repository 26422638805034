import React from 'react'
import EnhancedTableBox from '../../components/EnhancedTableBox'
import { Box } from '@mui/material'

const Image_repository = () => {
  //array of the columns we want to show on the table
  const visibleColumns = [
    {
      id: 'name',
      alignRight: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'gitlab_server',
      alignRight: false,
      disablePadding: false,
      label: 'GitLab Server',
    },
    {
      id: 'gitlab_username',
      alignRight: false,
      disablePadding: false,
      label: 'GitLab Username',
    },
  ]

  //array of actions placed at the end of each row
  const actions = [
    {
      action: 'edit',
      icon: 'edit',
      tooltip: 'edit current row',
    },

    {
      action: 'delete',
      icon: 'delete',
      tooltip: 'delete current row',
    },
  ]

  const editSchema = {
    name: {
      title: 'Name',
      type: 'text',
      md: 6,
    },
    gitlab_server: {
      title: 'GitLab Server',
      type: 'text',
      md: 6,
    },
    gitlab_username: {
      title: 'GitLab Username',
      type: 'text',
      md: 6,
    },
    gitlab_password: {
      title: 'GitLab Password',
      type: 'password',
      md: 6,
    },
  }

  return (
    <Box sx={{ padding: '2%' }}>
      <EnhancedTableBox
        heightPercentage='0.7'
        title='Image repository'
        apiURL={`${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/image_repository`}
        valueMember='id'
        visibleColumns={visibleColumns}
        actions={actions}
        //onAction={onAction}
        addButton
        //addJsonSchema={{}}
        editJsonSchema={editSchema}
        search
      />
    </Box>
  )
}
export default Image_repository
