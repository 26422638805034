import { useMessageContext } from '../contexts/messageContext'
import { useConfirm } from 'material-ui-confirm'
import useHttpService from '../customHooks/useHttpService'

function useSaveDeleteCardBoxContent() {
  const messageContext = useMessageContext()
  const httpService = useHttpService()
  const confirm = useConfirm()

  const getApiDialog = (url, data, callback) => {
    console.log(url, data, callback)

    httpService.getAPI(url, data, () => callback())
  }

  const putCardBoxMainJsonData = (data, putItem, context) => {
    console.log(putItem, context)

    const splittedUrl = window.location.pathname.split('/')
    const itemType = splittedUrl[splittedUrl.length - 1].split('List')[0]

    if (putItem.confirm) {
      confirm({
        title: 'Confirm restore',
        description: `Submitting this action the current NSSI configuration will be changed, please confirm}?`,
        confirmationText: 'RESTORE',
        cancellationText: 'CANCEL',
      }).then(() => {
        callPutApi(data, putItem, context)
      })
    } else {
      callPutApi(data, putItem, context)
    }
  }

  const callPutApi = (data, putItem, context) => {
    httpService.putAPI(putItem.url, data, () => {
      if (putItem.hasOwnProperty('messages')) {
        putItem.messages.messageList.forEach((el) => {
          callPostApiMessage(data, putItem, data.id, el.body.Command)
        })
      }
      context.emptyListData()
      context.emptyMainCard()
    })
  }

  const postCardBoxMainJsonData = (data, postItem, context) => {
    const url = postItem.url
    postItem = postItem.hasOwnProperty('dialog') ? postItem.dialog : postItem
    httpService.postAPI(url, data, (res) => {
      if (postItem.hasOwnProperty('messages')) {
        postItem.messages.messageList.forEach((el) => {
          callPostApiMessage(data, postItem, res.id, el.body.Command)
        })
      }
      context.emptyListData()
      context.emptyMainCard()
    })
  }

  const callPostApiMessage = (data, postItem, id, messageName) => {
    console.log(data, postItem, id, messageName)
    // const name = data?.name || ''
    const ne_id = data?.NetworkElement_id || data?.id || id || ''

    let message = postItem.messages.messageList.find(
      (el) => el.body.Name === messageName
    )

    if (message.hasOwnProperty('uniqueKey')) delete message.uniqueKey

    message.body.moi_id = id
    message.body.ne_id = ne_id
    // message.name = name

    // if (data.scheduled != null && data.scheduled.length > 0)
    //   message.scheduled = data.scheduled
    console.log(message)
    httpService.postAPI(message.url, message.body, (res) => {
      console.log(res)
      messageContext.setTaskId(res['task-id'])
    })
  }

  const deleteCardBoxMainJsonData = (item, data, context) => {
    console.log(item, data, context)
    const id = data.id
    const progid = data.progid || null
    deleteItem(item, id, context, progid)
  }

  const deleteCardBoxListData = (item, index, context) => {
    const id = context.listData[index].infos.id
    deleteItem(item, id, context)
  }

  const deleteItem = (item, id, context, progid = null) => {
    const data = context?.choosenItem
    let body = null

    if (progid != null) {
      body = { ids: [{ id, progid }] }
    } else {
      body = { ids: [id] }
    }
    const splittedUrl = window.location.pathname.split('/')
    const itemType = splittedUrl[splittedUrl.length - 2].split('List')[0]

    confirm({
      title: 'Confirm deletion',
      description: `Do you really want to delete this ${itemType.toUpperCase()}?`,
      confirmationText: 'DELETE',
      cancellationText: 'EXIT',
    }).then(() => {
      // FARE LA DELETE DEI MESSAGGI CHE SERVONO!!!!!!!!!!!!!!
      httpService.deleteAPI(item.url, body, (res) => {
        if (item.hasOwnProperty('messages')) {
          item.messages.messageList.forEach((el) => {
            callPostApiMessage(data, item, id, el.body.Command)
          })
        }
        context.emptyListData()
        context.emptyMainCard()
      })
    })
  }

  const importExcel = (e, item, context) => {
    const file = e.target.files[0]
    let formData = new FormData()
    formData.append('formFile', file)
    httpService.postAPI(
      item.url,
      formData,
      (res) => {
        context.emptyListData()
        context.emptyMainCard()
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  }

  const exportExcel = (item, exportAll, context) => {
    if (item.type === 'template') {
      exportTemplate(item.url)
    }
    if (item.type === 'export') {
      const id = exportAll ? null : context?.choosenItem?.id
      exportData(item.url, id)
    }
  }

  const exportTemplate = (url) => {
    httpService.downloadAPI(url, (res) => {
      const blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const aEle = document.createElement('a') // Create a label
      const href = window.URL.createObjectURL(blob) // Create downloaded link
      aEle.href = href
      const objectType = (
        url.split('nssmf_managed_object/')[1] ||
        url.split('nssmf_oss_object/')[1]
      ).split('/')[0]

      aEle.download = `template_${objectType}.xlsx` // File name after download
      document.body.appendChild(aEle)
      aEle.click() // Click to download
      document.body.removeChild(aEle) // Download complete remove element
      window.URL.revokeObjectURL(href) // Release blob object
    })
  }

  const exportData = (url, id) => {
    url = id ? `${url}?id=${id}` : url
    httpService.downloadAPI(url, (res) => {
      const blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const aEle = document.createElement('a') // Create a label
      const href = window.URL.createObjectURL(blob) // Create downloaded link
      aEle.href = href

      const objectType = (
        url.split('nssmf_managed_object/')[1] ||
        url.split('nssmf_oss_object/')[1]
      ).split('/')[0]

      aEle.download = `${objectType}_${
        id || new Date().toLocaleDateString('sv')
      }.xlsx` // File name after download
      document.body.appendChild(aEle)
      aEle.click() // Click to download
      document.body.removeChild(aEle) // Download complete remove element
      window.URL.revokeObjectURL(href) // Release blob object
    })
  }

  return {
    getApiDialog,
    putCardBoxMainJsonData,
    postCardBoxMainJsonData,
    deleteCardBoxMainJsonData,
    deleteCardBoxListData,
    importExcel,
    exportExcel,
    callPostApiMessage,
  }
}

export default useSaveDeleteCardBoxContent
