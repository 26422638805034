import React from 'react'
import EnhancedTableBox from '../../components/EnhancedTableBox'
import { Box } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { useConfirm } from 'material-ui-confirm'

import useHttpService from '../../customHooks/useHttpService.js'
import { useMessageContext } from '../../contexts/messageContext'

const Subscription = () => {
  let messageContext = useMessageContext()
  const httpService = useHttpService()
  React.useEffect(() => {
    if (messageContext.taskId === undefined) loadData()
  }, [messageContext, messageContext.taskId])

  const [selectedInstance, setSelectedInstance] = React.useState()
  const [openPost, setOpenPost] = React.useState(false)
  //prepare the confirm dialog
  const confirm = useConfirm()

  const [mainData, setMainData] = React.useState([])

  function loadData() {
    httpService.getAPI(
      `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_sym/Subscription`,
      onDataLoaded
    )
  }

  function onDataLoaded(data) {
    setMainData(data)
  }

  React.useEffect(loadData, [])

  //array of the columns we want to show on the table
  const visibleColumns = [
    {
      id: 'name',
      alignRight: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'inserted',
      alignRight: false,
      disablePadding: false,
      label: 'Inserted',
    },
    {
      id: 'modified',
      alignRight: false,
      disablePadding: false,
      label: 'Modified',
    },
    {
      id: 'active',
      alignRight: false,
      disablePadding: false,
      label: 'Active',
    },
    {
      id: 'TimeTick',
      alignRight: false,
      disablePadding: false,
      label: 'Time Tick',
    },
    {
      id: 'TimeTickTimer',
      alignRight: false,
      disablePadding: false,
      label: 'Time Tick Timer',
    },
    {
      id: 'filter',
      alignRight: false,
      disablePadding: false,
      label: 'Filter',
    },
    {
      id: 'ConsumerReference',
      alignRight: false,
      disablePadding: false,
      label: 'ConsumerReference',
    },
  ]

  // const applicationVisibleColumns = [
  // 	{
  // 		id: 'id',
  // 		alignRight: false,
  // 		disablePadding: false,
  // 		label: 'ID',
  // 	},
  // 	{
  // 		id: 'additionalText',
  // 		alignRight: false,
  // 		disablePadding: false,
  // 		label: 'additional Text',
  // 	},
  // 	{
  // 		id: 'sourceIndicator',
  // 		alignRight: false,
  // 		disablePadding: false,
  // 		label: 'Source Indicator',
  // 	},
  // ];

  //array of actions placed at the end of each row
  // const actions = [
  // 	{
  // 		action: 'list',
  // 		icon: 'list',
  // 		tooltip: '',
  // 	},

  // 	// {
  // 	// 	action: 'stop',
  // 	// 	icon: 'dangerous',
  // 	// 	tooltip: 'stop current instance',
  // 	// },
  // 	// {
  // 	// 	action: 'delete',
  // 	// 	icon: 'delete',
  // 	// 	tooltip: 'delete current instance',
  // 	// },
  // ];

  function onInstanceStopped(data) {
    messageContext.setTaskId(data['task-id'])
  }

  function onAction(action, e) {
    switch (action) {
      case 'list':
        setSelectedInstance(e.id)
        setOpenPost(true)
        break
      default:
        break
    }
  }

  return (
    <Box sx={{ padding: '2%' }}>
      <EnhancedTableBox
        heightPercentage='0.7'
        title='Subscription'
        apiURL={`${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_sym/Subscription`}
        data={mainData}
        valueMember='id'
        visibleColumns={visibleColumns}
        // actions={actions}
        onAction={onAction}
        search
      />

      {/* <Dialog
				fullWidth
				maxWidth='lg'
				open={openPost}
				onClose={() => {
					setOpenPost(false);
				}}>
				<DialogTitle>Subscription</DialogTitle>
				<DialogContent>
					<EnhancedTableBox
						heightPercentage='0.5'
						title=''
						apiURL={``}
						valueMember='id'
						visibleColumns={applicationVisibleColumns}
						search
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setOpenPost(false);
						}}>
						Close
					</Button>
				</DialogActions>
			</Dialog> */}
    </Box>
  )
}
export default Subscription
