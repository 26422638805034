import React from 'react'

// import "react-table/react-table.css";
import { Button, Grid } from '@mui/material'
import TextFieldCombo from '../../components/TextFieldCombo'
import { TextField } from '@mui/material'

import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'

import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import LinearProgress from '@mui/material/LinearProgress'
import produce from 'immer'
import { set, has } from 'lodash'
import { useState } from 'react'
import { useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import JsonSchemaBox from '../../components/JsonSchemaBox'

import useHttpService from '../../customHooks/useHttpService.js'

//funtion to manage json states
function enhancedReducer(state, updateArg) {
  // check if the type of update argument is a callback function
  if (updateArg.constructor === Function) {
    return { ...state, ...updateArg(state) }
  }

  // if the type of update argument is an object
  if (updateArg.constructor === Object) {
    // does the update object have _path and _value as it's keys
    // if yes then use them to update deep object values
    if (has(updateArg, '_path') && has(updateArg, '_value')) {
      const { _path, _value } = updateArg

      return produce(state, (draft) => {
        set(draft, _path, _value)
      })
    } else {
      return { ...state, ...updateArg }
    }
  }
}

let table_style = {
  borderLeft: 1,
  borderColor: '#A2A6A6',
}

let siteState = {}

let antennasPorts = []

const RNP = () => {
  const { uuid } = useParams()
  const history = useHistory()

  const upperFormSchema = {
    // system_type: {
    //     tab: 'Simulation Parameters',
    //     title: 'System Type',
    //     type: 'combo',
    //     url: `https://networkteam.it.nttdata-emea.com/api/scalar/rnp/templates`,
    //     valueMember: 'technology',
    //     displayMember: 'technology',
    //     md: 3,
    // },
    modello_propagazione: {
      tab: 'Simulation Parameters',
      title: 'Propagation Model',
      type: 'combo',
      url: `https://networkteam.it.nttdata-emea.com/api/scalar/rnp/propagation_models`,
      valueMember: 'model',
      displayMember: 'model',
      md: 2,
    },
    raggio: {
      tab: 'Simulation Parameters',
      title: 'Calc Radius',
      type: 'number',
      md: 2,
    },
    risoluzione: {
      tab: 'Simulation Parameters',
      title: 'Calc Resolution',
      type: 'number',
      md: 2,
    },
    flag_shadow: {
      tab: 'Simulation Parameters',
      title: 'Shadowing',
      type: 'string',
      enum: ['YES', 'NO'],
      md: 1,
    },
    coverage_prob_percentage: {
      tab: 'Simulation Parameters',
      title: 'Cell Edge Coverage Probability (%)',
      type: 'number',
      md: 3,
    },
    flag_indoor: {
      tab: 'Simulation Parameters',
      title: 'Indoor Coverage',
      type: 'string',
      enum: ['YES', 'NO'],
      md: 2,
    },
    metri_raggio_zona_di_calcolo: {
      tab: 'Simulation Parameters',
      title: 'Computation Zone',
      type: 'number',
      md: 3,
    },

    soglia_di_copertura: {
      tab: 'Simulation Parameters',
      title: 'Coverage Threshold (dBm)',
      type: 'number',
      md: 2,
    },
    soglia_di_sinr: {
      tab: 'Simulation Parameters',
      title: 'SINR Threshold (dB)',
      type: 'number',
      md: 2,
    },
    coverage_weight: {
      tab: 'Simulation Parameters',
      title: 'Coverage Weight (%)',
      type: 'number',
      md: 2,
    },
    sinr_weight: {
      tab: 'Simulation Parameters',
      title: 'SINR Weight (%)',
      type: 'number',
      md: 2,
    },
    target: {
      tab: 'Simulation Parameters',
      title: 'Target',
      type: 'string',
      enum: ['POPOLAZIONE', 'SUPERFICIE'],
      md: 3,
    },
    abc_sn: {
      tab: 'Simulation Parameters',
      title: 'ABC Generations',
      type: 'number',
      md: 2,
    },
    abc_cycles: {
      tab: 'Simulation Parameters',
      title: 'ABC Cycles',
      type: 'number',
      md: 2,
    },
  }

  //method to update json states with own setter
  const updateForm = React.useCallback(
    ({ target: { value, name, type } }, stateSetter) => {
      const updatePath = name.split('.')

      switch (type) {
        case 'number':
          if (value.includes('.')) value = parseFloat(value)
          else value = parseInt(value)
          break
        default:
          break
      }

      // if the input is a checkbox then use callback function to update
      // the toggle state based on previous state
      if (type === 'checkbox') {
        stateSetter((prevState) => ({
          [name]: !prevState[name],
        }))

        return
      }

      // if we have to update the root level nodes in the form
      if (updatePath.length === 1) {
        const [key] = updatePath

        stateSetter({
          [key]: value,
        })
      }

      // if we have to update nested nodes in the form object
      // use _path and _value to update them.
      if (updatePath.length > 1) {
        stateSetter({
          _path: updatePath,
          _value: value,
        })
      }
    },
    []
  )

  function lockClicked(e) {
    switch (e.currentTarget.name) {
      case 'lockAzimuth':
        Object.entries(vincoliState).forEach(([key, value]) => {
          if (key.includes(formState.system_type) && key.includes('azimuth'))
            vincoliState[key] = 0
        })
        break
      case 'lockEtilt':
        formState.sectors.forEach((sector) =>
          sector.antennas.forEach((antenna) =>
            antenna.technologies.forEach((technology) => {
              if (technology.band === formState.system_type) {
                vincoliState[
                  sector.azimuth +
                    '_' +
                    antenna.model +
                    '_' +
                    technology.band +
                    '_etiltmin'
                ] = technology.downtiltElettrico
                vincoliState[
                  sector.azimuth +
                    '_' +
                    antenna.model +
                    '_' +
                    technology.band +
                    '_etiltmax'
                ] = technology.downtiltElettrico
              }
            })
          )
        )
        break
      case 'lockTilt':
        formState.sectors.forEach((sector) =>
          sector.antennas.forEach((antenna) =>
            antenna.technologies.forEach((technology) => {
              if (technology.band === formState.system_type) {
                vincoliState[
                  sector.azimuth +
                    '_' +
                    antenna.model +
                    '_' +
                    technology.band +
                    '_mtiltmin'
                ] = antenna.arpaParameters.tiltMeccanicoMax
                vincoliState[
                  sector.azimuth +
                    '_' +
                    antenna.model +
                    '_' +
                    technology.band +
                    '_mtiltmax'
                ] = antenna.arpaParameters.tiltMeccanicoMax
              }
            })
          )
        )
        break
      case 'lockHBA':
        Object.entries(vincoliState).forEach(([key, value]) => {
          if (key.includes(formState.system_type) && key.includes('hba'))
            vincoliState[key] = 0
        })
        break
      case 'lockPower':
        Object.entries(vincoliState).forEach(([key, value]) => {
          if (key.includes(formState.system_type) && key.includes('power'))
            vincoliState[key] = 0
        })
        break

      default:
        break
    }
    setVincoliState(vincoliState)
  }
  const httpService = useHttpService()
  //state to trace data  loading
  const [dataLoaded, setDataLoaded] = useState(false)
  //main state for the whole page data
  const [formState, setFormState] = React.useReducer(enhancedReducer, {})
  const [vincoliState, setVincoliState] = React.useReducer(enhancedReducer, {})
  //const [siteState, setSiteState] = useState({});

  let formStateChanged = {}

  //state for TARGET radio button
  const [targetPopolazione, setTargetPopolazione] = useState()
  const [targetSuperficie, setTargetSuperficie] = useState()

  function fillVincoliState(data) {
    data.sectors.forEach((sector) =>
      sector.antennas.forEach((antenna) => {
        antenna.technologies.forEach((technology) => {
          if (data.system_type) {
            let neType = ''
            if (data.system_type.startsWith('GSM')) neType = 'GSM'
            else if (data.system_type.startsWith('UMTS')) neType = 'UMTS'
            else if (data.system_type.startsWith('LTE')) neType = 'LTE'

            let band = parseInt(data.system_type.replace(neType, ''))
            let eTiltRange = getEtiltRange(antenna.model, band)

            vincoliState[
              sector.azimuth +
                '_' +
                antenna.model +
                '_' +
                technology.band +
                '_azimuth_variation'
            ] = 20
            vincoliState[
              sector.azimuth +
                '_' +
                antenna.model +
                '_' +
                technology.band +
                '_azimuth_step'
            ] = 5

            vincoliState[
              sector.azimuth +
                '_' +
                antenna.model +
                '_' +
                technology.band +
                '_etiltmin'
            ] = eTiltRange[0]
            vincoliState[
              sector.azimuth +
                '_' +
                antenna.model +
                '_' +
                technology.band +
                '_etiltmax'
            ] = eTiltRange[1]

            vincoliState[
              sector.azimuth +
                '_' +
                antenna.model +
                '_' +
                technology.band +
                '_mtiltmin'
            ] = antenna.arpaParameters.tiltMeccanicoMax - 4
            vincoliState[
              sector.azimuth +
                '_' +
                antenna.model +
                '_' +
                technology.band +
                '_mtiltmax'
            ] = antenna.arpaParameters.tiltMeccanicoMax + 6

            vincoliState[
              sector.azimuth +
                '_' +
                antenna.model +
                '_' +
                technology.band +
                '_hba_variation'
            ] = 1
            vincoliState[
              sector.azimuth +
                '_' +
                antenna.model +
                '_' +
                technology.band +
                '_hba_step'
            ] = 0.5

            vincoliState[
              sector.azimuth +
                '_' +
                antenna.model +
                '_' +
                technology.band +
                '_power_variation'
            ] = 3
            vincoliState[
              sector.azimuth +
                '_' +
                antenna.model +
                '_' +
                technology.band +
                '_power_step'
            ] = 1

            setVincoliState(vincoliState)
          }
        })
      })
    )
  }

  function queryAPISiteInfo(myURL) {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    fetch(myURL, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status)
        } else return response.json()
      })
      .then((jsondata) => {
        if (jsondata.data) {
          //setting main state
          siteState = jsondata.data[0]
        }
      })
      .catch((error) => {
        console.error(error)
        //localStorage.removeItem('token');
        //window.location.reload();
      })
  }

  function getEtiltRange(model, frequency) {
    let etiltrange = []
    antennasPorts.forEach((antenna) => {
      antenna.ports.forEach((port) => {
        if (
          antenna.model_code === model &&
          frequency >= port.frequency_from &&
          frequency <= port.frequency_to
        )
          etiltrange = [port.tilt_from, port.tilt_to]
      })
    })
    return etiltrange
  }

  function queryAPIAntennaPorts(myURL, formData) {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    fetch(myURL, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status)
        } else return response.json()
      })
      .then((jsondata) => {
        if (jsondata.data) {
          //setting main state
          antennasPorts = jsondata.data
          fillVincoliState(formData)
        }
      })
      .catch((error) => {
        console.error(error)
        //localStorage.removeItem('token');
        //window.location.reload();
      })
  }

  function postAPI(myURL, data) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify(data),
    }
    fetch(myURL, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status)
        } else return response.json()
      })
      .then((jsondata) => {})
      .catch((error) => {
        console.error(error)
        //localStorage.removeItem('token');
        //window.location.reload();
      })
  }

  function startSimulation() {
    let neType = ''
    if (formStateChanged.system_type.startsWith('GSM')) neType = 'GSM'
    else if (formStateChanged.system_type.startsWith('UMTS')) neType = 'UMTS'
    else if (formStateChanged.system_type.startsWith('LTE')) neType = 'LTE'

    let band = parseInt(formStateChanged.system_type.replace(neType, ''))

    let fband = ''
    if (band <= 1000) fband = '800 FDD - 10 MHz Vodafone'
    if (band > 1000 && band <= 2000) fband = '1800 FDD - 20 MHz Vodafone'
    if (band > 2000 && band <= 2500) fband = '2100 FDD - 15 MHz Vodafone'
    if (band > 2500) fband = '2600 FDD - 15 MHz Vodafone'

    let transmitters = []
    formStateChanged.sectors.forEach((sector) =>
      sector.antennas.forEach((antenna) => {
        antenna.technologies.forEach((technology) => {
          if (technology.band === formStateChanged.system_type) {
            let transmitter = {
              eval_partition_uuid: '',
              sector: sector.sector,
              band: band,
              height: parseFloat(antenna.hbaSuolo),
              heightMin:
                parseFloat(antenna.hbaSuolo) -
                vincoliState[
                  sector.azimuth +
                    '_' +
                    antenna.model +
                    '_' +
                    technology.band +
                    '_hba_variation'
                ],
              heightMax:
                parseFloat(antenna.hbaSuolo) +
                vincoliState[
                  sector.azimuth +
                    '_' +
                    antenna.model +
                    '_' +
                    technology.band +
                    '_hba_variation'
                ],
              heightStep:
                vincoliState[
                  sector.azimuth +
                    '_' +
                    antenna.model +
                    '_' +
                    technology.band +
                    '_hba_step'
                ],
              azimuth: parseInt(sector.azimuth),
              azimuthMin:
                parseInt(sector.azimuth) -
                vincoliState[
                  sector.azimuth +
                    '_' +
                    antenna.model +
                    '_' +
                    technology.band +
                    '_azimuth_variation'
                ],
              azimuthMax:
                parseInt(sector.azimuth) +
                vincoliState[
                  sector.azimuth +
                    '_' +
                    antenna.model +
                    '_' +
                    technology.band +
                    '_azimuth_variation'
                ],
              azimuthStep:
                vincoliState[
                  sector.azimuth +
                    '_' +
                    antenna.model +
                    '_' +
                    technology.band +
                    '_azimuth_step'
                ],
              brand: antenna.brand,
              model: antenna.model,
              powerPilot: technology.potPilot,
              pwMin:
                technology.potPilot -
                vincoliState[
                  sector.azimuth +
                    '_' +
                    antenna.model +
                    '_' +
                    technology.band +
                    '_power_variation'
                ],
              pwMax:
                technology.potPilot +
                vincoliState[
                  sector.azimuth +
                    '_' +
                    antenna.model +
                    '_' +
                    technology.band +
                    '_power_variation'
                ],
              pwStep:
                vincoliState[
                  sector.azimuth +
                    '_' +
                    antenna.model +
                    '_' +
                    technology.band +
                    '_power_step'
                ],
              eTilt: technology.downtiltElettrico,
              eTiltMin:
                vincoliState[
                  sector.azimuth +
                    '_' +
                    antenna.model +
                    '_' +
                    technology.band +
                    '_etiltmin'
                ],
              eTiltMax:
                vincoliState[
                  sector.azimuth +
                    '_' +
                    antenna.model +
                    '_' +
                    technology.band +
                    '_etiltmax'
                ],
              eTiltStep: 1,
              tilt: parseInt(antenna.arpaParameters.tiltMeccanicoMax),
              tiltMin:
                vincoliState[
                  sector.azimuth +
                    '_' +
                    antenna.model +
                    '_' +
                    technology.band +
                    '_mtiltmin'
                ],
              tiltMax:
                vincoliState[
                  sector.azimuth +
                    '_' +
                    antenna.model +
                    '_' +
                    technology.band +
                    '_mtiltmax'
                ],
              tiltStep: 1,
              name: technology.name,
              TXLOSSES: 0,
              RXLOSSES: 0,
              NOISE_FIGURE: 5,
              FEEDERLENGTH_DL: 0,
              FEEDERLENGTH_UL: 0,
              powerMax: technology.arpaParameters.potenzaTotaleApparatodBm,
              latWGS84: siteState.geometry.coordinates[1],
              longWGS84: siteState.geometry.coordinates[0],
            }

            transmitters.push(transmitter)
          }
        })
      })
    )

    let message = {
      status: null,
      EVAL_UUID: formStateChanged.uuid,
      ABC_SN: formStateChanged.abc_sn ? formStateChanged.abc_sn : 1,
      ABC_CYCLES: formStateChanged.abc_cycles ? formStateChanged.abc_cycles : 1,
      CoverageWeight: formStateChanged.coverage_weight,
      CoverageTreeshold: parseInt(formStateChanged.soglia_di_copertura),
      InterferenceTreeshold: parseInt(formStateChanged.soglia_di_sinr),
      roomName: siteState.site_name,
      roomCode: formStateChanged.room_code,
      latWGS84: siteState.geometry.coordinates[1],
      longWGS84: siteState.geometry.coordinates[0],
      CALC_RADIUS: parseInt(formStateChanged.raggio),
      CALC_RESOLUTION: parseInt(formStateChanged.risoluzione),
      FOCUS_ZONE_RADIUS: formStateChanged.metri_raggio_zona_di_calcolo,
      COMPUTATION_ZONE_RADIUS: formStateChanged.metri_raggio_zona_di_calcolo,
      PROPAG_MODEL: formStateChanged.modello_propagazione,
      CELL_SIZE: 400,
      banda: formStateChanged.system_type,
      neType: neType,
      FBAND: fband,
      REPORT_COLUMN: formStateChanged.target === 'POPOLAZIONE' ? 3 : 2,
      ZONE: 'Z2',
      EDGECOVERAGEPROBABILITY: parseInt(
        formStateChanged.coverage_prob_percentage
      ),
      SHADOWING: formStateChanged.flag_shadow === 'NO' ? 0 : 1,
      INDOORCOVERAGE: formStateChanged.flag_indoor === 'NO' ? 0 : 1,
      COMPUTATION_ZONE_SHAPE_UUID: null,
      ZONE_SHAPE: false,
      transmitters: transmitters,
    }

    formStateChanged['final_result'] = 'CALCULATING'
    formStateChanged['status'] = 'SUBMITTED'
    httpService.putAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/eval/eval`,
      formStateChanged,
      onPutEval
    )

    console.log(JSON.stringify(message))
    postAPI(
      'https://networkteam.it.nttdata-emea.com/queue/push/ssd_rnp',
      message
    )

    history.push(
      `${process.env.REACT_APP_CLIENT_PATH_NAME}/evalMessages/${uuid}`
    )
    //window.location.href = `https://networkteam.it.nttdata-emea.com/Radiomod/cem/site/${formState.room_code}/simulations-history`
  }

  function onPutEval(data) {}

  function queryAPI(myURL) {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    fetch(myURL, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status)
        } else return response.json()
      })
      .then((jsondata) => {
        if (jsondata.data) {
          //setting main state
          setFormState(jsondata.data[0])

          queryAPISiteInfo(
            `https://networkteam.it.nttdata-emea.com/api/scalar/office/site_info?site_code=${jsondata.data[0].room_code}`
          )

          let models = []
          jsondata.data[0].sectors.forEach((sector) =>
            sector.antennas.forEach((antenna) => {
              models.push(antenna.model)
            })
          )
          queryAPIAntennaPorts(
            `https://networkteam.it.nttdata-emea.com/api/scalar/hwcatalog/antenna_port?model_codes=${models.join(
              ','
            )}`,
            jsondata.data[0]
          )

          setDataLoaded(true)

          if (jsondata.data[0].target === 'POPOLAZIONE') {
            setTargetPopolazione(true)
            setTargetSuperficie(false)
          } else if (jsondata.data[0].target === 'SUPERFICIE') {
            setTargetPopolazione(false)
            setTargetSuperficie(true)
          }
        }
      })
      .catch((error) => {
        console.error(error)
        //localStorage.removeItem('token');
        //window.location.reload();
      })
  }

  useEffect(() => {
    queryAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/eval/eval?uuid=${uuid}`
    )
  }, [])

  function onDataChange(data) {
    console.log(data)
    formStateChanged = data
  }

  function roundToDecimals(value, decimals) {
    return Math.round(parseFloat(value) * 10 ** decimals) / 10 ** decimals
  }

  if (!dataLoaded)
    return (
      <div>
        <h1 style={{ display: 'flex', justifyContent: 'center' }}>
          RNP Page data not loaded!
        </h1>
        <LinearProgress />
      </div>
    )
  else
    return (
      <div>
        <Grid container spacing={'1%'} padding={5}>
          <Grid md={3}>
            <TextFieldCombo
              label='System Type'
              fieldSize='70%'
              labelSize={14}
              apiURL='https://networkteam.it.nttdata-emea.com/api/scalar/rnp/templates'
              valueMember='technology'
              displayMember='technology'
              selectedValue={formState.system_type}
              name='system_type'
              type='text'
              onSelectChange={(e) => {
                formState.abc_sn = 1
                formState.abc_cycles = 1

                formState.system_type = e.target.value

                formState.raggio = e.target.row.calculation_radius
                formState.metri_raggio_zona_di_calcolo =
                  e.target.row.calculation_radius
                formState.modello_propagazione = e.target.row.propagation_model
                formState.risoluzione = e.target.row.calculation_resolution

                formState.flag_shadow = e.target.row.shadowing
                formState.flag_indoor = e.target.row.indoor_coverage

                formState.coverage_prob_percentage =
                  e.target.row.edge_coverage_probability
                formState.soglia_di_copertura = e.target.row.coverage_threshold
                formState.soglia_di_sinr = e.target.row.interference_threshold
                formState.coverage_weight = e.target.row.coverage_weight
                formState.sinr_weight = e.target.row.interference_weight
                formState.target = e.target.row.target

                if (e.target.row.target === 'POPOLAZIONE') {
                  setTargetPopolazione(true)
                  setTargetSuperficie(false)
                } else if (e.target.row.target === 'SUPERFICIE') {
                  setTargetPopolazione(false)
                  setTargetSuperficie(true)
                }
                updateForm(e, setFormState)
                fillVincoliState(formState)
              }}
            />
          </Grid>
          <Grid md={12} sx={{ height: '30px' }}></Grid>
          <Grid md={12}>
            <JsonSchemaBox
              schema={upperFormSchema}
              formData={formState}
              onChange={onDataChange}
              tabs={['Simulation Parameters']}
            />
          </Grid>
        </Grid>

        <h3 style={{ display: 'flex', justifyContent: 'left', padding: 20 }}>
          Parameters Range
        </h3>

        <TableContainer sx={{ backgroundColor: '#D1D2D2' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={table_style}>Sector</TableCell>

                <TableCell sx={table_style} align='center'>
                  Antenna
                </TableCell>

                <TableCell sx={table_style} align='center'>
                  Azimuth (°)
                  <IconButton
                    name='lockAzimuth'
                    onClick={(e) => {
                      lockClicked(e)
                    }}
                  >
                    <Icon
                      fontSize='small'
                      sx={{
                        backgroundColor: 'background.light',
                        color: 'primary.light',
                      }}
                    >
                      lock
                    </Icon>
                  </IconButton>
                  <TableRow>
                    <TableCell
                      sx={{ fontSize: 11, borderBottom: 'none' }}
                      align='center'
                    >
                      Current&nbsp;Value (°)
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: 11, borderBottom: 'none' }}
                      align='right'
                    >
                      Variation (+/- °)
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: 11, borderBottom: 'none' }}
                      align='right'
                    >
                      Step (°)
                    </TableCell>
                  </TableRow>
                </TableCell>

                <TableCell sx={table_style} align='center'>
                  Electrical tilt
                  <IconButton
                    name='lockEtilt'
                    onClick={(e) => {
                      lockClicked(e)
                    }}
                  >
                    <Icon
                      fontSize='small'
                      sx={{
                        backgroundColor: 'background.light',
                        color: 'primary.light',
                      }}
                    >
                      lock
                    </Icon>
                  </IconButton>
                  <TableRow>
                    <TableCell
                      sx={{ fontSize: 11, borderBottom: 'none' }}
                      align='center'
                    >
                      Current&nbsp;Value (°)
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: 11, borderBottom: 'none' }}
                      align='right'
                    >
                      Min&nbsp;Value (°)
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: 11, borderBottom: 'none' }}
                      align='right'
                    >
                      Max&nbsp;Value (°)
                    </TableCell>
                  </TableRow>
                </TableCell>

                <TableCell sx={table_style} align='center'>
                  Mechanical tilt
                  <IconButton
                    name='lockTilt'
                    onClick={(e) => {
                      lockClicked(e)
                    }}
                  >
                    <Icon
                      fontSize='small'
                      sx={{
                        backgroundColor: 'background.light',
                        color: 'primary.light',
                      }}
                    >
                      lock
                    </Icon>
                  </IconButton>
                  <TableRow>
                    <TableCell
                      sx={{ fontSize: 11, borderBottom: 'none' }}
                      align='center'
                    >
                      Current&nbsp;Value (°)
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: 11, borderBottom: 'none' }}
                      align='right'
                    >
                      Min&nbsp;Value (°)
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: 11, borderBottom: 'none' }}
                      align='right'
                    >
                      Max&nbsp;Value (°)
                    </TableCell>
                  </TableRow>
                </TableCell>

                <TableCell sx={table_style} align='center'>
                  HBA
                  <IconButton
                    name='lockHBA'
                    onClick={(e) => {
                      lockClicked(e)
                    }}
                  >
                    <Icon
                      fontSize='small'
                      sx={{
                        backgroundColor: 'background.light',
                        color: 'primary.light',
                      }}
                    >
                      lock
                    </Icon>
                  </IconButton>
                  <TableRow>
                    <TableCell
                      sx={{ fontSize: 11, borderBottom: 'none' }}
                      align='center'
                    >
                      Current&nbsp;Value (m)
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: 11, borderBottom: 'none' }}
                      align='right'
                    >
                      Variation (+/- m)
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: 11, borderBottom: 'none' }}
                      align='right'
                    >
                      Step (m)
                    </TableCell>
                  </TableRow>
                </TableCell>

                <TableCell sx={table_style} align='center'>
                  TX Power
                  <IconButton
                    name='lockPower'
                    onClick={(e) => {
                      lockClicked(e)
                    }}
                  >
                    <Icon
                      fontSize='small'
                      sx={{
                        backgroundColor: 'background.light',
                        color: 'primary.light',
                      }}
                    >
                      lock
                    </Icon>
                  </IconButton>
                  <TableRow>
                    <TableCell
                      sx={{ fontSize: 11, borderBottom: 'none' }}
                      align='center'
                    >
                      Current&nbsp;Value (dBm)
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: 11, borderBottom: 'none' }}
                      align='right'
                    >
                      Variation (+/- dB)
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: 11, borderBottom: 'none' }}
                      align='right'
                    >
                      Step (dB)
                    </TableCell>
                  </TableRow>
                </TableCell>
              </TableRow>
            </TableHead>
            {formState &&
              formState.sectors.map((sector) =>
                sector.antennas.map((antenna) => {
                  return antenna.technologies.map((technology) => {
                    if (technology.band === formState.system_type) {
                      return (
                        <TableRow>
                          <TableCell
                            sx={{
                              borderLeft: 1,
                              borderColor: '#A2A6A6',
                              borderBottom: 'none',
                            }}
                          >
                            {sector.sector}
                          </TableCell>

                          <TableCell
                            sx={{
                              borderLeft: 1,
                              borderColor: '#A2A6A6',
                              borderBottom: 'none',
                            }}
                          >
                            {antenna.model}
                          </TableCell>

                          <TableCell
                            sx={{
                              borderLeft: 1,
                              borderColor: '#A2A6A6',
                              borderBottom: 'none',
                            }}
                          >
                            <Table>
                              <TableRow>
                                <TableCell sx={{ borderBottom: 'none' }}>
                                  {sector.azimuth}
                                </TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}>
                                  <TextField
                                    fullWidth
                                    align='center'
                                    variant='standard'
                                    value={
                                      vincoliState[
                                        sector.azimuth +
                                          '_' +
                                          antenna.model +
                                          '_' +
                                          technology.band +
                                          '_azimuth_variation'
                                      ]
                                    }
                                    name={
                                      sector.azimuth +
                                      '_' +
                                      antenna.model +
                                      '_' +
                                      technology.band +
                                      '_azimuth_variation'
                                    }
                                    type='number'
                                    onChange={(e) => {
                                      updateForm(e, setVincoliState)
                                    }}
                                  />
                                </TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}>
                                  <TextField
                                    fullWidth
                                    align='center'
                                    variant='standard'
                                    value={
                                      vincoliState[
                                        sector.azimuth +
                                          '_' +
                                          antenna.model +
                                          '_' +
                                          technology.band +
                                          '_azimuth_step'
                                      ]
                                    }
                                    name={
                                      sector.azimuth +
                                      '_' +
                                      antenna.model +
                                      '_' +
                                      technology.band +
                                      '_azimuth_step'
                                    }
                                    type='number'
                                    onChange={(e) => {
                                      updateForm(e, setVincoliState)
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            </Table>
                          </TableCell>

                          <TableCell
                            sx={{
                              borderLeft: 1,
                              borderColor: '#A2A6A6',
                              borderBottom: 'none',
                            }}
                          >
                            <Table>
                              <TableRow>
                                <TableCell sx={{ borderBottom: 'none' }}>
                                  {technology.downtiltElettrico}
                                </TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}>
                                  <TextField
                                    fullWidth
                                    align='center'
                                    variant='standard'
                                    value={
                                      vincoliState[
                                        sector.azimuth +
                                          '_' +
                                          antenna.model +
                                          '_' +
                                          technology.band +
                                          '_etiltmin'
                                      ]
                                    }
                                    name={
                                      sector.azimuth +
                                      '_' +
                                      antenna.model +
                                      '_' +
                                      technology.band +
                                      '_etiltmin'
                                    }
                                    type='number'
                                    onChange={(e) => {
                                      updateForm(e, setVincoliState)
                                    }}
                                  />
                                </TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}>
                                  <TextField
                                    fullWidth
                                    align='center'
                                    variant='standard'
                                    value={
                                      vincoliState[
                                        sector.azimuth +
                                          '_' +
                                          antenna.model +
                                          '_' +
                                          technology.band +
                                          '_etiltmax'
                                      ]
                                    }
                                    name={
                                      sector.azimuth +
                                      '_' +
                                      antenna.model +
                                      '_' +
                                      technology.band +
                                      '_etiltmax'
                                    }
                                    type='number'
                                    onChange={(e) => {
                                      updateForm(e, setVincoliState)
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            </Table>
                          </TableCell>

                          <TableCell
                            sx={{
                              borderLeft: 1,
                              borderColor: '#A2A6A6',
                              borderBottom: 'none',
                            }}
                          >
                            <Table>
                              <TableRow>
                                <TableCell sx={{ borderBottom: 'none' }}>
                                  {antenna.arpaParameters.tiltMeccanicoMax}
                                </TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}>
                                  <TextField
                                    fullWidth
                                    align='center'
                                    variant='standard'
                                    value={
                                      vincoliState[
                                        sector.azimuth +
                                          '_' +
                                          antenna.model +
                                          '_' +
                                          technology.band +
                                          '_mtiltmin'
                                      ]
                                    }
                                    name={
                                      sector.azimuth +
                                      '_' +
                                      antenna.model +
                                      '_' +
                                      technology.band +
                                      '_mtiltmin'
                                    }
                                    type='number'
                                    onChange={(e) => {
                                      updateForm(e, setVincoliState)
                                    }}
                                  />
                                </TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}>
                                  <TextField
                                    fullWidth
                                    align='center'
                                    variant='standard'
                                    value={
                                      vincoliState[
                                        sector.azimuth +
                                          '_' +
                                          antenna.model +
                                          '_' +
                                          technology.band +
                                          '_mtiltmax'
                                      ]
                                    }
                                    name={
                                      sector.azimuth +
                                      '_' +
                                      antenna.model +
                                      '_' +
                                      technology.band +
                                      '_mtiltmax'
                                    }
                                    type='number'
                                    onChange={(e) => {
                                      updateForm(e, setVincoliState)
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            </Table>
                          </TableCell>

                          <TableCell
                            sx={{
                              borderLeft: 1,
                              borderColor: '#A2A6A6',
                              borderBottom: 'none',
                            }}
                          >
                            <Table>
                              <TableRow>
                                <TableCell sx={{ borderBottom: 'none' }}>
                                  {antenna.hbaSuolo}
                                </TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}>
                                  <TextField
                                    fullWidth
                                    align='center'
                                    variant='standard'
                                    value={
                                      vincoliState[
                                        sector.azimuth +
                                          '_' +
                                          antenna.model +
                                          '_' +
                                          technology.band +
                                          '_hba_variation'
                                      ]
                                    }
                                    name={
                                      sector.azimuth +
                                      '_' +
                                      antenna.model +
                                      '_' +
                                      technology.band +
                                      '_hba_variation'
                                    }
                                    type='number'
                                    onChange={(e) => {
                                      updateForm(e, setVincoliState)
                                    }}
                                  />
                                </TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}>
                                  <TextField
                                    fullWidth
                                    align='center'
                                    variant='standard'
                                    value={
                                      vincoliState[
                                        sector.azimuth +
                                          '_' +
                                          antenna.model +
                                          '_' +
                                          technology.band +
                                          '_hba_step'
                                      ]
                                    }
                                    name={
                                      sector.azimuth +
                                      '_' +
                                      antenna.model +
                                      '_' +
                                      technology.band +
                                      '_hba_step'
                                    }
                                    type='number'
                                    onChange={(e) => {
                                      updateForm(e, setVincoliState)
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            </Table>
                          </TableCell>

                          <TableCell
                            sx={{
                              borderLeft: 1,
                              borderColor: '#A2A6A6',
                              borderBottom: 'none',
                            }}
                          >
                            <Table>
                              <TableRow>
                                <TableCell sx={{ borderBottom: 'none' }}>
                                  {roundToDecimals(technology.potPilot, 2)}
                                </TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}>
                                  <TextField
                                    fullWidth
                                    variant='standard'
                                    value={
                                      vincoliState[
                                        sector.azimuth +
                                          '_' +
                                          antenna.model +
                                          '_' +
                                          technology.band +
                                          '_power_variation'
                                      ]
                                    }
                                    name={
                                      sector.azimuth +
                                      '_' +
                                      antenna.model +
                                      '_' +
                                      technology.band +
                                      '_power_variation'
                                    }
                                    type='number'
                                    onChange={(e) => {
                                      updateForm(e, setVincoliState)
                                    }}
                                  />
                                </TableCell>
                                <TableCell sx={{ borderBottom: 'none' }}>
                                  <TextField
                                    fullWidth
                                    align='center'
                                    variant='standard'
                                    value={
                                      vincoliState[
                                        sector.azimuth +
                                          '_' +
                                          antenna.model +
                                          '_' +
                                          technology.band +
                                          '_power_step'
                                      ]
                                    }
                                    name={
                                      sector.azimuth +
                                      '_' +
                                      antenna.model +
                                      '_' +
                                      technology.band +
                                      '_power_step'
                                    }
                                    type='number'
                                    onChange={(e) => {
                                      updateForm(e, setVincoliState)
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            </Table>
                          </TableCell>
                        </TableRow>
                      )
                    } else return <TableRow />
                  })
                })
              )}
          </Table>
        </TableContainer>

        <Grid container spacing={'1%'} padding={5}>
          <Grid md={11}></Grid>
          <Grid md={1}>
            <Button variant='outlined' onClick={startSimulation}>
              Simulate
            </Button>
          </Grid>
        </Grid>
      </div>
    )
}

export default RNP
