import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { useHistory } from 'react-router-dom'

import EnhancedTableBox from '../../components/EnhancedTableBox'

import { useConfirm } from 'material-ui-confirm'

export default function TENANT() {
  const history = useHistory()

  const visibleColumns = [
    {
      id: 'tenant',
      alignRight: false,
      disablePadding: false,
      label: 'Tenant',
    },
  ]


  //array of actions placed at the end of each row
  const actions = [
    {
      action: 'edit',
      icon: 'edit',
      tooltip: 'edit tenant',
    },
    {
      action: 'delete',
      icon: 'delete',
      tooltip: 'delete tenant',
    },
    {
      action: 'open',
      icon: 'open_in_browser',
      tooltip: 'open technology page',
    },
  ]

  const schema = {
    tenant: {
      title: 'Tenant',
      type: 'text',
      md: 5,
    },
  }

  const confirm = useConfirm()

  //event that is fired when an action is clicked
  function onAction(action, row) {
    //set the clicked row in a state

    switch (action) {
      case 'open':
        history.push(`/tenantTechnology/${row.tenant}`)
        break
    }
    setActionRow(row)
    //different actions may have different beaviour

  }
  const [actionRow, setActionRow] = React.useState(null)

  return (
    <div>
      <Grid container spacing={5} padding={5}>
        <Grid item md={12}>

          <EnhancedTableBox
            title='List of Tenants'
            addButton
            apiURL='https://networkteam.it.nttdata-emea.com/api/scalar/test/tenants_list'
            deleteURL='https://networkteam.it.nttdata-emea.com/api/scalar/common/tenants'
            addURL='https://networkteam.it.nttdata-emea.com/api/scalar/common/tenants'
            editURL='https://networkteam.it.nttdata-emea.com/api/scalar/common/tenants'
            valueMember='id'
            visibleColumns={visibleColumns}
            actions={actions}
            onAction={onAction}
            addJsonSchema={schema}
            editJsonSchema={schema}
          />
        </Grid>
      </Grid>
    </div>
  )
}
