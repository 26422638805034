import React, { useState, useEffect, useRef } from 'react';
import { useUserContext } from '../../contexts/userContext';
import { Button, TextField, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import TvIcon from '@mui/icons-material/Tv';
import Modal from 'react-modal';
import HistoryIcon from '@mui/icons-material/History';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
	titleField: {
		width: '25%',
		margin: '0 auto',
	},
	startButton: {
		margin: '5px',
		backgroundColor: '#4caf50',
		color: '#fff',
		'&:hover': {
			backgroundColor: '#388e3c',
		},
	},
	stopButton: {
		margin: '5px',
		backgroundColor: '#f44336',
		color: '#fff',
		'&:hover': {
			backgroundColor: '#d32f2f',
		},
	},
	centerContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	userStream: {
		margin: '10px 0',
		fontSize: '1.2rem',
		color: '#fff',
		'& strong': {
			color: '#03a9f4',
		},
	},
	watchButton: {
		margin: '5px',
		backgroundColor: '#3f51b5',
		color: '#fff',
		'&:hover': {
			backgroundColor: '#303f9f',
		},
		display: 'flex',
		alignItems: 'center',
	},
	previousButton: {
		margin: '5px',
		backgroundColor: '#795548',
		color: '#fff',
		'&:hover': {
			backgroundColor: '#5d4037',
		},
		display: 'flex',
		alignItems: 'center',
	},
	closeButton: {
		position: 'absolute',
		right: '10px',
		top: '10px',
		color: '#f44336',
	},
	lectureItem: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '10px 0',
	},
	lectureText: {
		flex: 1,
		textAlign: 'center',
	},
	playButton: {
		color: '#4caf50',
		marginRight: '20px',
	},
});

const MedicalTraining = () => {
	const [modalIsOpen, setIsOpen] = useState(false);
	const [stopButtonDisabled, setStopButtonDisabled] = useState(true);
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState('');
	const [streamingUrl, setStreamingUrl] = useState(null);
	const [isStopping, setIsStopping] = useState(false);
	const [progress, setProgress] = useState(0);
	const [startLoading, setStartLoading] = useState(false);
	const [stopLoading, setStopLoading] = useState(false);

	const classes = useStyles();
	const user = useUserContext().getUser();
	let myRole = 'admin';
	console.log(user.roles);
	user.roles.forEach((role) => {
		let t = role.split('|');
		console.log(t);
		if (t[0] === 'medicaltraining') myRole = t[1];
	});

	const openModal = () => {
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	const streamingTimeout = useRef(null);

	const [isStreaming, setIsStreaming] = useState(false);

	const startStreaming = () => {
		if (title.trim() === '') {
			alert('Please type a name for the stream!');
		} else {
			setStartLoading(true);
			setStopButtonDisabled(true);
			setIsStreaming(true);
			setLoading('Starting the stream, Please wait (Approx 1 min)');
			setProgress(0);
			const duration = 60000;
			let elapsedTime = 0;

			streamingTimeout.current = setInterval(() => {
				elapsedTime += 1000;
				const percentage = (elapsedTime / duration) * 100;
				setProgress(Math.min(percentage, 100));

				if (elapsedTime >= duration) {
					clearInterval(streamingTimeout.current);
					setLoading(false);
					setStartLoading(false);
					setStreamingUrl('https://www.youtube.com/channel/UC-ESR6aawOWoKIhyTaigqAw/live');
					window.open('https://www.youtube.com/channel/UC-ESR6aawOWoKIhyTaigqAw/live', '_blank');
					setStopButtonDisabled(false);
				}
			}, 1000);
		}
	};

	const stopStreaming = () => {
		if (window.confirm('Are you sure you want to stop the Stream?')) {
			setTitle('');
			setStopLoading(true);
			setStartLoading(false);
			setStopButtonDisabled(true);
			setStreamingUrl(null);
			setLoading('Stopping the stream, please wait...');
			clearInterval(streamingTimeout.current);

			const totalIncrements = 100;
			const incrementDuration = 10000;
			const incrementInterval = incrementDuration / totalIncrements;

			let progressValue = 0;

			const progressInterval = setInterval(() => {
				if (progressValue >= 100) {
					clearInterval(progressInterval);
					setLoading(false);
					setStopLoading(false);
					setIsStreaming(false);
					alert('Stream has been stopped successfully.');
				} else {
					progressValue += 100 / totalIncrements;
					setProgress(progressValue);
				}
			}, incrementInterval);
		}
	};

	const lectureLinks = [
		'https://www.youtube.com/watch?v=xgz8SkF96ho',
		'https://www.youtube.com/watch?v=SPfBEf63-zQ',
		'https://www.youtube.com/watch?v=auZIq3ltzi4',
		'https://www.youtube.com/watch?v=4FlKfbCVFVw',
		'https://www.youtube.com/watch?v=xbfB6gZzmrk',
	];

	useEffect(() => {
		if (!isStreaming) {
			clearInterval(streamingTimeout.current);
		}
	}, [isStreaming]);
	console.log(myRole);
	switch (myRole) {
		case 'admin':
			return (
				<div className={classes.centerContent}>
					<h1>Doctor page</h1>
					<TextField
						className={classes.titleField}
						fullWidth
						id='outlined-disabled'
						label='Title'
						placeholder='Title goes here...'
						value={title}
						onChange={(event) => setTitle(event.target.value)}
						disabled={isStreaming || isStopping}
						style={{ height: 100 }}
					/>
					<div>
						<Button
							className={classes.startButton}
							startIcon={<PlayArrowIcon />}
							onClick={startStreaming}
							disabled={isStreaming || isStopping}>
							Start streaming
						</Button>
						<Button
							className={classes.stopButton}
							startIcon={<StopIcon />}
							onClick={stopStreaming}
							disabled={!isStreaming || isStopping}>
							Stop streaming
						</Button>
					</div>
					{startLoading && (
						<div>
							<p>Starting the stream, Please wait (Approx 1 min)</p>
							<div style={{ width: '100%' }}>
								<LinearProgress
									variant='determinate'
									value={progress}
									style={{ height: '20px' }}
								/>
								<p>{progress.toFixed(0)}%</p>
							</div>
						</div>
					)}
					{stopLoading && (
						<div>
							<p>Stopping the stream, please wait...</p>
							<div style={{ width: '100%' }}>
								<LinearProgress
									variant='determinate'
									value={progress}
									style={{ height: '20px' }}
								/>
								<p>{progress.toFixed(0)}%</p>
							</div>
						</div>
					)}
					{loading && !startLoading && !stopLoading && (
						<div>
							<p>{loading}</p>
							<div style={{ width: '100%' }}>
								<LinearProgress
									variant='determinate'
									value={progress}
									style={{ height: '20px' }}
								/>
								<p>{progress.toFixed(0)}%</p>
							</div>
						</div>
					)}
					{streamingUrl && (
						<p>
							Currently streaming here:{' '}
							<a href={streamingUrl} target='_blank' rel='noopener noreferrer'>
								{streamingUrl}
							</a>
						</p>
					)}
				</div>
			);
		case 'user':
			return (
				<div className={classes.centerContent}>
					<h1>Student page</h1>
					<p className={classes.userStream}>
						Currently streaming: <strong>"Medical Training DEMO"</strong>
					</p>
					<Button
						className={classes.watchButton}
						startIcon={<TvIcon />}
						href='https://www.youtube.com/channel/UC-ESR6aawOWoKIhyTaigqAw/live'
						target='_blank'
						rel='noopener noreferrer'>
						Watch here
					</Button>
					<Button
						className={classes.previousButton}
						startIcon={<HistoryIcon />}
						onClick={openModal}>
						Previous Lectures
					</Button>
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel='Previous Lectures'
						style={{
							content: {
								backgroundColor: '#e6b600',
								color: '#fff',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								width: '50%',
								height: '50%',
								margin: 'auto',
								borderRadius: '20px',
								position: 'relative',
							},
						}}>
						<IconButton className={classes.closeButton} onClick={closeModal}>
							<CloseIcon />
						</IconButton>
						<h2>Previous Lectures</h2>
						{lectureLinks.map((link, index) => (
							<div key={index} className={classes.lectureItem}>
								<a
									href={link}
									target='_blank'
									rel='noopener noreferrer'
									className={classes.lectureText}>
									Lecture {index + 1}
								</a>
							</div>
						))}
					</Modal>
				</div>
			);

		default:
			return (
				<div>
					<h1>You have no role for this page</h1>
				</div>
			);
	}
};

export default MedicalTraining;
