import React, { useState, useEffect } from 'react'
import EnhancedTableBox from '../../components/EnhancedTableBox'

import useHttpService from '../../customHooks/useHttpService.js'
import { v4 as uuidv4 } from 'uuid'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { Grid } from '@mui/material'
import EnhancedIconButton from '../../components/EnhancedIconButton'
import {
  Button,
  Input,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import JsonSchemaBox from '../../components/JsonSchemaBox'
import ComboBox from '../../components/ComboBox'

const EvalMatrix = () => {
  const { uuid } = useParams()
  const history = useHistory()
  const httpService = useHttpService()
  const [visibleColumns, setVisibleColumns] = React.useState([
    {
      id: 'sector',
      alignRight: true,
      disablePadding: true,
      label: 'Sector',
    },
    {
      id: 'azimuth',
      alignRight: true,
      disablePadding: true,
      label: 'Azimuth',
    },
    {
      id: 'model',
      alignRight: false,
      disablePadding: false,
      label: 'Model',
    },
    {
      id: 'antenna_number',
      alignRight: true,
      disablePadding: true,
      label: 'Amount',
    },
    {
      id: 'hba',
      alignRight: true,
      disablePadding: true,
      label: 'HBA',
    },
    {
      id: 'mecTilt',
      alignRight: true,
      disablePadding: true,
      label: 'Tilt',
    },
    // {
    //     id: "tenant",
    //     alignRight: false,
    //     disablePadding: false,
    //     label: "Tenant"
    // },
  ])
  const [gridData, setGridData] = useState([])
  const [tecData, setTecData] = useState([])
  const [evalUuid, setEvalUuid] = useState('')
  const [openAntennaDialog, setOpenAntennaDialog] = useState(false)
  const [openTechnologyDialog, setOpenTechnologyDialog] = useState(false)
  const [rerenderState, setRerenderState] = useState(1)
  const [evalData, setEvalData] = useState(null)
  const [roomCode, setRoomCode] = useState(null)
  // const [modelBrandTechTuple, setModelBrandTechTuple] = useState(null)
  const [choosenTechnology, setChoosenTechnology] = useState(null)
  const [filteredAntennas, setFilteredAntennas] = useState(null)

  const [cableLoss, setCableLoss] = useState([])
  const [frequency, setFrequency] = useState([])

  function getcableloss() {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    fetch(
      `https://networkteam.it.nttdata-emea.com/api/scalar/rnp/cables`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status)
        } else return response.json()
      })
      .then((cabledata) => {
        setCableLoss(cabledata.data)
      })
      .catch((error) => {
        console.error(error)
        //localStorage.removeItem('token');
        //window.location.reload();
      })
  }

  function getfrequency() {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    fetch(
      `https://networkteam.it.nttdata-emea.com/api/scalar/common/tech_to_freq`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status)
        } else return response.json()
      })
      .then((freqdata) => {
        setFrequency(freqdata.data)
      })
      .catch((error) => {
        console.error(error)
        //localStorage.removeItem('token');
        //window.location.reload();
      })
  }

  console.log(gridData)

  const [searchAntennaFormData, setSearchAntennaFormData] = useState(null)
  const [antennaJsonSchema, setAntennaJsonSchema] = useState({
    hba: {
      title: 'HBA',
      type: 'number',
      md: 4,
    },
    tilt: {
      title: 'Mechanical Downtilt',
      type: 'number',
      md: 4,
    },
    model: {
      title: 'Model',
      type: 'eval',
      md: 4,
      enum: [],
    },
    sectors: {
      title: 'Sectors',
      type: 'list',
      data: [],
      md: 4,
    },
  })

  const [antennaSearchJsonSchema, setAntennaSearchJsonSchema] = useState({
    manufacturer: {
      title: 'Manufacturer',
      type: 'eval',
      md: 4,
      enum: [],
    },
    model: {
      title: 'Antenna Model',
      type: 'text',
      md: 4,
    },
    freq_min: {
      title: 'Frequency Range Min',
      type: 'number',
      md: 4,
    },
    freq_max: {
      title: 'Frequency Range Max',
      type: 'number',
      md: 4,
    },
  })

  const [antennaToPlace, setAntennaToPlace] = useState(null)

  const fillEnumAntennaJsonSchema = () => {
    httpService.getAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/hwcatalog/antenna_manufacturer`,
      (res) => {
        let tmp = { ...antennaSearchJsonSchema }
        tmp.manufacturer.enum = res
        setAntennaSearchJsonSchema(tmp)
      }
    )
  }

  useEffect(() => {
    console.log('in')
    getfrequency()
    getcableloss()
    httpService.getAPI(
      'https://networkteam.it.nttdata-emea.com/api/scalar/common/technologies',
      onTecLoaded
    )
    fillEnumAntennaJsonSchema()
  }, [])

  function onTecLoaded(data) {
    console.log(evalData)
    setTecData(data)
    let toAdd = []
    data.forEach((technology, technologyIndex) => {
      toAdd.push({
        id: technology,
        alignRight: true,
        disablePadding: true,
        vertical: true,
        label: technology,
      })
    })
    setVisibleColumns([...visibleColumns, ...toAdd])

    httpService.getAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/eval/eval?id=${uuid}`,
      onDataLoaded
    )
  }

  function onDataLoaded(data) {
    setEvalData(data[0])
    setEvalUuid(data[0].uuid)
    setRoomCode(data[0].room_code)
    let matrixRows = []
    let lastsector = null
    let lastazimuth = null
    data[0].sectors.forEach((sector, sectorIndex) => {
      sector.uuid = sector.uuid || uuidv4()
      sector.antennas.forEach((antenna, antennaIndex) => {
        antenna.uuid = antenna.uuid || uuidv4()
        let tenants = {}
        antenna.technologies.forEach((technology, technologyIndex) => {
          technology.uuid = technology.uuid || uuidv4()
          if (!(technology.tenant in tenants)) tenants[technology.tenant] = {}
          tenants[technology.tenant].tecList
            ? tenants[technology.tenant].tecList.push(technology.band)
            : (tenants[technology.tenant].tecList = [technology.band])
        })
        console.log(tenants)

        Object.entries(tenants).forEach((tenant) => {
          let tenantRow = {
            sector: sector.sector !== lastsector ? sector.sector : '',
            azimuth: sector.azimuth !== lastazimuth ? sector.azimuth : '',
            model: antenna.model,
            antenna_number: antenna.amount,
            hba: antenna.hbaSuolo,
            mecTilt: antenna.downtiltMeccanico,
            tenant: tenant[0],
          }
          lastsector = sector.sector
          lastazimuth = sector.azimuth
          tenant[1].tecList.forEach((tec) => {
            tenantRow[tec] = 'P'
          })
          matrixRows.push(tenantRow)
        })

        if (antenna.technologies.length === 0) {
          let tenantRow = {
            sector: sector.sector !== lastsector ? sector.sector : '',
            azimuth: sector.azimuth !== lastazimuth ? sector.azimuth : '',
            model: antenna.model,
            antenna_number: antenna.amount,
            hba: antenna.hbaSuolo,
            mecTilt: antenna.downtiltMeccanico,
            tenant: 'VODAFONE',
          }
          lastsector = sector.sector
          lastazimuth = sector.azimuth
          matrixRows.push(tenantRow)
        }
      })
    })

    setGridData(matrixRows)
  }

  //array of actions placed at the end of each row
  const actions = [
    {
      action: 'edit',
      icon: 'edit',
      tooltip: 'edit current row',
    },
  ]

  function onAction(action, e) {
    switch (action) {
      case 'edit':
        history.push(
          `${process.env.REACT_APP_CLIENT_PATH_NAME}/eval/${evalUuid}`
        )
        break

      default:
        break
    }
  }

  function goNext() {
    history.push(
      `${process.env.REACT_APP_CLIENT_PATH_NAME}/threshold/${evalUuid}`
    )
  }

  const importExcel = (e) => {
    const file = e.target.files[0]
    let formData = new FormData()
    formData.append('formFile', file)
    httpService.postAPI(
      `https://networkteam.it.nttdata-emea.com/fileio/eval/eval_radiomod/import?uuid=${evalUuid}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      (res) => {
        httpService.getAPI(
          `https://networkteam.it.nttdata-emea.com/api/scalar/eval/eval?id=${uuid}`,
          onDataLoaded
        )
      }
    )
  }
  const exportExcel = () => {
    httpService.downloadAPI(
      `https://networkteam.it.nttdata-emea.com/fileio/eval/eval_radiomod/export?uuid=${evalUuid}`,
      (res) => {
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const aEle = document.createElement('a') // Create a label
        const href = window.URL.createObjectURL(blob) // Create downloaded link
        aEle.href = href
        aEle.download = `eval_${evalUuid}.xlsx` // File name after download
        document.body.appendChild(aEle)
        aEle.click() // Click to download
        document.body.removeChild(aEle) // Download complete remove element
        window.URL.revokeObjectURL(href) // Release blob object
      }
    )
  }

  const searchAntennaModels = () => {
    const url = `https://networkteam.it.nttdata-emea.com/api/scalar/hwcatalog/antenna_model?model_code=${
      searchAntennaFormData.model || ''
    }&manufacturer=${searchAntennaFormData.manufacturer || ''}&freq_max=${
      searchAntennaFormData.freq_max || ''
    }&freq_min=${searchAntennaFormData.freq_min || ''}`

    httpService.getAPI(url, (res) => {
      console.log(evalData)
      let tmp = { ...antennaJsonSchema }
      tmp.model.enum = res
      tmp.sectors = {
        title: 'Sectors',
        type: 'list',
        multiselect: true,
        md: 4,
        data: evalData.sectors,
        displayMember: 'azimuth',
        valueMember: 'uuid',
      }
      setAntennaJsonSchema(tmp)
    })
  }

  const addAntenna = () => {
    console.log(evalData)
    console.log(antennaToPlace)
    let newData = JSON.parse(JSON.stringify(evalData))
    antennaToPlace.sectors.forEach((sectorToPlace) => {
      newData.sectors.forEach((originalSector) => {
        if (sectorToPlace === originalSector.uuid)
          originalSector.antennas.push({
            model: antennaToPlace.model,
            hbaSuolo: antennaToPlace.hba,
            downtiltMeccanico: antennaToPlace.tilt,
            arpaParameters: { tiltMeccanicoMax: antennaToPlace.tilt },
            poleID: '1',
            uuid: uuidv4(),
            altezza: 1000,
            hceSuolo: antennaToPlace.hba + 0.5,
            technologies: [],
          })
      })
    })
    httpService.putAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/eval/eval`,
      newData,
      onPutEval
    )
    setOpenAntennaDialog(false)
  }

  function onPutEval(data) {
    httpService.getAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/eval/eval?id=${uuid}`,
      onDataLoaded
    )
  }

  const addTechnology = () => {
    httpService.putAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/eval/eval`,
      evalDataToSave,
      onPutEval
    )
    setOpenTechnologyDialog(false)
  }

  function goBack() {
    window.location.href = `https://networkteam.it.nttdata-emea.com/Radiomod/cem/site/${roomCode}/simulations-history`
  }

  const [supportedTechnologyByAntenna, setSupportedTechnologyByAntenna] =
    useState([])

  const filterAntennasByTechnology = () => {
    let listOfAntennas = []
    evalData.sectors.map((sector) => {
      sector.antennas.map((antenna) => {
        listOfAntennas = [...listOfAntennas, antenna.model]
      })
    })

    // listOfAntennas = new Set(listOfAntennas)
    // listOfAntennas = Array.from(listOfAntennas)

    httpService.getAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/hwcatalog/antennas_technology?model_codes=${String(
        listOfAntennas
      )}&technology=${choosenTechnology.technology}`,
      (res) => {
        console.log(res)

        let tmp = []
        evalData.sectors.map((sector) => {
          sector.antennas.map((antenna) => {
            if (res.indexOf(antenna.model) !== -1) {
              tmp.push({
                azimuth: sector.azimuth,
                model: antenna.model,
                uuid: uuidv4(),
              })
            }
          })
        })
        setSupportedTechnologyByAntenna(tmp)
      }
    )

    // listOfAntennas.map((antenna) => {
    //   httpService.getAPI(
    //     `https://networkteam.it.nttdata-emea.com/api/scalar/common/tenants_technologies?model_codes=${antenna}&tenant=VODAFONE`,
    //     (res) => {
    //       console.log(res)
    //       apiCalled++
    //       if (apiCalled === listOfAntennas.length) {
    //         setHasTenantsTechnologiesEnded(true)
    //         apiCalled = 0
    //       }

    //       let tmp = [
    //         ...supportedTechnologyByAntenna,
    //         { model: antenna, technologies: res },
    //       ]
    //       setSupportedTechnologyByAntenna(tmp)
    //     }
    //   )
    // })

    // let tmp = []
    // evalData.sectors.map((sector) => {
    //   sector.antennas.map((antenna) => {
    //     antenna.technologies.map((tech) => {
    //       console.log(data.technologies, tech.band)
    //       if (data.technologies === tech.band) {
    //         tmp = [
    //           ...tmp,
    //           {
    //             model: antenna.model,
    //             brand: antenna.brand,
    //             azimuth: sector.azimuth,
    //           },
    //         ]
    //       }
    //     })
    //   })
    // })

    // tmp = new Set(tmp)
    // tmp = Array.from(tmp)
    // console.log(tmp)
    // setModelBrandTechTuple(tmp)
  }

  useEffect(() => {
    choosenTechnology && filterAntennasByTechnology()
  }, [choosenTechnology])

  useEffect(() => {
    console.log(supportedTechnologyByAntenna)
  }, [supportedTechnologyByAntenna])

  const [evalDataToSave, setEvalDataToSave] = useState(null)

  function getSplitterLosses(splitter) {
    if (splitter === '2-way Splitter') {
      return 3
    } else if (splitter === '3-way Splitter') {
      return 4.8
    } else if (splitter === '4-way Splitter') {
      return 6
    } else if (splitter === 'Diplexer') {
      return 0.5
    } else if (splitter === 'NO') {
      return 0
    }
    return 0
  }

  function getPotPilot(band, potMax, bandwidth) {
    if (band.startsWith('LTE') || band.startsWith('5G')) {
      return potMax - 10 * Math.log10(60 * bandwidth)
    } else if (band.startsWith('UMTS')) {
      return potMax - 10
    } else if (band.startsWith('GSM')) {
      return potMax
    }
  }

  function getFreq(band) {
    let freq
    frequency.forEach((tech) => {
      if (tech.technology === band) freq = tech.nominal_frequency
    })
    return freq
  }

  function getCableLoss(cavoRFTipo, freq) {
    let cableLossValue
    cableLoss.forEach((cable) => {
      if (cable.cable === cavoRFTipo && cable.frequency === freq)
        cableLossValue = cable.loss
    })
    return cableLossValue
  }

  const checkedTableValues = (data) => {
    let tmp = JSON.parse(JSON.stringify(evalData))

    supportedTechnologyByAntenna.forEach((stba) => {
      if (data.indexOf(stba.uuid) !== -1) {
        tmp.sectors.forEach((sector) => {
          if (sector.azimuth === stba.azimuth) {
            sector.antennas.forEach((antenna) => {
              if (antenna.model === stba.model) {
                let data = {
                  alloggiamento: choosenTechnology.housing,
                  arpaParameters: {
                    alfa24: choosenTechnology.alfa24,
                    fattoreRiduzione: choosenTechnology.fattoreriduzione,
                    nTrx: choosenTechnology.n_portanti_totale,
                    tiltElettricoMax: choosenTechnology.etilt,
                    potenzaTRXApparatodBm:
                      choosenTechnology.pot_max_dbm -
                      choosenTechnology.swattenuation,
                    potenzaTRXApparato:
                      Math.pow(
                        10,
                        (choosenTechnology.pot_max_dbm -
                          choosenTechnology.swattenuation) /
                          10
                      ) / 1000,
                  },
                  attenSw: choosenTechnology.swattenuation,
                  band: choosenTechnology.technology,
                  bandwidth: choosenTechnology.bandwidth,
                  bts: choosenTechnology.bts,
                  cavoRFLunghezza: choosenTechnology.rf_cable_length,
                  cavoRFTipo: choosenTechnology.rf_cable_type,
                  downtiltElettrico: choosenTechnology.etilt,
                  rangeTiltElettrico: null,
                  guadagno: 17,
                  name: `${sector.azimuth}-VODAFONE-${choosenTechnology.technology}`,
                  perditaSplitter: getSplitterLosses(
                    choosenTechnology.splitter
                  ),
                  perditeCaviRF: 0,
                  potMax: choosenTechnology.pot_max_dbm,
                  potPilot: getPotPilot(
                    choosenTechnology.technology,
                    choosenTechnology.pot_max_dbm,
                    choosenTechnology.bandwidth
                  ),
                  ru: choosenTechnology.rru,
                  splitter: choosenTechnology.splitter,
                  tenant: 'VODAFONE',
                  uuid: uuidv4(),
                }

                data.perditeCaviRF =
                  (data.cavoRFLunghezza / 100) *
                  getCableLoss(
                    choosenTechnology.rf_cable_type,
                    getFreq(choosenTechnology.technology)
                  )
                data.perditeTotali = data.perditaSplitter + data.perditeCaviRF
                data.arpaParameters.potenzaTotaleApparatoW =
                  data.arpaParameters.potenzaTRXApparato *
                  data.arpaParameters.nTrx
                data.arpaParameters.potenzaTotaleApparatodBm =
                  data.potMax + 10 * Math.log10(data.arpaParameters.nTrx)
                data.arpaParameters.potenzaTotAntennadBmNoriduzione =
                  data.arpaParameters.potenzaTotaleApparatodBm -
                  data.perditeTotali
                data.arpaParameters.potenzaTotAntennaWNoriduzione =
                  Math.pow(
                    10,
                    data.arpaParameters.potenzaTotAntennadBmNoriduzione / 10
                  ) / 1000
                data.eirp =
                  data.arpaParameters.potenzaTRXApparatodBm -
                  data.perditeTotali +
                  data.guadagno

                if (data.band) {
                  if (data.band.startsWith('GSM')) {
                    if (data.arpaParameters.fattoreRiduzione === 'DTX/PC') {
                      data.arpaParameters.potenzaTotAntennaRiduzione =
                        (data.arpaParameters.potenzaTotAntennaWNoriduzione /
                          data.arpaParameters.nTrx) *
                        (1 + 0.49 * (data.arpaParameters.nTrx - 1))
                    } else if (data.arpaParameters.fattoreRiduzione === 'NO') {
                      data.arpaParameters.potenzaTotAntennaRiduzione =
                        data.arpaParameters.potenzaTotAntennaWNoriduzione
                    }
                  } else {
                    data.arpaParameters.potenzaTotAntennaRiduzione =
                      data.arpaParameters.potenzaTotAntennaWNoriduzione *
                      data.arpaParameters.alfa24
                  }
                }

                data.arpaParameters.potenzaTotAntennaRiduzionedBm =
                  10 *
                  Math.log10(
                    1000 * data.arpaParameters.potenzaTotAntennaRiduzione
                  )

                antenna.technologies.push(data)
              }
            })
          }
        })
      }
    })

    setEvalDataToSave(tmp)
  }

  return (
    <>
      {rerenderState && (
        <Dialog
          fullWidth
          maxWidth='lg'
          open={openAntennaDialog}
          onClose={() => {
            setOpenAntennaDialog(false)
          }}
        >
          <DialogTitle
            sx={{
              marginTop: '30px',
              backgroundColor: 'background.light',
              paddingLeft: '6%',
              paddingRight: '6%',
              color: 'primary.light',
              width: '100%',
            }}
          >
            Add new Antenna
          </DialogTitle>
          <DialogContent>
            <JsonSchemaBox
              schema={antennaSearchJsonSchema}
              onChange={(data) => {
                setRerenderState((prev) => prev + 1)
                data && setSearchAntennaFormData(data)
              }}
            />
            {antennaJsonSchema.model.enum.length > 0 && (
              <JsonSchemaBox
                schema={antennaJsonSchema}
                onChange={(data) => {
                  console.log(data)
                  //setRerenderState((prev) => prev + 1)
                  data && setAntennaToPlace(data)
                }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenAntennaDialog(false)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                searchAntennaModels()
                // url &&
                //   httpService.postAPI(url, dataToSend, (res) => {
                //     setHasListChanged((prev) => prev + 1)
                //   })
              }}
            >
              Search
            </Button>
            <Button onClick={addAntenna}>Add</Button>
          </DialogActions>
        </Dialog>
      )}

      {rerenderState && (
        <Dialog
          fullWidth
          maxWidth='lg'
          open={openTechnologyDialog}
          onClose={() => {
            setOpenTechnologyDialog(false)
          }}
        >
          <DialogTitle
            sx={{
              marginTop: '30px',
              backgroundColor: 'background.light',
              paddingLeft: '6%',
              paddingRight: '6%',
              color: 'primary.light',
              width: '100%',
            }}
          >
            Add new Technology
          </DialogTitle>
          <DialogContent>
            <ComboBox
              label='Select technology to add:'
              apiURL={`https://networkteam.it.nttdata-emea.com/api/scalar/rnp/templates`}
              valueMember='technology'
              displayMember='technology'
              onSelectChange={(data) => {
                setChoosenTechnology(data)
              }}
            />
            {/* <JsonSchemaBox
              schema={{
                technologies: {
                  title: 'Technologies',
                  md: 4,
                  type: 'text',
                  enum: tecData,
                },
              }}
              onChange={(data) => {
                console.log(data)
                if (Object.keys(data).length > 0) {
                  setChoosenTechnology(data.technologies)
                }
                // data && setSearchAntennaFormData(data)
              }}
            /> */}
            {supportedTechnologyByAntenna && (
              <EnhancedTableBox
                valueMember='uuid'
                data={supportedTechnologyByAntenna}
                multiselect={true}
                visibleColumns={[
                  {
                    id: 'azimuth',
                    alignRight: true,
                    disablePadding: true,
                    label: 'Azimuth',
                  },
                  {
                    id: 'model',
                    alignRight: true,
                    disablePadding: true,
                    label: 'Antenna Model',
                  },
                ]}
                onSelectChange={checkedTableValues}
              />
            )}
            {/* {antennaJsonSchema.model.enum.length > 0 && (
              <JsonSchemaBox
                schema={antennaJsonSchema}
                onChange={(data) => {
                  console.log(data)
                  setRerenderState((prev) => prev + 1)
                  data && setAntennaToPlace(data)
                }}
              />
            )} */}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenTechnologyDialog(false)
              }}
            >
              Cancel
            </Button>

            <Button onClick={addTechnology}>Add</Button>
          </DialogActions>
        </Dialog>
      )}

      <Grid container spacing={5} padding={5}>
        <Grid item md={1}>
          <EnhancedIconButton
            icon='arrow_back'
            size='large'
            onClick={goBack}
            tooltip='Back to eval list'
          />
        </Grid>

        <Grid item md={5}></Grid>
        <Grid item md={6}>
          <Stack spacing={2} direction='row'>
            <label htmlFor='csv-uploader'>
              <Input
                inputProps={{
                  accept:
                    '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                }}
                id='csv-uploader'
                type='file'
                sx={{ display: 'none' }}
                onChange={importExcel}
              />
              <Button
                variant='outlined'
                component='span'
                sx={{ height: '100%' }}
              >
                Import Excel
              </Button>
            </label>
            <Button variant='outlined' onClick={exportExcel}>
              Export Excel
            </Button>
            <Button
              variant='outlined'
              onClick={() => setOpenAntennaDialog(true)}
            >
              Add Antenna
            </Button>
            <Button
              variant='outlined'
              onClick={() => setOpenTechnologyDialog(true)}
            >
              Add Technology
            </Button>
            <EnhancedIconButton
              icon='arrow_forward'
              size='large'
              onClick={goNext}
              tooltip='Next step'
            />
          </Stack>
        </Grid>
        <Grid item md={12}>
          <EnhancedTableBox
            headerStyle={{ backgroundColor: 'white', color: 'blue' }}
            heightPercentage='0.5'
            title='Eval'
            data={gridData}
            valueMember='gid'
            visibleColumns={visibleColumns}
            actions={actions}
            onAction={onAction}
          />
        </Grid>
      </Grid>
    </>
  )
}
export default EvalMatrix
