import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import ComboBox from '../../components/ComboBox'

import EnhancedTableBox from '../../components/EnhancedTableBox'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'


export default function Sites() {
    //begin - combo boxes selection states
    const [selectedZone, setSelectedZone] = useState()
    function onChangeZone(e) {
        setSelectedZone(e.id)
        setSelectedRegion()
        setSelectedProvince()
    }

    const [selectedRegion, setSelectedRegion] = useState()
    function onChangeRegion(e) {
        setSelectedRegion(e.cod_reg)
        setSelectedProvince()
    }

    const [selectedProvince, setSelectedProvince] = useState()
    function onChangeProvince(e) {
        setSelectedProvince(e.cod_prov)
    }

    const [selectedMunicipality, setSelectedMunicipality] = useState()
    function onChangeMunicipality(e) {
        setSelectedMunicipality(e.gid)
    }

    const [siteCode, setSiteCode] = useState()
    const [originalSiteCode, setOriginalSiteCode] = useState()
    const [siteName, setSiteName] = useState()
    const [address, setAddress] = useState()

    const [openPost, setOpenPost] = React.useState(false)

    const handleCloseCancel = () => {
        setOpenPost(false)
    }

    const editSchema = {
        site_code: {
            title: 'Site Code',
            type: 'text',
            md: 6,
        },
        original_site_code: {
            title: 'Original site Code',
            type: 'text',
            md: 6,
        },
        site_name: {
            title: 'Site Name',
            type: 'text',
            md: 6,
        },
        address: {
            title: 'Address',
            type: 'text',
            md: 6,
        },

    }

    const handleCloseSavePost = () => {


        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                "site_code": siteCode,
                "original_site_code": originalSiteCode,
                "site_name": siteName,
                "address": address,
                "id_municipality": selectedMunicipality
            }),
        }
        fetch("https://networkteam.it.nttdata-emea.com/api/scalar/office/site_info", requestOptions)

            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.status)
                } else return response.json()
            })
            .then((jsondata) => {
                if (jsondata.result === 1) {
                    if (jsondata.data.warnings) {
                        alert(`${jsondata.data.warnings}`)
                    }
                }
            })
            .catch((error) => {
                console.error(error)
            })
      
        setSiteCode(null)
        setSiteName(null)
        setOriginalSiteCode(null)
        setAddress(null)
    }



    //end - combo boxes selection states

    //array of the columns we want to show on the table
    const visibleColumns = [
        {
            id: 'site_code',
            alignRight: false,
            disablePadding: false,
            label: 'Site Code',
        },
        {
            id: 'original_site_code',
            alignRight: false,
            disablePadding: false,
            label: 'Original Site Code',
        },
        {
            id: 'site_name',
            alignRight: true,
            disablePadding: false,
            label: 'Site Name',
        },
        {
            id: 'address',
            alignRight: true,
            disablePadding: false,
            label: 'Address',
        },
    ]

    function handleAddNewSite() {
        setOpenPost(true)
    }

    //array of actions placed at the end of each row
    const actions = [
        {
            action: 'edit',
            icon: 'edit',
            tooltip: 'edit current row',
        },
        {
            action: 'delete',
            icon: 'delete',
            tooltip: 'delete current row',
        }
    ]

    function onChangeSiteCode(e) {
        setSiteCode(e.target.value)
    }

    function onChangeOriginalSiteCode(e) {
        setOriginalSiteCode(e.target.value)
    }

    function onChangeSiteName(e) {
        setSiteName(e.target.value)
    }

    function onChangeAddress(e) {
        setAddress(e.target.value)
    }



    return (
        <div>
            <Grid container spacing={5} padding={5}>
                <Grid item md={3}>
                    <ComboBox
                        label='Zone'
                        apiURL='https://networkteam.it.nttdata-emea.com/api/scalar/istat/zone'
                        valueMember='id'
                        displayMember='zone_name'
                        onSelectChange={onChangeZone}
                    // selectedValue=""
                    />
                </Grid>

                <Grid item md={3}>
                    <ComboBox
                        label='Region'
                        apiURL={`https://networkteam.it.nttdata-emea.com/api/scalar/istat/zona_regione?zone_id=${selectedZone}`}
                        valueMember='cod_reg'
                        displayMember='den_reg'
                        onSelectChange={onChangeRegion}
                    />
                </Grid>

                <Grid item md={3}>
                    <ComboBox
                        label='Province'
                        apiURL={`https://networkteam.it.nttdata-emea.com/api/scalar/istat/province?id=${selectedRegion}`}
                        valueMember='gid'
                        displayMember='den_pcm'
                        onSelectChange={onChangeProvince}
                    />
                </Grid>

                <Grid item md={3}>
                    <ComboBox
                        label='Comune'
                        apiURL={`https://networkteam.it.nttdata-emea.com/api/scalar/istat/comuni?id=${selectedProvince}`}
                        valueMember='gid'
                        displayMember='comune'
                        onSelectChange={onChangeMunicipality}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={5} padding={5}>
                <Grid item md={5}></Grid>
                <Grid item md={6}>
                    <Button variant='contained' onClick={handleAddNewSite}>ADD A NEW SITE</Button>
                </Grid>

                <Grid item md={12}>
                    <EnhancedTableBox
                        heightPercentage='0.5'
                        title='Sites'
                        onSelectChange={onChangeMunicipality}
                        apiURL={`https://networkteam.it.nttdata-emea.com/api/scalar/office/sites?id_municipality=${selectedMunicipality}`}
                        deleteURL={`https://networkteam.it.nttdata-emea.com/api/scalar/office/site_info`}
                        editURL={`https://networkteam.it.nttdata-emea.com/api/scalar/office/site_info`}
                        valueMember='id'
                        visibleColumns={visibleColumns}
                        editJsonSchema={editSchema}
                        actions={actions}
                    />
                </Grid>
            </Grid>

            <Dialog
                fullWidth
                maxWidth='lg'
                open={openPost}
                onClose={handleCloseCancel}
            >
                <DialogTitle>ADD A NEW SITE</DialogTitle>
                <DialogContent>

                    <Grid container spacing={5} padding={5}>
                        <Grid item md={4}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin='dense'
                                id='site_code'
                                label='Site Code'
                                variant='standard'
                                onChange={onChangeSiteCode}
                            />
                        </Grid>

                        <Grid item md={4}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin='dense'
                                id='og_site_code'
                                label='Original Site Code'
                                variant='standard'
                                onChange={onChangeOriginalSiteCode}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} padding={5}>

                        <Grid item md={4}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin='dense'
                                id='site_name'
                                label='Site Name'
                                variant='standard'
                                onChange={onChangeSiteName}
                            />
                        </Grid>

                        <Grid item md={4}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin='dense'
                                id='address'
                                label='Address'
                                variant='standard'
                                onChange={onChangeAddress}
                            />
                        </Grid>
                    </Grid>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCancel}>Cancel</Button>
                    <Button onClick={handleCloseSavePost}>Save</Button>
                </DialogActions>
            </Dialog>

        </div >
    )
}
