import React from 'react'
import EnhancedTableBox from '../../components/EnhancedTableBox'

export default function Flavio() {
  const visibleColumns = [
    {
      id: 'id',
      alignRight: false,
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'username',
      alignRight: false,
      disablePadding: false,
      label: 'UserName',
    },
    {
      id: 'email',
      alignRight: true,
      disablePadding: false,
      label: 'Email',
    },
  ]

  return (
    <div>
      <EnhancedTableBox
        heightPercentage='0.5'
        title='Users'
        multiselect
        // onSelectChange={onChangeMunicipality}
        apiURL={`http://127.0.0.1:8010/api/scalar/auth/users`}
        valueMember='id'
        visibleColumns={visibleColumns}
        // actions={actions}
        // onAction={onAction}
      />
    </div>
  )
}
