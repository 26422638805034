import { Box, Container } from "@mui/material";
import React, { useState } from "react";
import ReactJson from "react-json-view";
import JsonSchemaBox from "../../components/JsonSchemaBox";
import EnhancedTableBox from "../../components/EnhancedTableBox";

function Sky() {
  const schema = {
    textField: {
      title: "Text Field",
      type: "text",
      md: 4,
      tooltip: "Mandatory text field",
      mandatory: true,
      placeholder: "this is a placeholder",
      tab: "Text",
    },
    numericField: {
      title: "Numeric Field",
      type: "number",
      nonNegative: true,
      maxValue: 100,
      md: 4,
      tooltip: "Positive only numeric field with an admitted max value of 100",
      tab: "Text",
    },
    specificTextField: {
      title: "Specific Text Field",
      type: "text",
      md: 4,
      placeholder: "1,2,3",
      admittedChars: /^[0-9\,]+$/,
      tooltip: `Text field with regex that only accepts numbers and comma`,
      tab: "Text",
    },
    enumComboBox: {
      title: "Combo Box",
      type: "combo",
      md: 4,
      tab: "Lists",
    },
    multipleComboBox: {
      title: "Multiple Combo Box",
      type: "combo",
      md: 4,
      valueMember: "val",
      displayMember: "val",
      tooltip: `Dropdown box with moked data that allows multiple choises`,
      multiple: true,
      tab: "Lists",
    },
    listWithCheckboxes: {
      title: "List with Checkboxes",
      type: "list",
      multiselect: true,
      dense: true,
      md: 4,
      hasMokedData: true,
      valueMember: "val",
      displayMember: "val",
      tooltip: `A list that allows you to select one or more items`,
      tab: "Lists",
    },
    timeDatePicker: {
      type: "date",
      md: 4,
      title: "Time Date Picker",
      tab: "Others",
    },
    checkbox: {
      title: "Checkbox",
      type: "checkbox",
      md: 3,
      tab: "Others",
    },
  };

  const data = {
    textField: "Prova",
    numericField: 12,
    specificTextField: "0,2,4",
    enumComboBox: ["Foo", "Bar", "Baz"],
    multipleComboBox: [{ val: "Apple" }, { val: "Banana" }, { val: "Kiwi" }],
    listWithCheckboxes: [{ val: "Jeans" }, { val: "Shirt" }, { val: "Skirt" }],
    timeDatePicker: "2023-08-31 12:00:00",
    checkbox: true,
  };

  const columns = [
    {
      id: "id",
      alignRight: false,
      disablePadding: false,
      label: "Id",
    },
    {
      id: "name",
      alignRight: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "lastName",
      alignRight: false,
      disablePadding: false,
      label: "Last Name",
    },
    {
      id: "age",
      alignRight: false,
      disablePadding: false,
      label: "Age",
    },
    {
      id: "city",
      alignRight: false,
      disablePadding: false,
      label: "City",
    },
    {
      id: "registered",
      alignRight: false,
      disablePadding: false,
      label: "Registered",
      type: "datetime",
    },
  ];

  const table = [
    {
      id: 0,
      name: "Goofy",
      lastName: "",
      age: "68",
      city: "Disneyland",
      registered: "2023-06-08 08:22:10",
    },
    {
      id: 1,
      name: "Mikey",
      lastName: "Mouse",
      age: "55",
      city: "Disneyland",
      registered: "2023-06-09 18:20:23",
    },
    {
      id: 2,
      name: "Donald",
      lastName: "Duck",
      age: "58",
      city: "Disneyland",
      registered: "2023-06-05 22:12:16",
    },
  ];

  const actions = [
    {
      action: "edit",
      icon: "edit",
      tooltip: "Modify this row",
    },
    {
      action: "delete",
      icon: "delete",
      tooltip: "delete this row",
    },
  ];

  const tabs = ["Text", "Lists", "Others"];
  const [jsonSchema, setjsonSchema] = useState(schema);
  const [formData, setformData] = useState(data);
  const [visibleColumns, setvisibleColumns] = useState(columns);
  const [dataTable, setdataTable] = useState(table);

  return (
    <Container maxWidth="xl">
      <Box>
        <h2 style={{ textAlign: "center", color: "white" }}>Form Generation</h2>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <ReactJson
            name="JsonSchema"
            collapsed={true}
            theme="harmonic"
            src={jsonSchema}
            onEdit={(obj) => {
              setjsonSchema(obj.updated_src);
            }}
            onAdd={(obj) => {
              setjsonSchema(obj.updated_src);
            }}
            onDelete={(obj) => {
              setjsonSchema(obj.updated_src);
            }}
          ></ReactJson>
          <ReactJson
            name="FormData"
            collapsed={true}
            theme="harmonic"
            src={formData}
            onEdit={(obj) => {
              setformData(obj.updated_src);
            }}
            onAdd={(obj) => {
              setformData(obj.updated_src);
            }}
            onDelete={(obj) => {
              setformData(obj.updated_src);
            }}
          ></ReactJson>
        </Box>
        <Box>
          <JsonSchemaBox
            schema={jsonSchema}
            formData={formData}
            onChange={(data) => {
              console.log(data);
            }}
            tabs={tabs}
          />
        </Box>
      </Box>
      <Box sx={{ marginTop: "50px" }}>
        <h2 style={{ textAlign: "center", color: "white" }}>
          Table Management
        </h2>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <ReactJson
            name="TableSchema"
            collapsed={true}
            theme="harmonic"
            src={visibleColumns}
            onEdit={(obj) => {
              setvisibleColumns(obj.updated_src);
            }}
            onAdd={(obj) => {
              setvisibleColumns(obj.updated_src);
            }}
            onDelete={(obj) => {
              setvisibleColumns(obj.updated_src);
            }}
          ></ReactJson>
          <ReactJson
            name="DataTable"
            collapsed={true}
            theme="harmonic"
            src={dataTable}
            onEdit={(obj) => {
              setdataTable(obj.updated_src);
            }}
            onAdd={(obj) => {
              setdataTable(obj.updated_src);
            }}
            onDelete={(obj) => {
              setdataTable(obj.updated_src);
            }}
          ></ReactJson>
        </Box>
        <EnhancedTableBox
          heightPercentage="0.5"
          title="Disney"
          data={dataTable}
          valueMember="id"
          visibleColumns={visibleColumns}
          actions={actions}
          onAction={() => {}}
          search
        />
      </Box>
    </Container>
  );
}

export default Sky;
