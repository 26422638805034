import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import produce from 'immer'
import { set, has, startsWith } from 'lodash'
import { Grid } from '@mui/material'
import ListBox from '../../components/ListBox'
import JsonSchemaBox from '../../components/JsonSchemaBox'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import EnhancedIconButton from '../../components/EnhancedIconButton'
import { Box } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { TextField } from '@mui/material'


import useHttpService from '../../customHooks/useHttpService.js'
import { useConfirm } from 'material-ui-confirm'
import { v4 as uuidv4 } from 'uuid'

import { styled } from '@mui/system'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

const CommonListTitleBox = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '30px',
  paddingLeft: '7%',
  paddingRight: '7%',
  width: '115%',
})

function enhancedReducer(state, updateArg) {
  // check if the type of update argument is a callback function
  if (updateArg.constructor === Function) {
    return { ...state, ...updateArg(state) }
  }

  // if the type of update argument is an object
  if (updateArg.constructor === Object) {
    // does the update object have _path and _value as it's keys
    // if yes then use them to update deep object values
    if (has(updateArg, '_path') && has(updateArg, '_value')) {
      const { _path, _value } = updateArg

      return produce(state, (draft) => {
        set(draft, _path, _value)
      })
    } else {
      return { ...state, ...updateArg }
    }
  }
}

const Eval = () => {
  const { uuid } = useParams()
  const history = useHistory()
  const httpService = useHttpService()
  //state to trace data  loading
  const [dataLoaded, setDataLoaded] = useState(false)
  //main state for the whole page data
  const [formState, setFormState] = React.useReducer(enhancedReducer, {})
  const [antennaState, setAntennaState] = useState([])
  const [technologyState, setTechnologyState] = useState([])

  const [schema, setSchema] = React.useState({})

  const [tabs, setTabs] = useState([])
  const [formData, setFormData] = React.useState({})
  const [dialogData, setDialogData] = React.useState({})

  const [lastSelection, setLastSelection] = useState('SECTOR')
  const [lastUuid, setLastUuid] = useState('')
  const [lastDialogSelection, setLastDialogSelection] = useState('')

  const [openPost, setOpenPost] = React.useState(false)

  const [cableLoss, setCableLoss] = useState([])
  const [frequency, setFrequency] = useState([])

  const confirm = useConfirm()

  const handleCloseCancel = () => {
    switch (lastSelection) {
      // case 'SERVICECATEGORY':
      //     loadServiceCategoryData()
      //     break

      // case 'SERVICE':
      //     loadServiceData(selectedServiceCategory)
      //     break

      // case 'APPLICATION':
      //     loadApplicationData(selectedService)
      //     break

      // case 'PORT':
      //     loadPortData(selectedApplication)
      //     break

      default:
        break
    }
    setOpenPost(false)
  }

  const handleCloseSavePost = () => {
    switch (lastDialogSelection) {
      case 'SECTOR':
        dialogData.uuid = uuidv4()
        dialogData.antennas = []
        formState.sectors.push(dialogData)
        break

      case 'ANTENNA':
        formState.sectors.forEach((sector, sectorIndex) => {
          if (sector.uuid === lastUuid) {
            dialogData.uuid = uuidv4()
            dialogData.technologies = []
            formState.sectors[sectorIndex].antennas.push(dialogData)
            setFormState(formState)
          }
        })
        break

      case 'TECHNOLOGY':
        formState.sectors.forEach((sector, sectorIndex) => {
          for (
            var i = 0;
            i < formState.sectors[sectorIndex].antennas.length;
            i++
          ) {
            if (formState.sectors[sectorIndex].antennas[i].uuid === lastUuid) {
              dialogData.uuid = uuidv4()
              dialogData.arpaParameters = {}
              formState.sectors[sectorIndex].antennas[i].technologies.push(
                dialogData
              )
              setFormState(formState)
            }
          }
        })
        break

      default:
        break
    }
    setOpenPost(false)
  }

  function setSectorSchema() {
    setTabs(['Sector Properties'])
    setSchema({
      sector: {
        title: 'Sector',
        type: 'number',
      },
      azimuth: {
        title: 'Azimuth',
        type: 'number',
      },
    })
  }

  function setAntennaSchema() {
    setTabs(['Antenna Properties'])
    setSchema({
      hbaSuolo: {
        title: 'HBA',
        type: 'number',
      },
      downtiltMeccanico: {
        title: 'Mechanical DownTilt',
        type: 'number',
      },
      model: {
        title: 'Antenna Model',
        type: 'combo',
        url: `https://networkteam.it.nttdata-emea.com/api/scalar/hwcatalog/antenna_with_msi`,
        valueMember: 'antenna_model',
        displayMember: 'antenna_model',
        md: 3,
      },
    })
  }

  function setTechnologySchema() {
    setTabs(['Technology Properties'])
    setSchema({
      band: {
        title: 'Technology',
        type: 'combo',
        url: `https://networkteam.it.nttdata-emea.com/api/scalar/common/tech_to_freq`,
        valueMember: 'technology',
        displayMember: 'technology',
        md: 3,
      },
    })
  }

  function getfrequency() {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    fetch(
      `https://networkteam.it.nttdata-emea.com/api/scalar/common/tech_to_freq`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status)
        } else return response.json()
      })
      .then((freqdata) => {
        setFrequency(freqdata.data)
      })
      .catch((error) => {
        console.error(error)
        //localStorage.removeItem('token');
        //window.location.reload();
      })
  }

  function getcableloss() {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    fetch(
      `https://networkteam.it.nttdata-emea.com/api/scalar/rnp/cables`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status)
        } else return response.json()
      })
      .then((cabledata) => {
        setCableLoss(cabledata.data)
      })
      .catch((error) => {
        console.error(error)
        //localStorage.removeItem('token');
        //window.location.reload();
      })
  }

  // }
  // useEffect(() => {
  //     onDataChange(formData);
  // }, [cableLoss,frequency,formData]);

  useEffect(() => {
    getfrequency()
    getcableloss()
    httpService.getAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/eval/eval?uuid=${uuid}`,
      onDataLoaded
    )
  }, [])

  function onDataLoaded(data) {
    data[0].sectors.forEach((sector, sectorIndex) => {
      sector.uuid = sector.uuid || uuidv4()
      sector.antennas.forEach((antenna, antennaIndex) => {
        antenna.uuid = antenna.uuid || uuidv4()
        antenna.technologies.forEach((technology, technologyIndex) => {
          technology.uuid = technology.uuid || uuidv4()
        })
      })
    })

    //setting main state
    setFormState(data[0])
    setDataLoaded(true)
  }

  function onSectorChange(e) {
    setLastSelection('SECTOR')
    setLastUuid(e.uuid)
    setAntennaState(e.antennas)
    setFormData()
    setFormData(e)
    setSectorSchema()
    setTabs(['Sector Properties'])
    setSchema({
      sector: {
        tab: 'Sector Properties',
        title: 'Sector',
        type: 'number',
      },
      azimuth: {
        tab: 'Sector Properties',
        title: 'Azimuth',
        type: 'number',
      },
    })
  }

  function onAntennaChange(e) {
    setLastSelection('ANTENNA')
    setLastUuid(e.uuid)
    setTechnologyState(e.technologies)
    setFormData()
    setFormData(e)
    setAntennaSchema()
    setTabs(['Antenna Properties'])
    setSchema({
      hbaSuolo: {
        tab: 'Antenna Properties',
        title: 'HBA',
        type: 'number',
      },
      downtiltMeccanico: {
        tab: 'Antenna Properties',
        title: 'Mechanical DownTilt',
        type: 'number',
      },
    })
  }

  function onTechnologyChange(e) {
    let freq
    setLastSelection('TECHNOLOGY')
    setLastUuid(e.uuid)
    setFormData()
    setFormData(e)
    setTabs(['Equipment Properties', 'Electrical Properties'])

    frequency.forEach((tech) => {
      if (tech.technology === e.band) freq = tech.nominal_frequency
    })
    setSchema({
      downtiltElettrico: {
        tab: 'Equipment Properties',
        title: 'Electrical DownTilt',
        type: 'number',
        md: 2,
      },
      cavoRFTipo: {
        tab: 'Equipment Properties',
        title: 'Cable Type',
        type: 'combo',
        url: `https://networkteam.it.nttdata-emea.com/api/scalar/rnp/cables?frequency=${freq}`,
        valueMember: 'cable',
        displayMember: 'cable',
        md: 2,
      },
      cavoRFLunghezza: {
        tab: 'Equipment Properties',
        title: 'Cable Length',
        type: 'number',
        md: 2,
      },
      bandwidth: {
        tab: 'Equipment Properties',
        title: 'Bandwidth',
        type: 'number',
        md: 2,
      },
      alloggiamento: {
        tab: 'Equipment Properties',
        title: 'Cabinet Type',
        type: 'string',
        enum: ['Outdoor', 'Indoor'],
        md: 2,
      },

      attenSw: {
        tab: 'Electrical Properties',
        title: 'SW Attenuation',
        type: 'number',
        md: 1.5,
      },
      guadagno: {
        tab: 'Electrical Properties',
        title: 'Gain',
        type: 'number',
        decimals: 2,
        // sx: { backgroundColor: "red" },
        md: 1,
      },
      'arpaParameters.nTrx': {
        tab: 'Equipment Properties',
        title: 'Number of TX',
        type: 'number',
        md: 2,
      },
      splitter: {
        tab: 'Equipment Properties',
        title: 'Splitter',
        type: 'string',
        enum: [
          '2-way Splitter',
          '3-way Splitter',
          '4-way Splitter',
          'Diplexer',
          'NO',
        ],
        md: 2,
      },

      perditaSplitter: {
        tab: 'Electrical Properties',
        title: 'Splitter Losses',
        type: 'number',
        disabled: true,
        md: 1.5,
      },

      bts: {
        tab: 'Equipment Properties',
        title: 'BTS',
        type: 'string',
        md: 2,
      },

      ru: {
        tab: 'Equipment Properties',
        title: 'RU',
        type: 'string',
        md: 2,
      },

      potPilot: {
        tab: 'Electrical Properties',
        title: 'Pot. Pilot (dBm)',
        type: 'number',
        disabled: true,
        md: 1.5,
      },

      potMax: {
        tab: 'Electrical Properties',
        title: 'Pot. Max (dBm)',
        type: 'number',
        md: 1.5,
      },

      perditeCaviRF: {
        tab: 'Electrical Properties',
        title: 'Cable Loss (dB)',
        type: 'number',
        disabled: true,
        md: 1.5,
      },

      perditeTotali: {
        tab: 'Electrical Properties',
        title: 'Total Loss (dB)',
        type: 'number',
        disabled: true,
        md: 1.5,
      },

      eirp: {
        tab: 'Electrical Properties',
        title: 'EIRP(dBm)',
        type: 'number',
        disabled: true,
        md: 1.5,
      },

      'arpaParameters.fattoreRiduzione': {
        tab: 'Electrical Properties',
        title: 'Reduction Factor',
        type: 'string',
        enum: e.band.startsWith('GSM') ? ['NO', 'DTX/PC'] : ['NO', 'Alfa 24'],
        md: 2,
      },

      'arpaParameters.alfa24': {
        tab: 'Electrical Properties',
        title: 'Alfa 24',
        type: 'number',
        decimals: 2,
        disabled:
          e.band.startsWith('GSM') ||
          e.arpaParameters.fattoreRiduzione === 'N0',
        md: 2,
      },

      'arpaParameters.potenzaTRXApparatodBm': {
        tab: 'Electrical Properties',
        title: 'TRX Power (dBm)',
        type: 'number',
        disabled: true,
        md: 2.5,
      },

      'arpaParameters.potenzaTRXApparato': {
        tab: 'Electrical Properties',
        title: 'TRX Power (W)',
        type: 'number',
        disabled: true,
        md: 2,
      },

      'arpaParameters.potenzaTotaleApparatodBm': {
        tab: 'Electrical Properties',
        title: 'Total TX Power (dBm)',
        type: 'number',
        disabled: true,
        md: 2,
      },

      'arpaParameters.potenzaTotaleApparatoW': {
        tab: 'Electrical Properties',
        title: 'Total TX Power (W)',
        type: 'number',
        disabled: true,
        md: 2,
      },

      'arpaParameters.potenzaTotAntennaWNoriduzione': {
        tab: 'Electrical Properties',
        title: 'Total Power at Antenna (W)',
        type: 'number',
        disabled: true,
        md: 2,
      },

      'arpaParameters.potenzaTotAntennadBmNoriduzione': {
        tab: 'Electrical Properties',
        title: 'Total Power at Antenna (dBm)',
        type: 'number',
        disabled: true,
        md: 2,
      },

      'arpaParameters.potenzaTotAntennaRiduzione': {
        tab: 'Electrical Properties',
        title: 'Total Power at Antenna with Reduction (W)',
        type: 'number',
        disabled: true,
        md: 2.5,
      },
    })
  }

  function onDialogDataChange(data) {
    setDialogData(data)
  }

  function onDataChange(data) {
    //formData is OLD, data is NEW
    if (data !== {}) {
      switch (lastSelection) {
        case 'SECTOR':
          formState.sectors.forEach((sector, sectorIndex) => {
            if (sector.uuid === lastUuid) {
              formState.sectors[sectorIndex] = data
              setFormState(formState)
            }
          })
          break

        case 'ANTENNA':
          formState.sectors.forEach((sector, sectorIndex) => {
            sector.antennas.forEach((antenna, antennaIndex) => {
              if (antenna.uuid === lastUuid) {
                formState.sectors[sectorIndex].antennas[antennaIndex] = data
                setFormState(formState)
              }
            })
          })
          break

        case 'TECHNOLOGY':
          if (data.arpaParameters.fattoreRiduzione !== 'Alfa 24') {
            data.arpaParameters.alfa24 = 1.0
          }

          if (data.arpaParameters.alfa24 > 1) {
            data.arpaParameters.alfa24 = 1.0
          }

          if (data.arpaParameters.alfa24 < 0) {
            data.arpaParameters.alfa24 = 0.0
          }

          if (data.splitter === '2-way Splitter') {
            data.perditaSplitter = 3
          } else if (data.splitter === '3-way Splitter') {
            data.perditaSplitter = 4.8
          } else if (data.splitter === '4-way Splitter') {
            data.perditaSplitter = 6
          } else if (data.splitter === 'Diplexer') {
            data.perditaSplitter = 0.5
          } else if (data.splitter === 'NO') {
            data.perditaSplitter = 0
          }

          if (data.band) {
            if (data.band.startsWith('UMTS')) {
              data.bandwidth = 5
            } else if (data.band.startsWith('GSM')) {
              data.bandwidth = 0.2
            } else if (data.band === 'LTE800') {
              data.bandwidth = 10
            } else if (data.band === 'LTE2600') {
              data.bandwidth = 15
            }

            if (data.band.startsWith('LTE') || data.band.startsWith('5G')) {
              data.potPilot = data.potMax - 10 * Math.log10(60 * data.bandwidth)
            } else if (data.band.startsWith('UMTS')) {
              data.potPilot = data.potMax - 10
            } else if (data.band.startsWith('GSM')) {
              data.potPilot = data.potMax
            }
          }

          data.arpaParameters.potenzaTRXApparatodBm = data.potMax - data.attenSw
          data.arpaParameters.potenzaTRXApparato =
            Math.pow(10, data.arpaParameters.potenzaTRXApparatodBm / 10) / 1000

          let freq
          frequency.forEach((tech) => {
            if (tech.technology === data.band) freq = tech.nominal_frequency
          })

          let cableLossValue
          cableLoss.forEach((cable) => {
            if (cable.cable === data.cavoRFTipo && cable.frequency === freq)
              cableLossValue = cable.loss
          })
          data.perditeCaviRF = (data.cavoRFLunghezza / 100) * cableLossValue
          data.perditeTotali = data.perditaSplitter + data.perditeCaviRF
          data.arpaParameters.potenzaTotaleApparatoW =
            data.arpaParameters.potenzaTRXApparato * data.arpaParameters.nTrx
          data.arpaParameters.potenzaTotaleApparatodBm =
            data.potMax + 10 * Math.log10(data.arpaParameters.nTrx)
          data.arpaParameters.potenzaTotAntennadBmNoriduzione =
            data.arpaParameters.potenzaTotaleApparatodBm - data.perditeTotali
          data.arpaParameters.potenzaTotAntennaWNoriduzione =
            Math.pow(
              10,
              data.arpaParameters.potenzaTotAntennadBmNoriduzione / 10
            ) / 1000
          data.eirp =
            data.arpaParameters.potenzaTRXApparatodBm -
            data.perditeTotali +
            data.guadagno

          if (data.band) {
            if (data.band.startsWith('GSM')) {
              if (data.arpaParameters.fattoreRiduzione === 'DTX/PC') {
                data.arpaParameters.potenzaTotAntennaRiduzione =
                  (data.arpaParameters.potenzaTotAntennaWNoriduzione /
                    data.arpaParameters.nTrx) *
                  (1 + 0.49 * (data.arpaParameters.nTrx - 1))
              } else if (data.arpaParameters.fattoreRiduzione === 'NO') {
                data.arpaParameters.potenzaTotAntennaRiduzione =
                  data.arpaParameters.potenzaTotAntennaWNoriduzione
              }
            } else {
              data.arpaParameters.potenzaTotAntennaRiduzione =
                data.arpaParameters.potenzaTotAntennaWNoriduzione *
                data.arpaParameters.alfa24
            }
          }

          data.arpaParameters.potenzaTotAntennaRiduzionedBm =
            10 *
            Math.log10(1000 * data.arpaParameters.potenzaTotAntennaRiduzione)

          formState.sectors.forEach((sector, sectorIndex) => {
            sector.antennas.forEach((antenna, antennaIndex) => {
              antenna.technologies.forEach((technology, technologyIndex) => {
                if (technology.uuid === lastUuid) {
                  formState.sectors[sectorIndex].antennas[
                    antennaIndex
                  ].technologies[technologyIndex] = data
                  setFormState(formState)
                }
              })
            })
          })
          break

        default:
          break
      }
    }
  }

  function onDataSaved(data) {}

  const handleClickOpenPost = (e, selection) => {
    setLastDialogSelection(selection)
    switch (selection) {
      case 'SECTOR':
        setSectorSchema()
        break
      case 'ANTENNA':
        if (lastSelection === 'SECTOR') {
          setAntennaSchema()
        } else {
          alert('no Sector selected')
          return
        }
        break
      case 'TECHNOLOGY':
        if (lastSelection === 'ANTENNA') {
          setTechnologySchema()
        } else {
          alert('no Antenna selected')
          return
        }
        break
      default:
        break
    }
    setOpenPost(true)
  }

  function handleSave() {
    confirm({ description: `Do you want to save the current changes?` }).then(
      () => {
        httpService.putAPI(
          'https://networkteam.it.nttdata-emea.com/api/scalar/eval/eval',
          formState,
          onDataSaved
        )
      }
    )
  }

  function handleDelete() {
    confirm({
      description: `Do you want to delete the current technology?`,
    }).then(() => {
      switch (lastSelection) {
        case 'SECTOR':
          for (var i = 0; i < formState.sectors.length; i++) {
            if (formState.sectors[i].uuid === lastUuid) {
              formState.sectors.splice(i, 1)
              setFormState(formState)
            }
          }
          break

        case 'ANTENNA':
          formState.sectors.forEach((sector, sectorIndex) => {
            for (
              var i = 0;
              i < formState.sectors[sectorIndex].antennas.length;
              i++
            ) {
              if (
                formState.sectors[sectorIndex].antennas[i].uuid === lastUuid
              ) {
                formState.sectors[sectorIndex].antennas.splice(i, 1)
                setFormState(formState)
              }
            }
          })
          break

        case 'TECHNOLOGY':
          formState.sectors.forEach((sector, sectorIndex) => {
            sector.antennas.forEach((antenna, antennaIndex) => {
              for (
                var i = 0;
                i <
                formState.sectors[sectorIndex].antennas[antennaIndex]
                  .technologies.length;
                i++
              ) {
                if (
                  formState.sectors[sectorIndex].antennas[antennaIndex]
                    .technologies[i].uuid === lastUuid
                ) {
                  formState.sectors[sectorIndex].antennas[
                    antennaIndex
                  ].technologies.splice(i, 1)
                  setFormState(formState)
                }
              }
            })
          })
          break

        default:
          break
      }
      httpService.putAPI(
        'https://networkteam.it.nttdata-emea.com/api/scalar/eval/eval',
        formState,
        onDataSaved
      )
    })
  }

  function goBack() {
    history.push(
      `${process.env.REACT_APP_CLIENT_PATH_NAME}/evalMatrix/${formState.id}`
    )
  }

  if (dataLoaded) {
    return (
      <div>
        <Grid container spacing={3} padding={3}>
          <Grid item md={1}>
            <EnhancedIconButton
              icon='arrow_back'
              size='large'
              onClick={goBack}
              tooltip='Back to eval matrix'
            />
          </Grid>
          <Grid item md={11}></Grid>
          <Grid item md={3}>
            <Paper elevation={3} sx={{ height: '35vh', overflow: 'auto' }}>
              <Grid container spacing={1}>
                <CommonListTitleBox
                  sx={{
                    backgroundColor: 'background.light',
                    color: 'primary.light',
                  }}
                >
                  <Typography variant='h5' color='secondary'>
                    Sectors
                  </Typography>
                  <EnhancedIconButton
                    icon='add_circle'
                    size='large'
                    onClick={(e) => handleClickOpenPost(e, 'SECTOR')}
                    //color='primary'
                    tooltip='Add new sector'
                    sx={{
                      backgroundColor: 'background.light',
                      color: 'primary.light',
                    }}
                  />
                </CommonListTitleBox>
                <ListBox
                  data={formState.sectors}
                  valueMember='azimuth'
                  displayMember='azimuth'
                  onSelectChange={onSectorChange}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={3}>
            <Paper elevation={3} sx={{ height: '35vh', overflow: 'auto' }}>
              <Grid container spacing={1}>
                <CommonListTitleBox
                  sx={{
                    backgroundColor: 'background.light',
                    color: 'primary.light',
                  }}
                >
                  <Typography variant='h5' color='secondary'>
                    Antennas
                  </Typography>
                  <EnhancedIconButton
                    icon='add_circle'
                    size='large'
                    onClick={(e) => handleClickOpenPost(e, 'ANTENNA')}
                    //color='primary'
                    tooltip='Add new antenna'
                    sx={{
                      backgroundColor: 'background.light',
                      color: 'primary.light',
                    }}
                  />
                </CommonListTitleBox>
                <ListBox
                  data={antennaState}
                  valueMember='model'
                  displayMember='model'
                  onSelectChange={onAntennaChange}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={3}>
            <Paper elevation={3} sx={{ height: '35vh', overflow: 'auto' }}>
              <Grid container spacing={1}>
                <CommonListTitleBox
                  sx={{
                    backgroundColor: 'background.light',
                    color: 'primary.light',
                  }}
                >
                  <Typography variant='h5' color='secondary'>
                    Technologies
                  </Typography>
                  <EnhancedIconButton
                    icon='add_circle'
                    size='large'
                    onClick={(e) => handleClickOpenPost(e, 'TECHNOLOGY')}
                    //color='primary'
                    tooltip='Add new technology'
                    sx={{
                      backgroundColor: 'background.light',
                      color: 'primary.light',
                    }}
                  />
                </CommonListTitleBox>
                <ListBox
                  data={technologyState}
                  valueMember='band'
                  displayMember='band'
                  onSelectChange={onTechnologyChange}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={12}>
            <Paper elevation={3}>
              <Box sx={{ padding: '1%' }}>
                <JsonSchemaBox
                  schema={schema}
                  formData={formData}
                  onChange={onDataChange}
                  tabs={tabs}
                />
                <Box sx={{ textAlign: 'right' }}>
                  <EnhancedIconButton
                    icon='save'
                    size='large'
                    onClick={handleSave}
                    color='primary'
                    tooltip='Save the selected item'
                  />
                  <EnhancedIconButton
                    icon='delete'
                    size='large'
                    onClick={handleDelete}
                    color='primary'
                    tooltip='Delete the selected item'
                  />
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Dialog
          fullWidth
          maxWidth='lg'
          open={openPost}
          onClose={handleCloseCancel}
        >
          <DialogTitle>Add new</DialogTitle>
          <DialogContent>
            <JsonSchemaBox schema={schema} onChange={onDialogDataChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancel}>Cancel</Button>
            <Button onClick={handleCloseSavePost}>Add</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  } else return <div></div>
}
export default Eval
