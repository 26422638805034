import { v4 as uuidv4 } from 'uuid'
import { cardMain as cardMainRAN } from '../template/templateRAN-Main'

const onDataChange = () => {}
const doNothing = () => {}
const saveData = () => {}

const addDisabledToSchemaFields = () => {
  let tmp = JSON.parse(JSON.stringify(cardMainRAN.schema.fields))
  for (const k in tmp) {
    tmp[k].disabled = true
    tmp[k].type = 'string'
    if (tmp[k].displayMember) delete tmp[k].displayMember
    if (tmp[k].valueMember) delete tmp[k].valueMember
    if (tmp[k].url) delete tmp[k].url
  }
  return tmp
}

const cardMainActions = [
  {
    icon: 'book',
    place: 'top',
    label: 'Policy (parameters)',
    type: 'dialog',
    onClick: doNothing,
    uniqueKey: uuidv4(),
    dialog: {
      // url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/RANSliceSubnetProfileInstance?id=`,
      url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSliceSubnetHistory`,
      title: 'Set Policy Parameters',
      style: {
        card: {},
        title: {
          marginTop: '30px',
          backgroundColor: 'background.light',
          paddingLeft: '6%',
          paddingRight: '6%',
          color: 'primary.light',
          width: '100%',
        },
        content: {},
        actions: {},
      },
      schemaFields: addDisabledToSchemaFields(),
      tabs: ['General', 'Positioning', 'User Related Data'],
      onDataChange: doNothing,
      buttons: [{ type: 'close' }],
      dataAlreadyPresent: true,
    },
  },
  {
    icon: 'delete',
    iconSize: 'large',
    place: 'bottom',
    onClick: doNothing,
    type: 'icon',
    tooltip: 'Delete',
    uniqueKey: uuidv4(),
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSliceSubnetHistory`,
  },
  {
    icon: 'restore',
    iconSize: 'large',
    place: 'bottom',
    type: 'icon',
    onClick: doNothing,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSliceSubnet`,
    messages: {
      // url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_scheduler/Schedule`,
      messageList: [
        {
          uniqueKey: uuidv4(),
          name: '',
          scheduled: '',
          httpMethod: 'POST',
          url: `${process.env.REACT_APP_QUEUE_ADDRESS}/celery/push/OPERMOI/modifyMOIattributes`,
          body: {
            Name: 'modifyMOIattributes',
            moi_class: 'NSSI',
            moi_id: null,
            Command: 'modifyMOIattributes',
          },
        },
        {
          uniqueKey: uuidv4(),
          name: '',
          scheduled: '',
          httpMethod: 'POST',
          url: `${process.env.REACT_APP_QUEUE_ADDRESS}/celery/push/NOTIFMOI/notifyMOIattributeValueChanges`,
          body: {
            Name: 'notifyMOIattributeValueChanges',
            moi_class: 'NSSI',
            moi_id: null,
            Command: 'notifyMOIattributeValueChanges',
          },
        },
      ],
    },
    tooltip: 'Restore this NSSI',
    confirm: true,
  },
]

const schemaFields = {
  administrativeState: {
    title: 'Administrative State',
    type: 'text',
    md: 3,
    disabled: true,
  },
  operationalState: {
    title: 'Operational State',
    type: 'text',
    md: 3,
    disabled: true,
  },
  name: {
    title: 'NSSI Name',
    type: 'string',
    md: 12,
    disabled: true,
  },
  RANSliceSubnetProfile: {
    title: 'NSST',
    type: 'string',
    md: 6,
    // url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/RANSliceSubnetProfile`,
    // valueMember: 'id',
    // displayMember: 'name',
    disabled: true,
  },
  PlmnId: {
    title: 'PLMN',
    type: 'string',
    md: 6,
    // url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/PlmnId`,
    // valueMember: 'id',
    // displayMember: 'name',
    disabled: true,
  },
  NetworkSlices: {
    title: 'NSI',
    type: 'list',
    md: 6,
    dense: true,
    baseUrl: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSlice`,
    valueMember: 'id',
    displayMember: 'name',
    disabled: true,
  },
  NF: {
    title: 'NF',
    type: 'list',
    md: 6,
    dense: true,
    baseUrl: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkFunction`,
    valueMember: 'id',
    displayMember: 'name',
    disabled: true,
  },

  id: {
    title: 'NSSID',
    type: 'string',
    md: 12,
    disabled: true,
  },
}

const schemaValues = {
  administrativeState: 'Unlocked',
  operationalState: 'Enabled',
  name: 'MIoT Milano',
  id: 'NSSIDx',
  NSST: 'NSSTIDx',
  PLMN: 'PLMN2',
  NSI: 'NSIDc',
  NF: 'NFIDa',
}

const cardBoxStyleMain = {
  cardStyle: {
    width: '65vw',
    height: '75vh',
    backgroundColor: 'background.dark',
  },
  titleStyle: {
    marginTop: '30px',
    backgroundColor: 'background.light',
    paddingLeft: '6%',
    paddingRight: '6%',
    color: 'primary.light',
    width: '102%',
  },
  iconStyle: {
    topBox: {
      // border: '1px solid red',
    },
    bottomBox: {
      // border: '1px solid green',
    },
  },
}

const titleDataMain = {
  variant: 'h4',
}

const cardMain = {
  actions: cardMainActions,
  schema: {
    fields: schemaFields,
    values: schemaValues,
  },
  onDataChange,
  style: cardBoxStyleMain,
  title: titleDataMain,
}

export default cardMain
