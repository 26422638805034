import { v4 as uuidv4 } from 'uuid'

const doNothing = () => {}
const handleTextClick = () => {}

const apiDataToShow = ['name', 'id'] //we show the name of the item in the list if it setted, otherwise we show id
const dataTemplate = {
  content: '',
  onTextClick: handleTextClick,
  onIconClick: doNothing,
  leftIcons: [
    {
      icon: 'arrow_right',
      iconSize: 'large',
      infos: {},
      uniqueKey: uuidv4(),
    },
  ],
  rightIcons: [
    // {
    //   icon: 'delete',
    //   content: '',
    //   size: 'small',
    //   type: 'delete',
    //   tooltip: 'delete this nssi',
    //   url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSliceSubnet`,
    //   uniqueKey: uuidv4(),
    //   messages: [
    //     {
    //       uniqueKey: uuidv4(),
    //       url: `${process.env.REACT_APP_QUEUE_ADDRESS}/celery/push/OPERMOI/modifyMOIattributes`,
    //       parameters: {
    //         Name: 'modifyMOIattributes',
    //         moi_class: 'NSSI',
    //         moi_id: null,
    //         Command: 'modifyMOIattributes',
    //       },
    //     },
    //     {
    //       uniqueKey: uuidv4(),
    //       url: `${process.env.REACT_APP_QUEUE_ADDRESS}/celery/push/NOTIFMOI/notifyMOIdeletion`,
    //       parameters: {
    //         Name: 'notifyMOIdeletion',
    //         moi_class: 'NSSI',
    //         moi_id: null,
    //         Command: 'notifyMOIdeletion',
    //       },
    //     },
    //   ],
    // },
  ],
}

const api = `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSliceSubnet_short`
const apiSpecificItem = `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSliceSubnet?id=`

const cardBoxStyleList = {
  cardStyle: {
    width: '20vw',
    height: '75vh',
  },
  textItemStyle: { marginRight: '20px', marginLeft: '20px' },
  titleStyle: {
    marginTop: '30px',
    backgroundColor: 'background.light',
    paddingLeft: '7%',
    paddingRight: '7%',
    color: 'primary.light',
    width: '105%',
  },
}

const titleDataList = {
  title: 'List of NSSI',
  variant: 'h4',
}

export const list = {
  api,
  apiSpecificItem,
  apiDataToShow,
  dataTemplate,
  cardBoxStyleList,
  titleDataList,
}
