import React, { useState } from 'react';
import { Grid } from '@mui/material';
import ComboBox from '../../components/ComboBox';
import EnhancedTableBox from '../../components/EnhancedTableBox';
import EnhancedIconButton from '../../components/EnhancedIconButton';
import JsonSchemaBox from '../../components/JsonSchemaBox';
import { Button } from '@mui/material';

const Discovery = () => {
	const [filters, setFilters] = React.useState({});
	const [myUrl, setMyUrl] = React.useState(``);

	function buttonClick() {
		setMyUrl(``);
	}

	const jsonSchema = {
		ip: {
			title: 'IP',
			type: 'text',
			md: 3,
		},
		port: {
			title: 'Port',
			type: 'text',
			md: 3,
		},
	};

	return (
		<div>
			<Grid container spacing={5} padding={5}>
				<JsonSchemaBox schema={jsonSchema} />
				<Grid md={6}></Grid>
				<Grid>
					<Button variant='outlined' onClick={buttonClick}>
						Discover
					</Button>
				</Grid>
			</Grid>
		</div>
	);
};
export default Discovery;
