import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { Grid } from '@mui/material'
import JsonSchemaBox from '../../components/JsonSchemaBox'
import Paper from '@mui/material/Paper'

import EnhancedIconButton from '../../components/EnhancedIconButton'

import useHttpService from '../../customHooks/useHttpService.js'

import { Box } from '@mui/material'

import { useParams } from 'react-router-dom'



const ManualTask = () => {
    const pathParams = useParams()

    //state to trace data  loading
    const [dataLoaded, setDataLoaded] = useState(false)


    //states for json form
    const [tabs, setTabs] = useState([])
    const [formData, setFormData] = React.useState({})
    const [schema, setSchema] = React.useState({})
    const [editedFormData, setEditedFormData] = React.useState({})
    const httpService = useHttpService()




    const loadManualTaskData = useCallback(async () => {
        httpService.getAPI(
            `${process.env.REACT_APP_API_ADDRESS}/ngom_latest/workflow/manual_tasks?id=${pathParams.id}`,
            onManualTaskData
        )
    }, []);


    function onManualTaskData(jsondata) {
        console.log(jsondata)
        // setFormData({})
        // setTabs([])
        // setSchema({})
        setFormData(jsondata[0].json_in)
        //setTabs([jsondata[0].run_id])
        setSchema(jsondata[0].json_schema)
        setEditedFormData({});
        setDataLoaded(true)
    }



    useEffect(() => {
        document.title = 'Manual Task';
        loadManualTaskData()
    }, [])







    function onDataChange(data) {
        //formData is OLD, data is NEW
        setEditedFormData(data)
    }

    function handleCloseCancel() {
        window.open("about:blank", "_self");
        window.close();
    }

    function handleSave() {
        httpService.putAPI(
            `${process.env.REACT_APP_API_ADDRESS}/ngom_latest/workflow/manual_tasks`,
            {
                id: pathParams.id,
                json_out: { ...editedFormData }
            },
            handleCloseCancel
        );

    }

    if (dataLoaded)
        return (
            <div>
                <Grid container spacing={3} padding={3}>
                    <Grid item md={12}>
                        <Paper elevation={3}>
                            <Box sx={{ padding: '1%' }}>
                                <JsonSchemaBox
                                    schema={schema}
                                    formData={formData}
                                    onChange={onDataChange}
                                    tabs={tabs}
                                />
                                <Box sx={{ textAlign: 'right' }}>
                                    <EnhancedIconButton
                                        icon='play_arrow'
                                        size='large'
                                        onClick={handleSave}
                                        color='primary'
                                        tooltip='Go ahead with the workflow'
                                    />

                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>

            </div>
        )
    else return <div></div>
}
export default ManualTask
