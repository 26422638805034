import React from 'react'
import EnhancedTableBox from '../../components/EnhancedTableBox'
import TextFieldCombo from '../../components/TextFieldCombo'
import { Grid } from '@mui/material'
import ListBox from '../../components/ListBox'

const c = () => {
  const j = [
    {
      gid: 2888,
      cod_rip: 1,
      cod_reg: 7,
      cod_prov: 9,
      cod_cm: 0,
      cod_pcm: 9,
      pro_com: 9001,
      pro_com_t: '009001',
      comune: 'Alassio',
      comune_a: null,
      cc_p_cm: 0,
      shape_leng: 25306.364691,
      shape_area: 17247630.4178,
      shape_len: 25306.364691,
      boundary: {
        type: 'Polygon',
        coordinates: [
          [
            [8.12329567680787, 43.9878366478515],
            [8.1225934401502, 44.035483538975],
            [8.20729197728061, 44.0361026124436],
            [8.20792644325207, 43.9884546988289],
            [8.12329567680787, 43.9878366478515],
          ],
        ],
      },
    },
    {
      gid: 2967,
      cod_rip: 1,
      cod_reg: 7,
      cod_prov: 9,
      cod_cm: 0,
      cod_pcm: 9,
      pro_com: 9002,
      pro_com_t: '009002',
      comune: 'Albenga',
      comune_a: null,
      cc_p_cm: 0,
      shape_leng: 40678.0984058,
      shape_area: 36575145.7291,
      shape_len: 40678.0984058,
      boundary: {
        type: 'Polygon',
        coordinates: [
          [
            [8.10013769442565, 44.0202925245705],
            [8.09894448029656, 44.099015284547],
            [8.22746600832003, 44.0999566824759],
            [8.22848907364875, 44.0212313550613],
            [8.10013769442565, 44.0202925245705],
          ],
        ],
      },
    },
    {
      gid: 2131,
      cod_rip: 1,
      cod_reg: 7,
      cod_prov: 9,
      cod_cm: 0,
      cod_pcm: 9,
      pro_com: 9004,
      pro_com_t: '009004',
      comune: 'Albisola Superiore',
      comune_a: null,
      cc_p_cm: 0,
      shape_leng: 29753.0985924,
      shape_area: 28677075.4661,
      shape_len: 29753.0985924,
      boundary: {
        type: 'Polygon',
        coordinates: [
          [
            [8.41232523532091, 44.3255249495288],
            [8.41162693603365, 44.395337971592],
            [8.52473475328157, 44.3958646180707],
            [8.52529882648171, 44.3260503224494],
            [8.41232523532091, 44.3255249495288],
          ],
        ],
      },
    },
  ]

  //array of the columns we want to show on the table
  const visibleColumns = [
    {
      id: 'comune',
      alignRight: false,
      disablePadding: false,
      label: 'Municipality',
    },
    {
      id: 'pro_com_t',
      alignRight: true,
      disablePadding: false,
      label: 'procomT',
    },
  ]

  //array of actions placed at the end of each row
  const actions = [
    {
      action: 'edit',
      icon: 'edit',
      tooltip: 'edit current row',
    },
    {
      action: 'clone',
      icon: 'copy_all',
      tooltip: 'clone current row',
    },
    {
      action: 'delete',
      icon: 'delete',
      tooltip: 'delete current row',
    },
    {
      action: 'options',
      icon: 'more_vert',
    },
  ]

  //event that is fired when an action is clicked
  function onAction(action, row, e) {}

  function onCheckChange(e) {
    alert(JSON.stringify(e))
  }

  var region = 20

  return (
    <div>
      <h1>TEST App</h1>
      <p>This is c page</p>

      <Grid container spacing={5} padding={5}>
        <Grid item md={3}>
          <ListBox
            // label="Regione"
            //apiURL="https://networkteam.it.nttdata-emea.com/api/scalar/istat/zona_regione"
            data={j}
            valueMember='gid'
            displayMember='comune'
            multiselect
            checkedValues={[2131]}
            onCheckChange={onCheckChange}
          />
        </Grid>
        <Grid item md={3}>
          <TextFieldCombo
            label='Regione'
            apiURL='https://networkteam.it.nttdata-emea.com/api/scalar/istat/zona_regione'
            valueMember='cod_reg'
            displayMember='den_reg'
            selectedValue={region}
          />
        </Grid>
        <Grid item md={12}>
          <EnhancedTableBox
            headerStyle={{ backgroundColor: 'white', color: 'blue' }}
            heightPercentage='0.5'
            title='Municipalities'
            data={j}
            valueMember='gid'
            visibleColumns={visibleColumns}
            actions={actions}
            onAction={onAction}
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default c
