import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { v4 as uuidv4 } from 'uuid'

import { Divider, Grid } from '@mui/material'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import { Icon } from '@mui/material'
import EnhancedIconButton from './EnhancedIconButton'

import Slider from '@mui/material/Slider'

import gear_animated from './gear_animated.gif'
import useHttpService from '../customHooks/useHttpService.js'

const SignalrMessageList = (props) => {
  const [tasks, setTasks] = React.useState([])
  const [valueTab, setValueTab] = React.useState()
  const [idTab, setIdTab] = React.useState()
  const [filterMessages, setFilterMessages] = React.useState()
  const [timeRange, setTimeRange] = React.useState(8)

  const httpService = useHttpService()

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue)
    setIdTab(event.target.id)
    setFilterMessages(event.target.id)
  }

  //tasks polling
  function slideChange(e, value) {
    setTimeRange(value)
  }

  function pollTasks(tr) {
    let d = new Date()
    d.setHours(d.getHours() - tr)
    httpService.getAPI(
      `${
        process.env.REACT_APP_QUEUE_ADDRESS
      }/celery/tasks?received_start=${d.getUTCFullYear()}-${
        d.getUTCMonth() + 1
      }-${d.getUTCDate()} ${
        d.getUTCHours() < 10 ? '0' + d.getUTCHours() : d.getUTCHours()
      }:${
        d.getUTCMinutes() < 10 ? '0' + d.getUTCMinutes() : d.getUTCMinutes()
      }`,
      (data) => {
        Object.entries(data).forEach((task) => {
          try {
            data[task[0]].kwargs = JSON.parse(
              task[1].kwargs &&
                task[1].kwargs.replaceAll("'", '"').replaceAll('None', null)
            )
          } catch (e) {
            console.error(e)
          }
        })
        setTasks(data)

        // if (props.taskId) {
        //     let myIndex = 0;
        //     Object.entries(tasks).forEach((task) => {

        //         if (task[1].uuid === props.taskId)
        //             return;

        //         if (task[1].name && !task[1].parent)
        //             myIndex++;
        //     });
        //     console.log(myIndex);
        //     if (myIndex !== valueTab)
        //         setValueTab(myIndex);
        // }
      }
    )
  }

  function tasksToArray(tasks) {
    let a = []
    Object.entries(tasks).forEach((task) => {
      a.push(task[1])
    })
    return a
  }

  function TreeList(props) {
    if (props.data.length === 0) return <ListItem key={uuidv4()} />
    return props.data.map((element) => {
      if (!props.parent && props.firstParent !== element.uuid) return <></>
      if (element.parent === props.parent) {
        return (
          <>
            <ListItemButton
              key={element.uuid}
              onClick={(e) => {
                setFilterMessages(element.uuid)
              }}
              sx={{ backgroundColor: 'background.light' }}
            >
              <ListItemText
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingLeft: props.level,
                }}
                primary={
                  element.name
                    ? `${element.name} (${
                        new Date(element.started * 1000)
                          .toTimeString()
                          .split(' ')[0]
                      })`
                    : new Date(element.started * 1000)
                        .toTimeString()
                        .split(' ')[0]
                }
              />

              {element.state && element.state === 'STARTED' && (
                <img
                  src={gear_animated}
                  style={{ width: '30%' }}
                  alt='Running'
                />
              )}
              {element.state && element.state === 'SUCCESS' && (
                <Icon
                  fontSize='large'
                  sx={{ color: 'green', marginLeft: '10px' }}
                >
                  done
                </Icon>
              )}
              {element.state && element.state === 'FAILURE' && (
                <EnhancedIconButton
                  icon='error'
                  size='large'
                  style={{ color: 'red' }}
                  tooltip={element.exception}
                />
              )}
            </ListItemButton>
            <List>
              <TreeList
                key={element.uuid}
                data={props.data}
                parent={element.uuid}
                level={props.level + 1}
              />
            </List>
          </>
        )
      } else return <></>
    })
  }

  React.useEffect(() => {
    props.taskId && setIdTab(props.taskId)
  }, [props, props.taskId])

  React.useEffect(() => {
    pollTasks(timeRange)
    const timer = setInterval(function () {
      pollTasks(timeRange)
    }, 3000)
    return () => clearInterval(timer)
  }, [timeRange])

  return (
    <div>
      <Grid container spacing={3} padding={3} value={0} index={0}>
        <Grid item md={2}>
          <span>Time range hours:</span>
        </Grid>
        <Grid item md={10}>
          <Slider
            size='small'
            defaultValue={8}
            valueLabelDisplay='on'
            step={1}
            marks
            min={1}
            max={24}
            onChange={slideChange}
          />
        </Grid>
        <Grid item md={12}>
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            variant='scrollable'
            scrollButtons='auto'
            aria-label='scrollable auto tabs example'
          >
            {tasks &&
              Object.entries(tasks)
                .sort(function (a, b) {
                  return b[1].started - a[1].started
                })
                .map((task) => {
                  return (
                    task[1].started &&
                    !task[1].parent && (
                      <Tab
                        key={task[1].uuid}
                        id={task[1].uuid}
                        sx={{
                          backgroundColor:
                            idTab != null &&
                            idTab !== '' &&
                            idTab === task[1].uuid
                              ? 'white'
                              : 'primary.main',
                          color:
                            idTab != null &&
                            idTab !== '' &&
                            idTab === task[1].uuid
                              ? 'primary.main'
                              : 'white',
                        }}
                        label={`${
                          task[1].kwargs
                            ? task[1].kwargs.Name
                              ? task[1].kwargs.Name
                              : new Date(task[1].started * 1000)
                                  .toTimeString()
                                  .split(' ')[0]
                            : new Date(task[1].started * 1000)
                                .toTimeString()
                                .split('(')[0]
                        }`}
                      />
                    )
                  )
                })}
          </Tabs>
          <Divider></Divider>
        </Grid>
        <Grid item md={4}>
          {tasks && (
            <TreeList
              data={tasksToArray(tasks)}
              firstParent={idTab}
              parent={null}
              level={0}
            />
          )}
        </Grid>
        <Grid item md={8}>
          <List>
            {props.messages &&
              props.messages.map((row) => {
                return (
                  <ListItemText
                    key={uuidv4()}
                    hidden={filterMessages && filterMessages !== row.user}
                    primary={row.message.content}
                  />
                )
              })}
          </List>
        </Grid>
      </Grid>
    </div>
  )
}

export default SignalrMessageList
