import React from 'react'
import { Button } from '@mui/material'

import useHttpService from '../../customHooks/useHttpService.js'
import { useMessageContext } from '../../contexts/messageContext'

const CeleryTest = () => {
  let messageContext = useMessageContext()
  const httpService = useHttpService()

  // function onclick() {
  //   const message = {
  //     Name: 'pippo',
  //     x: 10,
  //     y: 20,
  //   }
  //   httpService.postAPI(
  //     `${process.env.REACT_APP_QUEUE_ADDRESS}/celery/push/MOCK/MOCKstart`,
  //     message,
  //     (data) => {
  //       console.log(data)
  //       console.log(data['task-id'])
  //       messageContext.setTaskId(data['task-id'])
  //     }
  //   )
  // }

  function onclick() {
    const message = {
      Name: 'notifyMOIcreation',
      Command: 'notifyMOIcreation',
      moi_class: 'NSSI',
      moi_id: '1234',
    }
    httpService.postAPI(
      `${process.env.REACT_APP_QUEUE_ADDRESS}/celery/push/NOTIFMOI/notifyMOIcreation`,
      message,
      (data) => {
        console.log(data)
        console.log(data['task-id'])
        messageContext.setTaskId(data['task-id'])
      }
    )
  }

  return (
    <div>
      <h1>CELERY Test Page</h1>
      <p>Push the button...</p>
      <Button variant='contained' onClick={onclick}>
        CLICK ME
      </Button>
    </div>
  )
}
export default CeleryTest
