import React, { useState, useEffect, useRef } from 'react'
import EnhancedTableBox from '../../components/EnhancedTableBox'
import { Box } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import useHttpService from '../../customHooks/useHttpService.js'


const EvalList = () => {
  const { id_site } = useParams()
  console.log(id_site);
  const history = useHistory()
  const httpService = useHttpService()


  //prepare the confirm dialog
  const confirm = useConfirm()


  //array of the columns we want to show on the table
  const visibleColumns = [
    {
      id: 'id',
      alignRight: false,
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'uuid',
      alignRight: false,
      disablePadding: false,
      label: 'UUID',
    },
    {
      id: 'project_description',
      alignRight: false,
      disablePadding: false,
      label: 'Description',
    },
    {
      id: 'date_updated',
      alignRight: false,
      disablePadding: false,
      label: 'Last update',
      type: 'datetime',
    },
    {
      id: 'actual',
      alignRight: false,
      disablePadding: false,
      label: 'actual',
    },
    {
      id: 'result',
      alignRight: false,
      disablePadding: false,
      label: 'result',
    },
  ]

  //array of actions placed at the end of each row
  const actions = [
    {
      action: 'open',
      icon: 'open_in_browser',
      tooltip: 'open eval',
    },

    {
      action: 'clone',
      icon: 'copy_all',
      tooltip: 'clone eval',
    },

    {
      action: 'delete',
      icon: 'delete',
      tooltip: 'delete eval',
    },
  ]

  const editSchema = {
    project_description: {
      title: 'Description',
      type: 'text',
      md: 6,
    },
  }

  useEffect(() => {
    httpService.getAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/eval/eval_list?id_site=${id_site}`,
    )
  })

  function onAction(action, e) {
    switch (action) {
      case 'open':
        history.push(`/evalMatrix/${e.id}`)
        break
    }
  }

  return (
    <Box sx={{ padding: '2%' }}>
      <EnhancedTableBox
        heightPercentage='0.7'
        title='Site eval'
        apiURL={`https://networkteam.it.nttdata-emea.com/api/scalar/eval/eval_list?id_site=${id_site}`}
        deleteURL={`https://networkteam.it.nttdata-emea.com/api/scalar/eval/eval`}
        cloneURL={`https://networkteam.it.nttdata-emea.com/api/scalar/eval/duplicate_eval`}
        valueMember='id'
        visibleColumns={visibleColumns}
        actions={actions}
        onAction={onAction}
        //addJsonSchema={{}}
        editJsonSchema={editSchema}
        search
      />
    </Box>
  )
}
export default EvalList
