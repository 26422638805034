import { v4 as uuidv4 } from 'uuid'

const doNothing = () => {}
const handleTextClick = () => {}

const apiDataToShow = ['name', 'id']
const dataTemplate = {
  icon: 'arrow_right',
  iconSize: 'large',
  content: '',
  onTextClick: handleTextClick,
  onIconClick: doNothing,
  infos: {},
}

const apiSpecificItem = `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/ServiceProfile?id=`
const api = `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/ServiceProfile_short`

// const listData = [
//   {
//     icon: 'arrow_right',
//     iconSize: 'large',
//     content: 'NSSIDa',
//     onTextClick: handleTextClick,
//     onIconClick: doNothing,
//   },
//   {
//     icon: 'arrow_right',
//     content: 'NSSIDb',
//     iconSize: 'large',
//     onTextClick: handleTextClick,
//     onIconClick: doNothing,
//   },
//   {
//     icon: 'arrow_right',
//     content: 'NSSIDc',
//     iconSize: 'large',
//     onTextClick: handleTextClick,
//     onIconClick: doNothing,
//   },
//   {
//     icon: 'arrow_right',
//     iconSize: 'large',
//     content: 'NSSIDd',
//     onTextClick: handleTextClick,
//     onIconClick: doNothing,
//   },
//   {
//     icon: 'arrow_right',
//     iconSize: 'large',
//     content: 'NSSIDe',
//     onTextClick: handleTextClick,
//     onIconClick: doNothing,
//   },
//   {
//     icon: 'arrow_right',
//     iconSize: 'large',
//     content: 'NSSIDg',
//     onTextClick: handleTextClick,
//     onIconClick: doNothing,
//   },
//   {
//     icon: 'arrow_right',
//     iconSize: 'large',
//     content: 'NSSIDh',
//     onTextClick: handleTextClick,
//     onIconClick: doNothing,
//   },
// ]

const cardBoxStyleList = {
  cardStyle: {
    width: '20vw',
    height: '75vh',
  },
  textItemStyle: { textAlign: 'right' },
  titleStyle: {
    marginTop: '30px',
    backgroundColor: 'background.light',
    paddingLeft: '7%',
    paddingRight: '7%',
    color: 'primary.light',
    width: '105%',
    fontSize: '2em',
    textAlign: 'center',
  },
}

const titleDataList = {
  title: 'Service Profile Templates',
}

export const list = {
  api,
  apiSpecificItem,
  apiDataToShow,
  dataTemplate,
  cardBoxStyleList,
  titleDataList,
}
