import React from 'react'
import Map from '../../components/Map/Map'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import ComboBox from '../../components/ComboBox'


import useHttpService from '../../customHooks/useHttpService.js'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'

import Button from '@mui/material/Button'
import { useMessageContext } from '../../contexts/messageContext'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import JsonSchemaBox from '../../components/JsonSchemaBox'


const MapPage = () => {
  let messageContext = useMessageContext()

  //states for selection
  const [selectedServiceCategory, setSelectedServiceCategory] = React.useState()
  const [selectedService, setSelectedService] = React.useState()
  const [selectedServiceLabel, setSelectedServiceLabel] = React.useState()
  const [applicationData, setApplicationData] = React.useState([])
  const [datacenterData, setDatacenterData] = React.useState([])
  const [applicationDatacenterData, setApplicationDatacenterData] =
    React.useState([])
  const [variableData, setVariableData] = React.useState([])
  const [variableSchema, setVariableSchema] = React.useState([])
  const [variableDefault, setVariableDefault] = React.useState({})
  const [tabs, setTabs] = React.useState([])

  const httpService = useHttpService()

  const [selectedDatacenter, setSelectedDatacenter] = React.useState({})
  const [selectedDatacenterType, setSelectedDatacenterType] = React.useState()
  const [selectedDatacenterTypeName, setSelectedDatacenterTypeName] = React.useState()

  const [selectedPcred, setSelectedPcred] = React.useState()

  const [openPost, setOpenPost] = React.useState(false)


  const handleCloseCancel = () => {
    setOpenPost(false)
  }
  const handleCloseSavePost = () => {
    startInstance();
    setOpenPost(false)
  }

  let askedVariables = {};
  function onVariableDataChange(data) {
    console.log(data);
    askedVariables = data;
  }




  function onServiceCategoryChange(e) {
    setApplicationData([])
    setApplicationDatacenterData([])
    setSelectedServiceCategory(e.id)
  }

  function onServiceChange(e) {
    setApplicationDatacenterData([])
    setSelectedService(e.id)
    setSelectedServiceLabel(e.name)
    httpService.getAPI(
      `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service_application?service_id=${e.id}`,
      onApplicationData
    )
    httpService.getAPI(
      `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service_application_variable?service_id=${e.id}`,
      onVariableData
    )
  }

  function onApplicationData(data) {
    setApplicationData(data)
  }

  function onVariableData(data) {
    setVariableData(data)
  }

  function onDatacenter_typeChange(e) {
    setApplicationDatacenterData([])
    setSelectedDatacenter({})
    setSelectedDatacenterType(e.id)
    setSelectedDatacenterTypeName(e.name)
    selectedPcred &&
      httpService.getAPI(
        `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/datacenter?datacenter_type_id=${e.id}&platform_credential_id=${selectedPcred}`,
        onDatacenterData
      )
  }

  function onPlatform_credentialChange(e) {
    setApplicationDatacenterData([])
    setSelectedDatacenter({})
    setSelectedPcred(e.id)
    selectedDatacenterType &&
      httpService.getAPI(
        `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/datacenter?datacenter_type_id=${selectedDatacenterType}&platform_credential_id=${e.id}`,
        onDatacenterData
      )
  }

  function onDatacenterData(data) {
    setDatacenterData(data)
  }

  function treeItemSelect(e) {
    setApplicationDatacenterData([
      ...applicationDatacenterData,
      {
        application_id: e,
        datacenter_id: selectedDatacenter.id,
        datacenterName: selectedDatacenter.name,
      },
    ])
  }

  function onMapClick(info, event) {
    info.object && setSelectedDatacenter(info.object)
  }

  function prepareInstance() {
    let varSchema = {};
    let varTabs = [];
    let varDefault = {};
    variableData.forEach(element => {
      if (element.var_type === 'ask') {
        if (varTabs.indexOf(element.application) === -1)
          varTabs.push(element.application);
        varSchema["var" + String(element.id)] = {
          tab: element.application,
          title: element.var_name,
          type: 'text',
          md: 6,
        };
        varDefault["var" + String(element.id)] = element.var_value;
      }
    });
    setTabs(varTabs);
    setVariableSchema(varSchema);
    setVariableDefault(varDefault);
    setOpenPost(true);
  }

  function startInstance() {
    const serviceInstance = {
      service_id: selectedService,
      applications: applicationDatacenterData,
    }
    httpService.postAPI(
      `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/serviceinstance`,
      serviceInstance,
      onInstanceCreated
    )
  }

  function onInstanceCreated(data) {

    variableData.forEach(element => {
      if (askedVariables["var" + String(element.id)])
        element.var_value = askedVariables["var" + String(element.id)];
      element.serviceinstance_id = data.id;
    });


    httpService.postAPI(
      `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/applicationinstance_variable_list`,
      variableData,
      () => {
        const message = {
          Name: data.name,
          Command: 'NewInstance',
          Platform: selectedDatacenterTypeName, //'kubernetes',
          IdInstance: data.id,
        }
        console.log(message);
        httpService.postAPI(
          `${process.env.REACT_APP_QUEUE_ADDRESS}/celery/push/TFO/tfoStart`,
          message,
          onInstanceStarted
        )
      }
    )
  }



  function onInstanceStarted(data) {
    console.log(data)
    messageContext.setTaskId(data['task-id'])
  }

  return (
    <>
      <Grid container spacing={3} padding={3}>
        <Grid item md={3}>
          <ComboBox
            label='Service Category'
            apiURL={`${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/servicecategory`}
            valueMember='id'
            displayMember='name'
            onSelectChange={onServiceCategoryChange}
          />
        </Grid>
        <Grid item md={3}>
          <ComboBox
            label='Service'
            apiURL={
              selectedServiceCategory &&
              `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/service?servicecategory_id=${selectedServiceCategory}`
            }
            valueMember='id'
            displayMember='name'
            onSelectChange={onServiceChange}
          />
        </Grid>
        <Grid item md={3}>
          <ComboBox
            label='Datacenter Type'
            apiURL={`${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/datacenter_type`}
            valueMember='id'
            displayMember='name'
            onSelectChange={onDatacenter_typeChange}
          />
        </Grid>
        <Grid item md={3}>
          <ComboBox
            label='Platform Credential'
            apiURL={`${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/platform_credential`}
            valueMember='id'
            displayMember='name'
            onSelectChange={onPlatform_credentialChange}
          />
        </Grid>

        <Grid item md={6}>
          <Map
            style={{ height: '65vh', width: '47vw', position: 'relative' }}
            initialViewState={{
              longitude: 13.0,
              latitude: 42.0,
              zoom: 4,
              bearing: 0,
              pitch: 45,
            }}
            geoJson={datacenterData}
            onClick={onMapClick}
          />
        </Grid>
        <Grid item md={3}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant='h5' component='div'>
                {selectedDatacenter && selectedDatacenter.name}
              </Typography>

              <Typography variant='body2'>
                {selectedDatacenter.address
                  ? selectedDatacenter.address
                  : 'please select a datacenter on the map'}
              </Typography>
              <br></br>
              <Typography variant='body2'>
                {selectedDatacenter.address &&
                  'click on application to bind --> '}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3}>
          <Paper>
            <List>
              <ListItemText
                primary={
                  selectedServiceLabel
                    ? selectedServiceLabel
                    : 'Select a service before'
                }
              />
              <List>
                {applicationData &&
                  applicationData.map((application) => (
                    <>
                      <ListItemButton>
                        <ListItemText
                          sx={{ pl: 4 }}
                          onClick={() =>
                            treeItemSelect(application.application_id)
                          }
                          primary={`${application.application} (min:${application.min_num} max:${application.max_num})`}
                        />
                      </ListItemButton>
                      <List>
                        {applicationDatacenterData &&
                          applicationDatacenterData.map((adc) => {
                            return (
                              application.application_id ===
                              adc.application_id && (
                                <ListItemText
                                  sx={{ pl: 8 }}
                                  primary={adc.datacenterName}
                                />
                              )
                            )
                          })}
                      </List>
                    </>
                  ))}
              </List>
            </List>
          </Paper>
        </Grid>
        <Grid item md={12} display='flex' justifyContent='flex-end'>
          <Button size='large' variant='contained' onClick={prepareInstance}>
            Finalize the Service Instance
          </Button>
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth='lg'
        open={openPost}
        onClose={handleCloseCancel}
      >
        <DialogTitle>Set Variables</DialogTitle>
        <DialogContent>
          <JsonSchemaBox
            schema={variableSchema}
            formData={variableDefault}
            onChange={onVariableDataChange}
            tabs={tabs}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancel}>Cancel</Button>
          <Button onClick={handleCloseSavePost}>Start Service Instance</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MapPage
