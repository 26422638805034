import React from 'react'
import produce from 'immer'
import { set, has } from 'lodash'
import { useState } from 'react'
import { useEffect } from 'react'
import { Grid } from '@mui/material'
import { TextField } from '@mui/material'
import pic1 from './pic1.png'
import pic2 from './pic2.png'
import puntoni from './puntoni.png'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { MenuItem } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import TextFieldCombo from '../../components/TextFieldCombo'
import EnhancedTableBox from '../../components/EnhancedTableBox'

import { Icon } from '@mui/material'
import { IconButton } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'

import { useConfirm } from 'material-ui-confirm'
import { v4 as uuidv4 } from 'uuid'

//funtion to manage json states
function enhancedReducer(state, updateArg) {
  // check if the type of update argument is a callback function
  if (updateArg.constructor === Function) {
    return { ...state, ...updateArg(state) }
  }

  // if the type of update argument is an object
  if (updateArg.constructor === Object) {
    // does the update object have _path and _value as it's keys
    // if yes then use them to update deep object values
    if (has(updateArg, '_path') && has(updateArg, '_value')) {
      const { _path, _value } = updateArg

      return produce(state, (draft) => {
        set(draft, _path, _value)
      })
    } else {
      return { ...state, ...updateArg }
    }
  }
}

const PUNTONI = () => {
  //read querystring
  const urlParams = new URLSearchParams(window.location.search)

  //method to update json states with own setter
  const updateForm = React.useCallback(
    ({ target: { value, name, type } }, stateSetter) => {
      const updatePath = name.split('.')

      switch (type) {
        case 'number':
          if (value.includes('.')) value = parseFloat(value)
          else value = parseInt(value)
          break
        default:
          break
      }

      // if the input is a checkbox then use callback function to update
      // the toggle state based on previous state
      if (type === 'checkbox') {
        stateSetter((prevState) => ({
          [name]: !prevState[name],
        }))

        return
      }

      // if we have to update the root level nodes in the form
      if (updatePath.length === 1) {
        const [key] = updatePath

        stateSetter({
          [key]: value,
        })
      }

      // if we have to update nested nodes in the form object
      // use _path and _value to update them.
      if (updatePath.length > 1) {
        stateSetter({
          _path: updatePath,
          _value: value,
        })
      }
    },
    []
  )

  //...........COLUMNS OF POLES TABLE.............//

  const visibleColumnsPoles = [
    {
      id: 'baseHeight',
      alignRight: true,
      disablePadding: false,
      label: 'Altezza base tronco (m)',
    },
    {
      id: 'summitHeight',
      alignRight: true,
      disablePadding: false,
      label: 'Altezza sommità tronco (m)',
    },
    {
      id: 'overlappingLogs',
      alignRight: true,
      disablePadding: false,
      label: 'Sovrapposizione tronchi (m)',
    },
    {
      id: 'extBaseDiameter',
      alignRight: true,
      disablePadding: false,
      label: 'Diametro ext. base (mm)',
    },
    {
      id: 'extSummitDiameter',
      alignRight: true,
      disablePadding: false,
      label: 'Diametro ext. sommità (mm)',
    },
    {
      id: 'thickness',
      alignRight: true,
      disablePadding: false,
      label: 'Spessore (mm)',
    },
    {
      id: 'sidesSection',
      alignRight: true,
      disablePadding: false,
      label: 'Lati sezione',
    },
    {
      id: 'steelType',
      alignRight: true,
      disablePadding: false,
      label: 'Tipo Acciaio',
    },
  ]

  //...........COLUMNS OF FLANGES TABLE.............//

  const visibleColumnsFlanges = [
    {
      id: 'quote',
      alignRight: true,
      disablePadding: false,
      label: 'Quota',
    },
    {
      id: 'boltType',
      alignRight: true,
      disablePadding: false,
      label: 'Tipo Bullone',
    },
    {
      id: 'boltClass',
      alignRight: true,
      disablePadding: false,
      label: 'Classe Bullone',
    },
    {
      id: 'boltNumber',
      alignRight: true,
      disablePadding: false,
      label: 'Numero Bulloni',
    },
    {
      id: 'holeDiameter',
      alignRight: true,
      disablePadding: false,
      label: 'Diametro a fori (mm)',
    },
  ]

  //...........COLUMNS OF BALLATOI TABLE.............//

  const visibleColumnsGalleries = [
    {
      id: 'quote',
      alignRight: true,
      disablePadding: false,
      label: 'Quota (m)',
    },
    {
      id: 'height',
      alignRight: true,
      disablePadding: false,
      label: 'Altezza (m)',
    },
    {
      id: 'width',
      alignRight: true,
      disablePadding: false,
      label: 'Larghezza (m)',
    },
    {
      id: 'mass',
      alignRight: true,
      disablePadding: false,
      label: 'Massa (kg)',
    },
    {
      id: 'shapeCoefficient',
      alignRight: true,
      disablePadding: false,
      label: 'Coefficiente Forma',
    },
    {
      id: 'reductionCoefficient',
      alignRight: true,
      disablePadding: false,
      label: 'Coefficiente Riduzione',
    },
  ]

  //...........COLUMNS OF ALTRI CARICHI TABLE.............//

  const visibleColumnsOtherLoads = [
    {
      id: 'number',
      alignRight: true,
      disablePadding: false,
      label: 'N° Carichi',
    },
    {
      id: 'typology',
      alignRight: true,
      disablePadding: false,
      label: 'Tipologia',
    },
    {
      id: 'quote',
      alignRight: false,
      disablePadding: false,
      label: 'Quota (m)',
    },
    {
      id: 'height',
      alignRight: true,
      disablePadding: false,
      label: 'Altezza (m)',
    },
    {
      id: 'width',
      alignRight: true,
      disablePadding: false,
      label: 'Larghezza (m)',
    },
    {
      id: 'mass',
      alignRight: true,
      disablePadding: false,
      label: 'Massa (kg)',
    },
    {
      id: 'shapeCoefficient',
      alignRight: true,
      disablePadding: false,
      label: 'Coefficiente Forma',
    },
    {
      id: 'reductionCoefficient',
      alignRight: true,
      disablePadding: false,
      label: 'Coefficiente Riduzione',
    },
  ]

  //...........COLUMNS OF VINCOLI TABLE.............//

  const visibleColumnsVincoli = [
    {
      id: 'height',
      alignRight: true,
      disablePadding: false,
      label: 'Quota (m)',
    },
  ]

  //...........SCHEMA FOR FLANGES TABLE.............//

  const flangesSchema = {
    quote: {
      title: 'Quota',
      type: 'number',
    },
    boltType: {
      title: 'Tipo Bullone',
      type: 'combo',
      url: 'https://networkteam.it.nttdata-emea.com/api/scalar/statica/bolt_types',
      valueMember: 'type',
      displayMember: 'type',
    },
    boltClass: {
      title: 'Classe Bullone',
      type: 'combo',
      url: 'https://networkteam.it.nttdata-emea.com/api/scalar/statica/bolt_classes',
      valueMember: 'class',
      displayMember: 'class',
    },
    boltNumber: {
      title: 'Numero Bulloni',
      type: 'number',
    },
    holeDiameter: {
      title: 'Diametro a fori (mm)',
      type: 'number',
    },
  }
  //...........SCHEMA FOR TRONCHI TABLE.............//

  const tronchiSchema = {
    baseHeight: {
      title: 'Altezza base tronco (m)',
      type: 'number',
    },
    summitHeight: {
      title: 'Altezza sommità tronco (m)',
      type: 'number',
    },
    overlappingLogs: {
      title: 'Sovrapposizione tronchi (m)',
      type: 'number',
    },
    extBaseDiameter: {
      title: 'Diametro ext. base (mm)',
      type: 'number',
    },
    extSummitDiameter: {
      title: 'Diametro ext. sommità (mm)',
      type: 'number',
    },
    thickness: {
      title: 'Spessore (mm)',
      type: 'number',
    },
    sidesSection: {
      title: 'Lati sezione',
      type: 'combo',
      url: 'https://networkteam.it.nttdata-emea.com/api/scalar/statica/pole_geometries',
      valueMember: 'pole_side',
      displayMember: 'pole_side',
    },
    steelType: {
      title: 'Tipo Acciaio',
      type: 'combo',
      url: 'https://networkteam.it.nttdata-emea.com/api/scalar/statica/steels',
      valueMember: 'type',
      displayMember: 'type',
    },
  }

  //...........SCHEMA FOR VINCOLI TABLE.............//

  const vincoliSchema = {
    height: {
      title: 'Quota(m)',
      type: 'number',
    },
  }

  //...........SCHEMA FOR BALLATOI TABLE.............//

  const ballatoiSchema = {
    quote: {
      title: 'Quota (m)',
      type: 'number',
    },
    height: {
      title: 'Altezza (m)',
      type: 'number',
    },
    width: {
      title: 'Larghezza (m)',
      type: 'number',
    },
    mass: {
      title: 'Massa (kg)',
      type: 'number',
    },
    shapeCoefficient: {
      title: 'Coefficiente Forma',
      type: 'number',
    },
    reductionCoefficient: {
      title: 'Coefficiente Riduzione',
      type: 'number',
    },
  }

  //...........SCHEMA FOR ALTRI CARICHI TABLE.............//

  const altriCarichiSchema = {
    number: {
      title: 'N° Carichi',
      type: 'number',
    },
    typology: {
      title: 'Tipologia',
      type: 'text',
    },
    quote: {
      title: 'Quota (m)',
      type: 'number',
    },
    height: {
      title: 'Altezza (m)',
      type: 'number',
    },
    width: {
      title: 'Larghezza (m)',
      type: 'number',
    },
    mass: {
      title: 'Massa (kg)',
      type: 'number',
    },
    shapeCoefficient: {
      title: 'Coefficiente Forma',
      type: 'number',
    },
    reductionCoefficient: {
      title: 'Coefficiente Riduzione',
      type: 'number',
    },
  }

  //...........FUNCTIONS FOR FLANGES TABLE.............//

  function onFlangeAdd(data) {
    data.uuid = uuidv4()
    var mynewState = JSON.parse(JSON.stringify(structureState))
    mynewState.ssdStaticaFlanges.push(data)
    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }

  function onFlangeChange(data) {
    var mynewState = JSON.parse(JSON.stringify(structureState))
    mynewState.ssdStaticaFlanges = []
    structureState.ssdStaticaFlanges.forEach((element) => {
      if (element.uuid === data.uuid) mynewState.ssdStaticaFlanges.push(data)
      else mynewState.ssdStaticaFlanges.push(element)
    })

    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }

  function onFlangeDelete(data) {
    var mynewState = JSON.parse(JSON.stringify(structureState))
    mynewState.ssdStaticaFlanges = []
    structureState.ssdStaticaFlanges.forEach((element) => {
      if (element.uuid !== data.uuid) mynewState.ssdStaticaFlanges.push(element)
    })

    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }

  //...........FUNCTIONS FOR TRONCHI TABLE.............//

  function onTronchiAdd(data) {
    data.uuid = uuidv4()
    var mynewState = JSON.parse(JSON.stringify(structureState))
    mynewState.ssdStaticaPoles.push(data)
    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }

  function onTronchiChange(data) {
    var mynewState = JSON.parse(JSON.stringify(structureState))
    mynewState.ssdStaticaPoles = []
    structureState.ssdStaticaPoles.forEach((element) => {
      if (element.uuid === data.uuid) mynewState.ssdStaticaPoles.push(data)
      else mynewState.ssdStaticaPoles.push(element)
    })

    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }

  function onTronchiDelete(data) {
    var mynewState = JSON.parse(JSON.stringify(structureState))
    mynewState.ssdStaticaPoles = []
    structureState.ssdStaticaPoles.forEach((element) => {
      if (element.uuid !== data.uuid) mynewState.ssdStaticaPoles.push(element)
    })

    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }

  //...........FUNCTIONS FOR VINCOLI TABLE.............//

  function onVincoliAdd(data) {
    data.uuid = uuidv4()
    var mynewState = JSON.parse(JSON.stringify(structureState))
    mynewState.ssdStaticaConstraints.push(data)
    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }

  function onVincoliChange(data) {
    var mynewState = JSON.parse(JSON.stringify(structureState))
    mynewState.ssdStaticaConstraints = []
    structureState.ssdStaticaConstraints.forEach((element) => {
      if (element.uuid === data.uuid)
        mynewState.ssdStaticaConstraints.push(data)
      else mynewState.ssdStaticaConstraints.push(element)
    })

    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }

  function onVincoliDelete(data) {
    var mynewState = JSON.parse(JSON.stringify(structureState))
    mynewState.ssdStaticaConstraints = []
    structureState.ssdStaticaConstraints.forEach((element) => {
      if (element.uuid !== data.uuid)
        mynewState.ssdStaticaConstraints.push(element)
    })

    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }

  //...........FUNCTIONS FOR BALLATOI TABLE.............//

  function onBallatoiAdd(data) {
    data.uuid = uuidv4()
    var mynewState = JSON.parse(JSON.stringify(structureState))
    mynewState.ssdStaticaGalleries.push(data)
    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }

  function onBallatoiChange(data) {
    var mynewState = JSON.parse(JSON.stringify(structureState))
    mynewState.ssdStaticaGalleries = []
    structureState.ssdStaticaGalleries.forEach((element) => {
      if (element.uuid === data.uuid) mynewState.ssdStaticaGalleries.push(data)
      else mynewState.ssdStaticaGalleries.push(element)
    })

    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }

  function onBallatoiDelete(data) {
    var mynewState = JSON.parse(JSON.stringify(structureState))
    mynewState.ssdStaticaGalleries = []
    structureState.ssdStaticaGalleries.forEach((element) => {
      if (element.uuid !== data.uuid)
        mynewState.ssdStaticaGalleries.push(element)
    })

    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }

  //...........FUNCTIONS FOR ALTRI CARICHI TABLE.............//

  function onaltriCarichiAdd(data) {
    data.uuid = uuidv4()
    var mynewState = JSON.parse(JSON.stringify(structureState))
    mynewState.ssdStaticaOtherLoads.push(data)
    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }

  function onaltriCarichiChange(data) {
    var mynewState = JSON.parse(JSON.stringify(structureState))
    mynewState.ssdStaticaOtherLoads = []
    structureState.ssdStaticaOtherLoads.forEach((element) => {
      if (element.uuid === data.uuid) mynewState.ssdStaticaOtherLoads.push(data)
      else mynewState.ssdStaticaOtherLoads.push(element)
    })

    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }

  function onaltriCarichiDelete(data) {
    var mynewState = JSON.parse(JSON.stringify(structureState))
    mynewState.ssdStaticaOtherLoads = []
    structureState.ssdStaticaOtherLoads.forEach((element) => {
      if (element.uuid !== data.uuid)
        mynewState.ssdStaticaOtherLoads.push(element)
    })

    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }

  //.........DECLARE ACTIONS ON TABLE............//

  const actions = [
    {
      action: 'edit',
      icon: 'edit',
      tooltip: 'edit current row',
    },
    {
      action: 'delete',
      icon: 'delete',
      tooltip: 'delete current row',
    },
  ]

  const handleCloseCancel = () => {
    setopenPutBallatoi(false)
    setopenPutAltriCarichi(false)
  }

  //prepare the confirm dialog
  const confirm = useConfirm()

  //state to trace data  loading
  const [dataLoaded, setDataLoaded] = useState(false)
  //main state for the whole page data
  const [formState, setFormState] = React.useReducer(enhancedReducer, {})
  //reducer can't handle arrays, so we need a state for the structure
  const [structureState, setStructureState] = React.useReducer(
    enhancedReducer,
    {}
  )

  //.........DECLARING STATES FOR EACH popup............//
  const [polePopupState, setPolePopupState] = React.useReducer(
    enhancedReducer,
    {}
  )
  const [openPutPoles, setopenPutPoles] = React.useState(false)

  const [flangePopupState, setFlangePopupState] = React.useReducer(
    enhancedReducer,
    {}
  )
  const [openPutFlanges, setopenPutFlanges] = React.useState(false)

  const [vincoliPopupState, setVincoliPopupState] = React.useReducer(
    enhancedReducer,
    {}
  )
  const [openPutVincoli, setopenPutVincoli] = React.useState(false)

  const [ballatoioPopupState, setBallatoioPopupState] = React.useReducer(
    enhancedReducer,
    {}
  )
  const [openPutBallatoi, setopenPutBallatoi] = React.useState(false)

  const [altriCarichiPopupState, setAltriCarichiPopupState] = React.useReducer(
    enhancedReducer,
    {}
  )
  const [openPutAltriCarichi, setopenPutAltriCarichi] = React.useState(false)

  //..........FUNCTION TO CALL GET API TO FILL ALL FIELDS..............//

  function queryAPI(myURL) {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    fetch(myURL, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status)
        } else return response.json()
      })
      .then((jsondata) => {
        let myStructure = jsondata.data.data.structures[0]
        if (jsondata.data && myStructure.rooftopType === 'PUNTONI') {
          if (
            jsondata.data.data.structures.findIndex(
              (x) => x.uuid === urlParams.get('pole_uuid')
            ) !== -1
          ) {
            myStructure =
              jsondata.data.data.structures[
                jsondata.data.data.structures.findIndex(
                  (x) => x.uuid === urlParams.get('pole_uuid')
                )
              ]
          }
          //setting main state
          setFormState(jsondata.data)
          //extracting single structure data
          myStructure.ssdStaticaConstraints.forEach((element) => {
            element.uuid = uuidv4()
          })
          myStructure.ssdStaticaPoles.forEach((element) => {
            element.uuid = uuidv4()
          })
          myStructure.ssdStaticaFlanges.forEach((element) => {
            element.uuid = uuidv4()
          })
          myStructure.ssdStaticaGalleries.forEach((element) => {
            element.uuid = uuidv4()
          })
          myStructure.ssdStaticaOtherLoads.forEach((element) => {
            element.uuid = uuidv4()
          })

          setStructureState(myStructure)
          setDataLoaded(true)
        } else alert(`No static DATA for site ${urlParams.get('site_code')}`)
      })
      .catch((error) => {
        console.error(error)
        //localStorage.removeItem('token');
        //window.location.reload();
      })
  }

  function putAPI(myURL, data) {
    let site_code = urlParams.get('site_code')
    data['site_code'] = site_code
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify(data),
    }
    fetch(myURL, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status)
        } else return response.json()
      })
      .then((jsondata) => {})
      .catch((error) => {
        console.error(error)
        //localStorage.removeItem('token');
        //window.location.reload();
      })
  }

  function updateStructureInFormState(newStructureState) {
    let mynewState = JSON.parse(JSON.stringify(formState))
    let myindex = mynewState.data.structures.findIndex(
      (x) => x.uuid === urlParams.get('pole_uuid')
    )
    if (myindex === -1) myindex = 0
    mynewState.data.structures[myindex] = newStructureState
    setFormState(mynewState)
    return mynewState
  }

  function saveData() {
    let mynewState = updateStructureInFormState(structureState)

    //alert(JSON.stringify(formState));
    putAPI(
      'https://networkteam.it.nttdata-emea.com/api/scalar/statica/structures',
      mynewState
    )

    window.location.href = `https://networkteam.it.nttdata-emea.com/Radiomod/cem/site/${urlParams.get(
      'site_code'
    )}/info`
  }

  //..................................................................//

  useEffect(() => {
    queryAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/statica/structures?site_code=${urlParams.get(
        'site_code'
      )}`
    ) //I068AT
  }, [])

  if (!dataLoaded)
    return (
      <div>
        <h1 style={{ display: 'flex', justifyContent: 'center' }}>
          ROOFTOP PUNTONI
        </h1>
        <LinearProgress />
      </div>
    )
  else
    return (
      <div>
        <Grid>
          <Grid>
            <h1 style={{ display: 'flex', justifyContent: 'center' }}>
              ROOFTOP PUNTONI
            </h1>
          </Grid>

          <Grid item md={2}>
            <img src={puntoni} style={{ width: '40%' }} alt='' />
          </Grid>
        </Grid>
        <Grid container spacing={'1%'} padding={5}>
          <Grid item md={4}>
            <h5> PARAMETRI TERRITORIALI </h5>

            <Grid xs={7}>
              <TextFieldCombo
                label='Regione'
                labelSize={14}
                apiURL='https://networkteam.it.nttdata-emea.com/api/scalar/statica/regions'
                valueMember='region'
                displayMember='region'
                selectedValue={
                  formState.data.ssdStaticaTerritorialParameter.territory
                }
                onSelectChange={(e) => {
                  updateForm(e, setFormState)
                }}
                type='text'
                name='data.ssdStaticaTerritorialParameter.territory'
              />
            </Grid>
            <Grid xs={7}>
              <TextField
                label='Zona'
                InputLabelProps={{ style: { fontSize: 14 } }}
                fullWidth
                select
                variant='standard'
                type='number'
                name='data.ssdStaticaTerritorialParameter.area'
                onChange={(e) => {
                  updateForm(e, setFormState)
                }}
                value={formState.data.ssdStaticaTerritorialParameter.area}
              >
                <MenuItem value={1}>{1}</MenuItem>
                <MenuItem value={2}>{2}</MenuItem>
                <MenuItem value={3}>{3}</MenuItem>
                <MenuItem value={4}>{4}</MenuItem>
                <MenuItem value={5}>{5}</MenuItem>
                <MenuItem value={6}>{6}</MenuItem>
                <MenuItem value={7}>{7}</MenuItem>
                <MenuItem value={8}>{8}</MenuItem>
                <MenuItem value={9}>{9}</MenuItem>
              </TextField>
            </Grid>

            <Grid xs={7}>
              <TextFieldCombo
                label='Categoria di Esposizione'
                labelSize={14}
                apiURL='https://networkteam.it.nttdata-emea.com/api/scalar/statica/exposure_categories'
                valueMember='exposure_category'
                displayMember='exposure_category'
                selectedValue={
                  formState.data.ssdStaticaTerritorialParameter.exposureCategory
                }
                onSelectChange={(e) => {
                  updateForm(e, setFormState)
                }}
                type='text'
                name='data.ssdStaticaTerritorialParameter.exposureCategory'
              />
            </Grid>
          </Grid>

          <Grid item md={4}>
            <img src={pic1} style={{ width: '70%' }} alt='' />
          </Grid>

          <Grid item md={4}>
            <Typography style={{ fontSize: 15 }}>
              Classe rugosità del terreno
            </Typography>

            <Typography style={{ fontSize: 12 }}>
              A: Aree urbane in cui almeno il 15% della superficie sia coperto
              da edifici la cui altezza mediasuperi i 15m
            </Typography>

            <Typography style={{ fontSize: 12 }}>
              B: Aree urbane (non di Classe A), suburbane, industriali e
              boschive
            </Typography>

            <Typography style={{ fontSize: 12 }}>
              C: Aree con ostacoli diffusi (alberi, case, muri, recinzioni..);
              aree con rugosità non riconducibili alle classi A, B e D
            </Typography>

            <Typography style={{ fontSize: 12 }}>
              D: Aree prive di ostacoli (aperta campagna, areporti, aree
              innevate o ghiacciate, mari, laghi..)
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={'1%'} padding={5}>
          <Grid item md={1.5}>
            <TextField
              helperText='Altitudine'
              FormHelperTextProps={{ style: { fontSize: 10 } }}
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='data.ssdStaticaTerritorialParameter.altitude'
              onChange={(e) => {
                updateForm(e, setFormState)
              }}
              value={formState.data.ssdStaticaTerritorialParameter.altitude}
            ></TextField>
          </Grid>

          <Grid item md={1.5}>
            <TextField
              helperText={
                <div>
                  <Typography style={{ fontSize: 10 }}>Coefficiente</Typography>
                  <Typography style={{ fontSize: 10 }}>topografico</Typography>
                </div>
              }
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='data.ssdStaticaTerritorialParameter.topographicCoefficient'
              onChange={(e) => {
                updateForm(e, setFormState)
              }}
              value={
                formState.data.ssdStaticaTerritorialParameter
                  .topographicCoefficient
              }
            ></TextField>
          </Grid>

          <Grid item md={1.5}>
            <TextField
              helperText={
                <div>
                  <Typography style={{ fontSize: 10 }}>Coefficiente</Typography>
                  <Typography style={{ fontSize: 10 }}>dinamico</Typography>
                </div>
              }
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='data.ssdStaticaTerritorialParameter.dynamicCoefficient'
              onChange={(e) => {
                updateForm(e, setFormState)
              }}
              value={
                formState.data.ssdStaticaTerritorialParameter.dynamicCoefficient
              }
            ></TextField>
          </Grid>

          <Grid item md={1.5}>
            <TextField
              helperText={
                <div>
                  <Typography style={{ fontSize: 10 }}>Velocita di</Typography>
                  <Typography style={{ fontSize: 10 }}>
                    progetto (km/h)
                  </Typography>
                </div>
              }
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='data.ssdStaticaTerritorialParameter.speedProject'
              onChange={(e) => {
                updateForm(e, setFormState)
              }}
              value={formState.data.ssdStaticaTerritorialParameter.speedProject}
            ></TextField>
          </Grid>

          <Grid item md={1.5}>
            <TextField
              helperText={
                <div>
                  <Typography style={{ fontSize: 10 }}>
                    Max defflessione
                  </Typography>
                  <Typography style={{ fontSize: 10 }}>(g°)</Typography>
                </div>
              }
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='data.ssdStaticaTerritorialParameter.maxDeflectionG'
              onChange={(e) => {
                updateForm(e, setFormState)
              }}
              value={
                formState.data.ssdStaticaTerritorialParameter.maxDeflectionG
              }
            ></TextField>
          </Grid>

          <Grid item md={1.5}>
            <TextField
              helperText={
                <div>
                  <Typography style={{ fontSize: 10 }}>
                    Max defflessione
                  </Typography>
                  <Typography style={{ fontSize: 10 }}>(mm')</Typography>
                </div>
              }
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='data.ssdStaticaTerritorialParameter.maxDeflectionMm'
              onChange={(e) => {
                updateForm(e, setFormState)
              }}
              value={
                formState.data.ssdStaticaTerritorialParameter.maxDeflectionMm
              }
            ></TextField>
          </Grid>

          <Grid item md={1.5}>
            <TextField
              helperText={
                <div>
                  <Typography style={{ fontSize: 10 }}>
                    Max defflessione
                  </Typography>
                  <Typography style={{ fontSize: 10 }}>(ss')</Typography>
                </div>
              }
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='data.ssdStaticaTerritorialParameter.maxDeflectionSs'
              onChange={(e) => {
                updateForm(e, setFormState)
              }}
              value={
                formState.data.ssdStaticaTerritorialParameter.maxDeflectionSs
              }
            ></TextField>
          </Grid>

          <Grid item md={1.5}>
            <TextField
              helperText={
                <div>
                  <Typography style={{ fontSize: 10 }}>Spessore del</Typography>
                  <Typography style={{ fontSize: 10 }}>
                    ghiaccio (mm)
                  </Typography>
                </div>
              }
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='data.ssdStaticaTerritorialParameter.iceThickness'
              onChange={(e) => {
                updateForm(e, setFormState)
              }}
              value={formState.data.ssdStaticaTerritorialParameter.iceThickness}
            ></TextField>
          </Grid>
        </Grid>

        <Grid padding={5} item md={2}>
          <h3>PALO</h3>
          <TextField
            style={{ width: '70%' }}
            helperText={
              <div>
                <Typography style={{ fontSize: 10 }}>Coefficiente</Typography>
                <Typography style={{ fontSize: 10 }}>Forma</Typography>
              </div>
            }
            variant='standard'
            type='number'
            name='cp'
            onChange={(e) => {
              updateForm(e, setStructureState)
            }}
            value={structureState.cp}
          ></TextField>
        </Grid>

        <Grid padding={5}>
          <EnhancedTableBox
            headerStyle={{ backgroundColor: 'white', color: 'blue' }}
            orderBy='baseHeight'
            heightPercentage='1'
            title='Tronchi'
            valueMember='id'
            data={structureState.ssdStaticaPoles}
            visibleColumns={visibleColumnsPoles}
            actions={actions}
            editJsonSchema={tronchiSchema}
            addButton
            onDataChange={onTronchiChange}
            onDataAdd={onTronchiAdd}
            onDataDelete={onTronchiDelete}
            search
          />

          <Grid item md={4}>
            <img src={pic2} style={{ width: '45%' }} alt='' />
          </Grid>
          <EnhancedTableBox
            headerStyle={{ backgroundColor: 'white', color: 'blue' }}
            orderBy='quote'
            heightPercentage='1'
            title='FLANGE'
            valueMember='id'
            data={structureState.ssdStaticaFlanges}
            visibleColumns={visibleColumnsFlanges}
            actions={actions}
            editJsonSchema={flangesSchema}
            addButton
            onDataChange={onFlangeChange}
            onDataAdd={onFlangeAdd}
            onDataDelete={onFlangeDelete}
            search
          />

          <Grid md={12} sx={{ height: '30px' }}></Grid>

          <Grid item md={4}>
            <EnhancedTableBox
              headerStyle={{ backgroundColor: 'white', color: 'blue' }}
              orderBy='height'
              heightPercentage='1'
              title='VINCOLI'
              valueMember='height'
              editJsonSchema={vincoliSchema}
              addButton
              onDataChange={onVincoliChange}
              onDataAdd={onVincoliAdd}
              onDataDelete={onVincoliDelete}
              data={structureState.ssdStaticaConstraints}
              visibleColumns={visibleColumnsVincoli}
              actions={actions}
              search
            />
          </Grid>

          <h3> PUNTONI </h3>

          <Grid container spacing={'1%'} padding={5}>
            <Grid item md={2}>
              <TextField
                helperText='Angolo (°)'
                FormHelperTextProps={{ style: { fontSize: 10 } }}
                style={{ width: '70%' }}
                variant='standard'
                type='number'
                name='ssdStaticaStrut.angle'
                onChange={(e) => {
                  updateForm(e, setStructureState)
                }}
                value={structureState.ssdStaticaStrut.angle}
              ></TextField>
            </Grid>

            <Grid item md={2}>
              <TextField
                helperText={
                  <div>
                    <Typography style={{ fontSize: 10 }}>Diametro</Typography>
                    <Typography style={{ fontSize: 10 }}>
                      Esterno (mm)
                    </Typography>
                  </div>
                }
                style={{ width: '70%' }}
                variant='standard'
                type='number'
                name='ssdStaticaStrut.outerDiameter'
                onChange={(e) => {
                  updateForm(e, setStructureState)
                }}
                value={structureState.ssdStaticaStrut.outerDiameter}
              ></TextField>
            </Grid>

            <Grid item md={2}>
              <TextField
                helperText='Spessore (mm)'
                style={{ width: '70%' }}
                variant='standard'
                type='number'
                name='ssdStaticaStrut.thickness'
                onChange={(e) => {
                  updateForm(e, setStructureState)
                }}
                value={structureState.ssdStaticaStrut.thickness}
              ></TextField>
            </Grid>

            <Grid item md={2}>
              <TextField
                helperText='Lunghezza (mm)'
                style={{ width: '70%' }}
                variant='standard'
                type='number'
                name='ssdStaticaStrut.length'
                onChange={(e) => {
                  updateForm(e, setStructureState)
                }}
                value={structureState.ssdStaticaStrut.length}
              ></TextField>
            </Grid>

            <Grid item md={1}>
              <TextFieldCombo
                helperText={
                  <div>
                    <Typography style={{ fontSize: 10 }}>Lati</Typography>
                    <Typography style={{ fontSize: 10 }}>Sezione</Typography>
                  </div>
                }
                apiURL='https://networkteam.it.nttdata-emea.com/api/scalar/statica/pole_geometries'
                valueMember='pole_side'
                displayMember='pole_side'
                selectedValue={structureState.ssdStaticaStrut.sideSection}
                onSelectChange={(e) => {
                  updateForm(e, setStructureState)
                }}
                type='text'
                name='ssdStaticaStrut.sideSection'
              />
            </Grid>

            <Grid item md={1}>
              <TextFieldCombo
                helperText={
                  <div>
                    <Typography style={{ fontSize: 10 }}>Tipo</Typography>
                    <Typography style={{ fontSize: 10 }}>Acciaio</Typography>
                  </div>
                }
                apiURL='https://networkteam.it.nttdata-emea.com/api/scalar/statica/steels'
                valueMember='type'
                displayMember='type'
                selectedValue={structureState.ssdStaticaStrut.steelType}
                onSelectChange={(e) => {
                  updateForm(e, setStructureState)
                }}
                type='text'
                name='ssdStaticaStrut.steelType'
              />
            </Grid>

            <Grid item md={2}>
              <TextField
                select
                helperText='Laminato'
                style={{ width: '70%' }}
                variant='standard'
                type='text'
                name='ssdStaticaStrut.laminate'
                onChange={(e) => {
                  updateForm(e, setStructureState)
                }}
                value={structureState.ssdStaticaStrut.laminate}
              >
                <MenuItem value={'a caldo'}>{'a caldo'}</MenuItem>
                <MenuItem value={'a freddo'}>{'a freddo'}</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Grid>

        <Grid padding={5}>
          <EnhancedTableBox
            headerStyle={{ backgroundColor: 'white', color: 'blue' }}
            orderBy='quote'
            heightPercentage='1'
            title='BALLATOI'
            valueMember='id'
            data={structureState.ssdStaticaGalleries}
            visibleColumns={visibleColumnsGalleries}
            actions={actions}
            editJsonSchema={ballatoiSchema}
            addButton
            onDataChange={onBallatoiChange}
            onDataAdd={onBallatoiAdd}
            onDataDelete={onBallatoiDelete}
            search
          />
        </Grid>

        <Grid padding={5}>
          <h3>SCALE E COASSIALI</h3>
          <Grid container spacing={'1%'} padding={5}>
            <Grid item md={4}>
              <TextField
                helperText='Larghezza Equivalente (m)'
                FormHelperTextProps={{ style: { fontSize: 10 } }}
                style={{ width: '70%' }}
                variant='standard'
                type='number'
                name='ssdStaticaScaleAndCoaxial.equivalentWidth'
                onChange={(e) => {
                  updateForm(e, setStructureState)
                }}
                value={structureState.ssdStaticaScaleAndCoaxial.equivalentWidth}
              ></TextField>
            </Grid>

            <Grid item md={4}>
              <TextField
                helperText='Peso per unità per Lunghezza (kg/m)'
                FormHelperTextProps={{ style: { fontSize: 10 } }}
                style={{ width: '70%' }}
                variant='standard'
                type='number'
                name='ssdStaticaScaleAndCoaxial.weightLength'
                onChange={(e) => {
                  updateForm(e, setStructureState)
                }}
                value={structureState.ssdStaticaScaleAndCoaxial.weightLength}
              ></TextField>
            </Grid>

            <Grid item md={4}>
              <TextField
                helperText='Coefficiente Forma'
                FormHelperTextProps={{ style: { fontSize: 10 } }}
                style={{ width: '70%' }}
                variant='standard'
                type='number'
                name='ssdStaticaScaleAndCoaxial.shapeCoefficient'
                onChange={(e) => {
                  updateForm(e, setStructureState)
                }}
                value={
                  structureState.ssdStaticaScaleAndCoaxial.shapeCoefficient
                }
              ></TextField>
            </Grid>
          </Grid>
        </Grid>

        <Grid padding={5}>
          <EnhancedTableBox
            headerStyle={{ backgroundColor: 'white', color: 'blue' }}
            orderBy='quote'
            heightPercentage='1'
            title='ALTRI CARICHI'
            valueMember='id'
            data={structureState.ssdStaticaOtherLoads}
            visibleColumns={visibleColumnsOtherLoads}
            actions={actions}
            editJsonSchema={altriCarichiSchema}
            addButton
            onDataChange={onaltriCarichiChange}
            onDataAdd={onaltriCarichiAdd}
            onDataDelete={onaltriCarichiDelete}
            search
          />
        </Grid>
        <Grid padding={5}>
          <Grid item md={12}>
            <Button variant='contained' onClick={saveData}>
              SAVE
            </Button>
          </Grid>
        </Grid>
      </div>
    )
}
export default PUNTONI
