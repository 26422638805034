import React from 'react'
import EnhancedTableBox from '../../components/EnhancedTableBox'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { Button, Grid } from '@mui/material'
import EnhancedIconButton from '../../components/EnhancedIconButton'

import useHttpService from '../../customHooks/useHttpService.js'
import { v4 as uuidv4 } from 'uuid'

const Threshold = () => {
  const httpService = useHttpService()
  const { uuid } = useParams()
  const history = useHistory()
  const [gridData, setGridData] = React.useState([])
  const [evalData, setEvalData] = React.useState([])
  const [siteInfo, setSiteInfo] = React.useState([])


  React.useEffect(() => {
    httpService.getAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/eval/thresholds?uuid_eval=${uuid}`,
      onDataLoaded
    )
    httpService.getAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/eval/eval?uuid=${uuid}`,
      updateEvalData
    )
  }, [])

  function onDataLoaded(data) {
    data.threshold.forEach((element) => {
      element.uuid = uuidv4()
    })
    setGridData(data.threshold)
  }

  function updateEvalData(jsondata) {
    setEvalData(jsondata[0])
    httpService.getAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/office/site_info?site_code=${jsondata[0].room_code}`,
      updateSiteInfo
    )
  }
  function updateSiteInfo(jsondata) {
    setSiteInfo(jsondata[0])
  }

  function onDataAdd(data) {
    setGridData([...gridData, data])
    let toSaveData = {
      uuid_eval: uuid,
      threshold: [...gridData, data],
    }

    httpService.putAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/eval/thresholds`,
      toSaveData,
      onSave
    )
  }

  function onDataChange(data) {
    let newData = []
    gridData.forEach((element) => {
      if (element.uuid === data.uuid) newData.push(data)
      else newData.push(element)
    })
    setGridData(newData)

    let toSaveData = {
      uuid_eval: uuid,
      threshold: newData,
    }

    httpService.putAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/eval/thresholds`,
      toSaveData,
      onSave
    )
  }

  function onDataDelete(data) {
    let newData = []
    gridData.forEach((element) => {
      if (element.uuid !== data.uuid) newData.push(element)
    })
    setGridData(newData)

    let toSaveData = {
      uuid_eval: uuid,
      threshold: newData,
    }

    httpService.putAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/eval/thresholds`,
      toSaveData,
      onSave
    )
  }

  function onSave() { }

  //array of the columns we want to show on the table
  const visibleColumns = [
    {
      id: 'threshold',
      alignRight: false,
      disablePadding: false,
      label: 'Threshold (V/m)',
    },
    {
      id: 'color',
      alignRight: false,
      disablePadding: false,
      label: 'Color',
      type: 'color',
    },
  ]

  //array of actions placed at the end of each row
  const actions = [
    {
      action: 'edit',
      icon: 'edit',
      tooltip: 'edit current row',
    },

    {
      action: 'delete',
      icon: 'delete',
      tooltip: 'delete current row',
    },
  ]

  const editSchema = {
    threshold: {
      title: 'Threshold (V/m)',
      type: 'number',
      md: 6,
    },
    color: {
      title: 'Color',
      type: 'color',
      md: 6,
    },
  }

  function goNext() {
    history.push(`${process.env.REACT_APP_CLIENT_PATH_NAME}/rnpPage/${uuid}`)
  }



  function startSimulation(data) {

    let message = {
      "queueMsg": {
        "status": null,
        "EVAL_UUID": evalData.uuid,
        "ABC_SN": 0,
        "ABC_CYCLES": 0,
        "CoverageWeight": null,
        "CoverageTreeshold": null,
        "InterferenceTreeshold": null,
        "roomName": siteInfo.site_name,
        "roomCode": siteInfo.site_code,
        "latWGS84": siteInfo.geometry.coordinates[1],
        "longWGS84": siteInfo.geometry.coordinates[0],
        "CALC_RADIUS": null,
        "CALC_RESOLUTION": null,
        "FOCUS_ZONE_RADIUS": null,
        "COMPUTATION_ZONE_RADIUS": null,
        "PROPAG_MODEL": null,
        "CELL_SIZE": null,
        "banda": null,
        "neType": null,
        "FBAND": null,
        "REPORT_COLUMN": null,
        "ZONE": null,
        "EDGECOVERAGEPROBABILITY": null,
        "SHADOWING": null,
        "INDOORCOVERAGE": null,
        "COMPUTATION_ZONE_SHAPE_UUID": null,
        "ZONE_SHAPE": false,
        "thresholdVariation": 100,
        "transmitters": [],
        "EVAL_PARENT_UUID": null,
        "ssdEmSectorValues": []
      }
    }

    evalData['final_result'] = 'CALCULATING'
    evalData['status'] = 'SUBMITTED'
    httpService.putAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/eval/eval`,
      evalData
    )

    console.log(JSON.stringify(message))
    httpService.postAPI(
      'https://networkteam.it.nttdata-emea.com/queue/push/ssd_em',
      message
    )

    history.push(
      `${process.env.REACT_APP_CLIENT_PATH_NAME}/evalMessages/${evalData.uuid}`
    )
    //window.location.href = `https://networkteam.it.nttdata-emea.com/Radiomod/cem/site/${formState.room_code}/simulations-history`
  
}


return (
  <Grid container spacing={5} padding={5}>
    <Grid item md={11}><Button variant='outlined' onClick={startSimulation}>Run em only simulation</Button></Grid>
    <Grid item md={1}>

      <EnhancedIconButton
        icon='arrow_forward'
        size='large'
        onClick={goNext}
        tooltip='Next step'
      />
    </Grid>
    <Grid item md={12}>
      <EnhancedTableBox
        heightPercentage='0.7'
        title='Thresholds'
        //apiURL={`https://networkteam.it.nttdata-emea.com/api/scalar/eval/thresholds_radiomod?uuid_eval=${uuid}`}
        data={gridData}
        valueMember='uuid'
        visibleColumns={visibleColumns}
        actions={actions}
        //onAction={onAction}
        addButton
        //addJsonSchema={{}}
        editJsonSchema={editSchema}
        onDataAdd={onDataAdd}
        onDataChange={onDataChange}
        onDataDelete={onDataDelete}
      />
    </Grid>
  </Grid>
)
}
export default Threshold
