import React from 'react';
import EnhancedTableBox from '../../components/EnhancedTableBox';
import { Box } from '@mui/material';
import useHttpService from '../../customHooks/useHttpService';
import { useMessageContext } from '../../contexts/messageContext';
import { v4 as uuidv4 } from 'uuid';


const NotifySym = () => {
	let messageContext = useMessageContext()
	const httpService = useHttpService();

	//array of the columns we want to show on the table
	const visibleColumns = [
		{
			id: 'id',
			alignRight: false,
			disablePadding: false,
			label: 'ID',
		},
		{
			id: 'name',
			alignRight: false,
			disablePadding: false,
			label: 'Name',
		},
		// {
		// 	id: 'correlatedNotifications',
		// 	alignRight: false,
		// 	disablePadding: false,
		// 	label: 'Correlated Notifications',
		// },
		{
			id: 'additionalText',
			alignRight: false,
			disablePadding: false,
			label: 'Additional Text',
		},
		{
			id: 'sourceIndicator',
			alignRight: false,
			disablePadding: false,
			label: 'Source Indicator',
		},
		// {
		// 	id: 'attributeList',
		// 	alignRight: false,
		// 	disablePadding: false,
		// 	label: 'Attribute List',
		// },
		// {
		// 	id: 'attributevaluechange',
		// 	alignRight: false,
		// 	disablePadding: false,
		// 	label: 'Attribute Value Change',
		// },
	];

	//array of actions placed at the end of each row
	const actions = [
		{
			action: 'NotifyMOICreation',
			icon: 'notification_add',
			tooltip: 'NotifyMOICreation',
		},
		{
			action: 'NotifyMOIAttributeValueChanges',
			icon: 'edit_notifications',
			tooltip: 'NotifyMOIAttributeValueChanges',
		},
		{
			action: 'NotifyMOIDeletion',
			icon: 'notifications_off',
			tooltip: 'NotifyMOIDeletion',
		},

		{
			action: 'clone',
			icon: 'content_copy',
			tooltip: 'Clone Notification',
		},

		{
			action: 'NotifyNewAlarm',
			icon: 'notifications_active',
			tooltip: 'NotifyNewAlarm',
		},
		{
			action: 'NotifyClearedAlarm',
			icon: 'notifications',
			tooltip: 'NotifyClearedAlarm',
		},


		{
			action: 'edit',
			icon: 'edit',
			tooltip: 'Edit Simulation',
		},

		{
			action: 'delete',
			icon: 'delete',
			tooltip: 'Delete Simulation',
		},
	];

	const editSchema = {
		name: {
			title: 'Name',
			type: 'text',
			md: 6,
		},
		correlatedNotifications: {
			title: 'correlatedNotifications',
			type: 'text',
			md: 6,
		},
		additionalText: {
			title: 'additionalText',
			type: 'text',
			md: 12,
			multiline: true,
		},
		sourceIndicator: {
			title: 'sourceIndicator',
			type: 'combo',
			url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_sym/sourceIndicator`,
			valueMember: 'val',
			displayMember: 'val',
			md: 6,
		},
		attributeList: {
			title: 'attributeList',
			type: 'text',
			md: 12,
			multiline: true,
		},
		attributevaluechange: {
			title: 'attributevaluechange',
			type: 'text',
			md: 12,
			multiline: true,
		},
	};


	function onAction(action, e) {
		console.log(action);
		switch (action) {
			case 'NotifyMOICreation':
				httpService.postAPI(
					`${process.env.REACT_APP_QUEUE_ADDRESS}/celery/push/NOTIFMOI/notifyMOIcreation`,
					{
						Name: 'notifyMOIcreation',
						moi_class: 'SYM',
						moi_id: e.id,
						Command: 'notifyMOIcreation',
					},
					(data) => { messageContext.setTaskId(data['task-id']) }
				)
				break;
			case 'NotifyMOIAttributeValueChanges':
				httpService.postAPI(
					`${process.env.REACT_APP_QUEUE_ADDRESS}/celery/push/NOTIFMOI/notifyMOIattributeValueChanges`,
					{
						Name: 'notifyMOIattributeValueChanges',
						moi_class: 'SYM',
						moi_id: e.id,
						Command: 'notifyMOIattributeValueChanges',
					},
					(data) => { messageContext.setTaskId(data['task-id']) }
				)
				break;
			case 'NotifyMOIDeletion':
				httpService.postAPI(
					`${process.env.REACT_APP_QUEUE_ADDRESS}/celery/push/NOTIFMOI/notifyMOIdeletion`,
					{
						Name: 'notifyMOIdeletion',
						moi_class: 'SYM',
						moi_id: e.id,
						Command: 'notifyMOIdeletion',
					},
					(data) => { messageContext.setTaskId(data['task-id']) }
				)
				break;
			case 'NotifyNewAlarm':
				httpService.postAPI(
					`${process.env.REACT_APP_QUEUE_ADDRESS}/celery/push/NOTIFALARM/notifyNewAlarm`,
					{
						Name: 'notifyNewAlarm',
						moi_class: 'SYM',
						moi_id: e.id,
						Command: 'notifyNewAlarm',
					},
					(data) => { messageContext.setTaskId(data['task-id']) }
				)


				// let newalarmjson = JSON.parse(e.attributeList);
				// newalarmjson["alarmId"] = e.id;
				// newalarmjson["notificationType"] = "notifyNewAlarm";
				// newalarmjson["alarmRaisedTime"] = new Date();

				// httpService.postAPI(
				// 	`${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_assurance/NotifyAlarm`,
				// 	newalarmjson,
				// 	(data) => { }
				// )

				break;
			case 'NotifyClearedAlarm':
				httpService.postAPI(
					`${process.env.REACT_APP_QUEUE_ADDRESS}/celery/push/NOTIFALARM/notifyClearedAlarm`,
					{
						Name: 'notifyClearedAlarm',
						moi_class: 'SYM',
						moi_id: e.id,
						Command: 'notifyClearedAlarm',
					},
					(data) => { messageContext.setTaskId(data['task-id']) }
				)


				// let clearalarmjson = JSON.parse(e.attributeList);
				// clearalarmjson["alarmid"] = e.id;
				// clearalarmjson["notificationtype"] = "notifyClearedAlarm";
				// clearalarmjson["alarmclearedtime"] = new Date();

				// httpService.postAPI(
				// 	`${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_assurance/NotifyAlarm`,
				// 	clearalarmjson,
				// 	(data) => { }
				// )
				break;
			case 'clone':
				let myclone = e;
				myclone['id'] = null;
				myclone['name'] = 'Clone_' + uuidv4();

				httpService.postAPI(
					`${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_sym/NotifySym`,
					myclone,
					(data) => { }
				)
				break;
			default:
				break;
		}
	}

	return (
		<Box sx={{ padding: '2%' }}>
			<EnhancedTableBox
				heightPercentage='0.7'
				title='NotifySym'
				apiURL={`${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_sym/NotifySym`}
				valueMember='id'
				visibleColumns={visibleColumns}
				actions={actions}
				onAction={onAction}
				addButton
				//addJsonSchema={{}}
				editJsonSchema={editSchema}
				search
			/>
		</Box>
	);
};
export default NotifySym;
