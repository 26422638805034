import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { Grid } from '@mui/material'
import ListBox from '../../components/ListBox'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'


import useHttpService from '../../customHooks/useHttpService.js'

import { styled } from '@mui/system'

const CommonListTitleBox = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '30px',
    paddingLeft: '7%',
    paddingRight: '7%',
    width: '115%',
})

const ManualTasks = () => {
    //state to trace data  loading
    const [dataLoaded, setDataLoaded] = useState(false)


    //states for json form

    const httpService = useHttpService()

    const [manualTaskState, setmanualTaskState] = React.useState([])



    const loadManualTaskData = useCallback(async () => {
        httpService.getAPI(
            `${process.env.REACT_APP_API_ADDRESS}/ngom_latest/workflow/manual_tasks`,
            onManualTaskData
        )
    }, []);

    useEffect(() => {
        setInterval(loadManualTaskData, 3000);
    }, [loadManualTaskData]);


    function onManualTaskData(jsondata) {
        setmanualTaskState(jsondata)
        setDataLoaded(true)
    }



    useEffect(() => {
        loadManualTaskData()
    }, [])



    function onmanualTaskChange(e) {
        window.open(`./manualTask/${e.id}`, "_blank", "noreferrer");

    }



    if (dataLoaded)
        return (
            <div>
                <Grid container spacing={3} padding={3}>
                    <Grid item md={12}>
                        <Paper elevation={3}>
                            <Grid container spacing={1}>
                                <CommonListTitleBox
                                    sx={{
                                        backgroundColor: 'background.light',
                                        color: 'primary.light',
                                    }}
                                >
                                    <Typography variant='h5' color='secondary'>
                                        Manual tasks waiting for user intervention
                                    </Typography>

                                </CommonListTitleBox>

                                <ListBox
                                    sx={{

                                        overflow: 'auto',
                                        paddingLeft: '5%',
                                        paddingRight: '5%',
                                    }}
                                    data={manualTaskState}
                                    valueMember='id'
                                    displayMember='run_id'
                                    onSelectChange={onmanualTaskChange}
                                />
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            </div>
        )
    else return <div></div>
}
export default ManualTasks
