import React from 'react'

import DeckGL, { FlyToInterpolator } from 'deck.gl';
import { GeoJsonLayer } from '@deck.gl/layers';


import { MapView } from '@deck.gl/core';
import { TileLayer } from '@deck.gl/geo-layers';
import { BitmapLayer } from '@deck.gl/layers';
import { IconLayer } from 'deck.gl';

import EnhancedIconButton from '../EnhancedIconButton'





const COPYRIGHT_LICENSE_STYLE = {
  position: 'absolute',
  right: 0,
  bottom: 0,
  backgroundColor: 'hsla(0,0%,100%,.5)',
  padding: '0 5px',
  font: '12px/20px Helvetica Neue,Arial,Helvetica,sans-serif'
};

const LINK_STYLE = {
  textDecoration: 'none',
  color: 'rgba(0,0,0,.75)',
  cursor: 'grab'
};




function Map(props) {
  const [initialViewState, setInitialViewState] = React.useState(props.initialViewState);

  const zoomToMyLocation = React.useCallback(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {

        setInitialViewState({
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
          zoom: 7,
          pitch: 45,
          bearing: 0,
          transitionDuration: 5000,
          transitionInterpolator: new FlyToInterpolator()
        });

      });
    } else {
      console.log("Geolocation Not Available");
    }
  }, []);


  const zoomToGeoJson = React.useCallback(() => {


    setInitialViewState({
      longitude: props.geoJson.coordinates[0],
      latitude: props.geoJson.coordinates[0],
      zoom: 7,
      pitch: 0,
      bearing: 0,
      transitionDuration: 5000,
      transitionInterpolator: new FlyToInterpolator()
    });


  }, []);


  const tileLayer = new TileLayer({
    // https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#Tile_servers
    data: [
      'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
      'https://b.tile.openstreetmap.org/{z}/{x}/{y}.png',
      'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png'
    ],

    // Since these OSM tiles support HTTP/2, we can make many concurrent requests
    // and we aren't limited by the browser to a certain number per domain.
    maxRequests: 20,

    pickable: true,
    onClick: (info, event) => props.onClick && props.onClick(info, event),
    highlightColor: [60, 60, 60, 40],
    // https://wiki.openstreetmap.org/wiki/Zoom_levels
    minZoom: 0,
    maxZoom: 19,
    tileSize: 256,
    zoomOffset: devicePixelRatio === 1 ? -1 : 0,
    renderSubLayers: props => {
      const {
        bbox: { west, south, east, north }
      } = props.tile;

      return [
        new BitmapLayer(props, {
          data: null,
          image: props.data,
          bounds: [west, south, east, north]
        }),

      ];
    }
  });

  const geoJsonLayer = new GeoJsonLayer({
    id: 'geojson-layer',
    data: props.geoJson,
    pickable: true,
    stroked: false,
    filled: true,
    extruded: true,
    pointType: 'circle',
    lineWidthScale: 200,
    lineWidthMinPixels: 20,
    getFillColor: [255, 0, 0, 255],
    getLineColor: [0, 0, 255, 255],
    getPointRadius: 5000,
    getLineWidth: 20,
    getElevation: 30
  });


  const iconLayer = new IconLayer({
    id: 'icon-layer',
    data: props.geoJson,
    pickable: true,
    sizeScale: 50,
    getPosition: d => d.geometry && d.geometry.coordinates,
    getIcon: d => ({
      url: `${process.env.PUBLIC_URL}/location.png`,
      width: 65,
      height: 102,
      anchorY: 102
    }),
    onClick: (info, event) => props.onClick && props.onClick(info, event)
  });

  //const layers = [new LineLayer({ id: 'line-layer', data })]

  return (



    <div style={props.style}>

      <DeckGL
        layers={[tileLayer, iconLayer]}
        views={new MapView({ repeat: true })}


        initialViewState={initialViewState}
        controller={true}
      //layers={layers}
      >
        <div style={{ position: 'absolute', left: 0, top: 0 }}>
          <EnhancedIconButton
            icon="adjust_circle"
            size="large"
            onClick={zoomToMyLocation}
            tooltip="Zoom on my location"
          />
        </div>
        {/* <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} /> */}


        <div style={COPYRIGHT_LICENSE_STYLE}>
          {'© '}
          <a style={LINK_STYLE} href="http://www.openstreetmap.org/copyright" target="blank">
            OpenStreetMap contributors
          </a>
        </div>
      </DeckGL>

    </div >
  )
}

export default Map
