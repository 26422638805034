import React, { useEffect } from 'react'
import CardBox from '../../components/CardBox/CardBox'

import { list } from '../../schema/NSI-list/listData-NSI'
import cardMain from '../../schema/NSI-list/mainData-NSI'

import { outsideActions } from '../../schema/NSI-list/outsideData-NSI'

import { useCardBoxContext } from '../../contexts/cardBoxContext'
import useSaveDeleteCardBoxContent from '../../customHooks/useSaveDeleteCardBoxContent'
import useHttpService from '../../customHooks/useHttpService'
import { useSnackbar } from '../../contexts/snackbarContext'

import { checkWhichMissingFields } from '../../utils'

function NSIList() {
  const cardBoxContext = useCardBoxContext()
  const httpService = useHttpService()
  const snackbar = useSnackbar()

  const {
    putCardBoxMainJsonData,
    deleteCardBoxMainJsonData,
    deleteCardBoxListData,
    postCardBoxMainJsonData,
    importExcel,
    exportExcel,
  } = useSaveDeleteCardBoxContent()

  //init the cardBoxContext with the list coming from the schema
  useEffect(() => {
    cardBoxContext.init(list, cardMain)
  }, [])

  //assign onClick handlers to the rightIcons array of icons you have on the dataTemplate of your listSchema
  useEffect(() => {
    if (cardBoxContext.cardListSchema) {
      cardBoxContext.cardListSchema.dataTemplate.rightIcons?.forEach((icon) => {
        if (icon.icon === 'delete') {
          icon.onClick = (e, index, context) =>
            deleteCardBoxListData(icon, index, context)
        }
      })
    }
  }, [cardBoxContext.cardListSchema])

  //Icons on the bottom of the card
  //assign onClick handlers to the actions array of icons you have on the cardMain schema

  useEffect(() => {
    if (cardBoxContext.cardMainSchema) {
      cardBoxContext.cardMainSchema.actions.forEach((action) => {
        if (action.icon === 'save')
          action.onClick = (e, data, context) => {
            const missingFields = checkWhichMissingFields(
              data,
              context.cardMainSchema.schema.fields
            )
            if (missingFields.length > 0) {
              snackbar.setSnackbarInfo({
                status: 'error',
                message: `Before saving, please set the following ${
                  missingFields.length === 1 ? 'field' : 'fields'
                }: ${missingFields.join(', ')}`,
              })
            } else putCardBoxMainJsonData(data, action, context)
          }
        if (action.icon === 'delete')
          action.onClick = (e, data, context) => {
            console.log(e, data, context)
            deleteCardBoxMainJsonData(action, data, context)
          }
      })
    }
  }, [cardBoxContext.cardMainSchema])

  //Assigning onChange handler to the combo box showing the nssi releated to the nsi
  useEffect(() => {
    cardMain.schema.fields.NetworkSliceSubnet_id.onChange = (data) => {
      console.log(data)
      //checking if the nsi i'm going to delete is the last one associated with its nssi. If so, I'll warn the user
      if (data.NetworkSliceSubnet_id === '_') {
        httpService.getAPI(
          `${cardBoxContext.cardMainSchema.schema.fields.NetworkSliceSubnet_id.url}`,
          (nsData) => {
            const ns = nsData.find((el) =>
              el?.NetworkSlices?.find((ns) => ns === data.id)
            )
            if (ns?.NetworkSlices != null && ns?.NetworkSlices.length === 1) {
              snackbar.setSnackbarInfo({
                status: 'warning',
                message: `The "${ns.name}" is now not linked to any NSI, then no service profile will be activated on the NFs`,
              })
            }
          }
        )
      }
    }
  }, [cardBoxContext.cardMainSchema])

  //Icons on the very bottom of the page
  //Assign onClick handlers for the icons outside the list and the card
  useEffect(() => {
    if (outsideActions) {
      outsideActions.map((action) => {
        if (action.type === 'dialog') {
          action.dialog.buttons.forEach((button) => {
            if (button.type === 'close') {
              button.onClick = () => {}
            }
            if (button.type === 'cancel') {
              button.onClick = () => {}
            }
            if (button.type === 'save') {
              button.onClick = (e, data, context) => {
                postCardBoxMainJsonData(data, action, context)
              }
            }
          })
        }
        if (action.type === 'upload') {
          action.onClick = (e, action, context) => {
            importExcel(e, action, context)
          }
        }
        if (action.icon === 'download') {
          action.onClick = (action) => {
            exportExcel(action, true)
          }
        }
      })
    }
  }, [outsideActions])

  return (
    <>
      {cardBoxContext.listData && <CardBox outsideActions={outsideActions} />}
    </>
  )
}

export default NSIList
