import { v4 as uuidv4 } from 'uuid'

export const onDataChange = () => {}
const doNothing = () => {}
const saveData = () => {}

const cardMainActions = [
  {
    icon: 'celebration',
    type: 'icon',
    place: 'top',
    label: 'ONLINE',
    onClick: doNothing,
    uniqueKey: uuidv4(),
    sx: { cursor: 'unset' },
    tooltip: `General State calculated on Administrative and Operational state values`,
  },
  {
    icon: 'delete',
    iconSize: 'large',
    place: 'bottom',
    onClick: doNothing,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSlice`,
    tooltip: 'Delete',
    uniqueKey: uuidv4(),
  },
  {
    icon: 'save',
    iconSize: 'large',
    place: 'bottom',
    onClick: saveData,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSlice`,
    tooltip: 'Save Changes',
    uniqueKey: uuidv4(),
  },
]

const schemaFields = {
  administrativeState: {
    title: 'Administrative State',
    md: 4,
    type: 'combo',
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/administrativeState`,
    valueMember: 'val',
    displayMember: 'val',
  },
  operationalState: {
    title: 'Operational State',
    type: 'text',
    md: 4,
    disabled: true,
  },
  name: {
    title: 'NSI name',
    type: 'string',
    md: 6,
    mandatory: true,
    showAsterisk: false,
  },
  ServiceProfile: {
    title: 'Nest (GST)',
    type: 'text',
    md: 6,
    disabled: true,
  },
  PlmnId_id: {
    title: 'PLMN',
    type: 'combo',
    md: 6,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/PlmnId`,
    valueMember: 'id',
    displayMember: 'name',
    disabled: true,
  },
  NetworkSliceSubnet_id: {
    title: 'NSSI',
    type: 'combo',
    md: 4,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSliceSubnet`,
    valueMember: 'id',
    displayMember: 'name',
    allowEmptySelection: true,
    onChange: doNothing,
  },
  id: {
    title: 'ID',
    type: 'text',
    md: 8,
    disabled: true,
  },
  // prova: {
  //   title: 'JSON',
  //   type: 'json',
  //   md: 8,
  // },
}

const schemaValues = {
  administrativeState: 'Unlocked',
  operationalState: 'Enabled',
  name: 'MIoT Milano1',
  NST: 'NSTIDx',
  PLMN: 'PLMN1',
  NSS: 'NSSIDb',
  id: '',
}

const cardBoxStyleMain = {
  cardStyle: {
    width: '65vw',
    height: '75vh',
    backgroundColor: 'background.dark',
  },
  titleStyle: {
    marginTop: '30px',
    backgroundColor: 'background.light',
    paddingLeft: '6%',
    paddingRight: '6%',
    color: 'primary.light',
    width: '102%',
  },
  iconStyle: {
    topBox: {
      // border: '1px solid red',
    },
    bottomBox: {
      // border: '1px solid green',
    },
  },
}

const titleDataMain = {
  variant: 'h4',
}

const cardMain = {
  actions: cardMainActions,
  schema: {
    fields: schemaFields,
    values: schemaValues,
  },
  onDataChange,
  style: cardBoxStyleMain,
  title: titleDataMain,
}

export default cardMain
