import React from 'react'
import EnhancedTableBox from '../../components/EnhancedTableBox'
import { Box } from '@mui/material'

const ManagedElement = () => {
    //array of the columns we want to show on the table
    const visibleColumns = [
        {
            id: 'id',
            alignRight: false,
            disablePadding: false,
            label: 'ID',
        },
        // {
        //     id: 'val',
        //     alignRight: false,
        //     disablePadding: false,
        //     label: 'Json Values',
        // }
    ]

    //array of actions placed at the end of each row
    const actions = [
        {
            action: 'edit',
            icon: 'edit',
            tooltip: 'edit current row',
        },

        {
            action: 'delete',
            icon: 'delete',
            tooltip: 'delete current row',
        },
    ]

    const editSchema = {
        id: {
            title: 'ID',
            type: 'text',
            md: 6,
        },
        val: {
            title: 'Json Values',
            type: 'text',
            md: 12,
            multiline: true,
        },

    }

    return (
        <Box sx={{ padding: '2%' }}>
            <EnhancedTableBox
                heightPercentage='0.7'
                title='Managed Element'
                apiURL={`${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_sym/ManagedElement_Page`}
                valueMember='id'
                visibleColumns={visibleColumns}
                actions={actions}
                //onAction={onAction}
                addButton
                //addJsonSchema={{}}
                editJsonSchema={editSchema}
                search
            />
        </Box>
    )
}
export default ManagedElement
