import React, { useState } from 'react'
import { Tabs, Tab, Box } from '@mui/material'
import Kpi from './Kpi'
import Threshold from './Threshold'
import { v4 as uuidv4 } from 'uuid'
import { useCardBoxContext } from '../../../contexts/cardBoxContext'

const Performance = () => {
  const cards = [
    { component: <Kpi />, name: 'PERFORMANCE' },
    { component: <Threshold />, name: 'THRESHOLD SETTINGS' },
  ]
  const [activeCard, setActiveCard] = useState(0)
  const cardBoxContext = useCardBoxContext()

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: '1%',
        }}
      >
        <Tabs
          value={activeCard}
          onChange={(e) => {
            let index = -1
            cards.find((card, i) => {
              if (card.name === e.target.outerText) index = i
            })
            setActiveCard(index)
          }}
          variant='scrollable'
          scrollButtons='auto'
          aria-label='scrollable auto tabs example'
        >
          {cards.map((card) => (
            <Tab
              key={uuidv4()}
              sx={{
                backgroundColor: 'background.dark',
                color: '#564800',
              }}
              label={card.name}
            />
          ))}
        </Tabs>
      </Box>
      {cards[activeCard].component}
    </>
  )
}
export default Performance
