import React, { useEffect } from 'react'
import CardBox from '../../components/CardBox/CardBox'

import { list } from '../../schema/NSSI-list/listData-NSSI'
import cardMain from '../../schema/NSSI-list/mainData-NSSI'

import { outsideActions } from '../../schema/NSSI-list/outsideData-NSSI'

import { useCardBoxContext } from '../../contexts/cardBoxContext'
import useSaveDeleteCardBoxContent from '../../customHooks/useSaveDeleteCardBoxContent'
import useHttpService from '../../customHooks/useHttpService'
import { useSnackbar } from '../../contexts/snackbarContext'
import { checkWhichMissingFields } from '../../utils'

function NSSIList() {
  const cardBoxContext = useCardBoxContext()
  const httpService = useHttpService()
  const snackbar = useSnackbar()

  const {
    putCardBoxMainJsonData,
    deleteCardBoxMainJsonData,
    deleteCardBoxListData,
    postCardBoxMainJsonData,
    importExcel,
    exportExcel,
  } = useSaveDeleteCardBoxContent()

  //Adding handlers to cardMain schema properties, if needed (ONLY onChange FUNCTION ON TYPE combo IS IMPLEMENTED!)
  useEffect(() => {
    cardMain.schema.fields.administrativeState.onChange = (data) => {
      if (
        data.administrativeState === 'UNLOCKED' &&
        (data.NetworkSlices === null || data.NetworkSlices.length === 0)
      ) {
        snackbar.setSnackbarInfo({
          status: 'warning',
          message: `The ${data.name} will be unlocked but no service profile will be applied on the NFs`,
        })
      }
    }
  }, [])

  //init the cardBoxContext with the list coming from the schema
  useEffect(() => {
    cardBoxContext.init(list, cardMain)
  }, [])

  //Icons in the list, on the right of the name
  //assign onClick handlers to the rightIcons array of icons you have on the dataTemplate of your listSchema
  useEffect(() => {
    if (cardBoxContext.cardListSchema) {
      cardBoxContext.cardListSchema.dataTemplate.rightIcons?.forEach((icon) => {
        if (icon.icon === 'delete') {
          icon.onClick = (e, index, context) => {
            deleteCardBoxListData(icon, index, context)
          }
        }
      })
    }
  }, [cardBoxContext.cardListSchema])

  //Icons in the card, on the top
  //Assign onClick handlers to the icons inside the card, on the top of it
  useEffect(() => {
    cardBoxContext?.choosenItem?.id &&
      cardBoxContext?.cardMainSchema?.actions?.forEach((contextAction) => {
        if (contextAction.label === 'Policy (parameters)') {
          const url = `${contextAction.dialog.url}${cardBoxContext.choosenItem.id}`
          httpService.getAPI(url, (data) => {
            cardMain.actions.forEach((cardMainAction) => {
              if (cardMainAction.label === 'Policy (parameters)') {
                //converting coverageAreaTAList from array to string
                data[0].coverageAreaTAList = data[0].coverageAreaTAList
                  ? data[0].coverageAreaTAList.toString()
                  : null
                cardMainAction.dialog.dialogData = data[0]
                cardBoxContext.init(list, cardMain)
              }
            })
          })
        }
        if (contextAction.label === 'Alarms') {
          contextAction.params = cardBoxContext.choosenItem.id
          contextAction.onClick = () => {}
        }
        if (contextAction.label === 'Kpi') {
          contextAction.params = cardBoxContext.choosenItem.id
          contextAction.onClick = () => {}
        }
        if (contextAction.label === 'Old Configurations') {
          contextAction.params = `${cardBoxContext.choosenItem.name}/${cardBoxContext.choosenItem.id}`
          contextAction.onClick = () => {
            cardBoxContext.emptyListData()
            cardBoxContext.emptyMainCard()
            cardBoxContext.emptyChoosenItem()
          }
        }
      })
  }, [cardBoxContext.choosenItem])

  //Icons on the bottom of the card
  //assign onClick handlers to the actions array of icons you have on the cardMain schema
  useEffect(() => {
    if (cardBoxContext.cardMainSchema) {
      cardBoxContext.cardMainSchema.actions.forEach((action) => {
        // if (action.icon === 'save')
        //   action.onClick = (e, data, context) =>
        //     putCardBoxMainJsonData(data, action, context)
        if (action.icon === 'delete')
          action.onClick = (e, data, context) => {
            // if(context.choosenItem.showClock){
            //   snackbar.setSnackbarInfo({
            //     status: 'error',
            //     message: `It is not possible to delete this NSSI because his creation is scheduled`,
            //   })
            // } else{
            //   deleteCardBoxMainJsonData(action, data, context)
            // }
            deleteCardBoxMainJsonData(action, data, context)
          }

        if (action.type === 'dialog') {
          action.dialog.buttons.forEach((button) => {
            if (button.type === 'close') {
              button.onClick = () => {}
            }
            if (button.type === 'cancel') {
              button.onClick = () => {}
            }
            if (button.type === 'save') {
              button.onClick = (e, data, context) => {
                console.log(data, context.cardMainSchema)
                const missingFields = checkWhichMissingFields(
                  data,
                  context.cardMainSchema.schema.fields
                )
                const missingPolicyParameters = checkWhichMissingFields(
                  data,
                  context.cardMainSchema.actions.find(
                    (a) => a.label === 'Policy (parameters)'
                  ).dialog.schemaFields
                )
                console.log(missingPolicyParameters)
                if (missingFields.length > 0) {
                  snackbar.setSnackbarInfo({
                    status: 'error',
                    message: `Before saving, please set the following ${
                      missingFields.length === 1 ? 'field' : 'fields'
                    }: ${missingFields.join(', ')}`,
                  })
                } else if (missingPolicyParameters.length > 0) {
                  snackbar.setSnackbarInfo({
                    status: 'error',
                    message: `Before saving, please set the following Policy ${
                      missingPolicyParameters.length === 1
                        ? 'parameter'
                        : 'parameters'
                    }: ${missingPolicyParameters.join(', ')}`,
                  })
                } else putCardBoxMainJsonData(data, action, context)
              }
            }
          })
        }
      })
    }
  }, [cardBoxContext.cardMainSchema])

  //Icons on the very bottom of the page
  //Assign onClick handlers for the icons outside the list and the card
  useEffect(() => {
    if (outsideActions) {
      outsideActions.map((action) => {
        if (action.type === 'dialog') {
          action.dialog.buttons.forEach((button) => {
            if (button.type === 'close') {
              button.onClick = () => {}
            }
            if (button.type === 'cancel') {
              button.onClick = () => {}
            }
            if (button.type === 'save') {
              button.onClick = (e, data, context) => {
                postCardBoxMainJsonData(data, action, context)
              }
            }
          })
        }
        if (action.type === 'upload') {
          action.onClick = (e, action, context) => {
            importExcel(e, action, context)
          }
        }
        if (action.icon === 'download') {
          action.onClick = (action) => {
            exportExcel(action, true)
          }
        }
        if (action.icon === 'refresh') {
          action.onClick = (e, context) => {
            // const searchedItem = context.listData
            //   .map((el, index) => {
            //     if (el.infos.id === context.choosenItem.id) {
            //       return { ...el, index }
            //     }
            //     return null
            //   })
            //   .find((el) => el)

            // context.setSelectedItemFromOtherPage(searchedItem)
            context.emptyMainCard()
            context.emptyListData()
            context.emptyChoosenItem()
            context.setIsRefreshed(true)
          }
        }
      })
    }
  }, [outsideActions])

  useEffect(() => {
    if (cardBoxContext.choosenItem) {
      const tmp = { ...cardBoxContext.cardMainSchema }
      if (
        cardBoxContext.choosenItem.num_cell_started >
        cardBoxContext.choosenItem.num_cell_end
      ) {
        tmp.actions.forEach((action) => {
          if (
            action.place === 'bottom' &&
            (action.icon === 'save' || action.icon === 'delete')
          ) {
            action.disabled = true
          }
        })
        snackbar.setSnackbarInfo({
          status: 'warning',
          message: `NSSI unmodified due to configuration in progress, waiting for all the NF cells to be ready.`,
        })
      } else {
        snackbar.setSnackbarInfo({ open: false })
        tmp.actions.forEach((action) => {
          if (
            action.place === 'bottom' &&
            (action.icon === 'save' || action.icon === 'delete')
          ) {
            action.disabled = false
          }
        })
      }
      cardBoxContext.setCardMainSchema(tmp)
    }
  }, [cardBoxContext.choosenItem])

  //render the CardBox component if there are data to show, passing the outside buttons if needed
  return (
    <>
      {cardBoxContext.listData && <CardBox outsideActions={outsideActions} />}
    </>
  )
}

export default NSSIList
