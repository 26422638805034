import React, { useState } from 'react'
import { Grid } from '@mui/material'
import EnhancedTableBox from '../../components/EnhancedTableBox'
import { useParams } from 'react-router-dom'

export default function TenantTechnology() {
    const { tenant } = useParams()
    const visibleColumns = [

        {
            id: 'nominal_frequency',
            alignRight: false,
            disablePadding: false,
            label: 'Nominal Frequency',
        },
        {
            id: 'base_frequency',
            alignRight: false,
            disablePadding: false,
            label: 'Base Frequency',
        },
    ]

    const actions = [
        {
            action: 'edit',
            icon: 'edit',
            tooltip: 'edit tenant',
        },
        {
            action: 'delete',
            icon: 'delete',
            tooltip: 'delete tenant',
        },
    ]

    const schema = {
        nominal_frequency: {
            title: 'Nominal Frequency',
            type: 'number',
            md: 5,
        },

        base_frequency: {
            title: 'Base Frequency',
            type: 'number',
            md: 5,
        },
    }

    return (
        <div>
            <Grid container spacing={5} padding={5}>
                <Grid item md={12}>
                    <EnhancedTableBox
                        title={`${tenant} : List of Technologies`}
                        addButton
                        apiURL={`https://networkteam.it.nttdata-emea.com/api/scalar/common/tenants_nominal_frequencies?tenant=${tenant}`}
                        valueMember='nominal_frequency'
                        visibleColumns={visibleColumns}
                        addJsonSchema={schema}
                        editJsonSchema={schema}
                        actions={actions}
                    />
                </Grid>
            </Grid>
        </div>
    )
}