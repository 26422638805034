import React from 'react'
import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import customtheme from './theme.js'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import TitleBar from './components/TitleBar'
import { ConfirmProvider } from 'material-ui-confirm'
import RouterBreadcrumbs from './components/RouterBreadcrumbs'
import routes from './routes'
import { UserContextProvider } from './contexts/userContext.js'
import { MessageContextProvider } from './contexts/messageContext.js'
import { CardBoxContextProvider } from './contexts/cardBoxContext.js'
import { SnackbarProvider } from './contexts/snackbarContext.js'

export default function App() {
  //if (localStorage.getItem('token') === null) {
  // if (!UserService.isLoggedIn()) {
  //   UserService.doLogin()
  //   //return <Login />
  //   return <div></div>
  // } else {
  //   console.log(UserService.getToken())
  //   console.log(UserService.getRoles('localhostWebpage'))
  // }

  return (
    <ThemeProvider theme={customtheme}>
      <UserContextProvider>
        <MessageContextProvider>
          <CssBaseline />
          <ConfirmProvider>
            <Router>
              <div>
                <Switch>
                  {routes.map(({ path, name, Component }, key) => (
                    <Route
                      exact
                      path={path}
                      key={key}
                      render={(props) => {
                        const crumbs = routes
                          // Get all routes that contain the current one.
                          .filter(({ path }) => props.match.path.includes(path))
                          // Swap out any dynamic routes with their param values.
                          // E.g. "/pizza/:pizzaId" will become "/pizza/1"
                          .map(({ path, ...rest }) => ({
                            path: Object.keys(props.match.params).length
                              ? Object.keys(props.match.params).reduce(
                                  (path, param) =>
                                    path.replace(
                                      `:${param}`,
                                      props.match.params[param]
                                    ),
                                  path
                                )
                              : path,
                            ...rest,
                          }))

                        // console.log(`Generated crumbs for ${props.match.path}`)
                        // crumbs.map(({ name, path }) =>
                        //   console.log({ name, path })
                        // )

                        return (
                          <div>
                            <SnackbarProvider>
                              <TitleBar
                                pages={routes}
                                selectedTab={props.match.path}
                              />
                              <RouterBreadcrumbs crumbs={crumbs} />
                              <CardBoxContextProvider>
                                <Component {...props} />
                              </CardBoxContextProvider>
                            </SnackbarProvider>
                          </div>
                        )
                      }}
                    />
                  ))}
                </Switch>
              </div>
              {/* <div>Fixed footer</div> */}
            </Router>
          </ConfirmProvider>
        </MessageContextProvider>
      </UserContextProvider>
    </ThemeProvider>
  )
}
