import { v4 as uuidv4 } from 'uuid'
const doNothing = () => {}
const handleTextClick = () => {}

const apiDataToShow = ['name', 'id']
const dataTemplate = {
  infos: {},
  content: '',
  onTextClick: handleTextClick,
  onIconClick: doNothing,
  leftIcons: [
    {
      icon: 'arrow_right',
      iconSize: 'large',
      infos: {},
      uniqueKey: uuidv4(),
    },
  ],
  rightIcons: [],
}

const apiSpecificItem = `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_oss_object/NetworkElement?id=`
const api = `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_oss_object/NetworkElement_short`

const cardBoxStyleList = {
  cardStyle: {
    width: '20vw',
    height: '75vh',
  },
  textItemStyle: { textAlign: 'right' },
  titleStyle: {
    marginTop: '30px',
    backgroundColor: 'background.light',
    paddingLeft: '7%',
    paddingRight: '7%',
    color: 'primary.light',
    width: '105%',
  },
}

const titleDataList = {
  title: 'List Of Network Elements',
  variant: 'h4',
}

export const list = {
  api,
  apiSpecificItem,
  apiDataToShow,
  dataTemplate,
  cardBoxStyleList,
  titleDataList,
}
