import React, { useState } from 'react'
import { Grid } from '@mui/material'
import EnhancedTableBox from '../../components/EnhancedTableBox'
import EnhancedIconButton from '../../components/EnhancedIconButton'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export default function TECH() {
  const [selectedtechnology, setSelectedtechnology] = useState()
  function onChangetechnology(e) {
    setSelectedtechnology(e.technology)
  }

  const visibleColumns = [
    {
      id: 'technology',
      alignRight: false,
      disablePadding: false,
      label: 'Technology',
    },
    {
      id: 'nominal_frequency',
      alignRight: true,
      disablePadding: false,
      label: 'Nominal Frequency',
    },

    {
      id: 'tech_type',
      alignRight: true,
      disablePadding: false,
      label: 'Tech Type',
    },
  ]


  const schema = {
    technology: {
        title: 'Technology',
        type: 'text',
        md: 6,
    },
    nominal_frequency: {
        title: 'Nominal Frequency',
        type: 'number',
        md: 6,
    },
    tech_type: {
        title: 'Technology Type',
        type: 'text',
        md: 6,
    },
}
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  //array of actions placed at the end of each row
  const actions = [
    {
      action: 'edit',
      icon: 'edit',
      tooltip: 'edit current row',
    },

    {
      action: 'delete',
      icon: 'delete',
      tooltip: 'delete current row',
    },
  ]

  return (
    <div>
      <Grid container spacing={5} padding={5}>
        <Grid item md={12}>
          <EnhancedTableBox
            title='List of technologies'
            addButton
            onSelectChange={onChangetechnology}
            apiURL='https://networkteam.it.nttdata-emea.com/api/scalar/common/tech_to_freq'
            deleteURL='https://networkteam.it.nttdata-emea.com/api/scalar/common/technologies'            
            valueMember='id'
            visibleColumns={visibleColumns}
            actions={actions}
            addJsonSchema={schema}
            editJsonSchema={schema}
          />
        </Grid>
      </Grid>
    </div>
  )
}
