import React, { useState, useEffect, useRef } from 'react'
import { Grid, Typography } from '@mui/material'
import ComboBox from '../../components/ComboBox'
import EnhancedTableBox from '../../components/EnhancedTableBox'
import EnhancedIconButton from '../../components/EnhancedIconButton'
import JsonSchemaBox from '../../components/JsonSchemaBox'
import { Button } from '@mui/material'
import useHttpService from '../../customHooks/useHttpService'
import { useParams } from 'react-router-dom'
import { isUndefined } from 'lodash'
import { useSnackbar } from '../../contexts/snackbarContext'
import { fromDBDateToGUIDate } from '../../utils'

const Alarms = () => {
  const [formData, setFormData] = useState()
  const [alarmTableData, setAlarmTableData] = useState(null)
  const [comingWordToSearch, setComingWordToSearch] = useState(null)
  const isInitialSearch = useRef(true)
  const searchFilters = useRef(null)
  const httpService = useHttpService()
  const pathParams = useParams()
  const snackbar = useSnackbar()

  const filterApiUrl = `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_assurance/AlarmsReport`
  const downloadApiUrl = `${process.env.REACT_APP_FILEIO_ADDRESS}/nssmf_assurance/AlarmsReport/export`

  //array of the columns we want to show on the table
  const visibleColumns = [
    {
      id: 'objectType',
      alignRight: false,
      disablePadding: false,
      label: 'Object Type',
    },
    {
      id: 'name',
      alignRight: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'objectID',
      alignRight: false,
      disablePadding: false,
      label: 'Object ID',
    },
    {
      id: 'involvedObject',
      alignRight: false,
      disablePadding: false,
      label: 'Involved Object',
    },
    {
      id: 'cause',
      alignRight: false,
      disablePadding: false,
      label: 'Alarm Cause',
    },
    {
      id: 'severity',
      alignRight: false,
      disablePadding: false,
      label: 'Severity',
    },
    {
      id: 'started',
      alignRight: false,
      disablePadding: false,
      label: 'Started',
      type: 'datetime',
    },

    {
      id: 'canceled',
      alignRight: false,
      disablePadding: false,
      label: 'Cleared',
      type: 'datetime',
    },
  ]

  //array of actions placed at the end of each row
  const actions = []

  //event that is fired when an action is clicked
  function onAction(action, row, e) {
    //set the clicked row in a state
    alert(action + ' on row:' + JSON.stringify(row))
  }

  const jsonSchema = {
    objectType: {
      title: 'Object Type',
      type: 'combo',
      md: 2,
      mockedData: [{ val: 'NSS' }, { val: 'NF' }],
      displayMember: 'val',
      valueMember: 'val',
      multiple: true,
    },
    severity: {
      title: 'Severity',
      type: 'combo',
      md: 2,
      url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_assurance/PerceivedSeverity`,
      multiple: true,
      displayMember: 'val',
      valueMember: 'val',
    },
    status: {
      title: 'Status',
      type: 'combo',
      md: 2,
      mockedData: [{ val: 'Active' }, { val: 'Not Active' }],
      displayMember: 'val',
      valueMember: 'val',
      multiple: true,
    },
    from: {
      title: 'From',
      type: 'date',
      md: 3,
      disablePast: false,
    },
    to: {
      title: 'To',
      type: 'date',
      md: 3,
      disablePast: false,
    },
  }

  //CHANGE THE ALARMS SEARCH RANGE
  const searchAlarms = () => {
    httpService.postAPI(filterApiUrl, searchFilters.current, (data) => {
      if (isUndefined(data)) setAlarmTableData(null)
      else setAlarmTableData(data)

      setComingWordToSearch('')
    })
  }

  const downloadTable = () => {
    const body = {}
    httpService.downloadAPI(
      downloadApiUrl,
      (res) => {
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const aEle = document.createElement('a') // Create a label
        const href = window.URL.createObjectURL(blob) // Create downloaded link
        aEle.href = href
        const objectType = downloadApiUrl
          .split('nssmf_assurance/')[1]
          .split('/')[0]
        aEle.download = `${objectType}_${new Date().toLocaleDateString(
          'sv'
        )}.xlsx` // File name after download
        document.body.appendChild(aEle)
        aEle.click() // Click to download
        document.body.removeChild(aEle) // Download complete remove element
        window.URL.revokeObjectURL(href) // Release blob object
      },
      'post',
      body
    )
  }

  useEffect(() => {
    if (pathParams.id != null && pathParams.id !== ':id') {
      setComingWordToSearch(pathParams.id)
    }
  }, [])

  // set default filter for the very first search
  useEffect(() => {
    const to = new Date()
    let from = new Date()
    from.setMinutes(from.getMinutes() - 20)

    if (formData == null) {
      setFormData({
        objectType: ['NSS', 'NF'],
        status: ['Active'],
        severity: [],
        from,
        to,
      })
    }
  }, [])

  //Perform the first search
  useEffect(() => {
    console.log(formData)
    if (formData && isInitialSearch.current) {
      httpService.postAPI(
        filterApiUrl,
        formData,
        (data) => {
          if (data && data.length > 0) {
            snackbar.setSnackbarInfo({
              status: 'info',
              message: `NOTE. This view is showing the Active alarms in the last 20 minutes.`,
            })
          } else {
            snackbar.setSnackbarInfo({
              status: 'info',
              message: `There are no alarms in the last 20 minutes.`,
            })
          }

          const to = new Date()
          let from = new Date()
          from.setMinutes(from.getMinutes() - 20)

          isInitialSearch.current = false
          setFormData(null)
          setAlarmTableData(data)
          searchFilters.current = {
            from,
            to,
          }
        },
        {},
        false
      )
    }
  }, [formData])

  return (
    <div>
      <Grid container spacing={5} padding={5}>
        <JsonSchemaBox
          schema={jsonSchema}
          formData={formData || {}}
          onChange={(data) => {
            console.log(data)
            if (data) searchFilters.current = data
          }}
        />
        <Grid md={11}></Grid>
        <Grid item md={1}>
          <Button variant='outlined' onClick={searchAlarms}>
            Search
          </Button>
        </Grid>
        <Grid item md={12}>
          {alarmTableData != null && alarmTableData.length > 0 ? (
            <EnhancedTableBox
              heightPercentage='0.5'
              title='Alarms'
              data={alarmTableData}
              valueMember='id'
              visibleColumns={visibleColumns}
              search
              comingWordToSearch={comingWordToSearch}
              setComingWordToSearch={setComingWordToSearch}
            />
          ) : (
            <Typography variant='h5'>No data found</Typography>
          )}
        </Grid>
        <Grid
          sx={{
            marginTop: '1vw',
            marginLeft: '3%',
            fontSize: 'inherit',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <span>{'Download Data'}</span>
          <EnhancedIconButton
            icon='download'
            place='bottom'
            onClick={downloadTable}
            // tooltip='Download excel/csv'
            color='inherit'
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default Alarms
