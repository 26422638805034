import { v4 as uuidv4 } from 'uuid'
import { cardMain } from './templateRAN-Main'
const doNothing = () => {}

const {
  id,
  coverageAreaTAList,
  name,
  maxNumberofUEs,
  // delayTolerance,
  dLThptPerUE,
  uLThptPerUE,
  sliceSimultaneousUse,
  positioning_availability,
  positioning_Predictionfrequency,
  positioning_Accuracy,
  nROperatingBands,
  dLThptPerSlice_guaThpt,
  dLThptPerSlice_maxThpt,
  uLThptPerSlice_guaThpt,
  uLThptPerSlice_maxThpt,
  ...schemaFields
} = cardMain.schema.fields

export const outsideActions = [
  {
    icon: 'add_circle',
    label: 'Add New Item',
    place: 'bottom',
    onClick: doNothing,
    type: 'dialog',
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/RANSliceSubnetProfile`,
    uniqueKey: uuidv4(),
    dialog: {
      title: 'Add New Item',
      style: {
        card: {},
        title: {
          marginTop: '30px',
          backgroundColor: 'background.light',
          paddingLeft: '6%',
          paddingRight: '6%',
          color: 'primary.light',
          width: '100%',
        },
        content: {},
        actions: {},
      },
      schemaFields: {
        coverageAreaTAList: {
          ...coverageAreaTAList,
          mandatory: true,
          showAsterisk: true,
        },
        name: {
          ...name,
          mandatory: true,
          showAsterisk: true,
        },
        maxNumberofUEs: {
          ...maxNumberofUEs,
          mandatory: true,
          showAsterisk: true,
        },
        // delayTolerance: {
        //   ...delayTolerance,
        //   mandatory: true,
        // },
        dLThptPerUE: {
          ...dLThptPerUE,
          mandatory: true,
          showAsterisk: true,
        },
        uLThptPerUE: {
          ...uLThptPerUE,
          mandatory: true,
          showAsterisk: true,
        },
        sliceSimultaneousUse: {
          ...sliceSimultaneousUse,
          mandatory: true,
          showAsterisk: true,
        },
        positioning_availability: {
          ...positioning_availability,
          mandatory: true,
          showAsterisk: true,
        },
        positioning_Predictionfrequency: {
          ...positioning_Predictionfrequency,
          mandatory: true,
          showAsterisk: true,
        },
        positioning_Accuracy: {
          ...positioning_Accuracy,
          mandatory: true,
          showAsterisk: true,
        },
        nROperatingBands: {
          ...nROperatingBands,
          mandatory: true,
          showAsterisk: true,
        },
        ...schemaFields,
      },
      tabs: ['General', 'Positioning', 'User Related Data'],
      // tabs: ['General', 'Deterministic Comm', 'Positioning'],
      buttons: [
        { type: 'cancel' },
        { type: 'save', tooltip: 'Please fill all the mandatory attributes.' },
      ],
    },
  },
  {
    icon: 'upload',
    label: 'Import Item',
    place: 'bottom',
    onClick: doNothing,
    type: 'upload',
    uniqueKey: uuidv4(),
    url: `${process.env.REACT_APP_API_ADDRESS.replace(
      '/api',
      ''
    )}/fileio/nssmf_managed_object/RANSliceSubnetProfile/import`,
  },
  {
    icon: 'download',
    label: 'Export Format',
    place: 'bottom',
    onClick: doNothing,
    type: 'template',
    // url: `${process.env.REACT_APP_API_ADDRESS.replace(
    //   '/api',
    //   ''
    // )}/fileio/nssmf_managed_object/RANSliceSubnetProfile/template`,
    templateURL: `/Template_RANSliceSubnetProfile.xlsx`,
    uniqueKey: uuidv4(),
  },
  {
    icon: 'download',
    label: 'Download Data',
    place: 'bottom',
    onClick: doNothing,
    type: 'export',
    uniqueKey: uuidv4(),
    url: `${process.env.REACT_APP_API_ADDRESS.replace(
      '/api',
      ''
    )}/fileio/nssmf_managed_object/RANSliceSubnetProfile/export`,
  },
]

// ADD THE TAB FEATURE TO THE SP TEMPLATE PAGE AND MAKE SURE THAT IF IT IS NOT SET, IT WORKS PROPERLY
