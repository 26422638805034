import React from 'react'
import { UseQuery, Child } from '../../utils'
import { Link } from 'react-router-dom'

const A = () => {
  return (
    <div>
      <h1>TEST App</h1>
      <p>This is a page</p>
      <Child name={UseQuery().get('name')} />
      <Link style={{ color: 'inherit' }} to='/a/c'>
        Go to Subpage C
      </Link>
    </div>
  )
}
export default A
