import cardMain from './mainData-NSSI'
import { v4 as uuidv4 } from 'uuid'

const doNothing = () => {}

const { NSI, name, scheduled, PlmnId_id } = cardMain.schema.fields

export const outsideActions = [
  {
    icon: 'add_circle',
    label: 'Add New Item',
    place: 'bottom',
    onClick: doNothing,
    uniqueKey: uuidv4(),
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSliceSubnet`,
    type: 'dialog',
    // onClickMessage: {
    //   status: 'info',
    //   message: `Only non associated NSI are shown in the list`,
    // },
    dialog: {
      title: 'Add New NSSI',
      style: {
        card: {},
        title: {
          marginTop: '30px',
          backgroundColor: 'background.light',
          paddingLeft: '6%',
          paddingRight: '6%',
          color: 'primary.light',
          width: '100%',
        },
        content: {},
        actions: {},
      },
      schemaFields: {
        name: {
          ...name,
          md: 6,
          mandatory: true,
          showAsterisk: true,
        },
        scheduled,
        PlmnId_id: {
          ...PlmnId_id,
          disabled: false,
          mandatory: true,
          showAsterisk: true,
        },
        RANSliceSubnetProfile_id: {
          title: 'Nest (GST)',
          type: 'combo',
          md: 6,
          url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/RANSliceSubnetProfile`,
          valueMember: 'id',
          displayMember: 'name',
          mandatory: true,
          showAsterisk: true,
        },
        nsInstanceId: {
          title: 'NSI',
          type: 'combo',
          md: 6,
          // url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSlice`,
          url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSlice?nssid=_`,
          valueMember: 'id',
          displayMember: 'name',
          mandatory: true,
          showAsterisk: true,
          tooltip: 'Only non associated NSI are shown in the list',
        },
        scheduled: {
          type: 'date',
          md: 4,
          title: 'Schedulation',
        },
      },

      // messages: {
      //   url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_scheduler/Schedule`,
      //   messageList: [
      //     {
      //       uniqueKey: uuidv4(),
      //       name: '',
      //       scheduled: '',
      //       httpMethod: 'POST',
      //       url: `http://localhost/queue/celery/push/OPERMOI/modifyMOIattributes`,
      //       body: {
      //         Name: 'modifyMOIattributes',
      //         moi_class: 'NSSI',
      //         moi_id: null,
      //         Command: 'modifyMOIattributes',
      //       },
      //     },
      //     {
      //       uniqueKey: uuidv4(),
      //       httpMethod: 'POST',
      //       name: '',
      //       scheduled: '',
      //       url: `http://localhost/queue/celery/push/NOTIFMOI/notifyMOIcreation`,
      //       body: {
      //         Name: 'notifyMOIcreation',
      //         moi_class: 'NSSI',
      //         moi_id: null,
      //         Command: 'notifyMOIcreation',
      //       },
      //     },
      //   ],
      // },
      tabs: [],
      onDataChange: doNothing,
      buttons: [{ type: 'cancel' }, { type: 'save' }],
    },
  },
  {
    icon: 'upload',
    type: 'upload',
    label: 'Import Item',
    place: 'bottom',
    onClick: doNothing,
    uniqueKey: uuidv4(),
    url: `${process.env.REACT_APP_API_ADDRESS.replace(
      '/api',
      ''
    )}/fileio/nssmf_managed_object/NetworkSliceSubnet/import`,
  },
  {
    icon: 'download',
    type: 'template',
    label: 'Export Format',
    place: 'bottom',
    onClick: doNothing,
    uniqueKey: uuidv4(),
    url: `${process.env.REACT_APP_API_ADDRESS.replace(
      '/api',
      ''
    )}/fileio/nssmf_managed_object/NetworkSliceSubnet/template`,
  },
  {
    icon: 'download',
    type: 'export',
    label: 'Download Data',
    place: 'bottom',
    onClick: doNothing,
    uniqueKey: uuidv4(),
    url: `${process.env.REACT_APP_API_ADDRESS.replace(
      '/api',
      ''
    )}/fileio/nssmf_managed_object/NetworkSliceSubnet/export`,
  },
  {
    icon: 'refresh',
    type: 'icon',
    label: 'Refresh',
    place: 'bottom',
    onClick: doNothing,
    uniqueKey: uuidv4(),
  },
]
