import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';


const RouterBreadcrumbs = ({ crumbs }) => {


    return (
        <Breadcrumbs aria-label="breadcrumb" color="secondary">
            {/* Link back to any previous steps of the breadcrumb. */}
            {crumbs.map(({ name, path }, key) =>
                key + 1 === crumbs.length ? (
                    <span key={key} className="bold">
                        <Typography variant="h7" color="secondary">{name}</Typography>
                    </span>
                ) : (
                    <Link key={key} to={path}>
                        <Typography variant="h7" color="secondary">{name}</Typography>
                    </Link>
                )
            )}
        </Breadcrumbs>
    );
};


export default RouterBreadcrumbs;