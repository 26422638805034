import React from 'react'
import EnhancedTableBox from '../../components/EnhancedTableBox'
import { Box } from '@mui/material'

const Application = () => {
  //array of the columns we want to show on the table
  const visibleColumns = [
    {
      id: 'name',
      alignRight: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'image_repository_name',
      alignRight: false,
      disablePadding: false,
      label: 'Image Repository',
    },
    // {
    //     id: "image",
    //     alignRight: false,
    //     disablePadding: false,
    //     label: "Image"
    // },
    {
      id: 'url',
      alignRight: false,
      disablePadding: false,
      label: 'URL',
    },
    {
      id: 'hscaling',
      alignRight: false,
      disablePadding: false,
      label: 'Horizontal Scaling',
    },
    {
      id: 'hscaling_min',
      alignRight: false,
      disablePadding: false,
      label: 'Horizontal Scaling Min',
    },
    {
      id: 'hscaling_max',
      alignRight: false,
      disablePadding: false,
      label: 'Horizontal Scaling Max',
    },
  ]

  //array of actions placed at the end of each row
  const actions = [
    {
      action: 'edit',
      icon: 'edit',
      tooltip: 'edit current row',
    },

    {
      action: 'delete',
      icon: 'delete',
      tooltip: 'delete current row',
    },
  ]

  const editSchema = {
    name: {
      title: 'Name',
      type: 'text',
      md: 6,
    },
    url: {
      title: 'URL',
      type: 'url',
      md: 6,
    },
    image_repository_id: {
      title: 'Image Repository',
      type: 'combo',
      url: `${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/image_repository`,
      valueMember: 'id',
      displayMember: 'name',
      md: 6,
    },
    image: {
      title: 'Image',
      type: 'text',
      md: 12,
      multiline: true,
    },
    command: {
      title: 'Command',
      type: 'text',
      md: 12,
      multiline: true,
    },
    hscaling: {
      title: 'Horizontal Scaling',
      type: 'checkbox',
      md: 4,
    },
    hscaling_min: {
      title: 'Horizontal Scaling Min',
      type: 'number',
      md: 4,
    },
    hscaling_max: {
      title: 'Horizontal Scaling Max',
      type: 'number',
      md: 4,
    },
  }

  return (
    <Box sx={{ padding: '2%' }}>
      <EnhancedTableBox
        heightPercentage='0.7'
        title='Application'
        apiURL={`${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/application`}
        valueMember='id'
        visibleColumns={visibleColumns}
        actions={actions}
        //onAction={onAction}
        addButton
        //addJsonSchema={{}}
        editJsonSchema={editSchema}
        search
      />
    </Box>
  )
}
export default Application
