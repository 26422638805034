import React from 'react'
import EnhancedTableBox from '../../components/EnhancedTableBox'
import { Box } from '@mui/material'

const Tenant = () => {
  //array of the columns we want to show on the table
  const visibleColumns = [
    {
      id: 'name',
      alignRight: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'ref_email',
      alignRight: false,
      disablePadding: false,
      label: 'Ref Mail',
    },
    {
      id: 'tenant_status',
      alignRight: false,
      disablePadding: false,
      label: 'Status',
    },
  ]

  //array of actions placed at the end of each row
  const actions = [
    {
      action: 'edit',
      icon: 'edit',
      tooltip: 'edit current row',
    },

    {
      action: 'delete',
      icon: 'delete',
      tooltip: 'delete current row',
    },
  ]

  const editSchema = {
    name: {
      title: 'Name',
      type: 'text',
      md: 6,
    },
    ref_email: {
      title: 'Ref Mail',
      type: 'text',
      md: 6,
    },
  }

  return (
    <Box sx={{ padding: '2%' }}>
      <EnhancedTableBox
        heightPercentage='0.7'
        title='Tenant'
        apiURL={`${process.env.REACT_APP_API_ADDRESS}/scalar/creedgeon/tenant`}
        valueMember='id'
        visibleColumns={visibleColumns}
        actions={actions}
        //onAction={onAction}
        addButton
        //addJsonSchema={{}}
        editJsonSchema={editSchema}
        search
      />
    </Box>
  )
}
export default Tenant
