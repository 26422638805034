import { v4 as uuidv4 } from 'uuid'
import cardMain from './mainData-Settings'

const { id, url, elementType, groupName, ...schemaFields } =
  cardMain.schema.fields
const doNothing = () => {}

export const outsideActions = [
  {
    icon: 'add_circle',
    label: 'Add New Item',
    place: 'bottom',
    onClick: doNothing,
    type: 'dialog',
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_oss_object/NetworkElement`,
    uniqueKey: uuidv4(),
    dialog: {
      title: 'Add New Item',
      style: {
        card: {},
        title: {
          marginTop: '30px',
          backgroundColor: 'background.light',
          paddingLeft: '6%',
          paddingRight: '6%',
          color: 'primary.light',
          width: '100%',
        },
        content: {},
        actions: {},
      },
      schemaFields: {
        ...schemaFields,
        elementType: {
          ...elementType,
          showAsterisk: true,
        },
        groupName: {
          ...groupName,
          showAsterisk: true,
        },
        url: {
          ...url,
          showAsterisk: true,
        },
      },
      // {
      // ip: {
      //   ...ip,
      //   // mandatoryIf: [{ DNS_supported: false }],
      //   // disabledIf: [{ DNS_supported: true }],
      //   mandatory: true,
      // },
      // port: {
      //   ...port,
      //   // mandatoryIf: [{ DNS_supported: false }],
      //   // disabledIf: [{ DNS_supported: true }],
      //   mandatory: true,
      // },
      // DNS_name: {
      //   ...DNS_name,
      //   mandatoryIf: [{ DNS_supported: true }],
      //   disabledIf: [{ DNS_supported: false }],
      // },
      // },
      buttons: [{ type: 'cancel' }, { type: 'save' }],
    },
  },
  // {
  //   icon: 'upload',
  //   label: 'Import Item',
  //   place: 'bottom',
  //   onClick: doNothing,
  //   type: 'upload',
  //   uniqueKey: uuidv4(),
  // },
  // {
  //   icon: 'download',
  //   label: 'Export Format',
  //   place: 'bottom',
  //   onClick: doNothing,
  //   type: 'download',
  //   uniqueKey: uuidv4(),
  // },
  {
    icon: 'download',
    label: 'Download Data',
    place: 'bottom',
    onClick: doNothing,
    type: 'export',
    uniqueKey: uuidv4(),
    url: `${process.env.REACT_APP_API_ADDRESS.replace(
      '/api',
      ''
    )}/fileio/nssmf_oss_object/NetworkElement/export`,
  },
]
