import React from 'react'
import EnhancedTableBox from '../../components/EnhancedTableBox'
import { Box } from '@mui/material'
import EnhancedIconButton from '../../components/EnhancedIconButton'
import { color } from '@mui/system'
import Tooltip from '@mui/material/Tooltip'
import { Link } from 'react-router-dom'
import useHttpService from '../../customHooks/useHttpService.js'

const NSSMFhome = () => {
  const httpService = useHttpService()

  const [nssiData, setNssiData] = React.useState()
  function loadNssiData() {
    httpService.getAPI(
      `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/Home_NetworkSliceSubnet`,
      onNssiDataReceived
    )
  }
  function onNssiDataReceived(data) {
    data.forEach((row) => {
      row['nssi_id'] = (
        <Link
          to={{
            pathname: `${process.env.REACT_APP_CLIENT_PATH_NAME}/nssiList/${row.id}`,
          }}
          style={{ all: 'unset', cursor: 'pointer' }}
        >
          {row['id']}
        </Link>
      )

      row['state'] = [
        <EnhancedIconButton
          tooltip={'Administrative state ' + row['administrativeState']}
          icon={
            row['administrativeState'] === 'UNLOCKED'
              ? 'lock_open'
              : 'lock_closed'
          }
        />,
        <EnhancedIconButton
          tooltip={'Operational state ' + row['operationalState']}
          icon='circle'
          sx={{
            color: row['operationalState'] === 'ENABLED' ? 'green' : 'red',
          }}
        />,
        <EnhancedIconButton
          tooltip={'Maintenance'}
          icon='build'
          sx={{
            display: row['maintenance'] ? 'unset' : 'none',
          }}
        />,
      ]

      row.perceivedSeverity =
        row.perceivedSeverity !== null && !row.maintenance ? (
          <EnhancedIconButton
            tooltip={row.perceivedSeverity}
            icon='circle'
            sx={{
              color:
                row.perceivedSeverity === 'CRITICAL'
                  ? 'red'
                  : row.perceivedSeverity === 'MAJOR'
                  ? 'orange'
                  : 'yellow',
              cursor: 'pointer',
            }}
            link={`${process.env.REACT_APP_CLIENT_PATH_NAME}/alarms/${row.id}`}
          />
        ) : null
    })

    setNssiData(data)
  }

  const [nsiData, setNsiData] = React.useState()
  function loadNsiData() {
    httpService.getAPI(
      `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/Home_NetworkSlice`,
      onNsiDataReceived
    )
  }
  function onNsiDataReceived(data) {
    data.forEach((row) => {
      row['nsi_id'] = (
        <Link
          to={{
            pathname: `${process.env.REACT_APP_CLIENT_PATH_NAME}/nsiList/${row.nsi_id}`,
          }}
          style={{ all: 'unset', cursor: 'pointer' }}
        >
          {row['name']}
        </Link>
      )

      row['state'] = [
        <EnhancedIconButton
          tooltip={'Administrative state ' + row['administrativeState']}
          icon={
            row['administrativeState'] === 'UNLOCKED'
              ? 'lock_open'
              : 'lock_closed'
          }
        />,
        <EnhancedIconButton
          tooltip={'Operational state ' + row['operationalState']}
          icon='circle'
          sx={{
            color: row['operationalState'] === 'ENABLED' ? 'green' : 'red',
          }}
        />,
      ]
    })
    setNsiData(data)
  }

  const [nfData, setNfData] = React.useState()
  function loadNfData() {
    httpService.getAPI(
      `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/Home_NetworkFunction`,
      onNfDataReceived
    )
  }

  function onNfDataReceived(data) {
    data.forEach((row) => {
      row['nfId'] = (
        <Link
          to={{
            pathname: `${process.env.REACT_APP_CLIENT_PATH_NAME}/nfList/${row.nf_id}`,
          }}
          style={{ all: 'unset', cursor: 'pointer' }}
        >
          {row['name']}
        </Link>
      )

      row['state'] = [
        <EnhancedIconButton
          tooltip={'Administrative state ' + row['administrativeState']}
          icon={
            row['administrativeState'] === 'UNLOCKED'
              ? 'lock_open'
              : 'lock_closed'
          }
        />,
        <EnhancedIconButton
          tooltip={'Operational state ' + row['operationalState']}
          icon='circle'
          sx={{
            color: row['operationalState'] === 'ENABLED' ? 'green' : 'red',
          }}
        />,
      ]
    })
    setNfData(data)
  }

  React.useEffect(() => {
    loadNssiData()
    loadNsiData()
    loadNfData()
  }, [])

  //array of the columns we want to show on the table
  const visibleColumnsB1 = [
    {
      id: 'name',
      alignRight: false,
      disablePadding: false,
      label: 'NSI',
    },
    {
      id: 'state',
      alignRight: false,
      disablePadding: false,
      label: 'State',
    },
  ]

  const visibleColumnsB2 = [
    {
      id: 'plmnid',
      alignRight: false,
      disablePadding: false,
      label: 'PLMN ID',
    },
    {
      id: 'id',
      alignRight: false,
      disablePadding: false,
      label: 'NSSI ID',
    },
    {
      id: 'nssiName',
      alignRight: false,
      disablePadding: false,
      label: 'NSSI Name',
    },
    {
      id: 'nssiTypeNsst',
      alignRight: false,
      disablePadding: false,
      label: 'NSSI Type (NSST)',
    },
    {
      id: 'state',
      alignRight: false,
      disablePadding: false,
      label: 'State',
    },
    {
      id: 'perceivedSeverity',
      alignRight: false,
      disablePadding: false,
      label: 'Active Alarm',
    },
  ]

  const visibleColumnsB3 = [
    {
      id: 'name',
      alignRight: false,
      disablePadding: false,
      label: 'NF',
    },
    {
      id: 'nfType',
      alignRight: false,
      disablePadding: false,
      label: 'NF Type',
    },
    {
      id: 'state',
      alignRight: false,
      disablePadding: false,
      label: 'State',
    },
  ]

  //array of actions placed at the end of each row
  const actionsB2 = [
    {
      action: 'options',
      icon: 'more_vert',
    },
  ]

  // event that is fired when an action is clicked
  function onActionB2(action, row, e) {
    alert(action)
  }

  // var region = 20;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '99vw',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '80vw',
          marginTop: '1em',
        }}
      >
        <EnhancedTableBox
          // headerStyle={{ backgroundColor: '#03a9f4', color: 'white' }}
          heightPercentage='0.5'
          rowHeight='75px'
          title='List of NSSI'
          data={nssiData}
          valueMember='gid'
          visibleColumns={visibleColumnsB2}
          // actions={actionsB2}
          // onAction={onActionB2}
          //search
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '80vw',
          marginTop: '3em',
          paddingBottom: '3%',
        }}
      >
        <Box sx={{ width: '38.5vw' }}>
          <EnhancedTableBox
            // headerStyle={{ backgroundColor: '#03a9f4', color: 'white' }}
            heightPercentage='0.5'
            rowHeight='75px'
            title='List of NSI'
            data={nsiData}
            valueMember='gid'
            visibleColumns={visibleColumnsB1}
            //actions={actionsB1}
            //onAction={onActionB1}
            //search
          />
        </Box>
        <Box sx={{ width: '38.5vw' }}>
          <EnhancedTableBox
            // headerStyle={{ backgroundColor: '#03a9f4', color: 'white' }}
            heightPercentage='0.5'
            rowHeight='75px'
            title='List of NF'
            data={nfData}
            valueMember='gid'
            visibleColumns={visibleColumnsB3}
            //search
          />
        </Box>
      </Box>
    </Box>
  )
}
export default NSSMFhome
