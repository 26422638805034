import React, { useState } from 'react'
import { useEffect } from 'react'

import { Grid } from '@mui/material'
import EnhancedTableBox from '../../components/EnhancedTableBox'

import picGreen from '../radiomod/green.png'
import picRed from '../radiomod/red.png'

export default function RESULT() {
  //read querystring
  const urlParams = new URLSearchParams(window.location.search)

  //state to trace data  loading
  const [dataLoaded, setDataLoaded] = useState(false)
  //main state for the whole page data
  const [formState, setFormState] = useState({})
  const visibleColumns = [
    {
      id: 'verificaDescr',
      alignRight: false,
      disablePadding: false,
      label: 'Verifica',
    },
    {
      id: 'misura',
      alignRight: false,
      disablePadding: false,
      label: 'Misura',
    },
    {
      id: 'risultato',
      alignRight: false,
      disablePadding: false,
      label: 'Risultato',
    },
    {
      id: 'esitoDescr',
      alignRight: false,
      disablePadding: false,
      label: 'Esito',
    },
    {
      id: 'azione_correttivaDescr',
      alignRight: false,
      disablePadding: false,
      label: 'Azione correttiva',
    },
  ]

  //..........FUNCTION TO CALL GET API TO FILL ALL FIELDS..............//

  function queryAPI(myURL) {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    fetch(myURL, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status)
        } else return response.json()
      })
      .then((jsondata) => {
        if (jsondata.data) {
          jsondata.data.forEach((row, index) => {
            //updating verifica descr
            switch (row.verifica) {
              case 'PALO':
                row.verificaDescr = 'Tasso di sfruttamento del palo'
                break
              case 'ROTAZIONE':
                row.verificaDescr = 'Rotazione del palo'
                break
              case 'TIRAFONDI':
                row.verificaDescr = 'Verifica dei tirafondi'
                break
              case 'FLANGE':
                row.verificaDescr = 'Verifica delle flange'
                break
              case 'PLINTO':
                row.verificaDescr = 'Verifica del plinto'
                break
              case 'TOTAL':
                row.verificaDescr = '--- ESITO COMPLESSIVO ---'
                break
              default:
                row.verificaDescr = row.verifica
                break
            }

            //updating esito descr
            switch (row.esito) {
              case 'VERIFICATO':
                row.esitoDescr = <img src={picGreen} alt='' />
                break
              case 'NON VERIFICATO':
                row.esitoDescr = <img src={picRed} alt='' />
                break

              default:
                row.esitoDescr = row.esito
                break
            }

            //updating azione_correttiva descr
            switch (row.azione_correttiva) {
              case 'PRE':
                row.azione_correttivaDescr = 'Stato iniziale'
                break
              case 'PAR':
                row.azione_correttivaDescr = 'Rimozione parabole dismesse'
                break
              case 'PAR/RRU':
                row.azione_correttivaDescr =
                  'Rimozione parabole dismesse, abbassamento RRU a base palo'
                break
              case 'POST':
                row.azione_correttivaDescr = 'Stato finale'
                break

              default:
                row.azione_correttivaDescr = row.azione_correttiva
                break
            }
          })

          //setting main state
          setFormState(jsondata.data)
          setDataLoaded(true)
        } else
          alert(`No static DATA results for eval ${urlParams.get('eval_uuid')}`)
      })
      .catch((error) => {
        console.error(error)
        //localStorage.removeItem('token');
        //window.location.reload();
      })
  }

  useEffect(() => {
    queryAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/statica/partial_results?eval_uuid=${urlParams.get(
        'eval_uuid'
      )}`
    ) //168bbbe0-7e46-41c9-a8f2-440a5d9281ae
  }, [])

  return (
    <div>
      <Grid container spacing={5} padding={5}>
        <Grid item md={12}>
          <EnhancedTableBox
            headerStyle={{ backgroundColor: 'white', color: 'blue' }}
            orderBy='quote'
            heightPercentage='0.65'
            title='Risultati analisi strutturale'
            valueMember='id'
            data={formState}
            visibleColumns={visibleColumns}
            actions={[]}
          />
        </Grid>
      </Grid>
    </div>
  )
}
