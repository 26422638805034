import React from 'react'
import produce from 'immer'
import { set, has } from 'lodash'
import { useState } from 'react'
import { useEffect } from 'react'
import { Grid } from '@mui/material'
import { TextField } from '@mui/material'
import pic1 from './pic1.png'
import pic2 from './pic2.png'
import pic3 from './pic3.png'
import { Typography } from '@mui/material'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import TextFieldCombo from '../../components/TextFieldCombo'
import EnhancedTableBox from '../../components/EnhancedTableBox'

import { Icon } from '@mui/material'
import { IconButton } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'

import { useConfirm } from 'material-ui-confirm'
import { v4 as uuidv4 } from 'uuid'

//funtion to manage json states
function enhancedReducer(state, updateArg) {
  // check if the type of update argument is a callback function
  if (updateArg.constructor === Function) {
    return { ...state, ...updateArg(state) }
  }

  // if the type of update argument is an object
  if (updateArg.constructor === Object) {
    // does the update object have _path and _value as it's keys
    // if yes then use them to update deep object values
    if (has(updateArg, '_path') && has(updateArg, '_value')) {
      const { _path, _value } = updateArg

      return produce(state, (draft) => {
        set(draft, _path, _value)
      })
    } else {
      return { ...state, ...updateArg }
    }
  }
}

const RAWLAND = () => {
  //read querystring
  const urlParams = new URLSearchParams(window.location.search)

  //method to update json states with own setter
  const updateForm = React.useCallback(
    ({ target: { value, name, type } }, stateSetter) => {
      const updatePath = name.split('.')

      switch (type) {
        case 'number':
          if (value.includes('.')) value = parseFloat(value)
          else value = parseInt(value)
          break
        default:
          break
      }

      // if the input is a checkbox then use callback function to update
      // the toggle state based on previous state
      if (type === 'checkbox') {
        stateSetter((prevState) => ({
          [name]: !prevState[name],
        }))

        return
      }

      // if we have to update the root level nodes in the form
      if (updatePath.length === 1) {
        const [key] = updatePath

        stateSetter({
          [key]: value,
        })
      }

      // if we have to update nested nodes in the form object
      // use _path and _value to update them.
      if (updatePath.length > 1) {
        stateSetter({
          _path: updatePath,
          _value: value,
        })
      }
    },
    []
  )

  //...........COLUMNS OF POLES TABLE.............//

  const visibleColumnsPoles = [
    {
      id: 'baseHeight',
      alignRight: true,
      disablePadding: false,
      label: 'Altezza base tronco (m)',
    },
    {
      id: 'summitHeight',
      alignRight: true,
      disablePadding: false,
      label: 'Altezza sommità tronco (m)',
    },
    {
      id: 'overlappingLogs',
      alignRight: true,
      disablePadding: false,
      label: 'Sovrapposizione tronchi (m)',
    },
    {
      id: 'extBaseDiameter',
      alignRight: true,
      disablePadding: false,
      label: 'Diametro ext. base (mm)',
    },
    {
      id: 'extSummitDiameter',
      alignRight: true,
      disablePadding: false,
      label: 'Diametro ext. sommità (mm)',
    },
    {
      id: 'thickness',
      alignRight: true,
      disablePadding: false,
      label: 'Spessore (mm)',
    },
    {
      id: 'sidesSection',
      alignRight: true,
      disablePadding: false,
      label: 'Lati sezione',
    },
    {
      id: 'steelType',
      alignRight: true,
      disablePadding: false,
      label: 'Tipo Acciaio',
    },
  ]

  //...........COLUMNS OF FLANGES TABLE.............//

  const visibleColumnsFlanges = [
    {
      id: 'quote',
      alignRight: true,
      disablePadding: false,
      label: 'Quota',
    },
    {
      id: 'boltType',
      alignRight: true,
      disablePadding: false,
      label: 'Tipo Bullone',
    },
    {
      id: 'boltClass',
      alignRight: true,
      disablePadding: false,
      label: 'Classe Bullone',
    },
    {
      id: 'boltNumber',
      alignRight: true,
      disablePadding: false,
      label: 'Numero Bulloni',
    },
    {
      id: 'holeDiameter',
      alignRight: true,
      disablePadding: false,
      label: 'Diametro a fori (mm)',
    },
  ]

  //...........COLUMNS OF BALLATOI TABLE.............//

  const visibleColumnsGalleries = [
    {
      id: 'quote',
      alignRight: true,
      disablePadding: false,
      label: 'Quota (m)',
    },
    {
      id: 'height',
      alignRight: true,
      disablePadding: false,
      label: 'Altezza (m)',
    },
    {
      id: 'width',
      alignRight: true,
      disablePadding: false,
      label: 'Larghezza (m)',
    },
    {
      id: 'mass',
      alignRight: true,
      disablePadding: false,
      label: 'Massa (kg)',
    },
    {
      id: 'shapeCoefficient',
      alignRight: true,
      disablePadding: false,
      label: 'Coefficiente Forma',
    },
    {
      id: 'reductionCoefficient',
      alignRight: true,
      disablePadding: false,
      label: 'Coefficiente Riduzione',
    },
  ]

  //...........COLUMNS OF ALTRI CARICHI TABLE.............//

  const visibleColumnsOtherLoads = [
    {
      id: 'number',
      alignRight: true,
      disablePadding: false,
      label: 'N° Carichi',
    },
    {
      id: 'typology',
      alignRight: true,
      disablePadding: false,
      label: 'Tipologia',
    },
    {
      id: 'quote',
      alignRight: false,
      disablePadding: false,
      label: 'Quota (m)',
    },
    {
      id: 'height',
      alignRight: true,
      disablePadding: false,
      label: 'Altezza (m)',
    },
    {
      id: 'width',
      alignRight: true,
      disablePadding: false,
      label: 'Larghezza (m)',
    },
    {
      id: 'mass',
      alignRight: true,
      disablePadding: false,
      label: 'Massa (kg)',
    },
    {
      id: 'shapeCoefficient',
      alignRight: true,
      disablePadding: false,
      label: 'Coefficiente Forma',
    },
    {
      id: 'reductionCoefficient',
      alignRight: true,
      disablePadding: false,
      label: 'Coefficiente Riduzione',
    },
  ]

  //.........DECLARE ACTIONS ON TABLE............//

  const actions = [
    {
      action: 'edit',
      icon: 'edit',
      tooltip: 'edit current row',
    },
    {
      action: 'delete',
      icon: 'delete',
      tooltip: 'delete current row',
    },
  ]

  const handleCloseCancel = () => {
    setopenPutPoles(false)
    setopenPutFlanges(false)
    setopenPutBallatoi(false)
    setopenPutAltriCarichi(false)
  }

  //prepare the confirm dialog
  const confirm = useConfirm()

  //***********************************ACTION FOR POLES*************************************************/
  //function called from action buttons on each row of the table
  function onActionPoles(action, row, e) {
    switch (action) {
      case 'edit':
        //set the json state of the popup
        setPolePopupState(row)
        //open the popup
        setopenPutPoles(true)
        break

      case 'delete':
        confirm({
          description: `Rimozione tronco da ${row.baseHeight}m a ${row.summitHeight}m`,
        }).then(() => {
          var mynewState = structureState
          //retrieve the row by id and remove from the array
          mynewState.ssdStaticaPoles.splice(
            mynewState.ssdStaticaPoles.findIndex(
              (x) => x.id === polePopupState.id
            ),
            1
          )
          //save to the structure json
          setStructureState(mynewState)
          updateStructureInFormState(mynewState)
        })
        break
      default:
        break
    }
  }

  //function called when save is choosen on the popup
  const handleClosePutPolesSave = () => {
    //close the popup
    setopenPutPoles(false)
    var mynewState = structureState
    //retrieve row by id and update with the popup state content
    mynewState.ssdStaticaPoles[
      mynewState.ssdStaticaPoles.findIndex((x) => x.id === polePopupState.id)
    ] = polePopupState
    //save to the structure json
    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }

  //function called to add new row to the table
  function addPole() {
    //prepare a row with default values using an uuid for the id
    var newRow = {
      baseHeight: 0,
      extBaseDiameter: 1,
      extSummitDiameter: 1,
      id: uuidv4(),
      overlappingLogs: 0,
      sidesSection: 'circ',
      steelType: 'S 355',
      summitHeight: 0,
      thickness: 1,
    }
    //if present copy from the last row
    if (structureState.ssdStaticaPoles.length > 0) {
      newRow =
        structureState.ssdStaticaPoles[
          structureState.ssdStaticaPoles.length - 1
        ]
      newRow.id = uuidv4()
    }
    //add the new row to the table appending in the array
    structureState.ssdStaticaPoles.push(newRow)
    //setting the popup json
    setPolePopupState(newRow)
    //open the inserted row in the popup to edit
    setopenPutPoles(true)
  }

  //***********************************ACTION FOR FLANGES*************************************************/

  function onActionFlanges(action, row, e) {
    switch (action) {
      case 'edit':
        setFlangePopupState(row)
        setopenPutFlanges(true)
        break

      case 'delete':
        confirm({
          description: `Rimozione flagia alla quota ${row.quote}m`,
        }).then(() => {
          var mynewState = structureState
          mynewState.ssdStaticaFlanges.splice(
            mynewState.ssdStaticaFlanges.findIndex(
              (x) => x.id === flangePopupState.id
            ),
            1
          )
          setStructureState(mynewState)
          updateStructureInFormState(mynewState)
        })
        break
      default:
        break
    }
  }

  const handleClosePutFlangesSave = () => {
    setopenPutFlanges(false)
    var mynewState = structureState
    mynewState.ssdStaticaFlanges[
      mynewState.ssdStaticaFlanges.findIndex(
        (x) => x.id === flangePopupState.id
      )
    ] = flangePopupState
    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }

  function addFlange() {
    var newRow = {
      boltClass: '6.8',
      boltNumber: 1,
      boltType: 'M20',
      holeDiameter: 1,
      id: uuidv4(),
      quote: 0,
    }
    if (structureState.ssdStaticaFlanges.length > 0) {
      newRow =
        structureState.ssdStaticaFlanges[
          structureState.ssdStaticaFlanges.length - 1
        ]
      newRow.id = uuidv4()
    }
    structureState.ssdStaticaFlanges.push(newRow)
    setFlangePopupState(newRow)
    setopenPutFlanges(true)
  }

  //***********************************ACTION FOR BALLATOI*************************************************/

  function onActionBallatoi(action, row, e) {
    switch (action) {
      case 'edit':
        setBallatoioPopupState(row)
        setopenPutBallatoi(true)
        break

      case 'delete':
        confirm({ description: `Rimozione ballatoio ${row.id}` }).then(() => {
          var mynewState = structureState
          mynewState.ssdStaticaGalleries.splice(
            mynewState.ssdStaticaGalleries.findIndex(
              (x) => x.id === ballatoioPopupState.id
            ),
            1
          )
          setStructureState(mynewState)
          updateStructureInFormState(mynewState)
        })
        break
      default:
        break
    }
  }

  const handleClosePutBallatoiSave = () => {
    setopenPutBallatoi(false)
    var mynewState = structureState
    mynewState.ssdStaticaGalleries[
      mynewState.ssdStaticaGalleries.findIndex(
        (x) => x.id === ballatoioPopupState.id
      )
    ] = ballatoioPopupState
    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }

  function addGallery() {
    var newRow = {
      height: 0,
      id: uuidv4(),
      mass: 0,
      quote: 0,
      reductionCoefficient: 1,
      shapeCoefficient: 1,
      width: 0,
    }
    if (structureState.ssdStaticaGalleries.length > 0) {
      newRow =
        structureState.ssdStaticaGalleries[
          structureState.ssdStaticaGalleries.length - 1
        ]
      newRow.id = uuidv4()
    }
    structureState.ssdStaticaGalleries.push(newRow)
    setBallatoioPopupState(newRow)
    setopenPutBallatoi(true)
  }

  //***********************************ACTION FOR ALTRI CARICHI*************************************************/

  function onActionAltriCarichi(action, row, e) {
    switch (action) {
      case 'edit':
        setAltriCarichiPopupState(row)
        setopenPutAltriCarichi(true)
        break

      case 'delete':
        confirm({ description: `Rimozione altro carico ${row.id}` }).then(
          () => {
            var mynewState = structureState
            mynewState.ssdStaticaOtherLoads.splice(
              mynewState.ssdStaticaOtherLoads.findIndex(
                (x) => x.id === altriCarichiPopupState.id
              ),
              1
            )
            setStructureState(mynewState)
            updateStructureInFormState(mynewState)
          }
        )
        break
      default:
        break
    }
  }

  const handleClosePutAltriCarichiSave = () => {
    setopenPutAltriCarichi(false)
    var mynewState = structureState
    mynewState.ssdStaticaOtherLoads[
      mynewState.ssdStaticaOtherLoads.findIndex(
        (x) => x.id === altriCarichiPopupState.id
      )
    ] = altriCarichiPopupState
    setStructureState(mynewState)
    updateStructureInFormState(mynewState)
  }
  function addOtherLoad() {
    var newRow = {
      height: 0,
      id: uuidv4(),
      mass: 0,
      number: 1,
      quote: 0,
      reductionCoefficient: 1,
      shapeCoefficient: 1,
      typology: 'RRU',
      width: 0,
    }
    if (structureState.ssdStaticaOtherLoads.length > 0) {
      newRow =
        structureState.ssdStaticaOtherLoads[
          structureState.ssdStaticaOtherLoads.length - 1
        ]
      newRow.id = uuidv4()
    }
    structureState.ssdStaticaOtherLoads.push(newRow)
    setAltriCarichiPopupState(newRow)
    setopenPutAltriCarichi(true)
  }

  //state to trace data  loading
  const [dataLoaded, setDataLoaded] = useState(false)
  //main state for the whole page data
  const [formState, setFormState] = React.useReducer(enhancedReducer, {})
  //reducer can't handle arrays, so we need a state for the structure
  const [structureState, setStructureState] = React.useReducer(
    enhancedReducer,
    {}
  )

  //.........DECLARING STATES FOR EACH popup............//
  const [polePopupState, setPolePopupState] = React.useReducer(
    enhancedReducer,
    {}
  )
  const [openPutPoles, setopenPutPoles] = React.useState(false)

  const [flangePopupState, setFlangePopupState] = React.useReducer(
    enhancedReducer,
    {}
  )
  const [openPutFlanges, setopenPutFlanges] = React.useState(false)

  const [ballatoioPopupState, setBallatoioPopupState] = React.useReducer(
    enhancedReducer,
    {}
  )
  const [openPutBallatoi, setopenPutBallatoi] = React.useState(false)

  const [altriCarichiPopupState, setAltriCarichiPopupState] = React.useReducer(
    enhancedReducer,
    {}
  )
  const [openPutAltriCarichi, setopenPutAltriCarichi] = React.useState(false)

  //..........FUNCTION TO CALL GET API TO FILL ALL FIELDS..............//

  function queryAPI(myURL) {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    fetch(myURL, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status)
        } else return response.json()
      })
      .then((jsondata) => {
        let myStructure = jsondata.data.data.structures[0]
        if (jsondata.data) {
          if (
            jsondata.data.data.structures.findIndex(
              (x) => x.uuid === urlParams.get('pole_uuid')
            ) !== -1
          ) {
            myStructure =
              jsondata.data.data.structures[
                jsondata.data.data.structures.findIndex(
                  (x) => x.uuid === urlParams.get('pole_uuid')
                )
              ]
          }
          //setting main state
          setFormState(jsondata.data)
          //extracting single structure data
          setStructureState(myStructure)
          setDataLoaded(true)
        } else alert(`No static DATA for site ${urlParams.get('site_code')}`)
      })
      .catch((error) => {
        console.error(error)
        //localStorage.removeItem('token');
        //window.location.reload();
      })
  }

  function putAPI(myURL, data) {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify(data),
    }
    fetch(myURL, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status)
        } else return response.json()
      })
      .then((jsondata) => {})
      .catch((error) => {
        console.error(error)
        //localStorage.removeItem('token');
        //window.location.reload();
      })
  }

  function updateStructureInFormState(newStructureState) {
    let mynewState = JSON.parse(JSON.stringify(formState))
    let myindex = mynewState.data.structures.findIndex(
      (x) => x.uuid === urlParams.get('pole_uuid')
    )
    if (myindex === -1) myindex = 0
    mynewState.data.structures[myindex] = newStructureState
    setFormState(mynewState)
    return mynewState
  }

  function saveData() {
    let mynewState = updateStructureInFormState(structureState)

    //alert(JSON.stringify(formState));
    putAPI(
      'https://networkteam.it.nttdata-emea.com/api/scalar/statica/structures',
      mynewState
    )
    window.location.href = `https://networkteam.it.nttdata-emea.com/Radiomod/cem/site/${urlParams.get(
      'site_code'
    )}/info`
  }

  //..................................................................//

  useEffect(() => {
    queryAPI(
      `https://networkteam.it.nttdata-emea.com/api/scalar/statica/structures?site_code=${urlParams.get(
        'site_code'
      )}`
    ) //I068AT
  }, [])

  if (!dataLoaded)
    return (
      <div>
        <h1 style={{ display: 'flex', justifyContent: 'center' }}>RAW LAND</h1>
        <LinearProgress />
      </div>
    )
  else
    return (
      <div>
        <h1 style={{ display: 'flex', justifyContent: 'center' }}>RAW LAND</h1>
        <Grid container spacing={'1%'} padding={5}>
          <Grid item md={4}>
            <h5> PARAMETRI TERRITORIALI </h5>

            <Grid xs={7}>
              <TextFieldCombo
                label='Regione'
                labelSize={14}
                apiURL='https://networkteam.it.nttdata-emea.com/api/scalar/statica/regions'
                valueMember='region'
                displayMember='region'
                selectedValue={
                  formState.data.ssdStaticaTerritorialParameter.territory
                }
                onSelectChange={(e) => {
                  updateForm(e, setFormState)
                }}
                type='text'
                name='data.ssdStaticaTerritorialParameter.territory'
              />
            </Grid>
            <Grid xs={7}>
              <TextField
                label='Zona'
                InputLabelProps={{ style: { fontSize: 14 } }}
                fullWidth
                variant='standard'
                type='number'
                name='data.ssdStaticaTerritorialParameter.area'
                onChange={(e) => {
                  updateForm(e, setFormState)
                }}
                value={formState.data.ssdStaticaTerritorialParameter.area}
              ></TextField>
            </Grid>

            <Grid xs={7}>
              <TextFieldCombo
                label='Categoria di Esposizione'
                labelSize={14}
                apiURL='https://networkteam.it.nttdata-emea.com/api/scalar/statica/exposure_categories'
                valueMember='exposure_category'
                displayMember='exposure_category'
                selectedValue={
                  formState.data.ssdStaticaTerritorialParameter.exposureCategory
                }
                onSelectChange={(e) => {
                  updateForm(e, setFormState)
                }}
                type='text'
                name='data.ssdStaticaTerritorialParameter.exposureCategory'
              />
            </Grid>
          </Grid>

          <Grid item md={4}>
            <img src={pic1} style={{ width: '70%' }} alt='' />
          </Grid>

          <Grid item md={4}>
            <Typography style={{ fontSize: 15 }}>
              Classe rugosità del terreno
            </Typography>

            <Typography style={{ fontSize: 12 }}>
              A: Aree urbane in cui almeno il 15% della superficie sia coperto
              da edifici la cui altezza mediasuperi i 15m
            </Typography>

            <Typography style={{ fontSize: 12 }}>
              B: Aree urbane (non di Classe A), suburbane, industriali e
              boschive
            </Typography>

            <Typography style={{ fontSize: 12 }}>
              C: Aree con ostacoli diffusi (alberi, case, muri, recinzioni..);
              aree con rugosità non riconducibili alle classi A, B e D
            </Typography>

            <Typography style={{ fontSize: 12 }}>
              D: Aree prive di ostacoli (aperta campagna, areporti, aree
              innevate o ghiacciate, mari, laghi..)
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={'1%'} padding={5}>
          <Grid item md={1.5}>
            <TextField
              helperText='Altitudine'
              FormHelperTextProps={{ style: { fontSize: 10 } }}
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='data.ssdStaticaTerritorialParameter.altitude'
              onChange={(e) => {
                updateForm(e, setFormState)
              }}
              value={formState.data.ssdStaticaTerritorialParameter.altitude}
            ></TextField>
          </Grid>

          <Grid item md={1.5}>
            <TextField
              helperText={
                <div>
                  <Typography style={{ fontSize: 10 }}>Coefficiente</Typography>
                  <Typography style={{ fontSize: 10 }}>topografico</Typography>
                </div>
              }
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='data.ssdStaticaTerritorialParameter.topographicCoefficient'
              onChange={(e) => {
                updateForm(e, setFormState)
              }}
              value={
                formState.data.ssdStaticaTerritorialParameter
                  .topographicCoefficient
              }
            ></TextField>
          </Grid>

          <Grid item md={1.5}>
            <TextField
              helperText={
                <div>
                  <Typography style={{ fontSize: 10 }}>Coefficiente</Typography>
                  <Typography style={{ fontSize: 10 }}>dinamico</Typography>
                </div>
              }
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='data.ssdStaticaTerritorialParameter.dynamicCoefficient'
              onChange={(e) => {
                updateForm(e, setFormState)
              }}
              value={
                formState.data.ssdStaticaTerritorialParameter.dynamicCoefficient
              }
            ></TextField>
          </Grid>

          <Grid item md={1.5}>
            <TextField
              helperText={
                <div>
                  <Typography style={{ fontSize: 10 }}>Velocita di</Typography>
                  <Typography style={{ fontSize: 10 }}>
                    progetto (km/h)
                  </Typography>
                </div>
              }
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='data.ssdStaticaTerritorialParameter.speedProject'
              onChange={(e) => {
                updateForm(e, setFormState)
              }}
              value={formState.data.ssdStaticaTerritorialParameter.speedProject}
            ></TextField>
          </Grid>

          <Grid item md={1.5}>
            <TextField
              helperText={
                <div>
                  <Typography style={{ fontSize: 10 }}>
                    Max defflessione
                  </Typography>
                  <Typography style={{ fontSize: 10 }}>(g°)</Typography>
                </div>
              }
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='data.ssdStaticaTerritorialParameter.maxDeflectionG'
              onChange={(e) => {
                updateForm(e, setFormState)
              }}
              value={
                formState.data.ssdStaticaTerritorialParameter.maxDeflectionG
              }
            ></TextField>
          </Grid>

          <Grid item md={1.5}>
            <TextField
              helperText={
                <div>
                  <Typography style={{ fontSize: 10 }}>
                    Max defflessione
                  </Typography>
                  <Typography style={{ fontSize: 10 }}>(mm')</Typography>
                </div>
              }
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='data.ssdStaticaTerritorialParameter.maxDeflectionMm'
              onChange={(e) => {
                updateForm(e, setFormState)
              }}
              value={
                formState.data.ssdStaticaTerritorialParameter.maxDeflectionMm
              }
            ></TextField>
          </Grid>

          <Grid item md={1.5}>
            <TextField
              helperText={
                <div>
                  <Typography style={{ fontSize: 10 }}>
                    Max defflessione
                  </Typography>
                  <Typography style={{ fontSize: 10 }}>(ss')</Typography>
                </div>
              }
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='data.ssdStaticaTerritorialParameter.maxDeflectionSs'
              onChange={(e) => {
                updateForm(e, setFormState)
              }}
              value={
                formState.data.ssdStaticaTerritorialParameter.maxDeflectionSs
              }
            ></TextField>
          </Grid>

          <Grid item md={1.5}>
            <TextField
              helperText={
                <div>
                  <Typography style={{ fontSize: 10 }}>Spessore del</Typography>
                  <Typography style={{ fontSize: 10 }}>
                    ghiaccio (mm)
                  </Typography>
                </div>
              }
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='data.ssdStaticaTerritorialParameter.iceThickness'
              onChange={(e) => {
                updateForm(e, setFormState)
              }}
              value={formState.data.ssdStaticaTerritorialParameter.iceThickness}
            ></TextField>
          </Grid>
        </Grid>

        <Grid padding={5} item md={2}>
          <h3>PALO</h3>
          <TextField
            style={{ width: '70%' }}
            helperText={
              <div>
                <Typography style={{ fontSize: 10 }}>Coefficiente</Typography>
                <Typography style={{ fontSize: 10 }}>Forma</Typography>
              </div>
            }
            variant='standard'
            type='number'
            name='cp'
            onChange={(e) => {
              updateForm(e, setStructureState)
            }}
            value={structureState.cp}
          ></TextField>
        </Grid>

        <Grid padding={5}>
          <EnhancedTableBox
            headerStyle={{ backgroundColor: 'white', color: 'blue' }}
            orderBy='baseHeight'
            heightPercentage='1'
            title='Tronchi'
            valueMember='id'
            data={structureState.ssdStaticaPoles}
            visibleColumns={visibleColumnsPoles}
            actions={actions}
            onAction={onActionPoles}
            search
          />

          <IconButton color='warning' onClick={addPole}>
            <Icon fontSize='small'>add_circle_outline</Icon>
            <Typography style={{ fontSize: 10 }}> Aggiungi Tronco</Typography>
          </IconButton>

          <Grid item md={4}>
            <img src={pic2} style={{ width: '45%' }} alt='' />
          </Grid>
          <EnhancedTableBox
            headerStyle={{ backgroundColor: 'white', color: 'blue' }}
            orderBy='quote'
            heightPercentage='1'
            title='FLANGE'
            valueMember='id'
            data={structureState.ssdStaticaFlanges}
            visibleColumns={visibleColumnsFlanges}
            actions={actions}
            onAction={onActionFlanges}
            search
          />
          <IconButton color='warning' onClick={addFlange}>
            <Icon fontSize='small'>add_circle_outline</Icon>
            <Typography style={{ fontSize: 10 }}> Aggiungi Flangia</Typography>
          </IconButton>
        </Grid>

        <Grid padding={5} item md={9}>
          <h3>PLINTO</h3>
          <img src={pic3} style={{ width: '80%' }} alt='' />
        </Grid>

        <Grid container spacing={'1%'} padding={5}>
          <Grid item md={1.2}>
            <TextField
              helperText='A1 (m)'
              FormHelperTextProps={{ style: { fontSize: 10 } }}
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='ssdStaticaPlinth.A1'
              onChange={(e) => {
                updateForm(e, setStructureState)
              }}
              value={structureState.ssdStaticaPlinth.A1}
            ></TextField>
          </Grid>

          <Grid item md={1.2}>
            <TextField
              helperText='B1 (m)'
              FormHelperTextProps={{ style: { fontSize: 10 } }}
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='ssdStaticaPlinth.B1'
              onChange={(e) => {
                updateForm(e, setStructureState)
              }}
              value={structureState.ssdStaticaPlinth.B1}
            ></TextField>
          </Grid>

          <Grid item md={1.2}>
            <TextField
              helperText='H1 (m)'
              FormHelperTextProps={{ style: { fontSize: 10 } }}
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='ssdStaticaPlinth.H1'
              onChange={(e) => {
                updateForm(e, setStructureState)
              }}
              value={structureState.ssdStaticaPlinth.H1}
            ></TextField>
          </Grid>

          <Grid item md={1.2}>
            <TextField
              helperText='A2 (m)'
              FormHelperTextProps={{ style: { fontSize: 10 } }}
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='ssdStaticaPlinth.A2'
              onChange={(e) => {
                updateForm(e, setStructureState)
              }}
              value={structureState.ssdStaticaPlinth.A2}
            ></TextField>
          </Grid>

          <Grid item md={1.2}>
            <TextField
              helperText='B2 (m)'
              FormHelperTextProps={{ style: { fontSize: 10 } }}
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='ssdStaticaPlinth.B2'
              onChange={(e) => {
                updateForm(e, setStructureState)
              }}
              value={structureState.ssdStaticaPlinth.B2}
            ></TextField>
          </Grid>

          <Grid item md={1.2}>
            <TextField
              helperText='H2 (m)'
              FormHelperTextProps={{ style: { fontSize: 10 } }}
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='ssdStaticaPlinth.H2'
              onChange={(e) => {
                updateForm(e, setStructureState)
              }}
              value={structureState.ssdStaticaPlinth.H2}
            ></TextField>
          </Grid>

          <Grid item md={1.2}>
            <TextField
              helperText='C (m)'
              FormHelperTextProps={{ style: { fontSize: 10 } }}
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='ssdStaticaPlinth.C'
              onChange={(e) => {
                updateForm(e, setStructureState)
              }}
              value={structureState.ssdStaticaPlinth.C}
            ></TextField>
          </Grid>

          <Grid item md={1.2}>
            <TextField
              helperText='D (m)'
              FormHelperTextProps={{ style: { fontSize: 10 } }}
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='ssdStaticaPlinth.D'
              onChange={(e) => {
                updateForm(e, setStructureState)
              }}
              value={structureState.ssdStaticaPlinth.D}
            ></TextField>
          </Grid>

          <Grid item md={1.2}>
            <TextField
              helperText='E (m)'
              FormHelperTextProps={{ style: { fontSize: 10 } }}
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='ssdStaticaPlinth.E'
              onChange={(e) => {
                updateForm(e, setStructureState)
              }}
              value={structureState.ssdStaticaPlinth.E}
            ></TextField>
          </Grid>

          <Grid item md={1.2}>
            <TextField
              helperText='F (m)'
              FormHelperTextProps={{ style: { fontSize: 10 } }}
              style={{ width: '70%' }}
              variant='standard'
              type='number'
              name='ssdStaticaPlinth.F'
              onChange={(e) => {
                updateForm(e, setStructureState)
              }}
              value={structureState.ssdStaticaPlinth.F}
            ></TextField>
          </Grid>
        </Grid>

        <Grid padding={5}>
          <EnhancedTableBox
            headerStyle={{ backgroundColor: 'white', color: 'blue' }}
            orderBy='quote'
            heightPercentage='1'
            title='BALLATOI'
            valueMember='id'
            data={structureState.ssdStaticaGalleries}
            visibleColumns={visibleColumnsGalleries}
            actions={actions}
            onAction={onActionBallatoi}
            search
          />
          <IconButton color='warning' onClick={addGallery}>
            <Icon fontSize='small'>add_circle_outline</Icon>
            <Typography style={{ fontSize: 10 }}>
              {' '}
              Aggiungi Ballatoio
            </Typography>
          </IconButton>
        </Grid>

        <Grid padding={5}>
          <h3>SCALE E COASSIALI</h3>
          <Grid container spacing={'1%'} padding={5}>
            <Grid item md={4}>
              <TextField
                helperText='Larghezza Equivalente (m)'
                FormHelperTextProps={{ style: { fontSize: 10 } }}
                style={{ width: '70%' }}
                variant='standard'
                type='number'
                name='ssdStaticaScaleAndCoaxial.equivalentWidth'
                onChange={(e) => {
                  updateForm(e, setStructureState)
                }}
                value={structureState.ssdStaticaScaleAndCoaxial.equivalentWidth}
              ></TextField>
            </Grid>

            <Grid item md={4}>
              <TextField
                helperText='Peso per unità per Lunghezza (kg/m)'
                FormHelperTextProps={{ style: { fontSize: 10 } }}
                style={{ width: '70%' }}
                variant='standard'
                type='number'
                name='ssdStaticaScaleAndCoaxial.weightLength'
                onChange={(e) => {
                  updateForm(e, setStructureState)
                }}
                value={structureState.ssdStaticaScaleAndCoaxial.weightLength}
              ></TextField>
            </Grid>

            <Grid item md={4}>
              <TextField
                helperText='Coefficiente Forma'
                FormHelperTextProps={{ style: { fontSize: 10 } }}
                style={{ width: '70%' }}
                variant='standard'
                type='number'
                name='ssdStaticaScaleAndCoaxial.shapeCoefficient'
                onChange={(e) => {
                  updateForm(e, setStructureState)
                }}
                value={
                  structureState.ssdStaticaScaleAndCoaxial.shapeCoefficient
                }
              ></TextField>
            </Grid>
          </Grid>
        </Grid>

        <Grid padding={5}>
          <EnhancedTableBox
            headerStyle={{ backgroundColor: 'white', color: 'blue' }}
            orderBy='quote'
            heightPercentage='1'
            title='ALTRI CARICHI'
            valueMember='id'
            data={structureState.ssdStaticaOtherLoads}
            visibleColumns={visibleColumnsOtherLoads}
            actions={actions}
            onAction={onActionAltriCarichi}
            search
          />
          <IconButton color='warning' onClick={addOtherLoad}>
            <Icon fontSize='small'>add_circle_outline</Icon>
            <Typography style={{ fontSize: 10 }}>
              {' '}
              Aggiungi Altri Carichi
            </Typography>
          </IconButton>
        </Grid>
        <Grid padding={5}>
          <Grid item md={12}>
            <Button variant='contained' onClick={saveData}>
              SAVE
            </Button>
          </Grid>
        </Grid>
        {/*******************************************EDIT POP UP FOR POLES***********************************************/}

        <Dialog fullWidth open={openPutPoles} onClose={handleCloseCancel}>
          <DialogTitle>Edit poles parameters</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <TextField
                  label='Altezza base'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='baseHeight'
                  value={polePopupState.baseHeight}
                  onChange={(e) => {
                    updateForm(e, setPolePopupState)
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label='Altezza sommità'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='summitHeight'
                  value={polePopupState.summitHeight}
                  onChange={(e) => {
                    updateForm(e, setPolePopupState)
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label='Sovrapposizione'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='overlappingLogs'
                  value={polePopupState.overlappingLogs}
                  onChange={(e) => {
                    updateForm(e, setPolePopupState)
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <TextField
                  label='Diametro base'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='extBaseDiameter'
                  value={polePopupState.extBaseDiameter}
                  onChange={(e) => {
                    updateForm(e, setPolePopupState)
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label='Diametro sommità'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='extSummitDiameter'
                  value={polePopupState.extSummitDiameter}
                  onChange={(e) => {
                    updateForm(e, setPolePopupState)
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label='Spessore'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='thickness'
                  value={polePopupState.thickness}
                  onChange={(e) => {
                    updateForm(e, setPolePopupState)
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item md={4}>
                <TextFieldCombo
                  label='Lati sezione'
                  labelSize={14}
                  apiURL='https://networkteam.it.nttdata-emea.com/api/scalar/statica/pole_geometries'
                  valueMember='pole_side'
                  displayMember='pole_side'
                  selectedValue={polePopupState.sidesSection}
                  onSelectChange={(e) => {
                    updateForm(e, setPolePopupState)
                  }}
                  type='text'
                  name='sidesSection'
                />
              </Grid>
              <Grid item md={4}>
                <TextFieldCombo
                  label='Tipo Acciaio'
                  labelSize={14}
                  apiURL='https://networkteam.it.nttdata-emea.com/api/scalar/statica/steels'
                  valueMember='type'
                  displayMember='type'
                  selectedValue={polePopupState.steelType}
                  onSelectChange={(e) => {
                    updateForm(e, setPolePopupState)
                  }}
                  type='text'
                  name='type'
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancel}>Cancel</Button>
            <Button onClick={handleClosePutPolesSave}>Save</Button>
          </DialogActions>
        </Dialog>

        {/*******************************************EDIT POP UP FOR FLANGES***********************************************/}

        <Dialog fullWidth open={openPutFlanges} onClose={handleCloseCancel}>
          <DialogTitle>Edit Flanges parameters</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  label='Quota (m)'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='quote'
                  value={flangePopupState.quote}
                  onChange={(e) => {
                    updateForm(e, setFlangePopupState)
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextFieldCombo
                  label='Tipo Bullone'
                  labelSize={14}
                  apiURL='https://networkteam.it.nttdata-emea.com/api/scalar/statica/bolt_types'
                  valueMember='type'
                  displayMember='type'
                  selectedValue={flangePopupState.boltType}
                  onSelectChange={(e) => {
                    updateForm(e, setFlangePopupState)
                  }}
                  type='text'
                  name='boltType'
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextFieldCombo
                  label='Classe Bullone'
                  labelSize={14}
                  apiURL='https://networkteam.it.nttdata-emea.com/api/scalar/statica/bolt_classes'
                  valueMember='class'
                  displayMember='class'
                  selectedValue={flangePopupState.boltClass}
                  onSelectChange={(e) => {
                    updateForm(e, setFlangePopupState)
                  }}
                  type='text'
                  name='boltClass'
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label='Numero Bulloni'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='boltNumber'
                  value={flangePopupState.boltNumber}
                  onChange={(e) => {
                    updateForm(e, setFlangePopupState)
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label='Diametro a fori'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='holeDiameter'
                  value={flangePopupState.holeDiameter}
                  onChange={(e) => {
                    updateForm(e, setFlangePopupState)
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancel}>Cancel</Button>
            <Button onClick={handleClosePutFlangesSave}>Save</Button>
          </DialogActions>
        </Dialog>

        {/*******************************************EDIT POP UP FOR BALLATOI***********************************************/}

        <Dialog fullWidth open={openPutBallatoi} onClose={handleCloseCancel}>
          <DialogTitle>Edit Ballatoi parameters</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  label='Quota (m)'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='quote'
                  value={ballatoioPopupState.quote}
                  onChange={(e) => {
                    updateForm(e, setBallatoioPopupState)
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label='Altezza'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='height'
                  value={ballatoioPopupState.height}
                  onChange={(e) => {
                    updateForm(e, setBallatoioPopupState)
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  label='Larghezza'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='width'
                  value={ballatoioPopupState.width}
                  onChange={(e) => {
                    updateForm(e, setBallatoioPopupState)
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label='Massa'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='mass'
                  value={ballatoioPopupState.mass}
                  onChange={(e) => {
                    updateForm(e, setBallatoioPopupState)
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  label='Coefficiente Forma'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='shapeCoefficient'
                  value={ballatoioPopupState.shapeCoefficient}
                  onChange={(e) => {
                    updateForm(e, setBallatoioPopupState)
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label='Coefficiente Riduzione'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='reductionCoefficient'
                  value={ballatoioPopupState.reductionCoefficient}
                  onChange={(e) => {
                    updateForm(e, setBallatoioPopupState)
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancel}>Cancel</Button>
            <Button onClick={handleClosePutBallatoiSave}>Save</Button>
          </DialogActions>
        </Dialog>

        {/*******************************************EDIT POP UP FOR ALTRI CARICHI***********************************************/}

        <Dialog
          fullWidth
          open={openPutAltriCarichi}
          onClose={handleCloseCancel}
        >
          <DialogTitle>Edit Other loads parameters</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  label='N° Carichi'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='number'
                  value={altriCarichiPopupState.number}
                  onChange={(e) => {
                    updateForm(e, setAltriCarichiPopupState)
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label='Tipologia'
                  fullWidth
                  variant='standard'
                  type='text'
                  name='typology'
                  value={altriCarichiPopupState.typology}
                  onChange={(e) => {
                    updateForm(e, setAltriCarichiPopupState)
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  label='Quota (m)'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='quote'
                  value={altriCarichiPopupState.quote}
                  onChange={(e) => {
                    updateForm(e, setAltriCarichiPopupState)
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label='Altezza'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='height'
                  value={altriCarichiPopupState.height}
                  onChange={(e) => {
                    updateForm(e, setAltriCarichiPopupState)
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  label='Larghezza'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='width'
                  value={altriCarichiPopupState.width}
                  onChange={(e) => {
                    updateForm(e, setAltriCarichiPopupState)
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label='Massa'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='mass'
                  value={altriCarichiPopupState.mass}
                  onChange={(e) => {
                    updateForm(e, setAltriCarichiPopupState)
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  label='Coefficiente Forma'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='shapeCoefficient'
                  value={altriCarichiPopupState.shapeCoefficient}
                  onChange={(e) => {
                    updateForm(e, setAltriCarichiPopupState)
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label='Coefficiente Riduzione'
                  fullWidth
                  variant='standard'
                  type='number'
                  name='reductionCoefficient'
                  value={altriCarichiPopupState.reductionCoefficient}
                  onChange={(e) => {
                    updateForm(e, setAltriCarichiPopupState)
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancel}>Cancel</Button>
            <Button onClick={handleClosePutAltriCarichiSave}>Save</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
}
export default RAWLAND
