import RAWLAND from "./webpages/radiomod/rawland";
import PUNTONI from "./webpages/radiomod/rooftopPuntoni";
import PALINA from "./webpages/radiomod/rooftopPalina";
import STAFFATO from "./webpages/radiomod/roofTopStaffato";
import RNP from "./webpages/radiomod/rnpPage";
import A from "./webpages/lab/a";
import B from "./webpages/lab/b";
import c from "./webpages/lab/c";
import TECH from "./webpages/efesto/technologies";
import TENANT from "./webpages/efesto/tenants";
import TestForm from "./webpages/lab/testForm";
import RESULT from "./webpages/lab/results";
import NSSMFhome from "./webpages/nssmf/NSSMFhome";
import NSIList from "./webpages/nssmf/NSIList";
import NSSIList from "./webpages/nssmf/NSSIList";
import NFList from "./webpages/nssmf/NFList";
import Eval from "./webpages/radiomod/eval";
import EvalList from "./webpages/radiomod/evalList";
import EvalMatrix from "./webpages/radiomod/evalMatrix";
import EvalMessages from "./webpages/radiomod/evalMessages";
import Threshold from "./webpages/radiomod/threshold";
import Service from "./webpages/creedgeon/service";
import ServiceInstance from "./webpages/creedgeon/serviceInstance";
import Alarms from "./webpages/nssmf/alarms";
// import Kpi from './webpages/nssmf/kpi'
import Performance from './webpages/nssmf/Performance/Performance'
import MapPage from './webpages/creedgeon/MapPage'
import Settings from './webpages/nssmf/settings'
import Template from './webpages/nssmf/Templates/template'
import Flavio from './webpages/lab/flavio'
import Login from './webpages/Login'
import RadioModMap from './webpages/radiomod/MapPage'
import Datacenter from './webpages/creedgeon/datacenter'
import Platform_credential from './webpages/creedgeon/platform_credential'
import Tenant from './webpages/creedgeon/tenant'
import Image_repository from './webpages/creedgeon/image_repository'
import Application from './webpages/creedgeon/application'
import CeleryTest from './webpages/lab/celeryTest'
import Notification from './webpages/nssmf_sym/notification'
import Subscription from './webpages/nssmf_sym/subscription'
import NotifySym from './webpages/nssmf_sym/notifySym'
import Sites from './webpages/efesto/sites'
import PreviousNSSI from './webpages/nssmf/previousNSSI'
import Discovery from './webpages/nssmf_sym/discovery'
import TenantTechnology from './webpages/efesto/tenantTechnology'
import ManagedElement from './webpages/nssmf_sym/managedElement'
import GitlabDashboard from './webpages/lab/gitlabDashboard'
import MedicalTraining from './webpages/medicalTraining/medicalTraining'
import ManualTask from './webpages/ngom/manualTask'
import ManualTasks from './webpages/ngom/manualTasks'
import Sky from "./webpages/sky/Sky";

const routes = [{
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/home` :
    "/home",
  name: "Home Page",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: NSSMFhome,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/map` :
    "/map",
  name: "Map",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: MapPage,
},

{
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/login` :
    "/login",
  name: "Login Legacy",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: Login,
},

{
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/evalList/:id_site` :
    "/evalList/:id_site",
  name: "Eval List",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: EvalList,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/mapRadio` :
    "/mapRadio",
  name: "MapRadio",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: RadioModMap,
},

{
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/evalMatrix/:uuid` :
    "/evalMatrix/:uuid",
  name: "Eval Matrix",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: EvalMatrix,
},

{
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/evalMessages/:uuid` :
    "/evalMessages/:uuid",
  name: "Eval Messages",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: EvalMessages,
},

{
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/eval/:uuid` :
    "/eval/:uuid",
  name: "Eval",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: Eval,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/threshold/:uuid` :
    "/threshold/:uuid",
  name: "Threshold",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: Threshold,
},

{
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/sites` :
    "/sites",
  name: "Sites",
  visibleForRoles: ["admin", "readonly"],
  Component: Sites,
},

{
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/tenantTechnology/:tenant` :
    "/tenantTechnology/:tenant",
  name: "tenantTechnology",
  visibleForRoles: ["admin", "readonly"],
  Component: TenantTechnology,
},

{
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/rawland` :
    "/rawland",
  name: "Rawland",
  visibleForRoles: ["admin", "readonly"],
  Component: RAWLAND,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/rooftopPuntoni` :
    "/rooftopPuntoni",
  name: "Rooftop Puntoni",
  visibleForRoles: ["admin", "readonly"],
  Component: PUNTONI,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/rooftopPalina` :
    "/rooftopPalina",
  name: "Rooftop Palina",
  visibleForRoles: ["admin", "readonly"],
  Component: PALINA,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/roofTopStaffato` :
    "/roofTopStaffato",
  name: "Rooftop Staffato",
  visibleForRoles: ["admin", "readonly"],
  Component: STAFFATO,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/rnpPage/:uuid` :
    "/rnpPage/:uuid",
  name: "RNP",
  visibleForRoles: ["admin", "readonly"],
  Component: RNP,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/page_a` :
    "/page_a",
  name: "Page A",
  visibleForRoles: ["admin", "readonly"],
  Component: A,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/page_b` :
    "/page_b",
  name: "Page B",
  visibleForRoles: ["admin", "readonly"],
  Component: B,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/page_c` :
    "/page_c",
  name: "Page C",
  visibleForRoles: ["admin", "readonly"],
  Component: c,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/technologies` :
    "/technologies",
  name: "Technologies",
  visibleForRoles: ["admin", "readonly"],
  Component: TECH,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/tenants` :
    "/tenants",
  name: "Tenants",
  visibleForRoles: ["admin", "readonly"],
  Component: TENANT,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/testForm` :
    "/testForm",
  name: "TestForm",
  visibleForRoles: ["admin", "readonly"],
  Component: TestForm,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/result` :
    "/result",
  name: "Results",
  visibleForRoles: ["admin", "readonly"],
  Component: RESULT,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/settings/:id?` :
    "/settings/:id?",
  name: "Settings",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: Settings,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/template/:id?` :
    "/template/:id?",
  name: "Template",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: Template,
},

{
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/image_repository` :
    "/image_repository",
  name: "Image Repository",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: Image_repository,
},

{
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/application` :
    "/application",
  name: "Application",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: Application,
},

{
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/service` :
    "/service",
  name: "Service",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: Service,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/serviceInstance` :
    "/serviceInstance",
  name: "Service Instance",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: ServiceInstance,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/nsiList/:id?` :
    "/nsiList/:id?",
  name: "NSI List",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: NSIList,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/nssiList/:id?` :
    "/nssiList/:id?",
  name: "NSSI List",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: NSSIList,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/nfList/:id?` :
    "/nfList/:id?",
  name: "NF List",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: NFList,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/Performance/:id?` :
    "/Performance/:id?",
  name: "KPI",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: Performance,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/alarms/:id?` :
    "/alarms/:id?",
  name: "Alarms",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: Alarms,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/flavio` :
    "/flavio",
  name: "Flavio",
  visibleForRoles: ["admin", "readonly"],
  Component: Flavio,
},

{
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/platform__credential` :
    "/platform__credential",
  name: "Platform Credential",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: Platform_credential,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/datacenter` :
    "/datacenter",
  name: "Datacenter",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: Datacenter,
},

{
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/tenant` :
    "/tenant",
  name: "Tenant",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: Tenant,
},

{
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/celeryTest` :
    "/celeryTest",
  name: "CeleryTest",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: CeleryTest,
},

{
  path: process.env.REACT_APP_ENV === 'prod' ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/notification` : '/notification',
  name: 'notification',
  visibleForRoles: ['admin', 'user', 'readonly'],
  Component: Notification,
}, {
  path: process.env.REACT_APP_ENV === 'prod' ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/subscription` : '/subscription',
  name: 'subscription',
  visibleForRoles: ['admin', 'user', 'readonly'],
  Component: Subscription,
}, {
  path: process.env.REACT_APP_ENV === 'prod' ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/notifySym` : '/notifySym',
  name: 'notifySym',
  visibleForRoles: ['admin', 'user', 'readonly'],
  Component: NotifySym,
}, {
  path: process.env.REACT_APP_ENV === 'prod' ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/previousNSSI/:name?/:id?` : '/previousNSSI/:name?/:id?',
  name: 'previousNSSI',
  visibleForRoles: ['admin', 'user', 'readonly'],
  Component: PreviousNSSI,
}, {
  path: process.env.REACT_APP_ENV === 'prod' ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/discovery` : '/discovery',
  name: 'discovery',
  visibleForRoles: ['admin', 'user', 'readonly'],
  Component: Discovery,
}, {
  path: process.env.REACT_APP_ENV === 'prod' ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/managedElement` : '/managedElement',
  name: 'managedElement',
  visibleForRoles: ['admin', 'user', 'readonly'],
  Component: ManagedElement,
}, {
  path: process.env.REACT_APP_ENV === 'prod' ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/gitlabDashboard` : '/gitlabDashboard',
  name: 'gitlabDashboard',
  visibleForRoles: ['admin', 'user', 'readonly'],
  Component: GitlabDashboard,
}, {
  path: process.env.REACT_APP_ENV === 'prod' ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/medicalTraining` : '/medicalTraining',
  name: 'medicalTraining',
  visibleForRoles: ['admin', 'user', 'readonly'],
  Component: MedicalTraining,
}, {
  path: process.env.REACT_APP_ENV === 'prod' ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/manualTask/:id?` : '/manualTask/:id?',
  name: 'manualTask',
  visibleForRoles: ['admin', 'user', 'readonly'],
  Component: ManualTask,
}, {
  path: process.env.REACT_APP_ENV === 'prod' ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/manualTasks` : '/manualTasks',
  name: 'manualTasks',
  visibleForRoles: ['admin', 'user', 'readonly'],
  Component: ManualTasks,
}, {
  path: process.env.REACT_APP_ENV === "prod" ?
    `${process.env.REACT_APP_CLIENT_PATH_NAME}/sky` :
    "/sky",
  name: "sky",
  visibleForRoles: ["admin", "user", "readonly"],
  Component: Sky,
},
].sort((a, b) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  return 0;
});

export default routes;