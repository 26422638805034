import { v4 as uuidv4 } from 'uuid'

export const onDataChange = () => {}
const doNothing = () => {}
const saveData = () => {}

const cardMainActions = [
  {
    uniqueKey: uuidv4(),
    icon: 'celebration',
    place: 'top',
    type: 'icon',
    label: 'ONLINE',
    onClick: doNothing,
    sx: { cursor: 'unset' },
    tooltip: `General State calculated on Administrative and Operational state values`,
    name: 'cell',
  },
  {
    uniqueKey: uuidv4(),
    icon: 'info',
    place: 'top',
    type: 'link',
    url: './alarms',
    label: 'Alarms',
    onClick: doNothing,
    tooltip: 'Active alarms',
  },
  {
    uniqueKey: uuidv4(),
    icon: 'api',
    place: 'top',
    type: 'link',
    url: './Performance',
    label: 'KPI',
    onClick: doNothing,
    tooltip: `KPIs related this item`,
  },
  {
    icon: 'book', //the icon you want to show
    place: 'top', // the position in the card: one of top/bottom
    label: 'NF Parameters', //a label on the left of the icon
    type: 'dialog', // if 'dialog', on click it will open a dialog
    onClick: doNothing, //to override, if you want to use the onClick function
    uniqueKey: uuidv4(), //leave this in this way
    allowSave: false,
    tooltip: `Parameters setting by RAN domain for this item`,

    dialog: {
      url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkFunction?id=`,
      //the dialog itself
      title: 'Network Function Parameters', //title of the dialog
      style: {
        //style of the dialog: you can style the card, the title, the content and the actions
        card: {},
        title: {
          marginTop: '30px',
          backgroundColor: 'background.light',
          paddingLeft: '6%',
          paddingRight: '6%',
          color: 'primary.light',
          width: '100%',
        },
        content: {},
        actions: {},
      },
      content: {
        //if you don't have a jsonSchema, you can pass the content
        type: 'json', //this will pretty print a json
      },
      tabs: [], //tabs to divide the jsonSchema into many pieces
      onDataChange: doNothing, //to be removed
      buttons: [{ type: 'close' }], //list of buttons at the end of the dialog
      dataAlreadyPresent: true, //if we have already the formdata to pass to the jsonSchemaBox, we set this to true
    },
  },
  {
    uniqueKey: uuidv4(),
    icon: 'refresh',
    place: 'top',
    type: 'icon',
    label: 'Discovery',
    onClick: doNothing,
    tooltip: `Activation for NF data update procedure`,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkFunctionManager?NetworkFunction_id=`,
    messages: {
      //this is used to send messages to the rabbit queue, after the onClick of the icon
      messageList: [
        //the list of messages
        {
          uniqueKey: uuidv4(),
          name: '',
          scheduled: '',
          httpMethod: 'POST',
          url: `${process.env.REACT_APP_QUEUE_ADDRESS}/celery/push/OPERMOI/getMOIattributes`, //specify here the url of the api call
          body: {
            Name: 'getMOIattributes',
            moi_class: 'NSSI',
            moi_id: null,
            ne_id: null,
            Command: 'getMOIattributes',
          },
        },
      ],
    },
  },
  // {
  //   uniqueKey: uuidv4(),
  //   icon: 'delete',
  //   iconSize: 'large',
  //   place: 'bottom',
  //   onClick: doNothing,
  //   tooltip: 'Delete',
  // },
  {
    uniqueKey: uuidv4(),
    icon: 'save',
    iconSize: 'large',
    place: 'bottom',
    onClick: saveData,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkFunction`,
    tooltip: 'Save Changes',
  },
]

const schemaFields = {
  administrativeState: {
    title: 'Administrative State',
    type: 'text',
    md: 4,
    disabled: true,
  },
  operationalState: {
    title: 'Operational State',
    type: 'text',
    md: 4,
    disabled: true,
  },
  name: {
    title: 'NF Name',
    type: 'text',
    md: 12,
    mandatory: true,
    showAsterisk: false,
  },
  functionType: {
    title: 'NF Type',
    type: 'text',
    md: 6,
    disabled: true,
  },
  NetworkSliceSubnet_id: {
    title: 'NSSI',
    type: 'combo',
    md: 4,
    disabled: true,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/NetworkSliceSubnet`,
    displayMember: 'name',
    valueMember: 'id',
  },
  plmn_id: {
    title: 'PLMN',
    type: 'combo',
    md: 6,
    url: `${process.env.REACT_APP_API_ADDRESS}/scalar/nssmf_managed_object/PlmnId`,
    displayMember: 'name',
    valueMember: 'id',
    disabled: true,
  },
  id: {
    title: 'ID',
    type: 'text',
    md: 4,
    disabled: true,
  },
  ManagedElement_id: {
    title: 'Managed Element ID',
    type: 'text',
    md: 4,
    disabled: true,
  },
}

const schemaValues = {
  administrativeState: 'Unlocked',
  operationalState: 'Enabled',
  name: 'CU Milano1',
  functionType: '',
  NSST: 'NSST1',
  plmn_id: 'PLMN1',
  id: '',
}

const cardBoxStyleMain = {
  cardStyle: {
    width: '65vw',
    height: '75vh',
    backgroundColor: 'background.dark',
  },
  titleStyle: {
    marginTop: '30px',
    backgroundColor: 'background.light',
    paddingLeft: '6%',
    paddingRight: '6%',
    color: 'primary.light',
    width: '102%',
  },
  iconStyle: {
    topBox: {
      // border: '1px solid red',
    },
    bottomBox: {
      // border: '1px solid green',
    },
  },
}

const titleDataMain = {
  variant: 'h4',
}

const cardMain = {
  actions: cardMainActions,
  schema: {
    fields: schemaFields,
    values: schemaValues,
  },
  onDataChange,
  style: cardBoxStyleMain,
  title: titleDataMain,
}

export default cardMain
