import React, { useEffect } from 'react'
import axios from 'axios'
import UserService from '../services/UserService'
import { useSnackbar } from '../contexts/snackbarContext'

const _axios = axios.create()

function useHttpService() {
  const snackbar = useSnackbar()

  const configure = () => {
    _axios.interceptors.request.use((config) => {
      if (UserService.isLoggedIn()) {
        const cb = () => {
          if (UserService.getUsername() === 'efesto')
            config.headers.Authorization = `Bearer ${localStorage.getItem(
              'token'
            )}`
          else config.headers.Authorization = `Bearer ${UserService.getToken()}`
          return Promise.resolve(config)
        }
        return UserService.updateToken(cb)
      }
    })
  }

  const getAxiosClient = () => _axios

  const getAPI = (url, callback) => {
    console.log(url)
    if (url == null) return
    _axios
      .get(url)
      .then((res) => {
        console.log(res)
        url.includes('export') && res.data && callback(res.data)
        if (res.data.data) callback(res.data.data)
        else callback([])
      })
      .catch(function (error) {
        console.log(error)
        showSnackbarError(error)
      })
  }

  const downloadAPI = (url, callback, method = 'get', body = {}) => {
    if (url == null) return
    if (method === 'get') {
      _axios
        .get(url, { responseType: 'blob' })
        .then((res) => {
          if (res.data) callback(res.data)
          else callback(null)
        })
        .catch(function (error) {
          showSnackbarError(error)
        })
    }
    if (method === 'post') {
      _axios
        .post(url, body, { responseType: 'blob' })
        .then((res) => {
          if (res.data) callback(res.data)
          else callback(null)
        })
        .catch(function (error) {
          showSnackbarError(error)
        })
    }
  }

  const postAPI = (
    url,
    data,
    callback,
    header = {},
    showSnackbar = true,
    errorCallback = () => {}
  ) => {
    if (url == null) return
    _axios
      .post(url, data)
      .then((res) => {
        console.log(res)
        showSnackbar &&
          snackbar.setSnackbarInfo({
            status: 'success',
            message: 'Your request has been processed successfully',
          })
        if (res.data.data) callback(res.data.data)
        else callback()
      })
      .catch(function (error) {
        showSnackbarError(error)
      })
  }

  const putAPI = (
    url,
    data,
    callback,
    showSnackbar = true,
    errorCallback = () => {}
  ) => {
    if (url == null) return
    _axios
      .put(url, data)
      .then((res) => {
        showSnackbar &&
          snackbar.setSnackbarInfo({
            status: 'success',
            message: 'Your request has been processed successfully',
          })
        if (res.data.data) callback(res.data.data)
        else callback()
      })
      .catch(function (error) {
        showSnackbarError(error)
        errorCallback(error.response.data.message)
      })
  }

  const deleteAPI = (url, data, callback) => {
    if (url == null) return
    _axios
      .delete(url, {
        data,
      })
      .then((res) => {
        snackbar.setSnackbarInfo({
          status: 'success',
          message: 'Your request has been processed successfully',
        })
        if (res.data.data) callback(res.data.data)
        else callback()
      })
      .catch(function (error) {
        showSnackbarError(error)
      })
  }

  const checkIfDataViolatesConstraints = (message) => {
    if (message.includes('violates foreign key constraint')) {
      const table = message.split('on table')[2]
      console.log(table)
      if (table.includes('NetworkSliceSubnet'))
        return 'Cannot delete this element because is used from a Network Slice Subnet'
      if (table.includes('NetworkSlice'))
        return 'Cannot delete this element because is used from a Network Slice'
      if (table.includes('NetworkFunction'))
        return 'Cannot delete this element because is used from a Network Function'
      if (table.includes('RANSliceSubnetProfileInstance'))
        return 'Cannot delete this element because is used from a Network Slice Subnet'
      if (table.includes('ServiceProfileInstance'))
        return 'Cannot delete this element because is used from a Network Slice'
    }
    console.log(message)
    if (message.includes('<null>')) {
      return message
        .replace('unknown', '')
        .replace(':', '')
        .replace('<null>', 'field is required')
    }
    return message
  }

  const showSnackbarError = async (error) => {
    console.error(error)
    if (error?.response?.data instanceof Blob) {
      let errorMessage = await error?.response?.data.text()
      errorMessage = JSON.parse(errorMessage)
      snackbar.setSnackbarInfo({
        status: 'warning',
        message: errorMessage.message,
      })
    } else if (error?.response?.status === 408) {
      if (window.location.pathname.includes('alarms')) {
        snackbar.setSnackbarInfo({
          status: 'error',
          message: `Too many alarms with the current filter. Please change the alarm search range.`,
        })
      }
    } else if (error?.response?.status && error?.response?.data?.message) {
      error.response.data.message = checkIfDataViolatesConstraints(
        error.response.data.message
      )
      snackbar.setSnackbarInfo({
        status: 'error',
        message: `Error ${error.response.status}: ${error.response.data.message}`,
      })
    } else if (error.toString().includes('Network Error')) {
      snackbar.setSnackbarInfo({
        status: 'error',
        message: `A network error occurred. Please check your connection.`,
      })
    } else if (error?.response?.status && error?.response?.data?.message) {
      snackbar.setSnackbarInfo({
        status: 'error',
        message: `Error ${error.response.status}: ${error.response.data.message}`,
      })
    } else if (!error.message.includes(`(reading 'protocol')`)) {
      snackbar.setSnackbarInfo({
        status: 'error',
        message: `An error occurred: please report it to the administrator.`,
      })
    }
  }

  useEffect(() => {
    configure()
  }, [])

  return {
    getAPI,
    postAPI,
    deleteAPI,
    putAPI,
    downloadAPI,
    getAxiosClient,
    configure,
  }
}

export default useHttpService
